import { Select } from '@ui';
import { VolumeOptions } from '@modules/source/SourceTypes';
import { IFormControlProps } from 'react-auto-form';

export const CustomVolumeNameField = ({
  options = [],
  property,
  field,
  form,
  isLoading,
}: CustomVolumeNameFieldProps) => {
  return (
    <Select
      {...field}
      defaultValue={property.defaultValue}
      options={options}
      onChange={(selectedOption: any) => {
        if (property.onChangeCustom) {
          property.onChangeCustom(property.name, selectedOption);
        }

        form.setFieldValue(field.name, selectedOption);
      }}
      loading={isLoading}
    />
  );
};

interface CustomVolumeNameFieldProps extends Exclude<IFormControlProps, 'renderCustomField'> {
  options?: VolumeOptions[];
  isLoading?: boolean;
}

import { renderCellTypedValue } from '@components';
import { ViewerDataFormatsFormValues } from '@modules/viewer/modals/components';
import { InfoIcon, ServerIcon } from '@components/icons';
import { RenderCellProps } from 'react-data-grid';
import { css, CSSObject, Theme } from '@emotion/react';
import { get } from 'lodash';
import { useCallback, useRef, useState } from 'react';

export const ViewerTableCell = ({
  row,
  columnKey,
  pattern,
  extractedItemType,
  dataFormats,
  showTraceIcon,
  onOpenTracedInfo,
  onOpenCellValue,
}: ViewerTableCellProps) => {
  const value = get(row, columnKey, '');

  const refCell = useRef<HTMLDivElement>(null);
  const [isOverflowing, setIsOverflowing] = useState(false);
  const [isHovered, setIsHovered] = useState(false);

  const getPaddingRight = (parent: HTMLElement | null) => {
    if (!parent) return 0;
    const computedStyle = window.getComputedStyle(parent);
    return parseFloat(computedStyle.paddingRight) || 0;
  };

  const checkOverflow = useCallback(() => {
    if (refCell.current && value) {
      const cell = refCell.current;
      const parent = cell.parentElement;
      const paddingRight = getPaddingRight(parent);
      setIsOverflowing(cell.scrollWidth - paddingRight > cell.clientWidth);
    }
  }, [value]);

  const handleMouseEnter = () => {
    setIsHovered(true);
    checkOverflow();
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  const showMask = isHovered && (isOverflowing || Boolean(showTraceIcon));

  return (
    <div onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
      <div ref={refCell} css={cssCellValue(showMask)}>
        {renderCellTypedValue(value, extractedItemType, dataFormats)}
      </div>
      {isHovered && (
        <span css={cssCellButtons}>
          {isOverflowing && <InfoIcon css={cssIcon} onClick={() => onOpenCellValue(value)} />}
          {showTraceIcon && <ServerIcon css={cssIcon} onClick={() => onOpenTracedInfo(row, columnKey, pattern)} />}
        </span>
      )}
    </div>
  );
};

const cssIcon = (theme: Theme): CSSObject => ({
  fontSize: 24,
  color: theme['color-grey-500'],
  cursor: 'pointer',
});

const cssCellValue = (isHovered: boolean): CSSObject => ({
  maskImage: isHovered ? 'linear-gradient(to right, black 60%, rgba(0, 0, 0, 0))' : 'none',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
});

const cssCellButtons = css({
  display: 'flex',
  position: 'absolute',
  right: '0',
  transform: 'translate(-5px, -100%)',
  background: 'var(--rdg-row-hover-background-color)',
  gap: 8,
});

interface ViewerTableCellProps {
  row: RenderCellProps<any>;
  columnKey: string;
  pattern: string;
  extractedItemType: string;
  dataFormats?: ViewerDataFormatsFormValues;
  showTraceIcon?: boolean | string;
  onOpenTracedInfo: (row: RenderCellProps<any>, columnKey: string, pattern: string) => void;
  onOpenCellValue: (value: string) => void;
}

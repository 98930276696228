import { appApi } from '@config/appApi';
import { EnvDataListQueryBase } from '@shared/GlobalTypes';
import { ICDRReportListPaginated } from '../CDRReportTypes';

export const CDRReportApiRoutes = {
  envCDRReportList: `/api/external/env/cdr`,
};

export const CDRReportInvalidations: {} = {};

export const CDRReportApi = appApi.injectEndpoints({
  endpoints: (builder) => ({
    envCDRReportList: builder.query<
      ICDRReportListPaginated,
      EnvDataListQueryBase & { page: number; page_size: number; name?: string }
    >({
      query: (params) => ({
        params: { ...params, analysis_objects: 'true' },
        url: CDRReportApiRoutes.envCDRReportList,
      }),
    }),
  }),
});

export const { useEnvCDRReportListQuery, useLazyEnvCDRReportListQuery } = CDRReportApi;

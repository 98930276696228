import { ModelEditorSchemaEditor } from '@modules/modelEditor/components';
import { Modal, FormLayout, Form, FormItem, Alert, Space, Typography } from '@ui';
import { ModelEditorNodeTransformation } from '@modules/modelEditor/ModelEditorTypes';
import { modelEditorActions } from '@modules/modelEditor/duck/modelEditorSlice';
import {
  selectModelEditorReadOnly,
  selectModelEditorSchemaEditor,
} from '@modules/modelEditor/duck/modelEditorSelectors';
import { exportSchemaData } from '@modules/modelEditor/components/schemaEditor/ModelEditorSchemaEditorUtils';
import { useSourceTableInfoAnalyzer } from '@modules/modelEditor/duck/modelEditorSourceTableInfoAnalyzer';
import { escapeName } from '@modules/modelEditor/duck/modelEditorSparkIntegration';
import { useLazyLineageQuery } from '@modules/model/duck/modelApi';
import { useSelector } from 'react-redux';
import { CSSObject } from '@emotion/react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { TFunction } from 'i18next';
import { useSaveStage } from './modelEditorModalsHooks';

const ModelEditorModalsSchemaSettingsContent = ({ data, t, onClose }: ModelEditorModalsSchemaSettingsContentProps) => {
  const [form] = Form.useForm();
  const readOnly = useSelector(selectModelEditorReadOnly);
  const [getLineage] = useLazyLineageQuery();
  const [nodeErrors, setNodeErrors] = useState<string[]>([]);
  const transformation = useSelector(selectModelEditorSchemaEditor)!;
  const { loading, sourceColumns } = useSourceTableInfoAnalyzer(data.nodeId);
  const { onSubmit, dispatch } = useSaveStage(data.nodeId, onClose);

  const onSave = async () => {
    setNodeErrors([]);
    const validColumns = transformation
      .filter((column) => !column.deleted && (!!column.name || !!column.newName))
      .map((item) => ({
        name: item.newName || item.name || '',
        position: item.index,
        expression: item.expression,
        custom: item.custom,
      }))
      .sort((a, b) => a.name.localeCompare(b.name));

    const errors: string[] = [];

    const isAllDeleted = transformation.filter((column) => column.name)?.every((el) => el.deleted);

    let lineage = {};

    if (isAllDeleted) {
      errors.push(t('schema.errors.deleteWarning'));
    }

    try {
      const statement = transformation
        .filter((column) => !column.deleted && (!!column.name || !!column.newName))
        .map((item) => {
          let name = escapeName(item.name);

          if (item.newName) {
            return `(${item.expression || name}) as ${escapeName(item.newName)}`;
          }
          return item.expression ? `(${item.expression}) as ${name}` : name;
        })
        .join(', ');
      if (statement) {
        const columns = transformation
          .filter((column) => !!column.name)
          .map((column) => `NULL as ${escapeName(column.name)}`)
          .join(', ');
        const previewSql = `select ${statement} from (select ${columns}) as source`.replace(/`/g, '"');
        const virtual_metadata = {
          source: transformation.filter((column) => !!column.name).map((column) => column.name) ?? [],
        };
        lineage = await getLineage({ sql_statement: previewSql, virtual_metadata }).unwrap();
      }
    } catch (e) {
      errors.push(t('schema.errors.failedToBuildLineage'));
    }

    validColumns.forEach((current, index) => {
      if (!current.name) {
        errors.push(t('schema.errors.missingColumnName', { position: current.position + 1 }));
      } else if (current.custom && !current.expression) {
        errors.push(t('schema.errors.missingExpression', { position: current.position + 1 }));
      } else if (index !== 0 && validColumns[index - 1].name === current.name) {
        errors.push(t('schema.errors.duplicatedColumn', { position: current.position + 1, name: current.name }));
      }
    });

    if (errors.length) {
      setNodeErrors(errors);
    } else {
      onSubmit(
        {
          transformation: exportSchemaData(transformation.filter((column) => !!column.name || !!column.newName)),
          lineage,
        },
        () => dispatch(modelEditorActions.destroySchemaEditor()),
      );
    }
  };

  return (
    <FormLayout form={form} onCancel={onClose} onSubmit={onSave} readOnly={readOnly} okText={t('save')}>
      <FormItem shouldUpdate wrapperCol={{ span: 24 }}>
        {() => (
          <ModelEditorSchemaEditor
            tableInfo={sourceColumns[0]}
            initData={data.initData.transformation}
            isLoading={loading}
            readOnly={readOnly}
          />
        )}
      </FormItem>
      {!!nodeErrors.length && (
        <FormItem wrapperCol={{ span: 24 }}>
          <Alert message={<Space direction="vertical" children={nodeErrors} />} type="error" />
        </FormItem>
      )}
    </FormLayout>
  );
};

export const ModelEditorModalsSchemaSettings = ({ open, data, onClose }: ModelEditorModalsSchemaSettingsProps) => {
  const { t } = useTranslation(['model']);

  return (
    <Modal
      css={cssModal}
      open={open}
      onCancel={onClose}
      title={
        <Typography.Title level={4} style={{ padding: '4px 0' }}>
          {t('schema.title')}
        </Typography.Title>
      }
      destroyOnClose
      width="80%"
      footer={null}
      okText={t('save')}
    >
      {open && <ModelEditorModalsSchemaSettingsContent t={t} data={data!} onClose={onClose} />}
    </Modal>
  );
};

const cssModal = (): CSSObject => ({
  top: 20,
  maxHeight: `calc(100vh - 20px)`,
  overflow: 'hidden',
  display: 'flex',
  flexDirection: 'column',
  '& .ant-modal-content': {
    display: 'flex',
    flexDirection: 'column',
    minHeight: 0,
  },
});

interface ModelEditorModalsSchemaSettingsContentProps {
  t: TFunction;
  data: NonNullable<ModelEditorModalsSchemaSettingsProps['data']>;
  onClose: ModelEditorModalsSchemaSettingsProps['onClose'];
}

export interface ModelEditorModalsSchemaSettingsProps {
  open: boolean;
  data?: { nodeId: string; initData: ModelEditorNodeTransformation };
  onClose: () => void;
}

import {
  IAnalysisObjectStackDatasetDeleteProps,
  IStackDatasetObject,
  IStackDatasetObjectListResponse,
} from '@modules/dnaObject/StackDataset/AnalysisObjectStackDatasetTypes';
import { getListOption } from '@shared/utils/Table';
import { DatasetScopeList, DatasetScopeListScope, renderInfoModalContent } from '@modules/dataset/components';
import { Table } from '@ui';
import { useColumnSearch } from '@components/ui/table/tableHooks';
import { ActorAndInfo, ButtonWithConfirmation, InformationModal } from '@components';
import { SupportedEnvs } from '@app/AppTypes';
import { Scope } from '@modules/dataset/DatasetTypes';
import { dateToString } from '@shared/utils/Date';
import { Trash } from '@components/icons';
import { TableColumnsType, TableProps } from 'antd';
import { TFunction } from 'i18next';
import { useMemo, useState } from 'react';

export const StackDatasetObjectList = ({
  data,
  sources,
  loading,
  pagination,
  onChange,
  loadingDelete,
  supportedEnvs,
  onDelete,
  t,
}: AnalysisObjectStackDatasetListProps) => {
  const [studiesDetailsModal, setStudiesDetailsModal] = useState<DatasetScopeListScope>();

  const { getColumnSearchProps, locale } = useColumnSearch<IStackDatasetObject>();

  const sourceListOption = getListOption(sources);

  const columns: TableColumnsType<IStackDatasetObject> = useMemo(
    () =>
      (
        [
          {
            title: t('stackDataset.rootTable.name'),
            dataIndex: 'name',
            ...getColumnSearchProps('name'),
            onFilter: undefined,
          },
          {
            title: t('stackDataset.rootTable.scope'),
            dataIndex: 'object_scope',
            render: (object_scope: Scope) => (
              <DatasetScopeList currentStudyName="-" onMoreBtnClick={setStudiesDetailsModal} scope={object_scope} />
            ),
          },
          {
            title: t('stackDataset.rootTable.source'),
            customKey: 'narrow',
            dataIndex: 'source',
            filters: sourceListOption,
            onFilter: undefined,
            render: (_, record) =>
              record.source && (
                <ActorAndInfo info={supportedEnvs[record.env]?.label || t('na')} actor={record.source} />
              ),
          },
          {
            title: t('stackDataset.rootTable.created'),
            customKey: 'narrow',
            dataIndex: 'linked_at',
            sorter: () => 0,
            sortDirections: ['ascend', 'descend'],
            render: (_, record) => (
              <ActorAndInfo info={dateToString(record.linked_at || record.created_at)} actor={record.created_by} />
            ),
          },
          !!onDelete && {
            width: 100,
            title: t('delete'),
            dataIndex: 'actionDelete',
            render: (_, { id, version_id, name }) => (
              <ButtonWithConfirmation
                icon={<Trash color="lightGrey" />}
                type="text"
                submitFunc={() => onDelete({ id, version_id: version_id!, name })}
                confirmTitle={t('stackDataset.confirmation.title')}
                confirmContent={t('stackDataset.confirmation.description', { name })}
                loading={loadingDelete}
              />
            ),
          },
        ] as TableColumnsType<IStackDatasetObject>
      ).filter(Boolean),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [loadingDelete, sourceListOption],
  );

  return (
    <>
      <Table
        locale={locale}
        columns={columns}
        dataSource={data?.items}
        loading={loading}
        rowKey={(item) => item.id}
        onChange={onChange}
        tableLayout="fixed"
        scroll={{ x: 900 }}
        pagination={pagination}
      />
      {studiesDetailsModal && (
        <InformationModal
          message={renderInfoModalContent(studiesDetailsModal)}
          onClose={() => setStudiesDetailsModal(null)}
          width="500px"
          autoHeightMin={50}
        />
      )}
    </>
  );
};

export interface AnalysisObjectStackDatasetListProps {
  data?: IStackDatasetObjectListResponse;
  sources: string[];
  pagination?: TableProps<IStackDatasetObject>['pagination'];
  onChange?: TableProps<IStackDatasetObject>['onChange'];
  loading?: boolean;
  loadingDelete?: boolean;
  onDelete?: (data: IAnalysisObjectStackDatasetDeleteProps) => void;
  supportedEnvs: SupportedEnvs;
  t: TFunction;
}

import { useLocationResolver } from '@routes/routesHooks';
import { StudyResolver } from '@routes/study/RoutesStudy';
import { useCallback } from 'react';
import { INodeRendererProps } from 'react-accessible-treeview';
import { useStoreAggregation } from './tableListSiderHooks';
import { TableListSiderTemplate, TableListSiderTreeItem } from './TableListSiderTemplate';
import { ITableListSiderProps } from './TableListSiderTypes';

export const TableListSider = (props: ITableListSiderProps) => {
  const { state: locationState } = useLocationResolver<StudyResolver['dataViewer']['params'], { external?: boolean }>();
  const isExternalRoute = locationState?.external;
  const { treeData, globalStudy, tablesQuery, tablesQueryTreeKey } = useStoreAggregation(props.draggable);

  const customTitle = useCallback(
    (node: INodeRendererProps, draggable?: boolean) => (
      <TableListSiderTreeItem
        node={node}
        draggable={draggable}
        refetch={props.refetch}
        onSelectTable={props.onSelectTable}
        selectedTable={props.selectedTable}
        isExternalRoute={isExternalRoute}
      />
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [tablesQuery.isFetching, props.selectedTable, props.onSelectTable],
  );

  const reloadTables = () => {
    tablesQuery.refetch();
    if (props.selectedTable) {
      props.refetch();
    }
  };

  return (
    <TableListSiderTemplate
      {...props}
      globalStudyId={globalStudy?.id!}
      treeData={treeData}
      tablesQueryTreeKey={tablesQueryTreeKey}
      titleRender={customTitle}
      isErrorLoadingTables={tablesQuery.isError}
      reloadTables={reloadTables}
      isLoadingData={tablesQuery.isFetching}
    />
  );
};

import { DraggableModal } from '@ui';
import { LibraryStatus } from '@modules/library/root/duck/libraryConstants';
import { prepareLibraryPsc } from '@modules/library/psc/duck/libraryPscUtils';
import { IAnalysisObjectPscQuery } from '@modules/library/analysisObjects/psc/AnalysisObjectPscTypes';
import { ELibraryEntityNames, ELibrarySourceType } from '@modules/library/root/LibraryTypes';
import { usePscColumns } from '@modules/library/psc/duck/libraryPscHooks';
import { ILibraryImportPscProps, LibraryImportPsc } from '@modules/library/psc/components/LibraryImportPsc';
import { useCopyAnalysisObjectPscMutation } from '@modules/library/analysisObjects/psc/duck/analysisObjectPscApi';
import { useTranslation } from 'react-i18next';
import { TFunction } from 'i18next';
import { CSSObject } from '@emotion/react';

const AnalysisObjectPscModalsCopyPscContent = ({ onClose, t }: IAnalysisObjectPscModalsCopyPscContentProps) => {
  const { pscColumns, locale } = usePscColumns();
  const [copyPsc] = useCopyAnalysisObjectPscMutation();

  const onImport: ILibraryImportPscProps['onImport'] = async (values, { kind, systemInfo }) => {
    await copyPsc({
      data: prepareLibraryPsc(values, kind, systemInfo) as IAnalysisObjectPscQuery['data'],
      source: ELibrarySourceType.Study === kind ? 'study' : undefined,
    }).unwrap();
  };

  return (
    <LibraryImportPsc
      onClose={onClose}
      columns={pscColumns}
      locale={locale}
      kind={ELibraryEntityNames.Psc}
      onImport={onImport}
      libraryStatuses={[LibraryStatus.Active]}
      hideOverwriteHandles
      onlyCurrentEnv
    />
  );
};

export const AnalysisObjectPscModalsCopyPsc = ({ open, data, onClose }: IAnalysisObjectPscModalsCopyPscProps) => {
  const { t } = useTranslation(['libraryPsc']);

  return (
    <DraggableModal
      css={cssModal}
      width={800}
      open={open}
      onCancel={onClose}
      title={t('copyForm.title')}
      footer={null}
      destroyOnClose
      footerInContent={true}
    >
      <AnalysisObjectPscModalsCopyPscContent data={data} onClose={onClose} t={t} />
    </DraggableModal>
  );
};

const cssModal = (): CSSObject => ({
  '& .ant-modal-content': {
    minWidth: 800,
  },
});

export interface IAnalysisObjectPscModalsCopyPscProps {
  open: boolean;
  data: any;
  onClose: () => void;
}

interface IAnalysisObjectPscModalsCopyPscContentProps
  extends Pick<IAnalysisObjectPscModalsCopyPscProps, 'data' | 'onClose'> {
  t: TFunction;
}

import {
  useRetrySnapshotMutation,
  useSnapshotListQuery,
  useSnapshotSourceTableListQuery,
} from '@modules/snapshot/duck/snapshotApi';
import { ITableFilterItemWithDescription, PageTemplateSimple, PropertiesView } from '@components';
import { Button, Space, Tag, Typography, notification } from '@ui';
import { ViewerGroupType } from '@modules/viewer/ViewerTypes';
import { useLocationResolver } from '@routes/routesHooks';
import { StudyResolver } from '@routes/study/RoutesStudy';
import { getFormattedDatetime } from '@shared/utils/Date';
import { beautifyUsername } from '@shared/utils/common';
import { useStudyPermissions } from '@modules/user/duck/userHooks';
import routes from '@routes';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useMemo } from 'react';
import { css } from '@emotion/react';
import { ISnapshot, SNAPSHOT_STATUS } from '../SnapshotTypes';
import { SnapshotTableList } from '../components/SnapshotTableList';
import { SnapshotCategoryOptionHelper } from '../duck/snapshotUtils';
import { EMPTY_SUBSTUDY_VALUE } from '../duck/snapshotConstants';

const defaultSnapshotTables: [] = [];

export const SnapshotViewPage = () => {
  const { t } = useTranslation(['snapshot']);
  const navigate = useNavigate();
  const {
    params: { snapshotId, studyId },
    state: preloadedSnapshot,
  } = useLocationResolver<StudyResolver['snapshots']['view']['params'], ISnapshot>();
  const snapshotListQuery = useSnapshotListQuery();
  const snapshotSourceTableListQuery = useSnapshotSourceTableListQuery();
  const [retrySnapshot, retrySnapshotQuery] = useRetrySnapshotMutation();
  const snapshot = snapshotListQuery.data?.find((item) => item.id === snapshotId) || preloadedSnapshot;
  const snapshotTables = snapshot?.tablesDetails || defaultSnapshotTables;
  const {
    userPermissions: { canSnapshotsInsert },
  } = useStudyPermissions();

  const onBack = () => navigate(routes.study.snapshots.root.resolver({ studyId }));
  const onRetry = () => {
    retrySnapshot({
      description: snapshot?.description,
    })
      .unwrap()
      .then(() => {
        notification.success({
          message: t('createPage.successMessageCreate', { name: snapshot?.description }),
        });
      })
      .catch(({ data }) => {
        notification.error({
          message: data.userMsg,
        });
      });
  };

  const data = useMemo(() => {
    if (snapshotTables.length) {
      const invertedSnapshotTables: Record<string, ISnapshot['tablesDetails'][0]> = snapshotTables.reduce(
        (acc, item) => ({ ...acc, [item.tableName]: item }),
        {},
      );

      const storeListResult: Array<ITableFilterItemWithDescription> = [];
      const result = (snapshotSourceTableListQuery.data || []).reduce((acc, item) => {
        item.tables?.forEach((tableName) => {
          const storeTableName = tableName.split('.')[1];
          if (invertedSnapshotTables[storeTableName]) {
            if (
              !storeListResult.some(
                (_item) => SnapshotCategoryOptionHelper.getOptionValue(item.name, item.type) === _item.value,
              )
            ) {
              storeListResult.push(SnapshotCategoryOptionHelper.getOption(item.name, item.type));
            }
            acc.push({
              ...invertedSnapshotTables[storeTableName],
              store: item.name,
              type: item.type,
            });
          }
        });
        return acc;
      }, [] as Array<ISnapshot['tablesDetails'][0] & { store: string; type: ViewerGroupType }>);

      return {
        tables: result,
        storeList: storeListResult,
      };
    }

    return {
      tables: [],
      storeList: [],
    };
  }, [snapshotSourceTableListQuery.data, snapshotTables]);

  const mayRetry = data.tables.some(
    (table) => table.status === SNAPSHOT_STATUS.FAILED || table.status === SNAPSHOT_STATUS.NEW,
  );

  const detailsMap = [
    {
      key: t('createPage.name'),
      value: snapshot?.description,
    },
    {
      key: t('createPage.creationDate'),
      value: getFormattedDatetime(snapshot?.createdAt, { format: 'YYYY-MM-DD HH:mm:ss [GMT]' }),
    },
    {
      key: t('viewPage.createdBy'),
      value: beautifyUsername(snapshot?.created_by),
    },
    {
      key: t('viewPage.subStudy'),
      value:
        Array.isArray(snapshot?.subStudies) && snapshot.subStudies.length
          ? snapshot.subStudies?.map((subStudy) => <Tag children={subStudy || EMPTY_SUBSTUDY_VALUE} />)
          : t('na'),
    },
    {
      key: t('viewPage.cutoffDate'),
      value: getFormattedDatetime(snapshot?.cutoffDate, { format: 'YYYY-MM-DD HH:mm:ss [GMT]' }),
    },
  ];

  const isLoading =
    snapshotSourceTableListQuery.isFetching || snapshotListQuery.isFetching || retrySnapshotQuery.isLoading;

  return (
    <PageTemplateSimple
      hideTitleSkeleton
      title={{
        children: t('viewPage.title'),
        refetch: snapshotListQuery.refetch,
        extra: (
          <Space>
            {canSnapshotsInsert && mayRetry && (
              <Button loading={retrySnapshotQuery.isLoading} children={t('retry')} onClick={onRetry} />
            )}
            <Button children={t('back')} onClick={onBack} />
          </Space>
        ),
      }}
      content={{ isLoading: isLoading && !data.tables.length }}
    >
      {!!data.tables.length && (
        <>
          <PropertiesView data={detailsMap} />
          <br />
          <SnapshotTableList data={data.tables} loading={snapshotListQuery.isFetching} storeList={data.storeList} />
        </>
      )}
      {!data.tables.length && !isLoading && (
        <div css={cssEmptyBox}>
          <Typography.Title type="secondary">{t('viewPage.contentEmpty')}</Typography.Title>
        </div>
      )}
    </PageTemplateSimple>
  );
};

const cssEmptyBox = css({
  width: '100%',
  height: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
});

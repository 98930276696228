import { ELibraryEntityKind, ELibrarySourceType } from '@modules/library/root/LibraryTypes';
import { TableComponentProps } from '@modules/library/analysisPackage/modals/components';
import { useTablePaginationState } from '@components/ui/table/tableHooks';
import { IAnalysisPackage } from '@modules/library/analysisPackage/AnalysisPackageTypes';
import { initialTabPage } from '@modules/library/analysisPackage/modals';
import { IBaseColumnProps, ObjectTable } from '@shared/components/ObjectTable';
import { useAnalysisObjectCDRReportListQuery } from '@modules/library/analysisObjects/duck/analysisObjectApi';
import { useCDRReportObjectColumns } from '@modules/dnaObject/CDR/duck/cdrReportObjectHooks';
import { prepareCDRReportTableData } from '@modules/dnaObject/CDR/duck/cdrReportUtils';
import { ICDRReportObject } from '@modules/dnaObject/CDR/CDRObjectTypes';
import { TableColumnsType } from 'antd';

export const CdrObjects = ({
  kind,
  isView,
  data,
  analysisPackageById = [],
  selectedTableItems,
  setSelectedTableItems,
  analysisPackageByIdFetching,
}: CdrAnalysisObjectsProps) => {
  const { paginationState, getPagination, preparedFilters, onTableChange } = useTablePaginationState(initialTabPage);
  const { cdrReportColumns, locale: cdrLocale } = useCDRReportObjectColumns(true);

  const cdrAnalysisObjects = useAnalysisObjectCDRReportListQuery(
    { page: paginationState.current - 1, page_size: initialTabPage.pageSize, detailed: '1', ...preparedFilters },
    { skip: isView },
  );

  const tableDataCdr =
    ((isView && data ? analysisPackageById : cdrAnalysisObjects.data?.items) as ICDRReportObject[]) || [];
  const totalItemsCdr = isView && data ? analysisPackageById.length : cdrAnalysisObjects.data?.totalItems;

  return (
    <ObjectTable
      kind={kind}
      columns={cdrReportColumns as TableColumnsType<IBaseColumnProps>}
      locale={cdrLocale}
      tableDataFetching={cdrAnalysisObjects.isFetching || analysisPackageByIdFetching}
      tableData={prepareCDRReportTableData({
        data: tableDataCdr,
        sourceType: ELibrarySourceType.Library,
      })}
      totalItems={totalItemsCdr}
      selectedTableItems={selectedTableItems}
      setSelectedTableItems={setSelectedTableItems}
      hideCheckboxes={isView}
      getPagination={getPagination}
      onTableChange={onTableChange}
    />
  );
};

interface CdrAnalysisObjectsProps extends TableComponentProps {
  analysisPackageById?: IAnalysisPackage['objects'][ELibraryEntityKind.CDR];
}

import { useColumnSearch } from '@components/ui/table/tableHooks';
import { ICDRReport } from '@modules/cdrReport/CDRReportTypes';
import { getReportVersionLabel } from '@shared/utils/common';
import { TableColumnsType } from 'antd';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { ICDRReportObject, IPrepareCDRReportTableData } from '../CDRObjectTypes';

export const useCDRReportObjectColumns = (isDnAObject: boolean) => {
  const { getColumnSearchProps, locale } = useColumnSearch<ICDRReportObject>();
  const { t } = useTranslation(['dnaObject']);

  const cdrReportColumns: TCDRReportColumns = useMemo(
    () =>
      (
        [
          {
            title: t('cdrReportObject.rootTable.name'),
            dataIndex: 'name',
            key: 'name',
            ...getColumnSearchProps('name'),
            onFilter: undefined,
          },
          {
            width: 150,
            key: 'sourceVersion',
            title: t('cdrReportObject.rootTable.sourceVersion'),
            dataIndex: 'sourceVersion',
            render: (_, record) =>
              isDnAObject
                ? getReportVersionLabel((record as TCDRReportObjectColumn).rawData.configuration?.version_label)
                : getReportVersionLabel((record as TCDRReportColumn).rawData.version_label),
          },
          isDnAObject && {
            width: 150,
            key: 'libraryVersion',
            title: t('cdrReportObject.rootTable.libraryVersion'),
            dataIndex: 'version',
            render: (_, record: TCDRReportObjectColumn) => record.libraryVersion ?? '-',
          },
          isDnAObject && {
            width: 160,
            title: t('cdrReportObject.rootTable.source'),
            key: 'source',
            dataIndex: 'source',
            render: (_, record: TCDRReportObjectColumn) => record.rawData.source || '-',
          },
        ] as TCDRReportColumns
      ).filter(Boolean),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isDnAObject, t],
  );
  return { cdrReportColumns, locale };
};

type TCDRReportObjectColumn = IPrepareCDRReportTableData<ICDRReportObject>[0];

type TCDRReportColumn = IPrepareCDRReportTableData<ICDRReport>[0];

type TCDRReportColumns = TableColumnsType<TCDRReportObjectColumn | TCDRReportColumn>;

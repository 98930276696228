import { Loader, PageNavigation, PageNavigationProps } from '@components';
import { Button, Modal, Space, Table, TableExpandableConfig, Tooltip } from '@ui';
import { useDatabricksFilesListQuery } from '@modules/source/duck/sourceApi';
import { FilesPayload } from '@modules/source/SourceTypes';
import { humanFileSize } from '@shared/utils/File';
import { dateToString } from '@shared/utils/Date';
import { getSortedData, isAllowedFileExtention, removeVolumeNameFromPath } from '@modules/source/duck/sourceUtils';
import { TableTree } from '@modules/source/modals/components/save/VolumeFiles';
import { FolderOpenOutlined, FolderOutlined } from '@ant-design/icons';
import { CSSObject, Theme } from '@emotion/react';
import { TableColumnsType, TableProps } from 'antd';
import { Key, useMemo, useState } from 'react';
import { TFunction } from 'i18next';

const VolumeFilesSelectorContent = ({ files, setFiles, volumeName, onClose, t }: VolumeFilesSelectorContentProps) => {
  const [selectedFiles, setSelectedFiles] = useState<TableTree[]>([]);
  const [expandedKeys, setExpandedKeys] = useState<Key[]>([]);
  const [loadingKey, setLoadingKey] = useState<string>('');
  const [breadcrumbs, setBreadcrumbs] = useState<PageNavigationProps['breadcrumbs']>([
    {
      label: volumeName,
      key: `/${volumeName}`,
      click: () => goToBreadcrumb(`/${volumeName}`),
    },
  ]);

  const latestBreadcrumbsKey = breadcrumbs.at(-1)?.key ?? '';
  const latestBreadcrumbsLabel = breadcrumbs.at(-1)?.label ?? '';
  const pathPrepared =
    latestBreadcrumbsKey && latestBreadcrumbsKey !== breadcrumbs[0].key
      ? removeVolumeNameFromPath(latestBreadcrumbsKey, volumeName)
      : undefined;

  const filesQuery = useDatabricksFilesListQuery(
    { volume_name: volumeName, path: pathPrepared },
    { skip: !breadcrumbs.length },
  );

  const tableTreeData = useMemo(
    () =>
      filesQuery.data?.map((item) => ({
        ...item,
        ...(item.is_directory ? { children: [] } : {}),
        key: item.path,
        parent_folder_path: latestBreadcrumbsKey,
      })) || [],
    [filesQuery.data],
  );

  const scrollY = tableTreeData.length > 11 ? 41 * 11 : undefined;

  const goToBreadcrumb = async (key: string) => {
    setLoadingKey('');
    setExpandedKeys([]);
    setSelectedFiles([]);
    setBreadcrumbs((prev) => prev.slice(0, prev.findIndex((b) => b.key === key) + 1));
  };

  const expandIcon: Required<TableExpandableConfig<TableTree>>['expandIcon'] = ({ expanded, onExpand, record }) => {
    if (record.is_directory) {
      if (expanded) {
        return <FolderOpenOutlined css={cssFolderIcon} onClick={(e) => onExpand(record, e)} />;
      } else {
        return <FolderOutlined css={cssFolderIcon} onClick={(e) => onExpand(record, e)} />;
      }
    }
  };

  const handleExpand = async (expanded: boolean, record: TableTree) => {
    setLoadingKey(record.key);
    setSelectedFiles([]);
    if (expanded) {
      setBreadcrumbs((prev) => [
        ...prev,
        { label: record.name, key: record.key, click: () => goToBreadcrumb(record.key) },
      ]);
    } else {
      setBreadcrumbs((prev) => prev.filter((b) => b.key !== record.key));
    }

    setExpandedKeys((prevKeys) =>
      expanded ? [...new Set([...prevKeys, record.key as Key])] : prevKeys.filter((k) => k !== record.key),
    );
  };

  const rowSelection: TableProps<TableTree>['rowSelection'] = {
    onChange: (_, selectedRows) => {
      setSelectedFiles(selectedRows);
    },
    getCheckboxProps: (record) => {
      const isDisabled = !record.is_directory && !isAllowedFileExtention(record.name);
      return {
        disabled: isDisabled,
      };
    },
    renderCell: (_, record, index, node) => {
      const isDisabled = !record.is_directory && !isAllowedFileExtention(record.name);
      return isDisabled ? <Tooltip title={t('filesModal.extensionTooltip')}>{node}</Tooltip> : node;
    },
  };

  const onHandlerAddingFiles = () => {
    const latestBreadcrumbsClearedKey =
      latestBreadcrumbsLabel !== volumeName ? latestBreadcrumbsKey.slice(1, -1) : latestBreadcrumbsKey.slice(1);

    const selectedFolderKeys = selectedFiles.filter((el) => el.is_directory).map((el) => el.key.slice(1, -1));
    const selectedFileKeys = selectedFiles.filter((el) => !el.is_directory).map((el) => el.key.slice(1));

    const isExist = files.some((el) => el.parent_folder_path === latestBreadcrumbsClearedKey);

    const newDirectory = {
      parent_folder_path:
        latestBreadcrumbsLabel !== volumeName ? latestBreadcrumbsKey.slice(1, -1) : latestBreadcrumbsKey.slice(1),
      selected_folders: selectedFolderKeys,
      selected_files: selectedFileKeys,
    };

    const data = isExist
      ? files.map((el) =>
          el.parent_folder_path === latestBreadcrumbsClearedKey
            ? {
                ...el,
                selected_folders: [...new Set([...el.selected_folders, ...selectedFolderKeys])],
                selected_files: [...new Set([...el.selected_files, ...selectedFileKeys])],
              }
            : el,
        )
      : [...files, newDirectory];

    const sortedData = getSortedData(data);

    setFiles(sortedData);
    onClose();
  };

  const footerActions = (
    <div css={cssFooter}>
      <Button key="close" onClick={onClose} size="large">
        {t('cancel')}
      </Button>
      <Button type="primary" key="save" size="large" disabled={!selectedFiles.length} onClick={onHandlerAddingFiles}>
        {t('submit')}
      </Button>
    </div>
  );

  const columns: TableColumnsType<TableTree> = [
    {
      title: t('filesModal.table.name'),
      dataIndex: 'name',
      key: 'name',
      render: (name, record) => {
        if (loadingKey === record.path && filesQuery.isFetching) {
          return (
            <Space>
              {name}
              <Loader mode="default" size="small" />
            </Space>
          );
        }
        return name;
      },
    },
    {
      title: t('filesModal.table.size'),
      dataIndex: 'file_size',
      key: 'file_size',
      width: 100,
      render: (size) => (size ? humanFileSize(size) : ''),
    },
    {
      title: t('filesModal.table.lastModified'),
      dataIndex: 'last_modified',
      width: 200,
      key: 'last_modified',
      render: (lastModified) => (lastModified ? dateToString(lastModified / 1000, false, '') : ''),
    },
  ];

  return (
    <>
      <div css={cssTableContainer}>
        <PageNavigation breadcrumbs={breadcrumbs} hideBackBtn />
        <Table
          css={cssTable}
          size="small"
          rowKey="key"
          columns={columns}
          rowSelection={{
            columnWidth: 48,
            type: 'checkbox',
            ...rowSelection,
            selectedRowKeys: selectedFiles.map((el) => el.key),
          }}
          dataSource={tableTreeData}
          expandable={{
            expandedRowKeys: expandedKeys,
            onExpand: handleExpand,
            showExpandColumn: true,
            rowExpandable: (record) => record.children.length > 0,
            expandIcon,
          }}
          tableLayout="fixed"
          scroll={scrollY ? { y: scrollY } : undefined}
          pagination={false}
          loading={!loadingKey && filesQuery.isFetching}
        />
      </div>
      {footerActions}
    </>
  );
};

export const VolumeFilesSelector = ({ open, onClose, files, setFiles, volumeName, t }: VolumeFilesSelectorProps) => {
  return (
    <Modal
      css={cssModal}
      open={open}
      title={t('filesModal.title')}
      destroyOnClose
      width={1000}
      footer={null}
      onCancel={onClose}
    >
      <VolumeFilesSelectorContent files={files} setFiles={setFiles} volumeName={volumeName} onClose={onClose} t={t} />
    </Modal>
  );
};

const cssTable = (theme: Theme): CSSObject => ({
  '.ant-table-body': {
    scrollbarWidth: 'thin',
    scrollbarColor: `${theme['color-grey-300']} transparent`,
  },
});

const cssTableContainer = () => ({
  overflow: 'auto',
  height: 'calc(100vh - 360px)',
  width: '100%',
  marginTop: 16,
});

const cssFolderIcon = () => ({
  marginRight: 8,
});

const cssModal = (): CSSObject => ({
  top: 20,
  minWidth: 500,
  maxHeight: `calc(100vh - 20px)`,
  overflow: 'hidden',
  display: 'flex',
  flexDirection: 'column',
  '& .ant-modal-content': {
    display: 'flex',
    flexDirection: 'column',
    minHeight: 0,
  },
});

const cssFooter = (): CSSObject => ({
  display: 'flex',
  justifyContent: 'end',
  columnGap: 12,
  alignSelf: 'self-end',
});

interface VolumeFilesSelectorProps {
  open: boolean;
  onClose: () => void;
  files: FilesPayload[];
  setFiles: (values: FilesPayload[]) => void;
  volumeName: string;
  t: TFunction;
}

type VolumeFilesSelectorContentProps = Omit<VolumeFilesSelectorProps, 'open'>;

import { useTablePaginationState } from '@components/ui/table/tableHooks';
import { initialPage } from '@shared/utils/common';
import { libraryObjectActions } from '@modules/library/libraryObject/duck/libraryObjectSlice';
import { ELibraryObjectModalsType } from '@modules/library/libraryObject/libraryObjectTypes';
import { PageTemplateSimple } from '@components';
import { Button, notification } from '@ui';
import { selectGlobalLibrary } from '@app/duck/appSelectors';
import { useSupportedEnvsQuery } from '@app/duck/appApi';
import { LibraryStatus } from '@modules/library/root/duck/libraryConstants';
import { useAppPermissions } from '@modules/user/duck/userHooks';
import { ISQLLabObject } from '@modules/dnaObject/SQLLab/SQLLabObjectTypes';
import { SQLLabObjectList, SQLLabObjectListProps } from '@modules/dnaObject/SQLLab/components/SQLLabObjectList';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useLibraryObjectDeleteSqlLabMutation, useLibraryObjectSqlLabListQuery } from '../duck/libraryObjectApi';

export const LibraryObjectSqlLabRootPage = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation(['libraryObject']);
  const supportedEnvsQuery = useSupportedEnvsQuery();
  const globalLibrary = useSelector(selectGlobalLibrary);
  const { paginationState, getPagination, onTableChange, preparedFilters, preparedSorter } =
    useTablePaginationState<ISQLLabObject>(initialPage);
  const sqlLabReportQuery = useLibraryObjectSqlLabListQuery({
    detailed: '0',
    page: paginationState.current - 1,
    page_size: paginationState.pageSize,
    ...preparedFilters,
    ...preparedSorter,
  });
  const [deleteSqlLab, deleteSqlLabQuery] = useLibraryObjectDeleteSqlLabMutation();

  const {
    appPermissions: { canGlSloInsert, canGlLibraryUpdate, canGlSloDelete },
  } = useAppPermissions();

  const pagination = getPagination(sqlLabReportQuery.data?.totalItems);

  const copySqlLab = () => dispatch(libraryObjectActions.pushModal({ type: ELibraryObjectModalsType.copySqlLab }));

  const onDelete: SQLLabObjectListProps['onDelete'] = async (value) => {
    const { id, name } = value;
    try {
      await deleteSqlLab(id).unwrap();
      notification.success({ message: t('sqlLab.confirmation.successMessage', { name }) });
    } catch (e) {
      console.error(e);
      notification.error({ message: t('sqlLab.confirmation.errorMessage', { name }) });
    }
  };

  const libraryIsEditable = canGlLibraryUpdate && globalLibrary?.status === LibraryStatus.Development;

  return (
    <PageTemplateSimple
      title={{
        level: 2,
        children: t('sqlLab.pageRootName'),
        refetch: sqlLabReportQuery.refetch,
        extra: libraryIsEditable && canGlSloInsert && <Button children={t('import')} onClick={copySqlLab} />,
      }}
      content={{
        isLoading: sqlLabReportQuery.isLoading && !sqlLabReportQuery.data,
        errorText: t('sqlLab.loadingError'),
        error: sqlLabReportQuery.error,
      }}
    >
      {!sqlLabReportQuery.isLoading && (
        <SQLLabObjectList
          data={sqlLabReportQuery.data}
          onChange={onTableChange}
          onDelete={libraryIsEditable && canGlSloDelete ? onDelete : undefined}
          pagination={pagination}
          loading={sqlLabReportQuery.isFetching}
          loadingDelete={deleteSqlLabQuery.isLoading}
          supportedEnvs={supportedEnvsQuery.data || {}}
          libraryId={globalLibrary?.id}
          t={t}
        />
      )}
    </PageTemplateSimple>
  );
};

import { Divider, Space, Tag, Typography } from '@ui';
import {
  DatasetsIcon,
  DeploymentIcon,
  DiscoveryIcon,
  ExportIcon,
  ImportIcon,
  MigrationIcon,
  StackIcon,
} from '@components/icons';
import { ITask, TaskType } from '@modules/task/TaskTypes';
import dayjs from 'dayjs';
import { CSSObject, Theme } from '@emotion/react';
import { capitalize } from 'lodash';
import { SyncOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';

const IconComponent = ({ task, iconTypesMap }: { task: ITask; iconTypesMap: Record<string, any> }) => {
  const Component = iconTypesMap[task.name];
  if (Component) {
    return <Component css={{ fontSize: 24 }} />;
  }
  return <>N/A</>;
};

export const TaskItem = ({ item }: { item: ITask }) => {
  const { t } = useTranslation(['shared']);

  const fileName = (item.file_name?.match(/[^/]+$/) ?? '')?.[0];

  const iconTypesMap = {
    [TaskType.Deployment]: DeploymentIcon,
    [TaskType.Discovery]: DiscoveryIcon,
    [TaskType.Import]: ImportIcon,
    [TaskType.Export]: ExportIcon,
    [TaskType.Datasets]: DatasetsIcon,
    [TaskType.Stack]: StackIcon,
    [TaskType.Migration]: MigrationIcon,
  };

  return (
    <Space key={item.id} css={cssList} direction="vertical" full>
      <Space full justify="space-between" css={{ paddingTop: 8 }}>
        <Space css={cssTitleContainer}>
          <IconComponent task={item} iconTypesMap={iconTypesMap} />
          <Typography.Text>{capitalize(item.name) || t('task.item.unknown')}</Typography.Text>
          <Divider type="vertical" css={cssDivider} />
          <Typography.Text type="secondary" css={cssTitle}>
            {t('task.item.study')}: {item.study_name}
          </Typography.Text>
        </Space>

        <Tag color="processing" icon={<SyncOutlined spin />}>
          {t('task.item.running')}
        </Tag>
      </Space>
      <Space css={{ gap: 4, paddingBottom: 16 }} direction="vertical" full>
        {item?.package_version && (
          <Typography.Text type="secondary" css={cssTitle}>
            {t('task.item.packageVersion')}: {item?.package_version}
          </Typography.Text>
        )}
        {item?.file_name && (
          <Typography.Text type="secondary" css={cssTitle}>
            {t('task.item.file')}: {fileName}
          </Typography.Text>
        )}
        <Typography.Text type="secondary" css={cssTitle}>
          {dayjs(item.started_at).fromNow()}
        </Typography.Text>
      </Space>
    </Space>
  );
};

const cssDivider = (theme: Theme): CSSObject => ({
  borderInlineStart: `1px solid ${theme['color-grey-600']}`,
});

const cssList = (theme: Theme): CSSObject => ({
  width: 630,
  borderBottom: `1px solid ${theme.colorBorder}`,

  '&:last-child': {
    borderBottom: 'none',
  },
});

const cssTitle = (): CSSObject => ({
  fontSize: 14,
});

const cssTitleContainer = (): CSSObject => ({
  '&& .ant-space-item:last-child': {
    flex: 1,
  },
});

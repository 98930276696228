import { DraggableModal } from '@ui';
import { useModelColumns } from '@modules/library/model/duck/libraryModelHooks';
import { LibraryStatus } from '@modules/library/root/duck/libraryConstants';
import { ELibraryEntityNames, ELibrarySourceType } from '@modules/library/root/LibraryTypes';
import { useCopyAnalysisObjectModelMutation } from '@modules/library/analysisObjects/model/duck/analysisObjectModelApi';
import { ILibraryImportModelProps, LibraryImportModel } from '@modules/library/model/components/LibraryImportModel';
import { IAnalysisObjectModelQuery } from '@modules/library/analysisObjects/model/AnalysisObjectModelTypes';
import { prepareLibraryModel } from '@modules/library/model/duck/libraryModelUtils';
import { useTranslation } from 'react-i18next';
import { TFunction } from 'i18next';
import { CSSObject } from '@emotion/react';

const AnalysisObjectModelModalsCopyModelContent = ({ onClose, t }: IAnalysisObjectModelModalsCopyModelContentProps) => {
  const { modelColumns, locale } = useModelColumns();
  const [copyModel] = useCopyAnalysisObjectModelMutation();

  const onImport: ILibraryImportModelProps['onImport'] = async (values, { kind, systemInfo }) => {
    await copyModel({
      data: prepareLibraryModel(values, kind, systemInfo) as IAnalysisObjectModelQuery['data'],
      source: ELibrarySourceType.Study === kind ? 'study' : undefined,
    }).unwrap();
  };

  return (
    <LibraryImportModel
      onClose={onClose}
      columns={modelColumns}
      locale={locale}
      kind={ELibraryEntityNames.Model}
      onImport={onImport}
      libraryStatuses={[LibraryStatus.Active]}
      hideOverwriteHandles
      onlyCurrentEnv
    />
  );
};

export const AnalysisObjectModelModalsCopyModel = ({
  open,
  data,
  onClose,
}: IAnalysisObjectModelModalsCopyModelProps) => {
  const { t } = useTranslation(['libraryModel']);

  return (
    <DraggableModal
      css={cssModal}
      width="50%"
      open={open}
      onCancel={onClose}
      title={t('copyForm.title')}
      footer={null}
      destroyOnClose
      footerInContent={true}
    >
      <AnalysisObjectModelModalsCopyModelContent data={data} onClose={onClose} t={t} />
    </DraggableModal>
  );
};

const cssModal = (): CSSObject => ({
  '& .ant-modal-content': {
    minWidth: 800,
  },
});

export interface IAnalysisObjectModelModalsCopyModelProps {
  open: boolean;
  data: any;
  onClose: () => void;
}

interface IAnalysisObjectModelModalsCopyModelContentProps
  extends Pick<IAnalysisObjectModelModalsCopyModelProps, 'data' | 'onClose'> {
  t: TFunction;
}

import { useAnalysisObjectModelListQuery } from '@modules/library/analysisObjects/model/duck/analysisObjectModelApi';
import { ELibraryEntityKind, ELibrarySourceType } from '@modules/library/root/LibraryTypes';
import { TableComponentProps } from '@modules/library/analysisPackage/modals/components';
import { useTablePaginationState } from '@components/ui/table/tableHooks';
import { useModelColumns } from '@modules/library/model/duck/libraryModelHooks';
import { ILibraryModel } from '@modules/library/model/LibraryModelTypes';
import { initialTabPage } from '@modules/library/analysisPackage/modals';
import { IAnalysisPackage } from '@modules/library/analysisPackage/AnalysisPackageTypes';
import { IBaseColumnProps, ObjectTable } from '@shared/components/ObjectTable';
import { getLibraryImportModelFromLibrarySource } from '@modules/library/model/components/LibraryImportModel';
import { TableColumnsType } from 'antd';

export const DataModelObjects = ({
  kind,
  isView,
  data,
  analysisPackageById = [],
  selectedTableItems,
  setSelectedTableItems,
  analysisPackageByIdFetching,
}: DataModelAnalysisObjectsProps) => {
  const { paginationState, getPagination, preparedFilters, onTableChange } = useTablePaginationState(initialTabPage);
  const { modelColumns, locale: modelLocale } = useModelColumns();

  const dataModelAnalysisObjects = useAnalysisObjectModelListQuery(
    { page: paginationState.current - 1, page_size: initialTabPage.pageSize, ...preparedFilters },
    { skip: isView },
  );

  const tableDataModels =
    ((isView && data ? analysisPackageById : dataModelAnalysisObjects.data?.items) as ILibraryModel[]) || [];
  const totalItemsModels = isView && data ? analysisPackageById.length : dataModelAnalysisObjects.data?.totalItems;

  return (
    <ObjectTable
      kind={kind}
      columns={modelColumns as TableColumnsType<IBaseColumnProps>}
      locale={modelLocale}
      tableDataFetching={dataModelAnalysisObjects.isFetching || analysisPackageByIdFetching}
      tableData={getLibraryImportModelFromLibrarySource(tableDataModels, ELibrarySourceType.Library) || []}
      totalItems={totalItemsModels}
      selectedTableItems={selectedTableItems}
      setSelectedTableItems={setSelectedTableItems}
      hideCheckboxes={isView}
      getPagination={getPagination}
      onTableChange={onTableChange}
    />
  );
};

interface DataModelAnalysisObjectsProps extends TableComponentProps {
  analysisPackageById?: IAnalysisPackage['objects'][ELibraryEntityKind.Model];
}

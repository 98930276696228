import { useAppContext } from '@app/AppContext';
import { Loader, PermissionDeniedPage } from '@components';
import { useCurrentUserQuery } from '@modules/user/duck/userApi';
import { formatUserPermissions } from '@modules/user/duck/userUtils';
import { TAuthAppPermissions } from '@modules/auth/AuthTypes';
import { Button, Result } from '@ui';
import { useAppPermissions } from '@modules/user/duck/userHooks';
import { removeParametersFromSearch } from '@app/duck/utils';
import { configuration } from '@config/constants';
import { PropsWithChildren, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { selectAuthReady } from './duck/AuthSelector';
import { authActions } from './duck/AuthSlice';

export const AuthProvider = ({ children }: PropsWithChildren) => {
  const { t } = useTranslation(['shared']);
  const { notificationApi } = useAppContext();
  const dispatch = useDispatch();
  const currentUserQuery = useCurrentUserQuery();

  const {
    appPermissions: { canDatastage },
  } = useAppPermissions();
  const authReady = useSelector(selectAuthReady);

  useEffect(() => {
    if (currentUserQuery.isSuccess && !!currentUserQuery.data) {
      localStorage.setItem('user_name', currentUserQuery.data?.preferred_username.split(':').at(0) || '');
      dispatch(authActions.setUser(currentUserQuery.data));
      dispatch(
        authActions.setAppPermissions(
          formatUserPermissions(currentUserQuery.data?.app_scope_permissions ?? {}) as TAuthAppPermissions,
        ),
      );
      if (currentUserQuery.data.permissions_not_available === true) {
        notificationApi.warning({ message: t('notification.permissionsNotAvailable') });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUserQuery]);

  if (authReady) {
    return canDatastage
      ? children
      : ((
          <Result
            status={'403'}
            title={t('permission.accessDenied')}
            subTitle={
              <span
                dangerouslySetInnerHTML={{
                  __html: t('permission.accessDeniedDescription'),
                }}
              />
            }
            extra={
              <Button
                type="primary"
                children={t('changeUser')}
                onClick={() =>
                  window.location.replace(
                    `${configuration.logoutURL}${removeParametersFromSearch(window.location.search, ['username'])}`,
                  )
                }
              />
            }
          />
        ) as any);
  }

  if (currentUserQuery.isError) {
    return <PermissionDeniedPage />;
  }

  return <Loader mode="fixed" size="large" />;
};

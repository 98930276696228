import {
  IDataset,
  IDatasetListQueryParams,
  IDatasetListResponse,
  IUsersFilterParams,
  IStackDatasetCreateQueryParams,
  EDatasetType,
  IDatasetLogResponse,
} from '@modules/dataset/DatasetTypes';
import { isCrossStudy } from '@shared/utils/common';
import { DATASET_TAG_DESCRIPTION } from '@modules/dataset/duck/datasetConstants';
import { appApi } from '@config/appApi';
import { EnvDataListQueryBase } from '@shared/GlobalTypes';
import { TagDescription } from '@reduxjs/toolkit/query/react';

export const DatasetApiRoutes = {
  list: 'api/datasets/study/list',
  globalList: 'api/datasets/global/list',
  stackDatasetList: `api/datasets/stack/list`,
  dataset: (datasetId: number) => `/api/datasets/${datasetId}`,
  // TODO this new api doesn't work if we use them from datastage. Need to create the copies of these api's on backend for using in datastage
  datasetStudy: 'api/internal/datasets/study',
  datasetGlobal: 'api/internal/datasets/global',
  envSQLDatasetList: '/api/external/env/sql-lab',
  envStackDatasetList: 'api/external/env/stack-dataset',
  stackDatasetTable: 'api/datasets/stack/table',
  stackDatasetTableRun: 'api/datasets/stack/table/run',
  studyUsers: 'api/datasets/study/users',
  globalUsers: 'api/datasets/global/users',
  stackUsers: 'api/datasets/stack/users',
  bulkDatasetDelete: '/api/datasets/delete',
  stackDatasetLogs: (datasetId: number) => `/api/datasets/log/${datasetId}`,
};

export const DatasetInvalidations: {
  LIST: TagDescription<DATASET_TAG_DESCRIPTION.LIST>;
  STACK_LIST: TagDescription<DATASET_TAG_DESCRIPTION.STACK_LIST>;
  ID: (id: number) => TagDescription<DATASET_TAG_DESCRIPTION.ID>;
  STUDY_USERS: TagDescription<DATASET_TAG_DESCRIPTION.STUDY_USERS>;
  GLOBAL_USERS: TagDescription<DATASET_TAG_DESCRIPTION.GLOBAL_USERS>;
  STACK_USERS: TagDescription<DATASET_TAG_DESCRIPTION.STACK_USERS>;
} = {
  LIST: { type: DATASET_TAG_DESCRIPTION.LIST, id: 'LIST' },
  STACK_LIST: { type: DATASET_TAG_DESCRIPTION.STACK_LIST, id: 'STACK_LIST' },
  ID: (datasetId: number) => ({ type: DATASET_TAG_DESCRIPTION.ID, datasetId }),
  STUDY_USERS: { type: DATASET_TAG_DESCRIPTION.STUDY_USERS, id: 'STUDY_USERS_LIST' },
  GLOBAL_USERS: { type: DATASET_TAG_DESCRIPTION.GLOBAL_USERS, id: 'GLOBAL_USERS_LIST' },
  STACK_USERS: { type: DATASET_TAG_DESCRIPTION.STACK_USERS, id: 'STACK_USERS_LIST' },
};

export const DatasetApi = appApi.injectEndpoints({
  endpoints: (builder) => ({
    datasetListPaginated: builder.query<IDatasetListResponse, IDatasetListQueryParams | void>({
      providesTags: [DatasetInvalidations.LIST],
      query: (params) => {
        const isGlobal = isCrossStudy(params?.study_id!);
        return {
          params,
          url: isGlobal ? DatasetApiRoutes.globalList : DatasetApiRoutes.list,
        };
      },
    }),
    stackDatasetListPaginated: builder.query<IDatasetListResponse, IDatasetListQueryParams | void>({
      providesTags: [DatasetInvalidations.STACK_LIST],
      query: (params) => ({
        params,
        url: DatasetApiRoutes.stackDatasetList,
      }),
    }),
    envSQLDatasetList: builder.query<IDataset[], EnvDataListQueryBase>({
      query: (params) => ({
        params,
        url: DatasetApiRoutes.envSQLDatasetList,
      }),
    }),
    envStackDatasetList: builder.query<IDataset[], EnvDataListQueryBase>({
      query: (params) => ({
        params,
        url: DatasetApiRoutes.envStackDatasetList,
      }),
    }),
    // TODO this new api doesn't work if we use them from datastage. Need to create the copies of these api's on backend for using in datastage
    // deleteDataset: builder.mutation<void, IDatasetDeleteParams>({
    //   invalidatesTags: [DatasetInvalidations.LIST],
    //   query: ({ name, isGlobal }) => ({
    //     params: {
    //       name,
    //     },
    //     method: 'DELETE',
    //     url: isGlobal ? DatasetApiRoutes.datasetGlobal : DatasetApiRoutes.datasetStudy,
    //   }),
    // }),
    // TODO this api work
    deleteDataset: builder.mutation<void, number>({
      invalidatesTags: (request, error, id) => [
        DatasetInvalidations.LIST,
        DatasetInvalidations.STACK_LIST,
        DatasetInvalidations.ID(id),
      ],
      query: (id) => ({
        method: 'DELETE',
        url: DatasetApiRoutes.dataset(id),
      }),
    }),
    createStackDataset: builder.mutation<void, IStackDatasetCreateQueryParams>({
      invalidatesTags: [DatasetInvalidations.STACK_LIST, DatasetInvalidations.LIST],
      query: ({ data, overwrite }) => ({
        data,
        params: { overwrite },
        method: data?.type === EDatasetType.Stack ? 'PUT' : 'POST',
        url: DatasetApiRoutes.stackDatasetTable,
        timeout: 0,
      }),
    }),
    runStackDataset: builder.mutation<void, { id: number }>({
      invalidatesTags: [DatasetInvalidations.STACK_LIST, DatasetInvalidations.LIST],
      query: (data) => ({
        data,
        method: 'POST',
        url: DatasetApiRoutes.stackDatasetTableRun,
      }),
    }),
    deleteBulkDataset: builder.mutation<void, { ids: IDataset['id'][] }>({
      invalidatesTags: [DatasetInvalidations.LIST, DatasetInvalidations.STACK_LIST],
      query: (data) => ({
        data,
        method: 'POST',
        url: DatasetApiRoutes.bulkDatasetDelete,
        timeout: 0,
      }),
    }),
    datasetStudyUsers: builder.query<IUsersFilterParams, void>({
      providesTags: [DatasetInvalidations.STUDY_USERS],
      query: (params) => ({
        params,
        url: DatasetApiRoutes.studyUsers,
      }),
    }),
    datasetGlobalUsers: builder.query<IUsersFilterParams, void>({
      providesTags: [DatasetInvalidations.GLOBAL_USERS],
      query: (params) => ({
        params,
        url: DatasetApiRoutes.globalUsers,
      }),
    }),
    datasetStackUsers: builder.query<IUsersFilterParams, void>({
      providesTags: [DatasetInvalidations.STACK_USERS],
      query: (params) => ({
        params,
        url: DatasetApiRoutes.stackUsers,
      }),
    }),
    datasetStackLogs: builder.query<IDatasetLogResponse, number>({
      query: (id) => ({
        url: DatasetApiRoutes.stackDatasetLogs(id),
      }),
    }),
  }),
});

export const {
  useStackDatasetListPaginatedQuery,
  useLazyEnvSQLDatasetListQuery,
  useLazyEnvStackDatasetListQuery,
  useDatasetListPaginatedQuery,
  useDeleteDatasetMutation,
  useCreateStackDatasetMutation,
  useRunStackDatasetMutation,
  useDatasetStudyUsersQuery,
  useDatasetGlobalUsersQuery,
  useDatasetStackUsersQuery,
  useDeleteBulkDatasetMutation,
  useDatasetStackLogsQuery,
} = DatasetApi;

import { I18nLocales } from '@i18n';
import { I18nDnAObjectTranslations } from '@i18n/dnaObject';
import en from './libraryObject_en.json';
import enDnAObjects from '../dnaObject/dnaObject_en.json';

export const libraryObjectLocales: Record<I18nLocales, I18nlibraryObjectTranslations> = {
  en: {
    ...en,
    sqlLab: { ...en.sqlLab, ...enDnAObjects.sqlLab },
    codeLabObject: { ...en.codeLabObject, ...enDnAObjects.codeLabObject },
    cdrReportObject: { ...en.cdrReportObject, ...enDnAObjects.cdrReportObject },
  },
};

interface I18nlibraryObjectTranslations {
  sqlLab: {
    pageRootName: string;
    loadingError: string;
    confirmation: {
      title: string;
      description: string;
      successMessage: string;
    };
    copyForm: {
      title: string;
    };
  } & I18nDnAObjectTranslations['sqlLab'];
  codeLabObject: {
    pageRootName: string;
    loadingError: string;
    confirmation: {
      title: string;
      description: string;
      successMessage: string;
      errorMessage: string;
    };
    importForm: {
      title: string;
    };
  } & I18nDnAObjectTranslations['codeLabObject'];
  cdrReportObject: {
    pageRootName: string;
    loadingError: string;
    confirmation: {
      title: string;
      description: string;
      successMessage: string;
      errorMessage: string;
    };
    importForm: {
      title: string;
    };
  } & I18nDnAObjectTranslations['cdrReportObject'];
}

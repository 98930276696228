import { Space } from '@ui';
import { NoDataFound } from '@components';
import { CSSObject } from '@emotion/react';
import { useTranslation } from 'react-i18next';

export const NoTasksData = () => {
  const { t } = useTranslation(['shared']);

  return (
    <Space css={cssNoDataContainer} align="center" justify="center">
      <NoDataFound title={t('task.noDataTitle')} description={t('task.noDataDescription')} />
    </Space>
  );
};

const cssNoDataContainer = (): CSSObject => ({
  width: 630,
  minHeight: 460,
});

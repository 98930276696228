import { Form, FormItem, FormLayout, Select, Radio, Table, notification } from '@ui';
import {
  ELibraryEntityNames,
  ELibrarySourceType,
  ESelectedActions,
  IBaseLibraryEntitySystemInfo,
} from '@modules/library/root/LibraryTypes';
import { useTablePaginationState } from '@components/ui/table/tableHooks';
import {
  useLazyEnvPscListQuery,
  useLazyEnvStudyListQuery,
  useLazyLibraryListQuery,
} from '@modules/library/root/duck/libraryApi';
import { useLazyLibraryPscListQuery } from '@modules/library/psc/duck/libraryPscApi';
import { LibraryStatus } from '@modules/library/root/duck/libraryConstants';
import { itemsToOptions } from '@shared/utils/Form';
import { selectAuthUser } from '@modules/auth/duck/AuthSelector';
import { IAnalysisObjectPscListItem } from '@modules/library/analysisObjects/psc/AnalysisObjectPscTypes';
import { SupportedEnvs } from '@app/AppTypes';
import { IBaseColumnProps } from '@shared/components/ObjectTable';
import { selectGlobalLibrary, selectGlobalStudy } from '@app/duck/appSelectors';
import { QueryErrorType } from '@shared/utils/Error';
import { EPscType, ILibraryPsc, IPsc } from '@modules/library/psc/PscTypes';
import { useFeatures } from '@modules/user/duck/userHooks';
import { Col, Row, TableColumnsType, TablePaginationConfig } from 'antd';
import { CSSObject } from '@emotion/react';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

const initialPage = {
  current: 1,
  pageSize: 10,
  pageSizeOptions: [],
};

export interface GetLibraryImportPscFromLibrarySource extends IBaseColumnProps {
  version?: number | string;
  description?: string;
  rawData: ILibraryPsc | IPsc | IAnalysisObjectPscListItem;
  type: EPscType;
}

export const getLibraryImportPscFromLibrarySource = (
  data: ILibraryPsc[] | IPsc[] | IAnalysisObjectPscListItem[],
  kind: ELibrarySourceType,
): Array<GetLibraryImportPscFromLibrarySource> => {
  if (!Array.isArray(data)) return [];

  if (kind === ELibrarySourceType.Library) {
    return (data as ILibraryPsc[]).map((item) => ({
      id: item.id,
      name: item.name,
      description: item.description,
      version: Array.isArray(item?.library_id || item?.versions)
        ? (item?.library_id || item?.versions!).length
          ? item.version
          : '-'
        : item.version,
      type: item.type,
      rawData: item,
      version_id: item.version_id,
    }));
  }

  return (data as ILibraryPsc[]).map((item) => ({
    id: item.id,
    name: item.name,
    description: item.description,
    type: item.type,
    rawData: item,
  }));
};

export const LibraryImportPsc = ({
  onClose,
  columns,
  sourceOnlyLibrary,
  locale,
  kind,
  libraryStatuses,
  hideOverwriteHandles,
  onImport,
  onlyCurrentEnv,
}: ILibraryImportPscProps) => {
  const globalStudy = useSelector(selectGlobalStudy);
  const globalLibrary = useSelector(selectGlobalLibrary);
  const user = useSelector(selectAuthUser);
  const [form] = Form.useForm();
  const { t } = useTranslation(['libraryRoot']);
  const { setPagination, getPagination } = useTablePaginationState(initialPage);
  const { hasGL } = useFeatures();

  const [sourceData, setSourceData] = useState<TSourceDataProps>([]);
  const [selectedFilter, setSelectedFilter] = useState<ESelectedActions>(ESelectedActions.SHOW_ALL);
  const [libraryListQuery, libraryListQueryData] = useLazyLibraryListQuery();
  const [envStudyListQuery, studiesListQueryData] = useLazyEnvStudyListQuery();

  const [libraryPscListQuery, libraryPscListQueryData] = useLazyLibraryPscListQuery();
  const [studyPscListQuery, studyPscListQueryData] = useLazyEnvPscListQuery();

  const [tableData, setTableData] = useState<ILibraryImportPsc[]>([]);
  const [selectedTableItems, setSelectedTableItems] = useState<React.Key[]>([]);

  const [columnsFiltered, setColumnsFiltered] = useState(columns);

  const tableDataFetching = libraryPscListQueryData.isFetching || studyPscListQueryData.isFetching;
  const fetchingNames = libraryListQueryData.isFetching || studiesListQueryData.isFetching;

  const pagination = getPagination(tableData.length);

  const userCurrentEnv = user?.env_name ?? '';
  const allUserEnvs = user?.environments;
  const currentEnvLabel = allUserEnvs && allUserEnvs[userCurrentEnv]?.label;

  const sourceType = useMemo(() => {
    const sourceOptions = [
      {
        label: ELibrarySourceType.Library,
        value: ELibrarySourceType.Library,
      },
    ];

    if (onlyCurrentEnv) {
      sourceOptions.push({ label: `Study (${currentEnvLabel})`, value: userCurrentEnv } as any);
      return sourceOptions;
    }

    if (!sourceOnlyLibrary) {
      sourceOptions.push(
        ...Object.entries(user?.environments || ({} as SupportedEnvs)).map(([env, envData]) => {
          return { label: `Study (${envData!.label})`, value: env } as any;
        }),
      );
    }
    return sourceOptions;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allUserEnvs]);

  const initValues = {
    sourceKind: (Array.isArray(sourceType) && sourceType.length && sourceType[0].value) || undefined,
    sourceId: (!hasGL && globalStudy?.id) || undefined,
    overwrite: false,
  };

  useEffect(() => {
    if (initValues.sourceKind) {
      onChangeSource(initValues.sourceKind);
    }
    if (initValues.sourceId) {
      onChangeSourceIdInput(initValues.sourceId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onChangeSource = async (value: string) => {
    setTableData([]);
    setSelectedTableItems([]);
    setSourceData([]);
    form.setFieldValue('sourceId', '');
    if (value === ELibrarySourceType.Library) {
      setColumnsFiltered(columns);
      const data = await libraryListQuery({
        status: libraryStatuses ? libraryStatuses.join(',') : undefined,
        page_size: 999,
      }).unwrap();
      const libraryListOption = itemsToOptions(data?.items?.filter((item) => item.id !== globalLibrary?.id));
      setSourceData(libraryListOption);
    } else if (value) {
      // TODO: maybe after updating Antd to 5.13 version we can use property 'hidden' directly in the column and remove state variable
      setColumnsFiltered(columns.filter((el) => el.key && !['version'].includes(el.key as string)));
      const data = await envStudyListQuery({ env: value }).unwrap();
      const studiesListOption = itemsToOptions(data);
      setSourceData(studiesListOption);
    }
  };

  const onChangeSourceIdInput = async (id: number) => {
    try {
      const source = form.getFieldValue('sourceKind');
      if (source === ELibrarySourceType.Library) {
        const data = await libraryPscListQuery({ library_id: id, page_size: 9999, detailed: '1' }).unwrap();
        setTableData(getLibraryImportPscFromLibrarySource(data.items, ELibrarySourceType.Library));
      } else if (source) {
        const data = await studyPscListQuery({
          study_id: id,
          env: source,
        }).unwrap();
        setTableData(getLibraryImportPscFromLibrarySource(data, ELibrarySourceType.Study));
      }
    } catch (e) {
      console.error('Error while changing source id', e);
    }
  };

  const onSubmit = async (values: ILibraryCopyFieldFormValues) => {
    try {
      let options: IOnImportOptions = { overwrite: values.overwrite, kind: ELibrarySourceType.Library };

      if (values['sourceKind'] && values['sourceKind'] !== ELibrarySourceType.Library) {
        const studyName = sourceData.find((item) => item.value === values['sourceId'])?.label!;

        options = {
          ...options,
          kind: ELibrarySourceType.Study,
          systemInfo: {
            env: values['sourceKind'],
            tenant_info: user!.tenant_info,
            source: studyName,
          },
        };
      }

      await onImport(
        tableData.filter((item) => selectedTableItems.includes(item.id)),
        options,
      );
      notification.success({ message: t('copyForm.successMessage', { kind }) });
      onClose();
    } catch (e: any) {
      console.error('Error while importing', e);
      if ((e as QueryErrorType)?.data?.userMsg) {
        notification.error({ message: (e as QueryErrorType)?.data?.userMsg });
      }
    }
  };

  const onTableChange = (tablePagination: TablePaginationConfig) => {
    setPagination(tablePagination.current!);
  };

  const rowSelection = {
    onChange: (selectedRowKeys: React.Key[], selectedRows: ILibraryImportPsc[]) => {
      setSelectedTableItems(selectedRowKeys);
    },
  };

  const filteredPsc = useMemo(() => {
    if (selectedFilter === ESelectedActions.HIDE_SELECTED) {
      return tableData?.filter((model) => !selectedTableItems.includes(model.id));
    }
    return tableData;
  }, [selectedFilter, tableData, selectedTableItems]);

  const onChangeSourceInput = (value: string) => {
    form.setFieldValue('sourceId', '');
    onChangeSource(value);
  };

  return (
    <FormLayout
      form={form}
      layout="vertical"
      onCancel={onClose}
      onSubmit={onSubmit}
      okText={t('save')}
      initialValues={initValues}
      submitIsDisabled={selectedTableItems.length === 0}
    >
      <Row gutter={24}>
        <Col span={12}>
          <FormItem
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24 }}
            name="sourceKind"
            label={t('copyForm.sourceKind')}
            rules={[{ required: true }]}
          >
            <Select
              placeholder={t('copyForm.sourceKindPlaceholder')}
              options={sourceType}
              onChange={onChangeSourceInput}
            />
          </FormItem>
        </Col>
        <Col span={12}>
          <FormItem
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24 }}
            name="sourceId"
            label={t('copyForm.sourceId')}
            rules={[{ required: true }]}
            dependencies={['source']}
          >
            <Select
              placeholder={t('copyForm.sourceIdPlaceholder')}
              options={sourceData}
              loading={fetchingNames}
              disabled={fetchingNames}
              onChange={onChangeSourceIdInput}
            />
          </FormItem>
        </Col>
      </Row>
      {!hideOverwriteHandles && (
        <Row gutter={24}>
          <Col span={24}>
            <Form.Item name="overwrite" label={t('copyForm.duplicateLabel.label')} css={cssHorizontalFormItem}>
              <Radio.Group>
                <Radio value={false}>{t('copyForm.duplicateLabel.createCopy')}</Radio>
                <Radio value={true}>{t('copyForm.duplicateLabel.useExisting')}</Radio>
              </Radio.Group>
            </Form.Item>
          </Col>
        </Row>
      )}
      <Table
        size="small"
        columns={columnsFiltered}
        locale={locale}
        loading={tableDataFetching}
        rowKey="id"
        dataSource={filteredPsc}
        pagination={pagination}
        onChange={onTableChange}
        rowSelection={{
          preserveSelectedRowKeys: true,
          type: 'checkbox',
          ...rowSelection,
          selectedRowKeys: selectedTableItems,
          selections: [
            Table.SELECTION_INVERT,
            {
              key: ESelectedActions.SHOW_ALL,
              text: 'Show all',
              onSelect: () => setSelectedFilter(ESelectedActions.SHOW_ALL),
            },
            {
              key: ESelectedActions.HIDE_SELECTED,
              text: 'Hide selected',
              onSelect: () => setSelectedFilter(ESelectedActions.HIDE_SELECTED),
            },
          ],
        }}
      />
    </FormLayout>
  );
};

const cssHorizontalFormItem = (): CSSObject => ({
  '&&& .ant-form-item-row': {
    flexDirection: 'row',
    alignItems: 'center',
  },
  '&&& .ant-form-item-label': {
    paddingBottom: 0,
    paddingRight: 16,
    flex: 'none',
  },
});

export interface ILibraryImportPscProps {
  onClose: () => void;
  columns: TableColumnsType<ILibraryImportPsc>;
  sourceOnlyLibrary?: boolean;
  locale: Record<string, any>;
  kind: ELibraryEntityNames;
  onImport: (data: ILibraryImportPsc[], options: IOnImportOptions) => void;
  libraryStatuses?: LibraryStatus[];
  hideOverwriteHandles?: boolean;
  onlyCurrentEnv?: boolean;
}

type TSourceDataProps = {
  label: string;
  value: number;
}[];

interface IOnImportOptions {
  overwrite: boolean;
  kind: ELibrarySourceType;
  systemInfo?: IBaseLibraryEntitySystemInfo;
}

export type ILibraryImportPsc = GetLibraryImportPscFromLibrarySource;

interface ILibraryCopyFieldFormValues {
  sourceKind: ELibrarySourceType;
  sourceId: number;
  overwrite: boolean;
}

import { GpdipExport } from '@modules/gpdip/GpdipTypes';
import { IJob } from '@modules/model/ModelTypes';

export enum EDatasetType {
  Table = 'table',
  View = 'view',
  Stack = 'stack',
}

export interface StudyScope {
  study_id: number;
  study_name: string;
}

export interface DatasetObjectScope {
  protocol_id: string;
  study_name: string;
}

export type Scope = 'global' | 'tenant' | StudyScope[] | DatasetObjectScope[] | null;

export enum EDatasetStatus {
  Success = 'success',
  Error = 'error',
  Running = 'running',
  Deleting = 'deleting',
  Pending = 'pending',
}

export interface IDataset {
  id: number;
  name: string;
  type: EDatasetType;
  status: EDatasetStatus | null;
  error?: string;
  created_at: string;
  updated_at: string;
  created_by: string;
  updated_by: string;
  rcc_user_id: number;
  username: string;
  export: GpdipExport;
  scope: Scope;
  query?: string;
  file_id?: number;
  report_id?: number;
  job?: IJob;
}

export interface IDatasetListResponse {
  currentPage: number;
  totalItems: number;
  totalPages: number;
  items: Array<IDataset>;
}

export interface IDatasetListQueryParams {
  page?: number;
  detailed?: number;
  source_env?: string;
  study_id: number;
}

export type DeleteDatasetProps = Pick<IDataset, 'id' | 'name'>;

export interface IDatasetDeleteParams {
  name: string;
  isGlobal: boolean;
}

export interface IStackDatasetCreateQueryParams {
  data: {
    name: string;
    scope: string;
    job?: IJob;
    type?: EDatasetType;
    export?: GpdipExport;
  };
  overwrite?: boolean;
}

export interface IUsersFilterParams {
  created_by: { [key: string]: string };
  updated_by: { [key: string]: string };
}

export interface IDatasetLogResponse {
  id: number;
  dataset_id: number;
  dataset_name: string;
  log: string;
  started_at: string | null;
  finished_at: string | null;
}

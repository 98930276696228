import { DataTable, Loader, QueryError } from '@components';
import { ViewerModalsController } from '@modules/viewer/modals';
import { Button, Dropdown, Pagination, Select, Space, Tag, Typography } from '@ui';
import { ChevronDownIcon, Refetch } from '@components/icons';
import { selectTasks } from '@modules/viewer/duck/viewerSelectors';
import { selectAppENV, selectGlobalStudy } from '@app/duck/appSelectors';
import { useAppPermissions, useStudyPermissions } from '@modules/user/duck/userHooks';
import { isCrossStudy } from '@shared/utils/common';
import { useEnvironments } from '@modules/viewer/duck/viewerHooks';
import { checkReviewTableName } from '@modules/viewer/duck/viewerUtils';
import { datasetActions } from '@modules/dataset/duck/datasetSlice';
import { EDatasetModalsType } from '@modules/dataset/modals/DatasetModalsConstants';
import { parsedErrorMessage } from '@shared/utils/Viewer';
import { QueryErrorType } from '@shared/utils/Error';
import { css, CSSObject, Theme } from '@emotion/react';
import { MenuProps } from 'antd/es/menu';
import { ForwardedRef, forwardRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { RedoOutlined } from '@ant-design/icons';
import { ViewerDataTableFilter } from './ViewerDataTableFilter';
import { useDataViewerTable } from './useDataViewerTable';
import { ViewerDataTableRef } from './ViewerDataTableTypes';

export const ViewerDataTable = forwardRef<ViewerDataTableRef, ViewerDataTableProps>(
  (props: ViewerDataTableProps, ref: ForwardedRef<ViewerDataTableRef>) => {
    const dispatch = useDispatch();

    const {
      userPermissions: { canDataViewerExport },
    } = useStudyPermissions();

    const {
      appPermissions: { canStackDatasetsInsert },
    } = useAppPermissions();

    const { t } = useTranslation();
    const { checkExternalSource } = useEnvironments();
    const appENV = useSelector(selectAppENV);
    const globalStudy = useSelector(selectGlobalStudy);
    const tasks = useSelector(selectTasks);
    const isBeingExported = tasks?.some(
      (task) => task.table_id === props.tableName && task.study_id === globalStudy?.id,
    );
    const environmentSource = checkExternalSource({ targetSource: props.sourceEnv || appENV?.env });

    const {
      tableId,
      appliedSnapshot,
      queryData,
      pagination,
      dataIsNotFilled,
      dataTableSource,
      appliedFilters,
      exportTable,
      onShowSizeChange,
      onTableChange,
      setAppliedFilters,
      openDataFormatModal,
    } = useDataViewerTable(props, ref);

    const title = (
      <Space align="center" full>
        {props.tableName}
        {appliedSnapshot.snapshotTableId && <Tag css={cssTag} type="snapshot" text={t('snapshotted')} upperCaseText />}
        {queryData.data?.options?.filtered && <Tag css={cssTag} type="filter" text={t('filtered')} upperCaseText />}
        {queryData.data?.options?.blinded && <Tag css={cssTag} type="blinded" text={t('blinded')} upperCaseText />}
        {environmentSource.isExternalSource && (
          <Tag css={cssTag} type="source" text={environmentSource.targetSourceEnvData?.label} upperCaseText />
        )}
      </Space>
    );

    const isPaginationExist = (pagination.pageSize || 0) < (pagination.total || 0);

    const isBelongsToCurrentStudy = globalStudy?.protocolId === tableId.split('.')[0];

    const actionMenuItems: MenuProps['items'] = [
      !environmentSource.isExternalSource &&
        (isCrossStudy(globalStudy!.id) || canDataViewerExport) && {
          key: 'export',
          label: isBeingExported ? t('viewer.exportForm.exportStarted.title') : t('export'),
          disabled: dataIsNotFilled || queryData.isLoading || isBeingExported,
        },
      {
        key: 'createStack',
        label: t('dataset.rootTable.actions.createStackDataset'),
        disabled: !canStackDatasetsInsert || !isBelongsToCurrentStudy,
      },
      { key: 'columnFormats', label: t('viewer.dataFormats.title') },
    ].filter((item) => typeof item !== 'boolean') as MenuProps['items'];

    const originError = queryData.error
      ? (queryData.error as QueryErrorType).data?.devMsg || (queryData.error as QueryErrorType).data?.userMsg
      : '';

    const parsedError = parsedErrorMessage(originError);

    return (
      <>
        {props.showHeader && (
          <>
            <Space css={cssHeader} size="middle" direction="horizontal" align="center" full>
              <Typography.Title level={3}>{title}</Typography.Title>

              <Button
                ghost
                size="large"
                tip={t('viewer.sider.reloadBtn')}
                icon={<Refetch />}
                onClick={() => {
                  props.refetch?.();
                }}
              />
              <Dropdown
                trigger={['click']}
                menu={{
                  items: actionMenuItems,
                  onClick: async ({ key }) => {
                    switch (key) {
                      case 'export':
                        exportTable();
                        break;
                      case 'createStack':
                        dispatch(
                          datasetActions.pushModal({
                            type: EDatasetModalsType.createStackDataset,
                            data: { name: props.tableName },
                          }),
                        );
                        break;
                      case 'columnFormats':
                        openDataFormatModal();
                        break;
                      default:
                        break;
                    }
                  },
                }}
              >
                <Button>
                  <Space>
                    {t('actions')}
                    <ChevronDownIcon />
                  </Space>
                </Button>
              </Dropdown>
            </Space>
          </>
        )}
        {props.showShortInfo && (
          <Space full justify="space-between" css={cssShortInfo(props.hideFilter)}>
            <Space>
              <Button
                icon={<RedoOutlined />}
                type="text"
                size="small"
                onClick={(e) => {
                  e.stopPropagation();
                  props.refetch && props.refetch();
                }}
              />
              <Typography.Text strong>{props.tableName}</Typography.Text>
              {environmentSource.isExternalSource && (
                <Tag css={cssTag} type="source" text={environmentSource.targetSourceEnvData?.label} upperCaseText />
              )}
              {checkReviewTableName(props.tableId) && <Tag css={cssTag} type="review" text="REVIEW" upperCaseText />}
            </Space>
            {!props.showPagination && <Typography.Text>First 10 rows returned</Typography.Text>}
          </Space>
        )}
        {!props.hideFilter && queryData.data && (
          <ViewerDataTableFilter
            data={queryData.data}
            appliedFilters={appliedFilters}
            setAppliedFilters={setAppliedFilters}
            tableId={tableId}
          />
        )}
        <div css={cssBody}>
          {queryData.isLoading && <Loader mode="absolute" />}
          {dataIsNotFilled ? (
            queryData.error ? (
              <QueryError
                error={queryData.error!}
                title={t('viewer.dataError')}
                subTitle={parsedError}
                devMessage={originError}
              />
            ) : (
              <div css={cssEmptyBox}>
                <Typography.Title
                  type="secondary"
                  children={props.isDataModel ? 'Data Model should be successfully Run' : 'Data is not filled yet'}
                />
              </div>
            )
          ) : (
            <>
              {queryData.error && (
                <QueryError
                  error={queryData.error!}
                  title={t('viewer.dataError')}
                  subTitle={parsedError}
                  devMessage={originError}
                />
              )}
              {queryData.data && <DataTable {...dataTableSource} />}
            </>
          )}
        </div>
        {queryData.data && props.showPagination && isPaginationExist && (
          <Space css={cssPaginationLayout}>
            <Pagination
              showTotal={(total, range) => `${range[0]}-${range[1]} of ${total} items`}
              onChange={onTableChange}
              onShowSizeChange={onShowSizeChange}
              disabled={queryData.isLoading}
              simple
              {...pagination}
            />
            <Select
              css={cssPageSize}
              size="small"
              value={pagination.pageSize}
              options={pagination.pageSizeOptions?.map((item) => ({ label: `${item} / page`, value: item }))}
              onChange={(value) => onShowSizeChange(pagination.current!, value)}
            />
          </Space>
        )}
        <ViewerModalsController />
      </>
    );
  },
);

interface ViewerDataTableProps {
  tableId: string;
  tableName: string;
  showShortInfo?: boolean;
  showHeader?: boolean;
  initialPage?: { current: number; pageSize: number; pageSizeOptions: number[] };
  showPagination?: boolean;
  showTracedButtons?: boolean;
  hideFilter?: boolean;
  isDataModel?: boolean;
  sourceEnv?: string; // Do not pass this props if you don't wan't to override source_env query param
  refetch?: () => void;
}

const cssBody = (): CSSObject => ({
  display: 'flex',
  width: '100%',
  height: '100%',
  position: 'relative',
  flexDirection: 'column',
  overflowX: 'auto',
  overflowY: 'auto',
});

const cssShortInfo =
  (hideFilter?: boolean) =>
  (theme: Theme): CSSObject => ({
    backgroundColor: theme['natural-2'],
    padding: '4px 8px',
    marginBottom: hideFilter ? '' : `${theme.padding}px`,
  });

const cssHeader = (): CSSObject => ({
  margin: '16px',
  '&& .ant-typography': { marginBottom: 0 },
});

const cssPaginationLayout = (): CSSObject => ({
  margin: '8px 8px 8px auto',
});

const cssPageSize = css({ marginTop: '-3px' });

const cssEmptyBox = (): CSSObject => ({
  width: '100%',
  height: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  '& > *': {
    textAlign: 'center',
  },
});

const cssTag = css({
  marginRight: 0,
});

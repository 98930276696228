import { DraggableModal, notification, Typography } from '@ui';
import { ELibraryEntityNames } from '@modules/library/root/LibraryTypes';
import {
  useImportToModelMutation,
  useLibraryCopyModelMutation,
  useUsedModelsMutation,
} from '@modules/library/model/duck/libraryModelApi';
import { ICascadeObjectsTableData, LibraryCascadeObjects } from '@shared/components/CascadeObjects';
import { useModelColumns } from '@modules/library/model/duck/libraryModelHooks';
import { LibraryStatus } from '@modules/library/root/duck/libraryConstants';
import { QueryErrorType } from '@shared/utils/Error';
import { ConfirmModal, useConfirmModal } from '@components';
import { isArray, isUndefined } from 'lodash';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { TFunction } from 'i18next';
import { prepareLibraryModel } from '../../duck/libraryModelUtils';
import { ILibraryImportModelProps, LibraryImportModel } from '../../components/LibraryImportModel';
import { IImportToModelQuery } from '../../LibraryModelTypes';

const LibraryModelModalsCopyModelContent = ({
  data: { importToStudyId, model, export: isExport } = {},
  onClose,
  t,
}: ILibraryModelModalsCopyModelContentProps) => {
  const { modelColumns, locale } = useModelColumns();
  const [copyModel] = useLibraryCopyModelMutation();
  const [importToModel] = useImportToModelMutation();
  const [usedModels] = useUsedModelsMutation();
  const confirmModal = useConfirmModal();

  const [isCascade, setIsCascade] = useState(false);
  const [selectedCascadeObjects, setSelectedCascadeObjects] = useState<ICascadeObjectsTableData[]>([]);
  const [isUsedError, setIsUsedError] = useState(false);

  const onImport: ILibraryImportModelProps['onImport'] = async (values, { overwrite, kind, systemInfo, libraryId }) => {
    if (!isUndefined(importToStudyId)) {
      return await importToModel({
        data: prepareLibraryModel(values, kind, systemInfo) as IImportToModelQuery['data'],
        study_id: importToStudyId,
      }).unwrap();
    }

    if (overwrite) {
      try {
        await usedModels({ model_names: values.map((item) => item.name), libraryId }).unwrap();

        await copyModel({
          data: prepareLibraryModel(values, kind, systemInfo),
          overwrite,
          ...(!!model ? { libraryId } : {}),
        }).unwrap();
      } catch (error) {
        const { data } = error as QueryErrorType;
        const isError =
          data.rawData?.message || typeof data.rawData?.error === 'string' || isArray(data.rawData?.error);
        setIsUsedError(isError);

        confirmModal.openConfirmation({
          content: isError ? (
            <Typography.Text type="danger">{data.rawData?.message || data.rawData?.error}</Typography.Text>
          ) : (
            <div>
              {t('confirmOverwrite.content')}
              <strong>{(Object.keys(data.rawData?.error) || []).join(', ')}</strong>
            </div>
          ),
          data: prepareLibraryModel(values, kind, systemInfo),
          cascadeObjects: !isError ? data.rawData?.error : {},
          options: { libraryId },
        });
        // TODO Refactor this stuff later
        // eslint-disable-next-line no-throw-literal
        throw '';
      }
    } else {
      await copyModel({
        data: prepareLibraryModel(values, kind, systemInfo),
        overwrite,
        ...(!!model ? { libraryId } : {}),
      }).unwrap();
    }
  };

  const onOverwriteConfirm = async (data: any, options?: Record<string, any>) => {
    try {
      const processSelectedCascadeObjects = selectedCascadeObjects.filter((el) => !el.children);
      await copyModel({
        data,
        cascade_update: processSelectedCascadeObjects,
        overwrite: true,
        ...(!!model ? { libraryId: options?.libraryId } : {}),
      }).unwrap();
      onClose();
    } catch (error) {
      notification.error({ message: (error as QueryErrorType)?.data?.userMsg });
    }
  };

  const clearCascadeData = () => {
    setIsCascade(false);
    setSelectedCascadeObjects([]);
  };

  return (
    <>
      <LibraryImportModel
        onClose={onClose}
        columns={modelColumns}
        locale={locale}
        kind={ELibraryEntityNames.Model}
        sourceOnlyLibrary={!importToStudyId && !!model}
        hideOverwriteHandles={!!importToStudyId}
        hideCurrentStudy={!!importToStudyId}
        onImport={onImport}
        libraryStatuses={isExport ? [LibraryStatus.Development] : [LibraryStatus.Active]}
        model={model}
        isModelSent={!!model}
        showExport={isExport}
      />
      <ConfirmModal
        title={t('confirmOverwrite.content')}
        submitFunc={onOverwriteConfirm}
        {...confirmModal}
        closeConfirmation={() => {
          clearCascadeData();
          confirmModal.closeConfirmation();
        }}
        okButtonProps={{ disabled: isUsedError }}
      >
        {!isUsedError && (
          <LibraryCascadeObjects
            data={confirmModal.confirmState?.cascadeObjects}
            isCascade={isCascade}
            setIsCascade={setIsCascade}
            setSelectedCascadeObjects={setSelectedCascadeObjects}
          />
        )}
      </ConfirmModal>
    </>
  );
};

export const LibraryModelModalsCopyModel = ({ open, data, onClose }: ILibraryModelModalsCopyModelProps) => {
  const { t } = useTranslation(['libraryModel']);

  return (
    <DraggableModal
      width="50%"
      open={open}
      onCancel={onClose}
      title={data?.model ? t('copyForm.titleModel', { name: data?.model.name }) : t('copyForm.title')}
      footer={null}
      destroyOnClose
      footerInContent={true}
    >
      <LibraryModelModalsCopyModelContent data={data} onClose={onClose} t={t} />
    </DraggableModal>
  );
};

export interface ILibraryModelModalsCopyModelProps {
  open: boolean;
  data?: { importToStudyId?: number; model?: any; export?: boolean };
  onClose: () => void;
}

interface ILibraryModelModalsCopyModelContentProps extends Pick<ILibraryModelModalsCopyModelProps, 'data' | 'onClose'> {
  t: TFunction;
}

import { appApi } from '@config/appApi';
import {
  ICDRReportObjectListQueryParams,
  ICDRReportObjectListResponse,
  ICDRReportObjectQuery,
  TCDRReportObjectFilterOptionsResponse,
} from '@modules/dnaObject/CDR/CDRObjectTypes';
import { ICodeLabObjectListResponse, ICodeLabObjectQuery } from '@modules/dnaObject/CodeLab/CodeLabObjectTypes';
import { IDnAObjectListQueryParams, TDnAObjectFilterResponse } from '@modules/dnaObject/DnAObjectTypes';
import {
  TSQLLabObjectFilterOptionsResponse,
  ISQLLabObjectListResponse,
  ISQLLabObjectQuery,
} from '@modules/dnaObject/SQLLab/SQLLabObjectTypes';
import {
  IStackDatasetObjectListResponse,
  IStackDatasetObjectQuery,
} from '@modules/dnaObject/StackDataset/AnalysisObjectStackDatasetTypes';
import { TagDescription } from '@reduxjs/toolkit/query/react';
import { ANALYSIS_OBJECT_TAG_DESCRIPTION } from './analysisObjectConstants';

export const AnalysisObjectApiRoutes = {
  sqlLabList: '/api/analysis/sql-lab',
  sqlLabItem: (sqlLabVersionId: number) => `/api/analysis/sql-lab/${sqlLabVersionId}`,
  sqlLabFilters: '/api/analysis/sql-lab/filters',
  codeLabList: '/api/analysis/python-notebook',
  codeLabItem: (codeLabVersionId: number) => `/api/analysis/python-notebook/${codeLabVersionId}`,
  stackDatasetList: 'api/analysis/stack-dataset',
  stackDatasetItem: (stackDatasetVersionId: number) => `/api/analysis/stack-dataset/${stackDatasetVersionId}`,
  stackDatasetFilters: '/api/analysis/stack-dataset/filters',
  cdrReportList: '/api/analysis/cdr',
  cdrReportItem: (cdrReportVersion: number) => `/api/analysis/cdr/${cdrReportVersion}`,
  cdrReportFilters: '/api/analysis/cdr/filters',
};

const AnalysisObjectInvalidations: {
  SQL_LAB_LIST: TagDescription<ANALYSIS_OBJECT_TAG_DESCRIPTION.SQL_LAB_LIST>;
  SQL_LAB_ITEM: (sqlLabVersionId: number) => TagDescription<ANALYSIS_OBJECT_TAG_DESCRIPTION.SQL_LAB_ITEM>;
  SQL_LAB_FILTERS: TagDescription<ANALYSIS_OBJECT_TAG_DESCRIPTION.SQL_LAB_FILTERS>;

  CODE_LAB_LIST: TagDescription<ANALYSIS_OBJECT_TAG_DESCRIPTION.CODE_LAB_LIST>;
  CODE_LAB_ITEM: (codeLabVersionId: number) => TagDescription<ANALYSIS_OBJECT_TAG_DESCRIPTION.CODE_LAB_ITEM>;

  STACK_DATASET_LIST: TagDescription<ANALYSIS_OBJECT_TAG_DESCRIPTION.STACK_DATASET_LIST>;
  STACK_DATASET_ITEM: (
    stackDatasetVersionId: number,
  ) => TagDescription<ANALYSIS_OBJECT_TAG_DESCRIPTION.STACK_DATASET_ITEM>;
  STACK_DATASET_FILTERS: TagDescription<ANALYSIS_OBJECT_TAG_DESCRIPTION.STACK_DATASET_FILTERS>;

  CDR_REPORT_LIST: TagDescription<ANALYSIS_OBJECT_TAG_DESCRIPTION.CDR_REPORT_LIST>;
  CDR_REPORT_ITEM: (stackDatasetVersionId: number) => TagDescription<ANALYSIS_OBJECT_TAG_DESCRIPTION.CDR_REPORT_ITEM>;
  CDR_REPORT_FILTERS: TagDescription<ANALYSIS_OBJECT_TAG_DESCRIPTION.CDR_REPORT_FILTERS>;
} = {
  SQL_LAB_LIST: { type: ANALYSIS_OBJECT_TAG_DESCRIPTION.SQL_LAB_LIST, id: 'LIST' },
  SQL_LAB_ITEM: (sqlLabVersionId: number) => ({
    type: ANALYSIS_OBJECT_TAG_DESCRIPTION.SQL_LAB_ITEM,
    sqlLabVersionId,
  }),
  SQL_LAB_FILTERS: { type: ANALYSIS_OBJECT_TAG_DESCRIPTION.SQL_LAB_FILTERS, id: 'SQL_LAB_FILTERS' },

  CODE_LAB_LIST: { type: ANALYSIS_OBJECT_TAG_DESCRIPTION.CODE_LAB_LIST, id: 'CODE_LAB_LIST' },
  CODE_LAB_ITEM: (codeLabVersionId: number) => ({
    type: ANALYSIS_OBJECT_TAG_DESCRIPTION.CODE_LAB_ITEM,
    codeLabVersionId,
  }),

  STACK_DATASET_LIST: { type: ANALYSIS_OBJECT_TAG_DESCRIPTION.STACK_DATASET_LIST, id: 'STACK_DATASET_LIST' },
  STACK_DATASET_ITEM: (stackDatasetVersionId: number) => ({
    type: ANALYSIS_OBJECT_TAG_DESCRIPTION.STACK_DATASET_ITEM,
    stackDatasetVersionId,
  }),
  STACK_DATASET_FILTERS: { type: ANALYSIS_OBJECT_TAG_DESCRIPTION.STACK_DATASET_FILTERS, id: 'STACK_DATASET_FILTERS' },

  CDR_REPORT_LIST: { type: ANALYSIS_OBJECT_TAG_DESCRIPTION.CDR_REPORT_LIST, id: 'CDR_REPORT_LIST' },
  CDR_REPORT_ITEM: (cdrVersionId: number) => ({
    type: ANALYSIS_OBJECT_TAG_DESCRIPTION.CDR_REPORT_ITEM,
    cdrVersionId,
  }),
  CDR_REPORT_FILTERS: { type: ANALYSIS_OBJECT_TAG_DESCRIPTION.CDR_REPORT_FILTERS, id: 'CDR_REPORT_FILTERS' },
};

export const AnalysisObjectSQLDatasetApi = appApi.injectEndpoints({
  endpoints: (builder) => ({
    analysisObjectSQLDatasetList: builder.query<ISQLLabObjectListResponse, IDnAObjectListQueryParams | void>({
      providesTags: [AnalysisObjectInvalidations.SQL_LAB_LIST],
      query: (params) => ({
        params,
        url: AnalysisObjectApiRoutes.sqlLabList,
      }),
    }),
    analysisObjectSQLDatasetFilters: builder.query<TSQLLabObjectFilterOptionsResponse, void>({
      providesTags: [AnalysisObjectInvalidations.SQL_LAB_FILTERS],
      query: (params) => ({
        params,
        url: AnalysisObjectApiRoutes.sqlLabFilters,
      }),
    }),
    deleteAnalysisObjectSQLDataset: builder.mutation<void, number>({
      invalidatesTags: (request, error, analysisObjectId) => [
        AnalysisObjectInvalidations.SQL_LAB_LIST,
        AnalysisObjectInvalidations.SQL_LAB_ITEM(analysisObjectId),
      ],
      query: (analysisObjectId) => ({
        method: 'DELETE',
        url: AnalysisObjectApiRoutes.sqlLabItem(analysisObjectId),
      }),
    }),
    importAnalysisObjectSQLDataset: builder.mutation<void, ISQLLabObjectQuery>({
      invalidatesTags: [AnalysisObjectInvalidations.SQL_LAB_LIST],
      query: ({ data, source }) => ({
        params: { source: source?.toLowerCase() },
        data,
        method: 'POST',
        url: AnalysisObjectApiRoutes.sqlLabList,
      }),
    }),

    // Code Lab endpoints
    analysisObjectCodeLabList: builder.query<ICodeLabObjectListResponse, IDnAObjectListQueryParams | void>({
      providesTags: [AnalysisObjectInvalidations.CODE_LAB_LIST],
      query: (params) => ({
        params,
        url: AnalysisObjectApiRoutes.codeLabList,
      }),
    }),
    deleteAnalysisObjectCodeLab: builder.mutation<void, number>({
      invalidatesTags: (request, error, analysisObjectId) => [
        AnalysisObjectInvalidations.CODE_LAB_LIST,
        AnalysisObjectInvalidations.CODE_LAB_ITEM(analysisObjectId),
      ],
      query: (analysisObjectId) => ({
        method: 'DELETE',
        url: AnalysisObjectApiRoutes.codeLabItem(analysisObjectId),
      }),
    }),
    importAnalysisObjectCodeLab: builder.mutation<void, ICodeLabObjectQuery>({
      invalidatesTags: [AnalysisObjectInvalidations.CODE_LAB_LIST],
      query: ({ data, source }) => ({
        params: { source: source?.toLowerCase() },
        data,
        method: 'POST',
        url: AnalysisObjectApiRoutes.codeLabList,
      }),
    }),

    // Stack Datasets endpoints
    analysisObjectStackDatasetList: builder.query<IStackDatasetObjectListResponse, IDnAObjectListQueryParams | void>({
      providesTags: [AnalysisObjectInvalidations.STACK_DATASET_LIST],
      query: (params) => ({
        params,
        url: AnalysisObjectApiRoutes.stackDatasetList,
      }),
    }),
    deleteAnalysisObjectStackDataset: builder.mutation<void, number>({
      invalidatesTags: (request, error, analysisObjectId) => [
        AnalysisObjectInvalidations.STACK_DATASET_LIST,
        AnalysisObjectInvalidations.STACK_DATASET_ITEM(analysisObjectId),
      ],
      query: (analysisObjectId) => ({
        method: 'DELETE',
        url: AnalysisObjectApiRoutes.stackDatasetItem(analysisObjectId),
      }),
    }),
    importAnalysisObjectStackDataset: builder.mutation<void, IStackDatasetObjectQuery>({
      invalidatesTags: [AnalysisObjectInvalidations.STACK_DATASET_LIST],
      query: ({ data, source }) => ({
        params: { source: source?.toLowerCase() },
        data,
        method: 'POST',
        url: AnalysisObjectApiRoutes.stackDatasetList,
      }),
    }),
    analysisObjectStackDatasetFilters: builder.query<TDnAObjectFilterResponse, void>({
      providesTags: [AnalysisObjectInvalidations.STACK_DATASET_FILTERS],
      query: (params) => ({
        params,
        url: AnalysisObjectApiRoutes.stackDatasetFilters,
      }),
    }),
    // CDR Reports endpoints
    analysisObjectCDRReportList: builder.query<ICDRReportObjectListResponse, ICDRReportObjectListQueryParams | void>({
      providesTags: [AnalysisObjectInvalidations.CDR_REPORT_LIST],
      query: (params) => ({
        params,
        url: AnalysisObjectApiRoutes.cdrReportList,
      }),
    }),
    analysisObjectCDRReportFilters: builder.query<TCDRReportObjectFilterOptionsResponse, void>({
      providesTags: [AnalysisObjectInvalidations.CDR_REPORT_FILTERS],
      query: (params) => ({
        params,
        url: AnalysisObjectApiRoutes.cdrReportFilters,
      }),
    }),
    deleteAnalysisObjectCDRReport: builder.mutation<void, number>({
      invalidatesTags: (request, error, analysisObjectId) => [
        AnalysisObjectInvalidations.CDR_REPORT_LIST,
        AnalysisObjectInvalidations.CDR_REPORT_ITEM(analysisObjectId),
      ],
      query: (analysisObjectId) => ({
        method: 'DELETE',
        url: AnalysisObjectApiRoutes.cdrReportItem(analysisObjectId),
      }),
    }),
    importAnalysisObjectCDRReport: builder.mutation<void, ICDRReportObjectQuery>({
      invalidatesTags: [AnalysisObjectInvalidations.CDR_REPORT_LIST],
      query: ({ data, source }) => ({
        params: { source: source?.toLowerCase() },
        data,
        method: 'POST',
        url: AnalysisObjectApiRoutes.cdrReportList,
      }),
    }),
  }),
});

export const {
  useAnalysisObjectCodeLabListQuery,
  useDeleteAnalysisObjectCodeLabMutation,
  useImportAnalysisObjectCodeLabMutation,

  useAnalysisObjectSQLDatasetListQuery,
  useAnalysisObjectSQLDatasetFiltersQuery,
  useDeleteAnalysisObjectSQLDatasetMutation,
  useImportAnalysisObjectSQLDatasetMutation,

  useAnalysisObjectStackDatasetListQuery,
  useDeleteAnalysisObjectStackDatasetMutation,
  useImportAnalysisObjectStackDatasetMutation,
  useAnalysisObjectStackDatasetFiltersQuery,

  useAnalysisObjectCDRReportListQuery,
  useAnalysisObjectCDRReportFiltersQuery,
  useDeleteAnalysisObjectCDRReportMutation,
  useImportAnalysisObjectCDRReportMutation,
} = AnalysisObjectSQLDatasetApi;

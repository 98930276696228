import { DraggableModal, Form, FormItem, FormLayout, Select, Space, Typography, Row, Col, Button, Alert } from '@ui';
import {
  ModelEditorNodeJoin,
  ModelEditorNodeJoinType,
  ModelEditorNodeJoin_deprecated,
} from '@modules/modelEditor/ModelEditorTypes';
import { MinusCircle, PlusCircle } from '@components/icons';
import { isShowRelationships } from '@modules/modelEditor/duck/modelEditorUtils';
import { listToOptions } from '@shared/utils/Form';
import { useSourceTableInfoAnalyzer } from '@modules/modelEditor/duck/modelEditorSourceTableInfoAnalyzer';
import { findNodeById, findSourceNodesByNodeId } from '@modules/modelEditor/components/builder/Utils';
import { selectModelEditorReadOnly } from '@modules/modelEditor/duck/modelEditorSelectors';
import { useTranslation } from 'react-i18next';
import { TFunction } from 'i18next';
import { PauseOutlined } from '@ant-design/icons';
import { css, CSSObject } from '@emotion/react';
import { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useSaveStage } from './modelEditorModalsHooks';

// TODO hide 'cross' join for now because of we need to investigate how it works in spark, test different cases
const joinOptions = listToOptions(
  Object.values(ModelEditorNodeJoinType).filter((el) => el !== 'cross'),
  true,
);

const ModelEditorModalsJoinSettingsContent = ({ data, onClose, t }: ModelEditorModalsJoinSettingsContentProps) => {
  const [form] = Form.useForm();

  const readOnly = useSelector(selectModelEditorReadOnly);
  const [showRelationshipSection, setShowRelationshipSection] = useState(
    data.initData && isShowRelationships(data.initData?.type),
  );

  const { onSubmit } = useSaveStage(data.nodeId, onClose);
  const { loading, sourceColumns, edges, nodes } = useSourceTableInfoAnalyzer(data.nodeId);

  const sourceLeftFields = sourceColumns[0]?.map(({ name }) => ({ label: name, value: name })) || [];
  const sourceRightFields = sourceColumns[1]?.map(({ name }) => ({ label: name, value: name })) || [];

  const sourceNodes = findSourceNodesByNodeId(data.nodeId, edges);

  const sourceLeft = findNodeById(nodes, sourceNodes[0]);
  const sourceRight = findNodeById(nodes, sourceNodes[1]);

  const initValues: Partial<ModelEditorNodeJoin> = useMemo(() => {
    if (!data.initData) return {};

    if (
      Array.isArray(data.initData.relations) &&
      typeof (data.initData as ModelEditorNodeJoin_deprecated).relations[0] === 'string'
    ) {
      // Deprecated flow
      return {
        ...data.initData,
        relations: data.initData.relations.map((old_relation) => ({
          leftColumn: old_relation,
          rightColumn: old_relation,
        })),
      };
    }

    return { ...data.initData };
  }, [data.initData]);

  const nodeErrors = [];

  if (!loading) {
    if (!sourceLeft || !sourceRight) {
      nodeErrors.push(t('join.errors.missingSource'));
    }
  }

  const onChangeJoinType = () => {
    setShowRelationshipSection(isShowRelationships(form.getFieldValue('type')));
  };

  return (
    <FormLayout
      readOnly={readOnly}
      form={form}
      layout="vertical"
      onCancel={onClose}
      onSubmit={onSubmit}
      okText={t('save')}
      initialValues={initValues}
      labelCol={{ span: 24 }}
      wrapperCol={{ span: 24 }}
      submitIsDisabled={!!nodeErrors.length || loading}
    >
      <Row style={{ marginBottom: 40 }}>
        <Col span={24}>
          <Row gutter={8} css={{ alignItems: 'center' }}>
            <Col span={10}>
              <Form.Item wrapperCol={{ span: 24 }} name="type" label={t('join.type')} rules={[{ required: true }]}>
                <Select size="large" options={joinOptions} placeholder={t('join.select')} onChange={onChangeJoinType} />
              </Form.Item>
            </Col>
            <Col span={2} />
            <Col span={12}>
              <Space direction="vertical">
                <Space>
                  <Typography.Text css={cssLabel}>{t('join.leftSource')}:</Typography.Text>
                  <Typography.Text>{sourceLeft?.data?.tableName}</Typography.Text>
                </Space>
                <Space>
                  <Typography.Text css={cssLabel}>{t('join.rightSource')}:</Typography.Text>
                  <Typography.Text>{sourceRight?.data?.tableName}</Typography.Text>
                </Space>
              </Space>
            </Col>
          </Row>
          {showRelationshipSection && (
            <FormItem label={t('join.relations')} required>
              <Form.List
                name="relations"
                rules={[
                  {
                    validator: (_, value: Array<ModelEditorNodeJoin['relations'][0] | undefined>) => {
                      if (value && value.length && value.some((item) => item?.leftColumn && item?.rightColumn)) {
                        return Promise.resolve();
                      }
                      return Promise.reject(new Error(t('join.errors.missingRelations')));
                    },
                  },
                ]}
              >
                {(fields, { add, remove }, { errors }) => (
                  <>
                    {fields.map(({ key, name, ...restField }) => (
                      <Row key={key} gutter={8}>
                        <Col span={10}>
                          <FormItem
                            {...restField}
                            name={[name, 'leftColumn']}
                            rules={[
                              {
                                required: true,
                                validator: (_, value: string) => {
                                  if (!value) {
                                    return Promise.reject(new Error(t('join.errors.missingColumn')));
                                  }
                                  return Promise.resolve();
                                },
                              },
                            ]}
                            wrapperCol={{ span: 24 }}
                          >
                            <Select
                              size="large"
                              showSearch
                              optionFilterProp="label"
                              options={sourceLeftFields}
                              allowClear
                              placeholder={t('join.columnLeftSelector')}
                              loading={loading}
                            />
                          </FormItem>
                        </Col>
                        <Col span={2} css={cssEqualBtn}>
                          <PauseOutlined size={32} />
                        </Col>
                        <Col span={10}>
                          <FormItem
                            {...restField}
                            name={[name, 'rightColumn']}
                            rules={[
                              {
                                required: true,
                                validator: (_, value: string) => {
                                  if (!value) {
                                    return Promise.reject(new Error(t('join.errors.missingColumn')));
                                  }
                                  return Promise.resolve();
                                },
                              },
                            ]}
                            wrapperCol={{ span: 24 }}
                          >
                            <Select
                              size="large"
                              showSearch
                              optionFilterProp="label"
                              options={sourceRightFields}
                              allowClear
                              placeholder={t('join.columnRightSelector')}
                              loading={loading}
                            />
                          </FormItem>
                        </Col>
                        <Col span={2} css={cssRemoveBtn}>
                          <Button
                            size="large"
                            icon={<MinusCircle />}
                            disabled={readOnly}
                            onClick={() => remove(name)}
                            tip={t('groupBy.remove')}
                          />
                        </Col>
                      </Row>
                    ))}
                    <FormItem wrapperCol={{ span: 24 }}>
                      <Button size="large" type="dashed" onClick={() => add()} block icon={<PlusCircle />}>
                        {t('join.add')}
                      </Button>
                      <Form.ErrorList errors={errors} />
                    </FormItem>
                  </>
                )}
              </Form.List>
            </FormItem>
          )}
          {!!nodeErrors.length && (
            <FormItem wrapperCol={{ span: 24 }}>
              <Alert message={<Space direction="vertical" children={nodeErrors} />} type="error" />
            </FormItem>
          )}
        </Col>
      </Row>
    </FormLayout>
  );
};

export const ModelEditorModalsJoinSettings = ({ open, data, onClose }: ModelEditorModalsJoinSettingsProps) => {
  const { t } = useTranslation(['model']);

  return (
    <DraggableModal
      width={880}
      open={open}
      onCancel={onClose}
      title={t('join.title')}
      footer={null}
      destroyOnClose
      footerInContent
    >
      {open && <ModelEditorModalsJoinSettingsContent data={data} onClose={onClose} t={t} />}
    </DraggableModal>
  );
};

interface ModelEditorModalsJoinSettingsContentProps
  extends Pick<ModelEditorModalsJoinSettingsProps, 'data' | 'onClose'> {
  t: TFunction;
}

export interface ModelEditorModalsJoinSettingsProps {
  open: boolean;
  data: { nodeId: string; initData?: ModelEditorNodeJoin | ModelEditorNodeJoin_deprecated };
  onClose: () => void;
}

const cssRemoveBtn = css({
  display: 'flex',
  alignItems: 'start',
  justifyContent: 'center',
});

const cssEqualBtn = css({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'start',
  marginTop: '8px',
  '> *': {
    transform: 'rotate(90deg)',
  },
});

const cssLabel = (): CSSObject => ({
  width: 120,
});

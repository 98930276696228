import { Space, Tag } from '@ui';
import { AntdIconBox, QuestionMark } from '@components/icons';
import { InformationModal } from '@components';
import { capitalizeFirstLetter } from '@shared/utils/Form';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import { CSSObject } from '@emotion/react';

const dashSymbol = <>&mdash;</>;

export const StatusTag = ({ showSuccessTag, showErrorTag, showRunningTag, errorMessage, tag }: StatusTagProps) => {
  const { t } = useTranslation();
  const [openModal, setOpenModal] = useState(false);

  const closeModal = () => setOpenModal(false);

  if (showErrorTag) {
    return (
      <Space full align="center">
        <Tag css={cssTag} color="error" children={t('status.errorTag')} />
        {errorMessage && (
          <AntdIconBox
            css={cssIcon}
            icon={QuestionMark}
            tip={t('status.tooltipErrorIcon')}
            color="danger"
            onClick={() => {
              setOpenModal(true);
            }}
          />
        )}
        {openModal && (
          <InformationModal
            width="600px"
            autoHeightMin={200}
            title={t('errorDetails')}
            message={errorMessage}
            onClose={closeModal}
          />
        )}
      </Space>
    );
  }

  if (showSuccessTag) return <Tag color="success" children={t('status.successTag')} />;
  if (showRunningTag) return <Tag color="running" children={t('status.runnintTag')} />;

  return tag ? (
    <Tag color={tag === 'deleting' ? 'warning' : 'processing'} children={capitalizeFirstLetter(tag)} />
  ) : (
    dashSymbol
  );
};

interface StatusTagProps {
  showErrorTag?: boolean;
  showSuccessTag?: boolean;
  showRunningTag?: boolean;
  errorMessage?: string;
  tag?: string;
}

const cssTag = (): CSSObject => ({
  marginRight: 0,
});

const cssIcon = (): CSSObject => ({
  fontSize: 24,
  cursor: 'pointer',
});

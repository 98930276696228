import i18n from '@i18n';
import dayjs from 'dayjs';

const localTimeZone = dayjs.tz?.guess();

export const timestampToUNIX = (date: string) => dayjs(date).unix();

export function getFormattedDatetime(
  date: number | string | undefined | null,
  {
    dateInUTC = true,
    noDataSymbol,
    format = 'YYYY-MM-DD HH:mm',
  }: {
    dateInUTC?: boolean;
    noDataSymbol?: string;
    format: 'YYYY-MM-DD HH:mm:ss' | 'YYYY-MM-DD HH:mm' | 'YYYY-MM-DD HH:mm:ss [GMT]' | 'YYYY-MM-DD HH:mm:ss UTCZ';
  } = { format: 'YYYY-MM-DD HH:mm', dateInUTC: true },
) {
  const dayjsFunction = dateInUTC ? dayjs.utc : dayjs;
  const dateFormat = format;

  if (typeof date === 'string') {
    if (dateInUTC) {
      return dayjsFunction(date)
        .tz(format === 'YYYY-MM-DD HH:mm:ss [GMT]' ? 'GMT' : localTimeZone)
        .format(dateFormat);
    }
    return dayjsFunction(date).format(dateFormat);
  }
  if (typeof date === 'number') {
    return dayjsFunction(date * 1000).format(dateFormat);
  }
  return noDataSymbol ?? i18n.t('na');
}

export const dateToString = (
  date?: number | string,
  isUTCTime?: boolean,
  noDataSymbol?: string,
  displaySec?: boolean,
) => {
  const dayjsFunction = isUTCTime ? dayjs.utc : dayjs;
  const dateFormat = displaySec ? 'YYYY-MM-DD HH:mm:ss' : 'YYYY-MM-DD HH:mm';

  if (typeof date === 'string') {
    if (isUTCTime) {
      return dayjsFunction(date).tz(localTimeZone).format(dateFormat);
    }
    return dayjsFunction(date).format(dateFormat);
  }
  if (typeof date === 'number') {
    return dayjsFunction(date * 1000).format(dateFormat);
  }
  return noDataSymbol ?? i18n.t('na');
};

export const dataToHumanMonthDayYear = (date?: number) => (date ? dayjs(date).format('MMM DD, YYYY') : i18n.t('na'));

import { Tooltip } from '@ui';
import { default as AntButton } from 'antd/es/button';
import { ButtonProps, TooltipProps } from 'antd';
import { CSSObject, Theme, useTheme } from '@emotion/react';
import { forwardRef } from 'react';

const Button = forwardRef<HTMLButtonElement, ButtonProps & { placement?: TooltipProps['placement']; tip?: string }>(
  ({ tip, danger, icon, size, children, placement, ...props }, ref) => {
    const theme = useTheme();

    const button = (
      <AntButton
        ref={ref}
        css={cssButton(theme, size)}
        {...props}
        size={size ?? (icon ? 'middle' : 'large')}
        danger={danger}
        icon={icon}
        children={children}
      />
    );

    if (tip) {
      return (
        <Tooltip placement={placement ?? 'bottom'} title={tip}>
          <div css={cssTooltipBtn} className={props.className}>
            {button}
          </div>
        </Tooltip>
      );
    }

    return button;
  },
);

export { Button };

const cssButton = (theme: Theme, size?: ButtonProps['size']): CSSObject => {
  const iconSizeMap: Record<Exclude<ButtonProps['size'], undefined>, number | string | undefined> = {
    small: theme?.Button?.onlyIconSizeSM,
    middle: theme?.Button?.onlyIconSize,
    large: theme?.Button?.onlyIconSizeLG,
  };

  return {
    '&&': {
      '&&.ant-btn-color-dangerous.ant-btn-variant-outlined': {
        borderColor: theme['color-grey-400'],
      },

      '&&.ant-btn .ant-btn-icon': {
        display: 'flex',
        fontSize: size ? iconSizeMap[size] : 24,
      },

      '&&.ant-btn-icon-only': {
        padding: 4,
      },
    },
  };
};

const cssTooltipBtn = (): CSSObject => ({
  width: 'fit-content',
});

import { selectGlobalStudy } from '@app/duck/appSelectors';
import { IAuthStudyRolesItem, IAuthStudyRolesResponse, TAuthStudyRolesPermissions } from '@modules/auth/AuthTypes';
import { useSetActiveStudyRoleMutation, useStudyRolesQuery } from '@modules/auth/duck/authApi';
import { formatUserPermissions } from '@modules/user/duck/userUtils';
import { Select } from '@ui';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { selectStudyActiveUserRole } from '../duck/studySelectors';
import { studyActions } from '../duck/studySlice';
import { setLastStudyRole } from '../duck/studyUtils';

export const StudyRoleSelector = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation(['study', 'shared']);
  const globalStudy = useSelector(selectGlobalStudy);
  const studyActiveRole = useSelector(selectStudyActiveUserRole);

  const studyRolesQuery = useStudyRolesQuery(globalStudy?.id!, { skip: !globalStudy?.id });
  const [setStudyRole, setStudyRoleQuery] = useSetActiveStudyRoleMutation();

  const setStudyUserRole = (data?: IAuthStudyRolesResponse) => {
    if (data?.current_active_role && studyActiveRole?.role_id !== data.current_active_role.role_id) {
      dispatch(studyActions.setActiveUserRole(data.current_active_role));
    }
    if (data?.permissions) {
      const formattedUserPermissions = formatUserPermissions(data.permissions);
      dispatch(studyActions.setUserPermissions(formattedUserPermissions as TAuthStudyRolesPermissions));
    }
  };

  useEffect(() => {
    setStudyUserRole(studyRolesQuery.data);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, studyRolesQuery.data]);

  const isLoading = studyRolesQuery.isLoading || setStudyRoleQuery.isLoading;

  return (
    <Select
      size="large"
      style={{ order: 999, maxWidth: 200 }}
      placeholder={isLoading ? t('loading') : t('toolbar.noRolesPlaceholder')}
      popupMatchSelectWidth={false}
      loading={isLoading}
      value={studyActiveRole?.role_id}
      options={studyRolesQuery.data?.all_roles || (studyActiveRole ? [studyActiveRole!] : [])}
      fieldNames={{ label: 'name', value: 'role_id' }}
      onChange={async (value, option) => {
        const { role_id, name } = option as IAuthStudyRolesItem;

        if (role_id !== studyActiveRole?.role_id) {
          setLastStudyRole({ role_id, name });
          try {
            const response = await setStudyRole({ role_id: role_id, study_id: globalStudy?.id! }).unwrap();

            setStudyUserRole(response);
          } catch (error) {
            console.error(error);
          }
        }
      }}
    />
  );
};

import { Divider, Space, Tag, Typography } from '@ui';
import { ISparkJobQueueItem } from '@modules/task/TaskTypes';
import dayjs from 'dayjs';
import { CSSObject, Theme } from '@emotion/react';
import { useTranslation } from 'react-i18next';
import { SyncOutlined } from '@ant-design/icons';

export const SparkJobItem = ({ item }: { item: ISparkJobQueueItem }) => {
  const { t } = useTranslation(['shared']);

  return (
    <Space key={item.name} css={cssList} direction="vertical" full>
      <Space full justify="space-between" css={{ paddingTop: 8 }}>
        <Space css={cssTitleContainer}>
          <Typography.Text>{item.name || t('task.sparkJobItem.unknown')}</Typography.Text>
          {item.study_name && (
            <>
              <Divider type="vertical" css={cssDivider} />
              <Typography.Text type="secondary" css={cssTitle}>
                {t('task.sparkJobItem.study')}: {item.study_name}
              </Typography.Text>
            </>
          )}
        </Space>

        {item.started_at ? (
          <Tag color="processing" icon={<SyncOutlined spin />}>
            {t('task.sparkJobItem.running')}
          </Tag>
        ) : (
          <Tag color="warning">{t('task.sparkJobItem.waiting')}</Tag>
        )}
      </Space>
      <Space css={{ gap: 4, paddingBottom: 16 }} direction="vertical" full>
        <Typography.Text type="secondary" css={cssTitle}>
          {t('task.sparkJobItem.addedAt')}: {dayjs(item.added_at).fromNow()}
        </Typography.Text>

        {item.started_at && (
          <Typography.Text type="secondary" css={cssTitle}>
            {t('task.sparkJobItem.startedAt')}: {dayjs(item.started_at).fromNow()}
          </Typography.Text>
        )}
      </Space>
    </Space>
  );
};

const cssDivider = (theme: Theme): CSSObject => ({
  borderInlineStart: `1px solid ${theme['color-grey-600']}`,
});

const cssList = (theme: Theme): CSSObject => ({
  width: 630,
  borderBottom: `1px solid ${theme.colorBorder}`,

  '&:last-child': {
    borderBottom: 'none',
  },
});

const cssTitle = (): CSSObject => ({
  fontSize: 14,
});

const cssTitleContainer = (): CSSObject => ({
  '&& .ant-space-item:last-child': {
    flex: 1,
  },
});

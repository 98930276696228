import { useAnalysisObjectCodeLabListQuery } from '@modules/library/analysisObjects/duck/analysisObjectApi';
import { TableComponentProps } from '@modules/library/analysisPackage/modals/components';
import { useTablePaginationState } from '@components/ui/table/tableHooks';
import { useCodeLabObjectColumns } from '@modules/dnaObject/CodeLab/duck/codeLabObjectHooks';
import { initialTabPage } from '@modules/library/analysisPackage/modals';
import { IAnalysisPackage } from '@modules/library/analysisPackage/AnalysisPackageTypes';
import { ELibraryEntityKind } from '@modules/library/root/LibraryTypes';
import { IBaseColumnProps, ObjectTable } from '@shared/components/ObjectTable';
import { TableColumnsType } from 'antd';

export const CodeLabObjects = ({
  kind,
  isView,
  data,
  analysisPackageById = [],
  selectedTableItems,
  setSelectedTableItems,
  analysisPackageByIdFetching,
}: CodeLabObjectsProps) => {
  const { paginationState, getPagination, preparedFilters, onTableChange } = useTablePaginationState(initialTabPage);
  const { codeLabColumns, locale: codeLabLocale } = useCodeLabObjectColumns();

  const codeLabObjects = useAnalysisObjectCodeLabListQuery(
    { page: paginationState.current - 1, page_size: initialTabPage.pageSize, detailed: '1', ...preparedFilters },
    { skip: isView },
  );

  const tableDataCodeLab = ((isView && data ? analysisPackageById : codeLabObjects.data?.items) as any[]) || [];
  const totalItemsCodeLab = isView && data ? analysisPackageById.length : codeLabObjects.data?.totalItems;

  return (
    <ObjectTable
      kind={kind}
      columns={codeLabColumns as TableColumnsType<IBaseColumnProps>}
      locale={codeLabLocale}
      tableDataFetching={codeLabObjects.isFetching || analysisPackageByIdFetching}
      tableData={tableDataCodeLab}
      totalItems={totalItemsCodeLab}
      selectedTableItems={selectedTableItems}
      setSelectedTableItems={setSelectedTableItems}
      hideCheckboxes={isView}
      getPagination={getPagination}
      onTableChange={onTableChange}
    />
  );
};

interface CodeLabObjectsProps extends TableComponentProps {
  analysisPackageById?: IAnalysisPackage['objects'][ELibraryEntityKind.Notebook];
}

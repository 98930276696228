import { useAnalysisObjectSQLDatasetListQuery } from '@modules/library/analysisObjects/duck/analysisObjectApi';
import { TableComponentProps } from '@modules/library/analysisPackage/modals/components';
import { useTablePaginationState } from '@components/ui/table/tableHooks';
import { InformationModal } from '@components';
import { renderInfoModalContent } from '@modules/dataset/components';
import { useSqlLabObjectColumns } from '@modules/dnaObject/SQLLab/duck/sqlLabObjectHooks';
import { IAnalysisPackage } from '@modules/library/analysisPackage/AnalysisPackageTypes';
import { ELibraryEntityKind } from '@modules/library/root/LibraryTypes';
import { initialTabPage } from '@modules/library/analysisPackage/modals';
import { IBaseColumnProps, ObjectTable } from '@shared/components/ObjectTable';
import { TableColumnsType } from 'antd';

export const SqlDatasetsObjects = ({
  kind,
  isView,
  data,
  analysisPackageById = [],
  selectedTableItems,
  setSelectedTableItems,
  analysisPackageByIdFetching,
}: SqlDatasetsObjectsProps) => {
  const { paginationState, getPagination, preparedFilters, onTableChange } = useTablePaginationState(initialTabPage);
  const {
    sqlLabColumns,
    locale: sqlDatasetLocale,
    studiesDetailsModal,
    setStudiesDetailsModal,
  } = useSqlLabObjectColumns();

  const sqlDatasetsObjects = useAnalysisObjectSQLDatasetListQuery(
    { page: paginationState.current - 1, page_size: initialTabPage.pageSize, detailed: '1', ...preparedFilters },
    { skip: isView },
  );

  const tableDataSQLDatasets = ((isView && data ? analysisPackageById : sqlDatasetsObjects.data?.items) as any[]) || [];
  const totalItemsSQLDatasets = isView && data ? analysisPackageById.length : sqlDatasetsObjects.data?.totalItems;

  return (
    <>
      <ObjectTable
        kind={kind}
        columns={sqlLabColumns as TableColumnsType<IBaseColumnProps>}
        locale={sqlDatasetLocale}
        tableDataFetching={sqlDatasetsObjects.isFetching || analysisPackageByIdFetching}
        tableData={tableDataSQLDatasets}
        totalItems={totalItemsSQLDatasets}
        selectedTableItems={selectedTableItems}
        setSelectedTableItems={setSelectedTableItems}
        hideCheckboxes={isView}
        getPagination={getPagination}
        onTableChange={onTableChange}
      />
      {studiesDetailsModal && (
        <InformationModal
          message={renderInfoModalContent(studiesDetailsModal)}
          onClose={() => setStudiesDetailsModal(null)}
          width="500px"
          autoHeightMin={50}
        />
      )}
    </>
  );
};

interface SqlDatasetsObjectsProps extends TableComponentProps {
  analysisPackageById?: IAnalysisPackage['objects'][ELibraryEntityKind.SQL_Lab];
}

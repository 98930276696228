import { DraggableModal, notification } from '@ui';
import { ELibraryEntityNames, ELibrarySourceType } from '@modules/library/root/LibraryTypes';
import {
  useLazyLibrarySqlQueryListQuery,
  useLibraryCopySqlQueryMutation,
  useValidateSqlQueryMutation,
} from '@modules/library/sqlQuery/duck/librarySqlQueryApi';
import { prepareLibrarySqlQuery } from '@modules/library/sqlQuery/duck/librarySqlQueryUtils';
import { useSqlQueryColumns } from '@modules/library/sqlQuery/duck/librarySqlQueryHooks';
import { LibraryStatus } from '@modules/library/root/duck/libraryConstants';
import { ConfirmModal, useConfirmModal } from '@components';
import { QueryErrorType } from '@shared/utils/Error';
import { useTranslation } from 'react-i18next';
import { TFunction } from 'i18next';
import { isObject } from 'lodash';
import {
  getLibraryImportSqlQueryFromLibrarySource,
  ILibraryImportSqlQueryProps,
  LibraryImportSqlQuery,
  WrapperFn,
} from '../../components/LibraryImportSqlQuery';

const LibrarySqlQueryModalsCopySqlQueryContent = ({ t, onClose }: LibrarySqlQueryModalsCopySqlQueryContentProps) => {
  const { sqlQueryColumns, locale } = useSqlQueryColumns();
  const [librarySqlQueryListQuery, librarySqlQueryListQueryData] = useLazyLibrarySqlQueryListQuery();
  const [copySqlQuery] = useLibraryCopySqlQueryMutation();
  const [validateSqlQuery] = useValidateSqlQueryMutation();
  const confirmModal = useConfirmModal();

  const wrapperLibrary: WrapperFn = async (id: number) => {
    const data = await librarySqlQueryListQuery({ library_id: id, detailed: '1' }).unwrap();
    return getLibraryImportSqlQueryFromLibrarySource(data.items, ELibrarySourceType.Library);
  };

  const onImport: ILibraryImportSqlQueryProps['onImport'] = async (values, { kind, overwrite, systemInfo }) => {
    if (overwrite) {
      try {
        await validateSqlQuery({ sql_query_names: values.map((item) => item.name) }).unwrap();

        await copySqlQuery({ data: prepareLibrarySqlQuery(values, kind, systemInfo), overwrite: overwrite }).unwrap();
      } catch (error) {
        const { data } = error as QueryErrorType;
        const overwritedObjects = Array.isArray(data.rawData?.error)
          ? data.rawData?.error
          : isObject(data.rawData?.error)
          ? Object.keys(data.rawData?.error || [])
          : [];

        confirmModal.openConfirmation({
          content: (
            <div>
              {t('confirmOverwrite.content')}
              <strong>{overwritedObjects.join(', ')}</strong>
            </div>
          ),
          data: prepareLibrarySqlQuery(values, kind, systemInfo),
        });
        // TODO Refactor this stuff later
        // eslint-disable-next-line no-throw-literal
        throw '';
      }
    } else {
      await copySqlQuery({ data: prepareLibrarySqlQuery(values, kind, systemInfo), overwrite: overwrite }).unwrap();
    }
  };

  const onOverwriteConfirm = async (data: any) => {
    try {
      await copySqlQuery({ data, overwrite: true }).unwrap();
      onClose();
    } catch (error) {
      notification.error({ message: (error as QueryErrorType).data.userMsg });
    }
  };

  return (
    <>
      <LibraryImportSqlQuery
        onClose={onClose}
        columns={sqlQueryColumns}
        locale={locale}
        kind={ELibraryEntityNames.SQL_Lab}
        libraryTableListQuery={wrapperLibrary}
        tableDataFetching={librarySqlQueryListQueryData.isFetching}
        onImport={onImport}
        libraryStatuses={[LibraryStatus.Active, LibraryStatus.Development]}
        sourceOnlyLibrary
      />
      <ConfirmModal title={t('confirmOverwrite.content')} submitFunc={onOverwriteConfirm} {...confirmModal} />
    </>
  );
};

export const LibrarySqlQueryModalsCopySqlQuery = ({ open, data, onClose }: ILibrarySqlQueryModalsCopySqlQueryProps) => {
  const { t } = useTranslation(['librarySqlQuery']);

  return (
    <DraggableModal
      width="50%"
      open={open}
      onCancel={onClose}
      title={t('copyForm.title')}
      footer={null}
      destroyOnClose
      footerInContent={true}
    >
      <LibrarySqlQueryModalsCopySqlQueryContent data={data} onClose={onClose} t={t} />
    </DraggableModal>
  );
};

export interface ILibrarySqlQueryModalsCopySqlQueryProps {
  open: boolean;
  data: any;
  onClose: () => void;
}

interface LibrarySqlQueryModalsCopySqlQueryContentProps
  extends Pick<ILibrarySqlQueryModalsCopySqlQueryProps, 'data' | 'onClose'> {
  t: TFunction;
}

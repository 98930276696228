import { DraggableModal, notification } from '@ui';
import { ConfirmModal, useConfirmModal } from '@components';
import { QueryErrorType } from '@shared/utils/Error';
import { LibraryStatus } from '@modules/library/root/duck/libraryConstants';
import { ICascadeObjectsTableData, LibraryCascadeObjects } from '@shared/components/CascadeObjects';
import {
  CDRReportObjectSelectForm,
  ICDRReportObjectSelectFormProps,
} from '@modules/dnaObject/CDR/components/CDRReportObjectSelectForm';
import { ICDRReportObject } from '@modules/dnaObject/CDR/CDRObjectTypes';
import { ICDRReport } from '@modules/cdrReport/CDRReportTypes';
import { prepareCDRReportObjectForImport } from '@modules/dnaObject/CDR/duck/cdrReportUtils';
import { ELibrarySourceType } from '@modules/library/root/LibraryTypes';
import { useTranslation } from 'react-i18next';
import { TFunction } from 'i18next';
import { useState } from 'react';
import { isObject } from 'lodash';
import {
  useLibraryObjectImportCDRReportMutation,
  useLibraryObjectValidateCDRReportMutation,
} from '../duck/libraryObjectApi';

const LibraryObjectModalImportCDRReportContent = ({ onClose, t }: ILibraryObjectModalImportCDRReportContentProps) => {
  const [importLibraryCDRReport] = useLibraryObjectImportCDRReportMutation();
  const [validateCDRReport] = useLibraryObjectValidateCDRReportMutation();
  const confirmModal = useConfirmModal();
  const [isCascade, setIsCascade] = useState(false);
  const [selectedCascadeObjects, setSelectedCascadeObjects] = useState<ICascadeObjectsTableData[]>([]);

  const onImport: ICDRReportObjectSelectFormProps['onSubmit'] = async (
    values: (ICDRReportObject | ICDRReport)[],
    { systemInfo, overwrite, kind },
  ) => {
    if (overwrite) {
      try {
        await validateCDRReport({ cdr_names: values.map((item) => item.name) }).unwrap();

        await importLibraryCDRReport({
          data: prepareCDRReportObjectForImport(values, kind, systemInfo!),
          overwrite: overwrite,
        }).unwrap();
      } catch (error) {
        const { data } = error as QueryErrorType;

        // TODO Revert after https://redcapcloud.atlassian.net/browse/DNA-4625
        const cascadingError = isObject(data.rawData?.error) ? data.rawData?.error : {};
        confirmModal.openConfirmation({
          content: (
            <div style={{ width: '100%' }}>
              {t('confirmOverwrite.content')}
              <strong>{Object.keys(cascadingError).join(', ')}</strong>
            </div>
          ),
          data: prepareCDRReportObjectForImport(values, kind, systemInfo!),
          cascadeObjects: cascadingError,
        });

        // TODO Refactor this stuff later
        // eslint-disable-next-line no-throw-literal
        throw '';
      }
    } else {
      await importLibraryCDRReport({
        data: prepareCDRReportObjectForImport(values, kind, systemInfo!),
        overwrite,
      }).unwrap();
    }
  };

  const onOverwriteConfirm = async (data: any) => {
    try {
      const processSelectedCascadeObjects = selectedCascadeObjects.filter((el) => !el.children);
      await importLibraryCDRReport({ data, cascade_update: processSelectedCascadeObjects, overwrite: true }).unwrap();
      onClose();
    } catch (error) {
      notification.error({ message: (error as QueryErrorType).data.userMsg });
    }
  };

  const clearCascadeData = () => {
    setIsCascade(false);
    setSelectedCascadeObjects([]);
  };

  return (
    <>
      <CDRReportObjectSelectForm
        onClose={onClose}
        onSubmit={onImport}
        libraryStatuses={[LibraryStatus.Active, LibraryStatus.Development]}
        hideCurrentSource
        defaultSource={ELibrarySourceType.Library}
      />
      <ConfirmModal
        title={t('confirmOverwrite.content')}
        submitFunc={onOverwriteConfirm}
        {...confirmModal}
        closeConfirmation={() => {
          clearCascadeData();
          confirmModal.closeConfirmation();
        }}
      >
        <LibraryCascadeObjects
          data={confirmModal.confirmState?.cascadeObjects}
          isCascade={isCascade}
          setIsCascade={setIsCascade}
          setSelectedCascadeObjects={setSelectedCascadeObjects}
        />
      </ConfirmModal>
    </>
  );
};

export const LibraryObjectModalImportCDRReport = ({ open, data, onClose }: ILibraryObjectModalImportCDRReportProps) => {
  const { t } = useTranslation(['libraryObject']);

  return (
    <DraggableModal
      width={800}
      open={open}
      onCancel={onClose}
      title={t('cdrReportObject.importForm.title')}
      footer={null}
      destroyOnClose
      footerInContent={true}
    >
      <LibraryObjectModalImportCDRReportContent data={data} onClose={onClose} t={t} />
    </DraggableModal>
  );
};

export interface ILibraryObjectModalImportCDRReportProps {
  open: boolean;
  data: any;
  onClose: () => void;
}

interface ILibraryObjectModalImportCDRReportContentProps
  extends Pick<ILibraryObjectModalImportCDRReportProps, 'data' | 'onClose'> {
  t: TFunction;
}

import { STATUSES_TIMEOUT } from '@shared/GlobalConstants';
import { LazyGetTriggerType } from '@shared/GlobalTypes';
import { RefObject, useCallback, useEffect, useRef, useState } from 'react';
import { Blocker, useBlocker } from 'react-router-dom';

export const useUnsavedChanges = ({
  shouldBlock,
  onActionBlockHandler,
  onActionUnloadHandler,
}: {
  shouldBlock: () => boolean;
  onActionBlockHandler: () => void;
  onActionUnloadHandler: () => void;
}) => {
  const shouldBlockRef = useRef(shouldBlock);

  useEffect(() => {
    shouldBlockRef.current = shouldBlock;
  }, [shouldBlock]);

  const blocker: Blocker | null = useBlocker(() => {
    if (shouldBlock()) {
      onActionBlockHandler();
      return true;
    }
    return false;
  });

  const allowNavigation = () => {
    if (blocker.state === 'blocked') {
      blocker.proceed();
    }
  };

  const cancelNavigation = () => {
    if (blocker.state === 'blocked') {
      blocker.reset();
    }
  };

  const handleBeforeUnload = useCallback((e: any) => {
    if (shouldBlockRef.current()) {
      e.preventDefault();
      e.returnValue = '';

      const handleVisibilityChange = () => {
        if (document.visibilityState === 'hidden') {
          onActionUnloadHandler();
        }
      };

      document.addEventListener('visibilitychange', handleVisibilityChange, { once: true });
    }
  }, []);

  useEffect(() => {
    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, []);

  return { allowNavigation, cancelNavigation };
};

export const useClickOutside = (ref: RefObject<HTMLElement>, callback: () => void, useCapture?: boolean) => {
  const handleClick: EventListener = (event) => {
    if (ref.current && !ref.current.contains(event.target as Node)) {
      callback();
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClick, useCapture);

    return () => {
      document.removeEventListener('click', handleClick, useCapture);
    };
  });
};

export const useStatusesListener = <TArgs, TResult>(
  runStatusQuery: LazyGetTriggerType<TArgs, TResult>,
  isLoading: boolean,
  isModalOpen: boolean,
) => {
  const [statuses, setStatuses] = useState<TResult | []>([]);
  const refStatusesState: { intervalId: null | NodeJS.Timeout; lastFetch: number | null } = {
    intervalId: null,
    lastFetch: null,
  };

  let isFetchStatuses = false;

  const getStatuses = async () => {
    try {
      const data = await runStatusQuery().unwrap();
      setStatuses(data);
    } catch (err) {
      console.error(err);
    }
  };

  const currentFetchGpdipStatuses = () => {
    if (isFetchStatuses) {
      return;
    }
    refStatusesState.lastFetch = new Date().valueOf();
    isFetchStatuses = true;

    getStatuses().finally(() => {
      isFetchStatuses = false;
    });
  };

  const runStatusesLoop = () => {
    if (!refStatusesState.intervalId) {
      refStatusesState.intervalId = setInterval(currentFetchGpdipStatuses, STATUSES_TIMEOUT);
      if (!refStatusesState.lastFetch || new Date().valueOf() - refStatusesState.lastFetch > STATUSES_TIMEOUT) {
        currentFetchGpdipStatuses();
      }
    }
  };

  const stopStatusesLoop = () => {
    if (refStatusesState.intervalId) {
      clearInterval(refStatusesState.intervalId);
      refStatusesState.intervalId = null;
    }
  };

  const onVisibilityChangeHandler = (modalOpened: boolean) => () => {
    if (document.visibilityState === 'hidden' || modalOpened) {
      stopStatusesLoop();
    } else {
      runStatusesLoop();
    }
  };

  useEffect(() => {
    const onVisibilityChange = onVisibilityChangeHandler(isModalOpen);
    document.addEventListener('visibilitychange', onVisibilityChange);

    onVisibilityChange();

    return () => {
      stopStatusesLoop();
      document.removeEventListener('visibilitychange', onVisibilityChange);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isModalOpen]);

  return { statuses, isLoading };
};

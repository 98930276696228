import { selectViewerModals } from '@modules/viewer/duck/viewerSelectors';
import { ViewerModalsType } from '@modules/viewer/modals';
import {
  ViewerModalsExport,
  ViewerModalsExportProps,
  ViewerModalsTraced,
  ViewerModalsTracedProps,
  ViewerModalsDataFormats,
  ViewerModalsCellValue,
  ViewerModalsCellValueProps,
} from '@modules/viewer/modals/components';
import { viewerActions } from '@modules/viewer/duck/viewerSlice';
import { useDispatch, useSelector } from 'react-redux';

export const ViewerModalsController = () => {
  const dispatch = useDispatch();
  const { selectedModal, data } = useSelector(selectViewerModals);

  const closeModal = () => {
    dispatch(viewerActions.dropModal());
  };

  return (
    <>
      <ViewerModalsExport
        open={selectedModal === ViewerModalsType.export}
        data={data as ViewerModalsExportProps['data']}
        onClose={closeModal}
      />
      <ViewerModalsTraced
        open={selectedModal === ViewerModalsType.traced}
        data={data as ViewerModalsTracedProps['data']}
        onClose={closeModal}
      />
      <ViewerModalsCellValue
        open={selectedModal === ViewerModalsType.cellValue}
        data={data as ViewerModalsCellValueProps['data']}
        onClose={closeModal}
      />
      <ViewerModalsDataFormats open={selectedModal === ViewerModalsType.dataFormats} onClose={closeModal} />
    </>
  );
};

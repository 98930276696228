import { appApi } from '@config/appApi';
import { ISparkJobQueueItem, ITask, IUserQueue } from '@modules/task/TaskTypes';

export const TasksApiRoutes = {
  tasks: '/api/tasks',
  tasksRunning: '/api/tasks/user',
  sparkJobQueue: '/api/models/queue',
  userQueue: '/api/user/queue',
};

export const TasksApi = appApi.injectEndpoints({
  endpoints: (builder) => ({
    tasksList: builder.query<ITask[], { status: string } | void>({
      keepUnusedDataFor: 0,
      query: (params) => ({ params, url: TasksApiRoutes.tasksRunning }),
    }),
    sparkJobQueue: builder.query<ISparkJobQueueItem[], void>({
      keepUnusedDataFor: 0,
      query: (params) => ({ params, url: TasksApiRoutes.sparkJobQueue }),
    }),
    userQueue: builder.query<IUserQueue, void>({
      keepUnusedDataFor: 0,
      query: (params) => ({ params, url: TasksApiRoutes.userQueue }),
    }),
  }),
});

export const { useTasksListQuery, useSparkJobQueueQuery, useUserQueueQuery } = TasksApi;

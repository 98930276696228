import { ViewerCHTableInfoData } from '@modules/viewer/ViewerTypes';

export const tableInfoMetaToOptions = (data?: ViewerCHTableInfoData[]) =>
  data?.map((item) => ({
    label: item.name,
    value: item.name,
  })) || [];

export const isContainSpecialCharOrSpace = (value: string) => {
  const regex = /[^.\w]/;
  const regexPattern = new RegExp(regex, 'i');
  return regexPattern.test(value);
};

export const isContainTable = (sqlStatement: string, table: string) => {
  // Might be false positive f.e. inside comments or string constants,
  // but it is better than have broken model.
  const regexPattern = new RegExp(`\\.${table}\\b`, 'i');
  return regexPattern.test(sqlStatement);
};

import { IAnalysisPackageDeployment } from '@modules/library/analysisPackage/AnalysisPackageTypes';
import {
  useDeploymentQuery,
  useExportDeploymentLogMutation,
} from '@modules/library/analysisPackage/duck/analysisPackageApi';
import { PageSkeleton } from '@components';
import { Button, DraggableModal, notification, Space } from '@ui';
import { LogsInfo } from '@modules/library/analysisPackage/modals/components/deploymentLog/LogsInfo';
import { downloadByUrl } from '@shared/utils/File';
import { QueryErrorType } from '@shared/utils/Error';
import { getJsonFromString } from '@modules/library/analysisPackage/duck/analysisPackageUtils';
import { useTranslation } from 'react-i18next';
import { TFunction } from 'i18next';
import { useEffect } from 'react';

const AnalysisPackageModalsDeploymentLogContent = ({ data }: AnalysisPackageModalsDeploymentLogContentProps) => {
  const deploymentQuery = useDeploymentQuery(data?.id!, {
    skip: !data?.id,
    refetchOnMountOrArgChange: true,
  });

  // Update every 5 sec if Log is not finished yet;
  useEffect(() => {
    let refreshTimerId: NodeJS.Timeout | undefined;

    const ifRefreshNeeded = () => {
      const parsedLogs = getJsonFromString(deploymentQuery.currentData?.log ?? '');
      return !(parsedLogs === false || !!parsedLogs?.finished_at);
    };

    const runRefreshTimer = () => {
      refreshTimerId = setTimeout(() => {
        if (ifRefreshNeeded()) {
          deploymentQuery.refetch();
          return runRefreshTimer();
        }
        return clearTimeout(refreshTimerId);
      }, 5000);
    };

    if (!refreshTimerId && ifRefreshNeeded()) {
      runRefreshTimer();
    }
    return () => clearTimeout(refreshTimerId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deploymentQuery.currentData]);

  const isFetching = deploymentQuery.isLoading;
  const logs = deploymentQuery.data?.log ?? '';

  if (isFetching) {
    return <PageSkeleton hideTitle={isFetching} />;
  }

  return <LogsInfo logs={logs} isFetching={deploymentQuery.isFetching} />;
};

export const AnalysisPackageModalsDeploymentLog = ({
  open,
  data,
  onClose,
}: AnalysisPackageModalsDeploymentLogProps) => {
  const { t } = useTranslation(['analysisPackage']);
  const [exportData, { isLoading }] = useExportDeploymentLogMutation();

  const onExportLogs = async () => {
    try {
      const { url } = await exportData(data?.id!).unwrap();

      notification.info({
        message: t('deploymentLogModal.exportStarted'),
      });

      downloadByUrl(url);
    } catch (e) {
      console.error(e);

      notification.error({
        message: t('deploymentLogModal.exportError'),
        description: (e as QueryErrorType).data.userMsg,
      });
    }
  };

  return (
    <DraggableModal
      width={600}
      open={open}
      onCancel={onClose}
      title={
        <Space>
          {t('deploymentLogModal.title')}
          <Button children={t('deploymentLogModal.export')} onClick={onExportLogs} loading={isLoading} />
        </Space>
      }
      footer={null}
      destroyOnClose
    >
      <AnalysisPackageModalsDeploymentLogContent data={data} onClose={onClose} t={t} />
    </DraggableModal>
  );
};

export interface AnalysisPackageModalsDeploymentLogProps {
  open: boolean;
  data?: IAnalysisPackageDeployment;
  onClose: () => void;
}

interface AnalysisPackageModalsDeploymentLogContentProps
  extends Pick<AnalysisPackageModalsDeploymentLogProps, 'data' | 'onClose'> {
  t: TFunction;
}

import { RootState } from '@store';
import { createSelector } from '@reduxjs/toolkit';
import { Key } from 'react';
import { getApiTablesCacheKey } from './viewerUtils';

export const selectViewerDataFormats = (state: RootState) => state.viewer.dataFormats;

export const selectViewerModals = (state: RootState) => state.viewer.modals;

export const selectViewerLeftSideData = (state: RootState) => state.viewer.leftSide;

export const selectViewerSnapshot = (state: RootState) => state.viewer.snapshot;

export const selectViewerEnvironment = (state: RootState) => state.viewer.environment;

export const selectTasks = (state: RootState) => state.viewer.tasks;

export const selectViewerExpanded = (state: RootState) => state.viewer.expanded;

export const selectApiTablesCache = (state: RootState) => state.viewer.apiTablesCache;

const selectExpandedGroups = createSelector(
  selectViewerExpanded,
  selectViewerEnvironment,
  (_: any, studyId: string | number) => studyId,
  (
    expanded: Record<string, Record<number | string, (string | number)[]>>,
    environment: string,
    studyId: string | number,
  ) => {
    return (expanded || {})[studyId]?.[environment] || [];
  },
);

export const selectExpandedGroupsMemo = (studyId: string | number) => (state: RootState) =>
  selectExpandedGroups(state, studyId);

export const selectApiTablesCacheById = createSelector(
  [selectApiTablesCache, (_, props: { study_id: number; source_env?: string; role_id?: number }) => props],
  (
    apiTablesCache: RootState['viewer']['apiTablesCache'],
    props: { study_id: number; source_env?: string; role_id?: number },
  ) => {
    const key = getApiTablesCacheKey(props.study_id, props.role_id, props.source_env);
    return apiTablesCache ? apiTablesCache[key] || undefined : undefined;
  },
);

import { IDataset } from '@modules/dataset/DatasetTypes';
import { getJsonFromString } from '@modules/library/analysisPackage/duck/analysisPackageUtils';
import { Button, DraggableModal } from '@ui';
import { StackDatasetLogs } from '@modules/dataset/modals/components/StackDatasetLogs';
import { useDatasetStackLogsQuery } from '@modules/dataset/duck/datasetApi';
import { Refetch } from '@components/icons';
import { ModalDivider, PageSkeleton, QueryError } from '@components';
import { CSSObject } from '@emotion/react';
import { useTranslation } from 'react-i18next';
import { TFunction } from 'i18next';
import { useEffect } from 'react';

const DatasetModalsLogsStackDatasetContent = ({ data, t, onClose }: DatasetModalsLogsStackDatasetContentProps) => {
  const stackDatasetLogsQuery = useDatasetStackLogsQuery(data?.id!, {
    refetchOnMountOrArgChange: true,
  });

  // Update every 5 sec if Log is not finished yet;
  useEffect(() => {
    let refreshTimerId: NodeJS.Timeout | undefined;

    const ifRefreshNeeded = () => {
      const parsedLogs = getJsonFromString(stackDatasetLogsQuery.currentData?.log ?? '');
      return !(parsedLogs === false || !!stackDatasetLogsQuery.currentData?.finished_at);
    };

    const runRefreshTimer = () => {
      refreshTimerId = setTimeout(() => {
        if (ifRefreshNeeded()) {
          stackDatasetLogsQuery.refetch();
          return runRefreshTimer();
        }
        return clearTimeout(refreshTimerId);
      }, 5000);
    };

    if (!refreshTimerId && ifRefreshNeeded()) {
      runRefreshTimer();
    }
    return () => clearTimeout(refreshTimerId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stackDatasetLogsQuery.currentData]);

  if (!stackDatasetLogsQuery.data) {
    return <PageSkeleton />;
  }

  if (stackDatasetLogsQuery.error) {
    return <QueryError error={stackDatasetLogsQuery.error} title={t('stackDatasetModal.logs.logErrorTitle')} />;
  }

  return (
    <>
      <StackDatasetLogs
        logs={stackDatasetLogsQuery.data}
        isFetching={stackDatasetLogsQuery.isFetching}
        t={t}
        commonError={data?.error}
      />
      <ModalDivider />
      <div css={cssFooter}>
        <Button key="close" onClick={onClose} size="large">
          {t('close')}
        </Button>
        <Button
          key="refresh"
          size="large"
          disabled={stackDatasetLogsQuery.isFetching}
          onClick={stackDatasetLogsQuery.refetch}
          icon={<Refetch css={{ color: 'inherit', fontSize: 24 }} />}
        >
          {t('refresh')}
        </Button>
      </div>
    </>
  );
};

export const DatasetModalsLogsStackDataset = ({ open, data, onClose }: DatasetModalsLogsStackDatasetProps) => {
  const { t } = useTranslation(['dataset']);

  return (
    <DraggableModal
      css={cssModal}
      width={700}
      open={open}
      onCancel={onClose}
      title={t('stackDatasetModal.logs.title')}
      destroyOnClose
      footer={null}
      footerInContent
      withActionDivider
    >
      <DatasetModalsLogsStackDatasetContent data={data} t={t} onClose={onClose} />
    </DraggableModal>
  );
};

export interface DatasetModalsLogsStackDatasetProps {
  open: boolean;
  data?: Partial<IDataset>;
  onClose: () => void;
}

interface DatasetModalsLogsStackDatasetContentProps extends Omit<DatasetModalsLogsStackDatasetProps, 'open'> {
  t: TFunction;
}

const cssModal = (): CSSObject => ({
  '& .ant-modal-content': {
    minWidth: 500,
  },
});

const cssFooter = (): CSSObject => ({
  display: 'flex',
  justifyContent: 'end',
  columnGap: 12,
});

import { Checkbox, Space, Table, Typography } from '@ui';
import { useTablePaginationState } from '@components/ui/table/tableHooks';
import { initialPage } from '@shared/utils/common';
import { CheckboxChangeEvent } from 'antd/es/checkbox';
import { TableRowSelection } from 'antd/es/table/interface';
import { useMemo } from 'react';
import { TablePaginationConfig } from 'antd';
import { useTranslation } from 'react-i18next';

export const LibraryCascadeObjects = ({
  isCascade,
  setIsCascade,
  data = {},
  setSelectedCascadeObjects,
}: ILibraryModelModalsCascadeProps) => {
  const { t } = useTranslation();
  const { setPagination, getPagination } = useTablePaginationState(initialPage);

  const handleCascadeChange = async (event: CheckboxChangeEvent) => setIsCascade(event.target.checked);

  const columns = useMemo(
    () => [
      {
        title: t('cascadeObjects.cascadeTable.study'),
        dataIndex: 'study',
      },
      {
        title: t('cascadeObjects.cascadeTable.objects'),
        dataIndex: 'object',
      },
    ],
    [t],
  );

  const rowSelection: TableRowSelection<ICascadeObjectsTableData> = {
    onChange: (selectedRowKeys, selectedRows) => {
      setSelectedCascadeObjects(selectedRows);
    },
  };

  const transformedData = useMemo(() => {
    const processedData = data
      ? Object.keys(data).reduce((acc, obj) => {
          data[obj]?.forEach((study) => {
            const existingStudyIndex = acc.findIndex((item) => item.key === study.id);
            const child = {
              key: study.id + '_' + obj,
              study: study.name,
              studyId: study.id,
              object: obj,
            };
            if (existingStudyIndex === -1) {
              acc.push({
                key: study.id,
                study: study.name,
                studyId: study.id,
                object: obj,
                children: [child],
              });
            } else {
              const existingStudy = acc[existingStudyIndex];
              acc[existingStudyIndex] = {
                ...existingStudy,
                object: existingStudy.object + ', ' + obj,
                children: existingStudy.children ? [...existingStudy.children, child] : [child],
              };
            }
          });
          return acc;
        }, [] as ICascadeObjectsTableData[])
      : [];

    // we should not see plus sign if one child, so such children were removed
    const clearedTransformedData = processedData.map((item) => {
      if (item.children && item.children.length === 1) {
        const { children, ...rest } = item;
        return rest;
      }
      return item;
    });

    return clearedTransformedData;
  }, [data]);

  const pagination = getPagination(transformedData.length);

  const onTableChange = (tablePagination: TablePaginationConfig) => {
    setPagination(tablePagination.current!);
  };

  return (
    <Space style={{ margin: '16px 0' }} direction="vertical">
      {transformedData.length ? (
        <>
          <Space>
            <div>{t('cascadeObjects.applyCascade')}</div>
            <Checkbox id="applyCascade" onChange={handleCascadeChange} checked={isCascade} />
          </Space>
          {isCascade && (
            <Table
              sticky
              size="small"
              columns={columns}
              dataSource={transformedData}
              loading={false}
              rowSelection={{ ...rowSelection, checkStrictly: false, columnWidth: 40 }}
              rowKey={(item) => item.key}
              onChange={onTableChange}
              tableLayout="fixed"
              scroll={{ y: 400 }}
              pagination={pagination}
            />
          )}
        </>
      ) : (
        <Typography.Text type="secondary">{t('cascadeObjects.emptyCascadeObjects')}</Typography.Text>
      )}
    </Space>
  );
};

interface ILibraryModelModalsCascadeProps {
  data?: ICascadeObject;
  isCascade: boolean;
  setIsCascade: (val: boolean) => void;
  setSelectedCascadeObjects: (val: ICascadeObjectsTableData[]) => void;
}

export interface ICascadeObject {
  [key: string]: { id: number; name: string }[];
}

export interface ICascadeObjectsTableData {
  key: string | number;
  study: string;
  studyId: number;
  object: string;
  children?: { key: string; study: string; studyId: number; object: string }[];
}

import {
  EPscType,
  EPscTypeNames,
  ILibraryPsc,
  ILibraryPscResponse,
  TLibraryPscDeleteProps,
} from '@modules/library/psc/PscTypes';
import { Table } from '@ui';
import { Trash } from '@components/icons';
import { tableListToOptions } from '@shared/utils/Table';
import { useColumnSearch } from '@components/ui/table/tableHooks';
import { dateToString } from '@shared/utils/Date';
import { ActorAndInfo, ButtonWithConfirmation } from '@components';
import { SupportedEnvs } from '@app/AppTypes';
import { useMemo } from 'react';
import { TFunction } from 'i18next';
import { TableColumnsType, TableProps } from 'antd';

export const LibraryPscList = ({
  data,
  loading,
  loadingDelete,
  pagination,
  supportedEnvs,
  onChange,
  onDelete,
  t,
}: LibraryPscListProps) => {
  const { getColumnSearchProps, locale } = useColumnSearch<any>();

  const typeListOption = tableListToOptions(Object.entries(EPscTypeNames).map(([k, v]) => ({ name: v, id: k })));

  const columns: TableColumnsType<ILibraryPsc> = useMemo(
    () =>
      [
        {
          title: t('rootTable.name'),
          dataIndex: 'name',
          ...getColumnSearchProps('name'),
          onFilter: undefined,
        },
        {
          width: 150,
          title: t('rootTable.type'),
          dataIndex: 'type',
          filters: typeListOption,
          render: (type: EPscType) => (type ? EPscTypeNames[type] : 'N/A'),
        },
        {
          width: 130,
          title: t('rootTable.libraryVersion'),
          dataIndex: 'version',
        },
        {
          title: t('rootTable.source'),
          customKey: 'narrow',
          dataIndex: 'source',
          render: (_: any, record: ILibraryPsc) => (
            <ActorAndInfo
              info={supportedEnvs[record.env]?.label || t('na')}
              actor={record.source || t('crossStudy')}
              actorSize={'sm'}
            />
          ),
        },
        {
          title: t('rootTable.created'),
          customKey: 'narrow',
          dataIndex: 'created_at',
          sorter: () => 0,
          sortDirections: ['ascend', 'descend'],
          render: (createdAt: number, record: ILibraryPsc) => (
            <ActorAndInfo info={dateToString(createdAt)} actor={record.created_by} />
          ),
        },
        {
          title: t('rootTable.updated'),
          customKey: 'narrow',
          dataIndex: 'updated_at',
          sorter: () => 0,
          sortDirections: ['ascend', 'descend'],
          render: (updatedAt: number, record: ILibraryPsc) => (
            <ActorAndInfo info={dateToString(updatedAt)} actor={record.updated_by} />
          ),
        },
        onDelete && {
          width: 150,
          title: t('delete'),
          dataIndex: 'actionDelete',
          render: (_: any, { id, name }: ILibraryPsc) => (
            <ButtonWithConfirmation
              icon={<Trash color="lightGrey" />}
              type="text"
              submitFunc={() => onDelete({ id, name })}
              confirmContent={t('confirmation.description', { name })}
              confirmTitle={t('confirmation.title')}
              loading={loadingDelete}
            />
          ),
        },
      ].filter(Boolean) as TableColumnsType<ILibraryPsc>,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [loadingDelete, onDelete],
  );

  return (
    <Table
      locale={locale}
      columns={columns}
      dataSource={data?.items}
      loading={loading}
      rowKey={(item) => item.id}
      onChange={onChange}
      tableLayout="fixed"
      scroll={{ x: 900 }}
      pagination={pagination}
    />
  );
};

interface LibraryPscListProps {
  libraryId: number;
  data?: ILibraryPscResponse;
  pagination?: TableProps<ILibraryPscResponse['items'][0]>['pagination'];
  onChange?: TableProps<ILibraryPscResponse['items'][0]>['onChange'];
  onDelete?: (val: TLibraryPscDeleteProps) => void;
  loading?: boolean;
  loadingDelete?: boolean;
  pageSize?: number;
  supportedEnvs: SupportedEnvs;
  t: TFunction;
}

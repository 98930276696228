import { dataStageColors } from '@config/theme/colors';
import { ViewerGroupType } from '@modules/viewer/ViewerTypes';
import { NodeData } from '@shared/components/TableListSider/tableListSiderHooks';

const CLICKHOUSE_DB = 'data_stage';

export const getTableNameWithSchema = (tableId: string | null) => {
  // tableId can be like as Clinical:CH_001, default.rules, 123:database.table
  if (tableId) {
    const id = removeItemId(tableId);
    const [schema, tableName] = id.split('.');
    return {
      name: tableName || schema,
      schema: tableName && schema ? schema : CLICKHOUSE_DB,
    };
  }
  return { name: '', schema: '' };
};

export const generateDataViewerTableId = (storeId: string | number, tableIndex: number, tableId: string) =>
  `${storeId}~${tableIndex}:${tableId}`;

export const isContainSchema = (tableId: string) => tableId.split('.').length > 1;

export const removeItemId = (tableId: string) => tableId.split(':').at(-1) ?? tableId;

export const applySchemaToTable = (tableName: string, ch_db: string) => `${ch_db}.${tableName}`;

export const getFolderIconColor = (type: ViewerGroupType): string => {
  if (type === ViewerGroupType.internal) {
    return dataStageColors['color-brand-blue-700'];
  }

  if (type === ViewerGroupType.studyRT || type === ViewerGroupType.crossStudyRT) {
    return dataStageColors['color-grey-600'];
  }

  if (type === ViewerGroupType.external) {
    return dataStageColors['color-brand-blue-400'];
  }

  if (type === ViewerGroupType.rccGroups) {
    return dataStageColors['color-chart-cyan-02'];
  }

  if (type === ViewerGroupType.globalDS) {
    return dataStageColors['color-grey-400'];
  }

  return dataStageColors['color-grey-300'];
};

export const filteredTreeData = (treeData: NodeData[], searchText: string) =>
  treeData
    .map((group) => {
      const filteredChildren =
        group.children?.filter((child) => (child.name as string)?.toLowerCase().includes(searchText.toLowerCase())) ||
        [];
      return { ...group, children: filteredChildren };
    })
    .filter((group) => group.children?.length > 0);

const parseWithRegex = (message: string, pattern: RegExp) => {
  const match = message.match(pattern);
  return match ? match[1].trim() : message;
};

export const parsedErrorMessage = (message = '') => {
  const sqlOrDbErrorStartedMessage = /^(SQL Error|ClickHouse query failed:)/;
  const versionPattern = /\(version (\d+\.)*\d+ \(official build\)\)/g;
  const startUnknownPattern = /(Unknown .*:.*?),/;
  const builtinErrorsPattern = /(.*)\s\([A-Z_0-9]+\)\s/;

  if (sqlOrDbErrorStartedMessage.test(message)) {
    if (message.includes('DB::Exception:')) {
      const pattern = new RegExp(
        `(?:\\s*DB::Exception:)(.*?)(?:: while executing.*|\\s\\([A-Z_0-9]+\\)\\s|${versionPattern.source})`,
      );
      return parseWithRegex(message, pattern);
    }
    return message.replace(sqlOrDbErrorStartedMessage, '').trim();
  }

  if (message.includes(': While')) {
    return parseWithRegex(message, /^(.*?)(?=: While)/);
  }

  if (startUnknownPattern.test(message)) {
    return parseWithRegex(message, startUnknownPattern);
  }

  if (builtinErrorsPattern.test(message)) {
    return parseWithRegex(message, builtinErrorsPattern);
  }

  if (versionPattern.test(message)) {
    return parseWithRegex(message, new RegExp(`^(.*)${versionPattern.source}$`));
  }

  return message;
};

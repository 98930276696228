import { useTablePaginationState } from '@components/ui/table/tableHooks';
import { initialPage } from '@shared/utils/common';
import { libraryObjectActions } from '@modules/library/libraryObject/duck/libraryObjectSlice';
import { ELibraryObjectModalsType } from '@modules/library/libraryObject/libraryObjectTypes';
import { NotificationErrorDescription, PageTemplateSimple } from '@components';
import { Button, notification } from '@ui';
import { selectGlobalLibrary } from '@app/duck/appSelectors';
import { useSupportedEnvsQuery } from '@app/duck/appApi';
import { LibraryStatus } from '@modules/library/root/duck/libraryConstants';
import { useAppPermissions } from '@modules/user/duck/userHooks';
import { ICDRReportObject } from '@modules/dnaObject/CDR/CDRObjectTypes';
import { CDRReportObjectList, CDRReportObjectListProps } from '@modules/dnaObject/CDR/components/CDRReportObjectList';
import { QueryErrorType } from '@shared/utils/Error';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useLibraryObjectCDRReportListQuery, useLibraryObjectDeleteCDRReportMutation } from '../duck/libraryObjectApi';

export const LibraryObjectCDRReportRootPage = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation(['libraryObject']);
  const supportedEnvsQuery = useSupportedEnvsQuery();
  const globalLibrary = useSelector(selectGlobalLibrary);
  const { paginationState, getPagination, onTableChange, preparedFilters, preparedSorter } =
    useTablePaginationState<ICDRReportObject>(initialPage);
  const libraryCDRReportQuery = useLibraryObjectCDRReportListQuery({
    detailed: '1',
    page: paginationState.current - 1,
    page_size: paginationState.pageSize,
    ...preparedFilters,
    ...preparedSorter,
  });
  const [deleteLibraryCDRReport, deleteLibraryCDRReportQuery] = useLibraryObjectDeleteCDRReportMutation();

  const {
    appPermissions: { canGlCdroInsert, canGlLibraryUpdate, canGlCdroDelete },
  } = useAppPermissions();

  const pagination = getPagination(libraryCDRReportQuery.data?.totalItems);

  const onImportCDRReportModal = () =>
    dispatch(libraryObjectActions.pushModal({ type: ELibraryObjectModalsType.importCDRReport }));

  const onDelete: CDRReportObjectListProps['onDelete'] = async (value) => {
    const { id, name } = value;
    try {
      await deleteLibraryCDRReport(id).unwrap();
      notification.success({ message: t('cdrReportObject.confirmation.successMessage', { name }) });
    } catch (e) {
      notification.error({
        message: t('cdrReportObject.confirmation.errorMessage', { name }),
        description: <NotificationErrorDescription error={e as QueryErrorType} />,
        duration: 0,
      });
    }
  };

  const libraryIsEditable = canGlLibraryUpdate && globalLibrary?.status === LibraryStatus.Development;

  return (
    <PageTemplateSimple
      title={{
        level: 2,
        children: t('cdrReportObject.pageRootName'),
        refetch: libraryCDRReportQuery.refetch,
        extra: libraryIsEditable && canGlCdroInsert && (
          <Button children={t('import')} onClick={onImportCDRReportModal} />
        ),
      }}
      content={{
        isLoading: libraryCDRReportQuery.isLoading && !libraryCDRReportQuery.data,
        errorText: t('cdrReportObject.loadingError'),
        error: libraryCDRReportQuery.error,
      }}
    >
      {!libraryCDRReportQuery.isLoading && (
        <CDRReportObjectList
          data={libraryCDRReportQuery.data}
          onChange={onTableChange}
          onDelete={libraryIsEditable && canGlCdroDelete ? onDelete : undefined}
          pagination={pagination}
          loading={libraryCDRReportQuery.isFetching}
          loadingDelete={deleteLibraryCDRReportQuery.isLoading}
          supportedEnvs={supportedEnvsQuery.data}
          libraryId={globalLibrary?.id}
          t={t}
        />
      )}
    </PageTemplateSimple>
  );
};

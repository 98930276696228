import { IAuthStudyRolesItem } from '@modules/auth/AuthTypes';

export const setLastStudyRole = (role?: IAuthStudyRolesItem) => {
  if (typeof role?.role_id === 'number') {
    const carrentRole = JSON.stringify(role);

    sessionStorage.setItem('session_role', carrentRole);
    localStorage.setItem('last_role', carrentRole);
  } else {
    sessionStorage.removeItem('session_role');
  }
};

export const getLastStudyRole = () => {
  try {
    const lastRole = JSON.parse(
      sessionStorage.getItem('session_role') || localStorage.getItem('last_role') || '',
    ) as IAuthStudyRolesItem;
    return lastRole ?? null;
  } catch {
    return null;
  }
};

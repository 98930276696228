import { dateToString } from '@shared/utils/Date';
import { ActorAndInfo, ButtonWithConfirmation } from '@components';
import { useColumnSearch } from '@components/ui/table/tableHooks';
import { tableListToOptions } from '@shared/utils/Table';
import { Trash } from '@components/icons';
import { Table } from '@ui';
import { SupportedEnvs } from '@app/AppTypes';
import { ICodeLabObject, ICodeLabObjectListResponse } from '@modules/dnaObject/CodeLab/CodeLabObjectTypes';
import { parseCodeLabFilePath } from '@modules/codeLab/components/CodeLabFilePath';
import { useMemo } from 'react';
import { TableColumnsType, TableProps, Tooltip } from 'antd';
import { TFunction } from 'i18next';

export const CodeLabObjectList = ({
  data,
  loading,
  pagination,
  onChange,
  loadingDelete,
  supportedEnvs,
  onDelete,
  libraryId,
  t,
}: CodeLabObjectListProps) => {
  const { getColumnSearchProps, locale } = useColumnSearch<ICodeLabObject>();

  const columns = useMemo(() => {
    const sourceListOption = tableListToOptions(data?.items?.map((el) => ({ name: el.source!, id: el.source })));

    return (
      [
        {
          title: t('codeLabObject.rootTable.name'),
          dataIndex: 'name',
          ...getColumnSearchProps('name'),
          onFilter: undefined,
          render: (_, record) => (
            <Tooltip placement="top" title={parseCodeLabFilePath(record.object_file_path || '')}>
              {record.name}
            </Tooltip>
          ),
        },
        {
          title: t('codeLabObject.rootTable.libraryVersion'),
          dataIndex: 'version',
          render: (_, record) =>
            Array.isArray(record.library_id || record.versions) && (record.library_id || record.versions)?.length
              ? record.version
              : '-',
        },
        {
          title: t('codeLabObject.rootTable.source'),
          customKey: 'narrow',
          dataIndex: 'source',
          filters: sourceListOption,
          onFilter: undefined,
          render: (_, record) =>
            record.source && (
              <ActorAndInfo info={(supportedEnvs || {})[record.env]?.label || t('na')} actor={record.source} />
            ),
        },
        {
          title: t('codeLabObject.rootTable.created'),
          customKey: 'narrow',
          dataIndex: libraryId ? 'created_at' : 'linked_at',
          sorter: () => 0,
          sortDirections: ['ascend', 'descend'],
          render: (_, record) => (
            <ActorAndInfo info={dateToString(record.linked_at || record.created_at)} actor={record.created_by} />
          ),
        },
        libraryId && {
          title: t('codeLabObject.rootTable.updated'),
          customKey: 'narrow',
          dataIndex: 'updated_at',
          sorter: () => 0,
          sortDirections: ['ascend', 'descend'],
          render: (updatedAt, record) => <ActorAndInfo info={dateToString(updatedAt)} actor={record.updated_by} />,
        },
        !!onDelete && {
          width: 100,
          title: t('delete'),
          dataIndex: 'actionDelete',
          render: (_, { id, version_id, name }) => (
            <ButtonWithConfirmation
              icon={<Trash color="lightGrey" />}
              type="text"
              submitFunc={() => onDelete({ id, version_id: version_id!, name })}
              confirmContent={t('codeLabObject.confirmation.description', { name })}
              confirmTitle={t('codeLabObject.confirmation.title')}
              loading={loadingDelete}
            />
          ),
        },
      ] as TableColumnsType<ICodeLabObject>
    ).filter(Boolean);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, loadingDelete, supportedEnvs]);

  return (
    <Table
      locale={locale}
      columns={columns}
      dataSource={data?.items}
      loading={loading}
      rowKey={(item) => item.id}
      onChange={onChange}
      tableLayout="fixed"
      scroll={{ x: 900 }}
      pagination={pagination}
    />
  );
};

export interface CodeLabObjectListProps {
  data?: ICodeLabObjectListResponse;
  pagination?: TableProps<ICodeLabObject>['pagination'];
  onChange?: TableProps<ICodeLabObject>['onChange'];
  loading?: boolean;
  loadingDelete?: boolean;
  onDelete?: (data: { id: number; version_id: number; name: string }) => void;
  supportedEnvs?: SupportedEnvs;
  t: TFunction;
  libraryId?: number;
}

import { PageNavigation, PageNavigationProps } from '@components';
import { Modal, Typography } from '@ui';
import { css, CSSObject } from '@emotion/react';
import { useState } from 'react';

const releases = [
  {
    label: 'Release 2.9.13',
    time: '25.04.2024',
    link: '/static/releases/DNA-Release 2.9.13 - 26.04.2024.pdf',
  },
  {
    label: 'Release 2.9.14',
    time: '03.05.2024',
    link: '/static/releases/DNA-Release 2.9.14 - 03.05.2024.pdf',
  },
  {
    label: 'Release 2.9.15',
    time: '10.05.2024',
    link: '/static/releases/DNA-Release 2.9.15 - 10.05.2024.pdf',
  },
  {
    label: 'Release 2.9.16',
    time: '17.05.2024',
    link: '/static/releases/DNA-Release 2.9.16 - 17.05.2024.pdf',
  },
  {
    label: 'Release 2.9.17',
    time: '24.05.2024',
    link: '/static/releases/DNA-Release 2.9.17 - 24.05.2024.pdf',
  },
  {
    label: 'Release 2.9.18',
    time: '31.05.2024',
    link: '/static/releases/DNA-Release 2.9.18 - 31.05.2024.pdf',
  },
  {
    label: 'Release 2.9.19',
    time: '07.06.2024',
    link: '/static/releases/DNA-Release 2.9.19 - 07.06.2024.pdf',
  },
  {
    label: 'Release 2.9.20',
    time: '14.06.2024',
    link: '/static/releases/DNA-Release 2.9.20 - 14.06.2024.pdf',
  },
  {
    label: 'Release 2.9.21',
    time: '21.06.2024',
    link: '/static/releases/DNA-Release 2.9.21 - 21.06.2024.pdf',
  },
  {
    label: 'Release 2.9.22',
    time: '28.06.2024',
    link: '/static/releases/DNA-Release 2.9.22 - 28.06.2024.pdf',
  },
  {
    label: 'Release 2.9.23',
    time: '05.07.2024',
    link: '/static/releases/DNA-Release 2.9.23 - 05.07.2024.pdf',
  },
  {
    label: 'Release 2.9.24',
    time: '12.07.2024',
    link: '/static/releases/DNA-Release 2.9.24 - 12.07.2024.pdf',
  },
  {
    label: 'Release 2.9.25',
    time: '19.07.2024',
    link: '/static/releases/DNA-Release 2.9.25 - 19.07.2024.pdf',
  },
  {
    label: 'Release 2.9.26',
    time: '26.07.2024',
    link: '/static/releases/DNA-Release 2.9.26 - 26.07.2024.pdf',
  },
  {
    label: 'Release 2.9.27',
    time: '02.08.2024',
    link: '/static/releases/DNA-Release 2.9.27 - 02.08.2024.pdf',
  },
  {
    label: 'Release 2.9.28',
    time: '09.08.2024',
    link: '/static/releases/DNA-Release 2.9.28 - 09.08.2024.pdf',
  },
  {
    label: 'Release 2.9.29',
    time: '16.08.2024',
    link: '/static/releases/DNA-Release 2.9.29 - 16.08.2024.pdf',
  },
  {
    label: 'Release 2.9.30',
    time: '23.08.2024',
    link: '/static/releases/DNA-Release 2.9.30 - 23.08.2024.pdf',
  },
  {
    label: 'Release 2.9.31',
    time: '30.08.2024',
    link: '/static/releases/DNA-Release 2.9.31 - 30.08.2024.pdf',
  },
  {
    label: 'Release 2.9.32',
    time: '06.09.2024',
    link: '/static/releases/DNA-Release 2.9.32 - 06.09.2024.pdf',
  },
  {
    label: 'Release 2.9.33',
    time: '13.09.2024',
    link: '/static/releases/DNA-Release 2.9.33 - 13.09.2024.pdf',
  },
  {
    label: 'Release 2.9.34',
    time: '20.09.2024',
    link: '/static/releases/DNA-Release 2.9.34 - 20.09.2024.pdf',
  },
  {
    label: 'Release 2.9.35',
    time: '27.09.2024',
    link: '/static/releases/DNA-Release 2.9.35 - 27.09.2024.pdf',
  },
  {
    label: 'Release 2.9.36',
    time: '04.10.2024',
    link: '/static/releases/DNA-Release 2.9.36 - 04.10.2024.pdf',
  },
  {
    label: 'Release 2.9.37',
    time: '11.10.2024',
    link: '/static/releases/DNA-Release 2.9.37 - 11.10.2024.pdf',
  },
  {
    label: 'Release 2.9.38',
    time: '18.10.2024',
    link: '/static/releases/DNA-Release 2.9.38 - 18.10.2024.pdf',
  },
  {
    label: 'Release 2.9.39',
    time: '25.10.2024',
    link: '/static/releases/DNA-Release 2.9.39 - 25.10.2024.pdf',
  },
  {
    label: 'Release 2.9.40',
    time: '01.11.2024',
    link: '/static/releases/DNA-Release 2.9.40 - 01.11.2024.pdf',
  },
  {
    label: 'Release 2.9.41',
    time: '11.11.2024',
    link: '/static/releases/DNA-Release 2.9.41 - 11.11.2024.pdf',
  },
  {
    label: 'Release 2.9.42',
    time: '15.11.2024',
    link: '/static/releases/DNA-Release 2.9.42 - 15.11.2024.pdf',
  },
  {
    label: 'Release 2.9.43',
    time: '22.11.2024',
    link: '/static/releases/DNA-Release 2.9.43 - 22.11.2024.pdf',
  },
  {
    label: 'Release 2.9.44',
    time: '29.11.2024',
    link: '/static/releases/DNA-Release 2.9.44 - 29.11.2024.pdf',
  },
  {
    label: 'Release 2.9.45',
    time: '06.12.2024',
    link: '/static/releases/DNA-Release 2.9.45 - 06.12.2024.pdf',
  },
  {
    label: 'Release 2.9.46',
    time: '13.12.2024',
    link: '/static/releases/DNA-Release 2.9.46 - 13.12.2024.pdf',
  },
  {
    label: 'Release 2.9.47',
    time: '20.12.2024',
    link: '/static/releases/DNA-Release 2.9.47 - 20.12.2024.pdf',
  },
  {
    label: 'Release 2.9.48',
    time: '27.12.2024',
    link: '/static/releases/DNA-Release 2.9.48 - 27.12.2024.pdf',
  },
  {
    label: 'Release 2.9.49',
    time: '03.01.2025',
    link: '/static/releases/DNA-Release 2.9.49 - 03.01.2025.pdf',
  },
  {
    label: 'Release 2.9.50',
    time: '10.01.2025',
    link: '/static/releases/DNA-Release 2.9.50 - 10.01.2025.pdf',
  },
  {
    label: 'Release 2.9.51',
    time: '17.01.2025',
    link: '/static/releases/DNA-Release 2.9.51 - 17.01.2025.pdf',
  },
  {
    label: 'Release 2.9.52',
    time: '24.01.2025',
    link: '/static/releases/DNA-Release 2.9.52 - 24.01.2025.pdf',
  },
  {
    label: 'Release 2.9.53',
    time: '31.01.2025',
    link: '/static/releases/DNA-Release 2.9.53 - 31.01.2025.pdf',
  },
  {
    label: 'Release 2.9.54',
    time: '07.02.2025',
    link: '/static/releases/DNA-Release 2.9.54 - 07.02.2025.pdf',
  },
  {
    label: 'Release 2.9.55',
    time: '14.02.2025',
    link: '/static/releases/DNA-Release 2.9.55 - 14.02.2025.pdf',
  },
  {
    label: 'Release 2.9.56',
    time: '21.02.2025',
    link: '/static/releases/DNA-Release 2.9.56 - 21.02.2025.pdf',
  },
  {
    label: 'Release 2.9.57',
    time: '28.02.2025',
    link: '/static/releases/DNA-Release 2.9.57 - 28.02.2025.pdf',
  },
  {
    label: 'Release 2.9.58',
    time: '07.03.2025',
    link: '/static/releases/DNA-Release 2.9.58 - 07.03.2025.pdf',
  },
  {
    label: 'Release 2.9.59',
    time: '14.03.2025',
    link: '/static/releases/DNA-Release 2.9.59 - 14.03.2025.pdf',
  },
  {
    label: 'Release 2.9.60',
    time: '21.03.2025',
    link: '/static/releases/DNA-Release 2.9.60 - 21.03.2025.pdf',
  },
  {
    label: 'Release 2.9.61',
    time: '28.03.2025',
    link: '/static/releases/DNA-Release 2.9.61 - 28.03.2025.pdf',
  },
];

export const ReleasesModal = ({ open, onClose }: IReleasesModalProps) => {
  const [currentRelease, setCurrentRelease] = useState<(typeof releases)[0] | null>(null);

  const breadcrumbs: PageNavigationProps['breadcrumbs'] = [
    {
      label: 'Releases',
      click: () => setCurrentRelease(null),
    },
  ];

  if (currentRelease) {
    breadcrumbs.push({
      label: currentRelease.label,
      menu: releases.map((release) =>
        release.label === currentRelease.label
          ? { label: release.label }
          : {
              label: release.label,
              click: () => setCurrentRelease(release),
            },
      ),
    });
  }

  return (
    <Modal open={open} onCancel={onClose} css={cssModal} width="100%" footer={null}>
      <PageNavigation hideBackBtn breadcrumbs={breadcrumbs} />
      {!currentRelease ? (
        <>
          <Typography.Title children="Releases" />
          <Typography.Paragraph>
            <ul>
              {releases.map((release) => (
                <li key={release.label}>
                  <Typography.Link onClick={() => setCurrentRelease(release)}>
                    {release.label} <Typography.Text type="secondary">{release.time}</Typography.Text>
                  </Typography.Link>
                </li>
              ))}
            </ul>
          </Typography.Paragraph>
        </>
      ) : (
        <iframe css={cssIframe} src={currentRelease.link} title={currentRelease.label} />
      )}
    </Modal>
  );
};

export interface IReleasesModalProps {
  open: boolean;
  onClose: () => void;
}

const cssModal = (): CSSObject => ({
  top: 20,
  maxHeight: `calc(100vh - 20px)`,
  height: '100%',
  width: '100%',

  '& .ant-modal-content': {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    width: '100%',
  },

  '& .ant-modal-body': {
    flex: 1,
    paddingTop: '32px',
    display: 'flex',
    flexDirection: 'column',
  },
});

const cssIframe = css({
  width: '100%',
  height: 'calc(100vh - 150px)',
});

import { useTablePaginationState } from '@components/ui/table/tableHooks';
import { Button, notification } from '@ui';
import { initialPage, isCrossStudy } from '@shared/utils/common';
import { NotificationErrorDescription, PageTemplateSimple } from '@components';
import { QueryErrorType } from '@shared/utils/Error';
import { useSupportedEnvsQuery } from '@app/duck/appApi';
import { useStudyPermissions } from '@modules/user/duck/userHooks';
import { CDRReportObjectList, CDRReportObjectListProps } from '@modules/dnaObject/CDR/components/CDRReportObjectList';
import { ICDRReportObjectListResponse } from '@modules/dnaObject/CDR/CDRObjectTypes';
import { selectGlobalStudy } from '@app/duck/appSelectors';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { ComponentType } from 'react';
import {
  useAnalysisObjectCDRReportFiltersQuery,
  useAnalysisObjectCDRReportListQuery,
  useDeleteAnalysisObjectCDRReportMutation,
} from '../duck/analysisObjectApi';
import { analysisObjectActions } from '../duck/analysisObjectSlice';
import { EAnalysisObjectModalsType } from '../AnalysisObjectTypes';

const wrapMixPermissions = (Component: ComponentType<IAnalysisObjectCDRReportRootPageProps>) => {
  const WrapperComponent = () => {
    const globalStudy = useSelector(selectGlobalStudy);
    const {
      userPermissions: { canGlCdrAoImport, canGlCdrAoDelete },
    } = useStudyPermissions();

    if (isCrossStudy(globalStudy?.id)) {
      console.error("Global CDR Report doesn't supported");
    }

    return <Component mixCanCDRReportAoImport={canGlCdrAoImport} mixCanCDRReportAoDelete={canGlCdrAoDelete} />;
  };

  return WrapperComponent;
};

export const AnalysisObjectCDRReportRootPage = wrapMixPermissions(
  ({ mixCanCDRReportAoImport, mixCanCDRReportAoDelete }) => {
    const dispatch = useDispatch();
    const { t } = useTranslation(['analysisObjects']);
    const supportedEnvsQuery = useSupportedEnvsQuery();
    const { paginationState, getPagination, onTableChange, preparedFilters, preparedSorter } =
      useTablePaginationState<ICDRReportObjectListResponse['items'][0]>(initialPage);

    const analysisObjectCDRReportList = useAnalysisObjectCDRReportListQuery({
      detailed: '1',
      page: paginationState.current - 1,
      page_size: paginationState.pageSize,
      ...preparedFilters,
      ...preparedSorter,
    });

    const cdrFilterOptions = useAnalysisObjectCDRReportFiltersQuery();

    const [daleteAnalysisObjectCDRReport, daleteAnalysisObjectCDRReportQuery] =
      useDeleteAnalysisObjectCDRReportMutation();

    const pagination = getPagination(analysisObjectCDRReportList.data?.totalItems);

    const importCDRReport = () =>
      dispatch(analysisObjectActions.pushModal({ type: EAnalysisObjectModalsType.importCDRReport }));

    const onDeleteAnalysisObjectCDRReport: CDRReportObjectListProps['onDelete'] = async (value) => {
      const { name, version_id } = value;
      try {
        await daleteAnalysisObjectCDRReport(version_id!).unwrap();
        notification.success({ message: t('cdrReportObject.modals.deleteSuccess', { name }) });
      } catch (e) {
        notification.error({
          message: t('cdrReportObject.modals.deleteError', { name }),
          description: <NotificationErrorDescription error={e as QueryErrorType} />,
          duration: 0,
        });
      }
    };

    return (
      <PageTemplateSimple
        content={{
          isLoading: analysisObjectCDRReportList.isLoading && !analysisObjectCDRReportList.data,
          errorText: t('cdrReportObject.loadingListError'),
          error: analysisObjectCDRReportList.error,
        }}
        title={{
          children: t('cdrReportObject.rootPageName'),
          pageTitle: t('cdrReportObject.pageTitle'),
          refetch: analysisObjectCDRReportList.refetch,
          extra: mixCanCDRReportAoImport && <Button children={t('import')} onClick={importCDRReport} />,
        }}
      >
        {analysisObjectCDRReportList.data && (
          <CDRReportObjectList
            data={analysisObjectCDRReportList.data}
            filterOptions={cdrFilterOptions.data}
            onChange={onTableChange}
            pagination={pagination}
            loading={analysisObjectCDRReportList.isFetching}
            onDelete={mixCanCDRReportAoDelete ? onDeleteAnalysisObjectCDRReport : undefined}
            loadingDelete={daleteAnalysisObjectCDRReportQuery.isLoading}
            supportedEnvs={supportedEnvsQuery.data}
            t={t}
          />
        )}
        {/* <AnalysisObjectCDRModalsController /> */}
      </PageTemplateSimple>
    );
  },
);

interface IAnalysisObjectCDRReportRootPageProps {
  mixCanCDRReportAoImport?: boolean;
  mixCanCDRReportAoDelete?: boolean;
}

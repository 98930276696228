import { IAppStudyContext } from '@app/AppTypes';
import { appActions } from '@app/duck/appSlice';
import { StudyModalsPushModalPayload, StudyModalsState } from '@modules/study/modals';
import { isValidStudyId } from '@routes/utils';
import { IAuthStudyRolesItem, TAuthStudyRolesPermissions } from '@modules/auth/AuthTypes';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { getLastStudyRole, setLastStudyRole } from './studyUtils';

interface StudyStore {
  modals: StudyModalsState;
  ready: boolean;
  preloaded: boolean;
  fallbackCHDB: string;
  activeUserRole: IAuthStudyRolesItem | null;
  userPermissionsReady: boolean;
  userPermissions: Partial<TAuthStudyRolesPermissions>;
}

const initialState: StudyStore = {
  modals: { selectedModal: null },
  preloaded: false,
  ready: false,
  fallbackCHDB: '',
  activeUserRole: getLastStudyRole(),
  userPermissionsReady: false,
  userPermissions: {},
};

export const studySlice = createSlice({
  name: 'study',
  initialState,
  reducers: {
    pushModal: (state, action: PayloadAction<StudyModalsPushModalPayload>) => {
      const { type } = action.payload;
      state.modals = { selectedModal: type };
    },
    dropModal: (state, action: PayloadAction<void>) => {
      state.modals = initialState.modals;
    },
    setActiveUserRole: (state, action: PayloadAction<IAuthStudyRolesItem>) => {
      state.activeUserRole = action.payload;
      setLastStudyRole(action.payload);
    },
    setUserPermissions: (state, action: PayloadAction<TAuthStudyRolesPermissions>) => {
      state.userPermissions = action.payload;
      state.userPermissionsReady = true;
    },
    setReady: (state, action: PayloadAction<boolean>) => {
      state.ready = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(appActions.setContext, (state, { payload }) => {
        const context = payload.context as IAppStudyContext | null;
        const isStudyId = isValidStudyId(context?.id);

        const isPreloaded = payload.type === 'study' && isStudyId && !!context?.configured;

        state.ready = isPreloaded && !!(context?.name && context.studySchema);
        state.preloaded = isPreloaded;
        state.fallbackCHDB = context?.studySchema || '';
        if (!context) {
          state.activeUserRole = null;
        }
        if (!context?.name) {
          state.userPermissions = initialState.userPermissions;
          state.userPermissionsReady = initialState.userPermissionsReady;
        }
      })
      .addDefaultCase((state, action) => {});
  },
});

export const studyActions = studySlice.actions;

export const studyReducer = studySlice.reducer;

import {
  DraggableModal,
  Form,
  FormItem,
  FormLayout,
  Input,
  notification,
  Radio,
  Select,
  Skeleton,
  Space,
  Tabs,
} from '@ui';
import { QueryErrorType } from '@shared/utils/Error';
import { EDatasetType, IDataset, IStackDatasetCreateQueryParams } from '@modules/dataset/DatasetTypes';
import { useStudyListAllQuery } from '@modules/study/duck/studyApi';
import { useCreateStackDatasetMutation } from '@modules/dataset/duck/datasetApi';
import { getPreparedValues } from '@modules/dataset/duck/datasetUtils';
import { useAppContext } from '@app/AppContext';
import {
  getDefaultInitialValue,
  getDefaultInitialValueGpdip,
  GpdipForm,
  prepareFields,
  prepareFieldsGpdip,
  SchedulerRunForm,
  SchedulerRunFormValues,
  SchedulerRunFormValuesGpdip,
} from '@components/form';
import { useAppPermissions, useFeatures } from '@modules/user/duck/userHooks';
import { ConfirmModal, useConfirmModal } from '@components';
import { useTranslation } from 'react-i18next';
import { TFunction } from 'i18next';
import { isArray } from 'lodash';
import { TabsProps } from 'antd';

export enum FieldFormScopeTypeValue {
  AllStudies = 'all-studies',
  Studies = 'studies',
}

const tabs = {
  scheduler: {
    id: 'scheduler',
  },
  gpdip: {
    id: 'gpdip',
  },
};

const CONFLICT_STATUS = 409;

const DatasetModalsEditStackDatasetContent = ({
  data,
  t,
  onClose,
}: IDatasetModalsEditStackDatasetContentContentProps) => {
  const [form] = Form.useForm();
  const studyListQuery = useStudyListAllQuery();
  const { notificationApi } = useAppContext();
  const [createStackDataset, createStackDatasetQuery] = useCreateStackDatasetMutation();
  const { hasGPDIP } = useFeatures();
  const {
    appPermissions: { canCrossGpdipConfigure },
  } = useAppPermissions();
  const confirmModal = useConfirmModal();

  const onSubmit = async (values: IDatasetModalsEditStackDatasetFieldFormValues) => {
    try {
      await createStackDataset({ data: getPreparedValues(values, data) }).unwrap();

      notificationApi.success({
        message: data?.id
          ? t('stackDatasetModal.notification.successUpdateMessage', { name: data?.name })
          : t('stackDatasetModal.notification.successCreateMessage', { name: data?.name }),
      });
      onClose();
    } catch (error) {
      const { status, data: errorData } = error as QueryErrorType;

      if (status === CONFLICT_STATUS) {
        confirmModal.openConfirmation({
          content: (
            <Space direction="vertical">
              {(error as QueryErrorType).data.userMsg}
              {t('shared.confirmOverwrite.content')}
            </Space>
          ),
          data: getPreparedValues(values, data),
        });
      } else {
        notification.error({
          message: t('shared.errors.smthWrong'),
          description: (error as QueryErrorType).data.userMsg,
        });
      }
    }
  };

  const onHandleOverwrite = async (data: IStackDatasetCreateQueryParams['data']) => {
    try {
      await createStackDataset({ data, overwrite: true }).unwrap();
      onClose();
    } catch (error) {
      notification.error({
        message: t('shared.errors.smthWrong'),
        description: (error as QueryErrorType).data.userMsg,
      });
    }
  };

  const initValues = (data && {
    name: data?.name,
    type: data?.type,
    scope_type: data?.scope === 'tenant' ? FieldFormScopeTypeValue.AllStudies : FieldFormScopeTypeValue.Studies,
    studies: isArray(data?.scope) ? data?.scope.map((item: any) => item.id) : [],
    job: data?.job ? prepareFields(data.job!) : getDefaultInitialValue(),
    export: data?.export ? prepareFieldsGpdip(data.export!) : getDefaultInitialValueGpdip(),
  }) || { job: getDefaultInitialValue(), export: getDefaultInitialValueGpdip() };

  const errors =
    createStackDatasetQuery.error && (createStackDatasetQuery.error as QueryErrorType).status !== CONFLICT_STATUS
      ? [
          `${(createStackDatasetQuery.error as QueryErrorType).data.userMsg!}: ${
            (createStackDatasetQuery.error as QueryErrorType).data.devMsg ?? 'No details'
          }`,
        ]
      : undefined;

  return (
    <>
      <FormLayout
        form={form}
        onCancel={onClose}
        onSubmit={onSubmit}
        okText={t('save')}
        initialValues={initValues}
        disabled={createStackDatasetQuery.isLoading}
        submitIsDisabled={createStackDatasetQuery.isLoading}
        cancelIsDisabled={createStackDatasetQuery.isLoading}
        errors={errors}
      >
        <FormItem name="name" label={t('stackDatasetModal.name')}>
          <Input disabled />
        </FormItem>
        <FormItem name="scope_type" label={t('stackDatasetModal.scopeType')}>
          <Radio.Group>
            <Radio value={FieldFormScopeTypeValue.AllStudies}>{t('stackDatasetModal.allStudies')}</Radio>
            <Radio value={FieldFormScopeTypeValue.Studies}>{t('stackDatasetModal.studies')}</Radio>
          </Radio.Group>
        </FormItem>
        <FormItem dependencies={['scope_type']} asInfo wrapperCol={{ span: 24 }}>
          {(form) => {
            if (form.getFieldValue('scope_type') === FieldFormScopeTypeValue.Studies) {
              return (
                <FormItem
                  name="studies"
                  label={t('stackDatasetModal.studies')}
                  labelCol={{ span: 8 }}
                  wrapperCol={{ span: 16 }}
                >
                  {studyListQuery.isFetching ? (
                    <Skeleton.Input active size="small" />
                  ) : (
                    <Select
                      options={studyListQuery.data}
                      placeholder={t('select')}
                      loading={studyListQuery.isFetching}
                      filterOption={(input, option) => (option?.name ?? '').toLowerCase().includes(input.toLowerCase())}
                      fieldNames={{ label: 'name', value: 'id' }}
                      mode="multiple"
                    />
                  )}
                </FormItem>
              );
            }
            return null;
          }}
        </FormItem>
        <FormItem wrapperCol={{ span: 24 }}>
          <Tabs
            defaultActiveKey={tabs.scheduler.id}
            size="small"
            items={
              [
                {
                  key: tabs.scheduler.id,
                  label: t(`tabs.${tabs.scheduler.id}`),
                  children: (
                    <FormItem wrapperCol={{ span: 24 }}>
                      <SchedulerRunForm isDisabledProp={false} showShedulerLabel renderActiveField isStackDataset />
                    </FormItem>
                  ),
                },
                hasGPDIP &&
                  canCrossGpdipConfigure && {
                    key: tabs.gpdip.id,
                    label: t(`tabs.${tabs.gpdip.id}`),
                    children: <GpdipForm />,
                  },
              ].filter((el) => el) as TabsProps['items']
            }
          />
        </FormItem>
      </FormLayout>

      <ConfirmModal title={t('shared.confirmOverwrite.title')} submitFunc={onHandleOverwrite} {...confirmModal} />
    </>
  );
};

export const DatasetModalsEditStackDataset = ({ open, data, onClose }: IDatasetModalsEditStackDatasetProps) => {
  const { t } = useTranslation(['dataset']);

  return (
    <DraggableModal
      width={700}
      open={open}
      onCancel={onClose}
      title={data?.type === EDatasetType.Stack ? t('stackDatasetModal.editTitle') : t('stackDatasetModal.createTitle')}
      footer={null}
      destroyOnClose
      footerInContent={true}
    >
      {open && <DatasetModalsEditStackDatasetContent data={data} onClose={onClose} t={t} />}
    </DraggableModal>
  );
};

export interface IDatasetModalsEditStackDatasetProps {
  open: boolean;
  data?: Partial<IDataset>;
  onClose: () => void;
}

interface IDatasetModalsEditStackDatasetContentContentProps
  extends Pick<IDatasetModalsEditStackDatasetProps, 'data' | 'onClose'> {
  t: TFunction;
}

export interface IDatasetModalsEditStackDatasetFieldFormValues {
  name: string;
  type: EDatasetType;
  scope_type: FieldFormScopeTypeValue;
  studies: string[];
  job?: SchedulerRunFormValues & { active: boolean };
  export?: SchedulerRunFormValuesGpdip;
}

import { Alert, DraggableModal, Form, FormItem, FormLayout, Select, Space, Typography } from '@ui';
import {
  ModelEditorNodeUnion,
  ModelEditorNodeUnionType,
  ModelEditorNodeUnionMode,
} from '@modules/modelEditor/ModelEditorTypes';
import { useSourceTableInfoAnalyzer } from '@modules/modelEditor/duck/modelEditorSourceTableInfoAnalyzer';
import { findNodeById, findSourceNodesByNodeId } from '@modules/modelEditor/components/builder/Utils';
import { selectModelEditorReadOnly } from '@modules/modelEditor/duck/modelEditorSelectors';
import { useTranslation } from 'react-i18next';
import { TFunction, t } from 'i18next';
import { useSelector } from 'react-redux';
import { CSSObject } from '@emotion/react';
import { useSaveStage } from './modelEditorModalsHooks';

const modeOptions = [
  {
    label: t('model.union.options.all'),
    value: ModelEditorNodeUnionType.all,
  },
  {
    label: t('model.union.options.distinct'),
    value: ModelEditorNodeUnionType.distinct,
  },
];

const typeOptions = [
  {
    label: t('model.union.options.byName'),
    value: ModelEditorNodeUnionMode.byName,
  },
  {
    label: t('model.union.options.byPosition'),
    value: ModelEditorNodeUnionMode.byPosition,
  },
];

const ModelEditorModalsUnionSettingsContent = ({ data, onClose, t }: ModelEditorModalsUnionSettingsContentProps) => {
  const [form] = Form.useForm();
  const readOnly = useSelector(selectModelEditorReadOnly);
  const { onSubmit } = useSaveStage(data.nodeId, onClose);
  const { loading, sourceColumns, edges, nodes } = useSourceTableInfoAnalyzer(data.nodeId);

  const sourceNodes = findSourceNodesByNodeId(data.nodeId, edges);

  const sourceLeftFields = sourceColumns[0]?.map((item) => item.name) || [];
  const sourceRightFields = sourceColumns[1]?.map((item) => item.name) || [];

  const sourceLeft = findNodeById(nodes, sourceNodes[0]);
  const sourceRight = findNodeById(nodes, sourceNodes[1]);

  const nodeErrors = [];

  if (!loading) {
    if (sourceColumns[0] === undefined || sourceColumns[1] === undefined) {
      nodeErrors.push(t('union.errors.missingSource'));
    }
    if (sourceLeftFields.length !== sourceRightFields.length) {
      nodeErrors.push(t('union.errors.wrongColumnsCount'));
    }
  }

  const initValues = data.initData || {};

  return (
    <FormLayout
      form={form}
      labelCol={{ span: 4 }}
      wrapperCol={{ span: 20 }}
      readOnly={readOnly}
      layout="horizontal"
      onCancel={onClose}
      onSubmit={onSubmit}
      okText={t('save')}
      initialValues={initValues}
      submitIsDisabled={!!nodeErrors.length || loading}
    >
      <FormItem>
        <Space direction="vertical">
          <Space>
            <Typography.Text css={cssLabel}>{t('join.leftSource')}:</Typography.Text>
            <Typography.Text>{sourceLeft?.data?.tableName}</Typography.Text>
          </Space>
          <Space>
            <Typography.Text css={cssLabel}>{t('join.rightSource')}:</Typography.Text>
            <Typography.Text>{sourceRight?.data?.tableName}</Typography.Text>
          </Space>
        </Space>
      </FormItem>
      <FormItem wrapperCol={{ span: 24 }}>
        <Alert style={{ margin: '30px 0' }} message={t('union.note')} type="info" />
      </FormItem>
      <FormItem name="mode" label={t('union.mode')} rules={[{ required: true }]}>
        <Select options={typeOptions} placeholder={t('union.modePlaceholder')} />
      </FormItem>
      <FormItem name="type" label={t('union.type')} rules={[{ required: true }]}>
        <Select options={modeOptions} placeholder={t('union.typePlaceholder')} />
      </FormItem>
      {!!nodeErrors.length && (
        <FormItem wrapperCol={{ span: 24 }}>
          <Alert
            style={{ margin: '30px 0 24px' }}
            message={<Space direction="vertical" children={nodeErrors} />}
            type="error"
          />
        </FormItem>
      )}
    </FormLayout>
  );
};

export const ModelEditorModalsUnionSettings = ({ open, data, onClose }: ModelEditorModalsUnionSettingsProps) => {
  const { t } = useTranslation(['model']);

  return (
    <DraggableModal
      open={open}
      onCancel={onClose}
      title={t('union.title')}
      footer={null}
      destroyOnClose
      footerInContent
    >
      {open && <ModelEditorModalsUnionSettingsContent data={data} onClose={onClose} t={t} />}
    </DraggableModal>
  );
};

const cssLabel = (): CSSObject => ({
  width: 120,
});

interface ModelEditorModalsUnionSettingsContentProps
  extends Pick<ModelEditorModalsUnionSettingsProps, 'data' | 'onClose'> {
  t: TFunction;
}

export interface ModelEditorModalsUnionSettingsProps {
  open: boolean;
  data: { nodeId: string; initData?: ModelEditorNodeUnion };
  onClose: () => void;
}

import {
  getDefaultInitialValue,
  getDefaultInitialValueGpdip,
  prepareFieldsGpdip,
  prepareUpdateData,
  prepareUpdateDataExport,
} from '@components/form';
import { IDataset } from '@modules/dataset/DatasetTypes';
import {
  FieldFormScopeTypeValue,
  IDatasetModalsEditStackDatasetFieldFormValues,
} from '@modules/dataset/modals/components';

export const getPreparedValues = (values: IDatasetModalsEditStackDatasetFieldFormValues, data?: Partial<IDataset>) => ({
  name: values.name,
  type: values?.type ?? data?.type,
  scope:
    values.scope_type === FieldFormScopeTypeValue.AllStudies
      ? 'tenant'
      : (values.studies as any).map((item: any) => item.toString()).join(','),
  job: prepareUpdateData({ values: { ...(values.job || getDefaultInitialValue()), isStackDataset: true } }),
  export: prepareUpdateDataExport({
    values: values.export || (data?.export ? prepareFieldsGpdip(data?.export!) : getDefaultInitialValueGpdip()),
  }),
});

import { useColumnSearch } from '@components/ui/table/tableHooks';
import { dateToString } from '@shared/utils/Date';
import { Table, Typography } from '@ui';
import { libraryRoutes } from '@routes/library';
import { useAppPermissions } from '@modules/user/duck/userHooks';
import { ColumnType, TableProps } from 'antd/lib/table';
import { Link } from 'react-router-dom';
import { TableColumnsType } from 'antd';
import { useTranslation } from 'react-i18next';
import { useMemo } from 'react';
import { LibraryStatusTag } from './LibraryStatusTag';
import { LibraryActionButtons } from './LibraryActionButtons';
import { Library, LibraryListResponse } from '../LibraryTypes';

export const LibraryList = ({ data, loading, pagination, onChange }: LibraryListProps) => {
  const { t } = useTranslation(['libraryRoot']);
  const { getColumnSearchProps, locale } = useColumnSearch<Library>();

  const {
    appPermissions: { canGlLibraryUpdate },
  } = useAppPermissions();

  const columns: TableColumnsType<Library> = useMemo(
    () =>
      [
        {
          title: t('root.table.name'),
          dataIndex: 'name',
          key: 'name',
          ...getColumnSearchProps('name'),
          onFilter: undefined,
          render: (_: any, record: Library) => (
            <Link
              to={libraryRoutes.view.resolver({ libraryId: record.id })}
              state={{ name: record.name, status: record.status }}
              children={record.name}
            />
          ),
        },
        {
          title: t('root.table.description'),
          render: (_: any, record: Library) => record.description,
        },
        {
          title: t('root.table.created'),
          customKey: 'narrow',
          dataIndex: 'created_at',
          sorter: () => 0,
          sortDirections: ['ascend', 'descend'],
          render: (createdAt: number, record: Library) => (
            <>
              <div>{record.created_by}</div>
              <Typography.Text type="secondary">{dateToString(createdAt)}</Typography.Text>
            </>
          ),
        },
        {
          width: 290,
          title: t('root.table.status'),
          render: (_: any, record: Library) => <LibraryStatusTag status={record.status} />,
        },
        canGlLibraryUpdate && {
          width: 250,
          title: t('actions'),
          render: (_: any, record: Library) => <LibraryActionButtons record={record} t={t} />,
        },
      ].filter((item) => typeof item !== 'boolean') as ColumnType<Library>[],
    [canGlLibraryUpdate, t],
  );

  return (
    <Table
      locale={locale}
      columns={columns}
      dataSource={data.items}
      loading={loading}
      rowKey={(item) => item.id}
      tableLayout="fixed"
      scroll={{ x: 1200 }}
      pagination={pagination}
      onChange={onChange}
    />
  );
};

interface LibraryListProps {
  data: LibraryListResponse;
  loading?: boolean;
  pagination?: TableProps<LibraryListResponse['items'][0]>['pagination'];
  onChange?: TableProps<LibraryListResponse['items'][0]>['onChange'];
  pageSize?: number;
}

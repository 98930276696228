export enum EAnalysisPackageType {
  'saveAnalysisPackage' = 'saveAnalysisPackage',
  'deployAnalysisPackage' = 'deployAnalysisPackage',
  'deploymentLog' = 'deploymentLog',
}

export const ProdLabels = ['EXEC', 'PROD', 'exec', 'prod'];
export const UatLabels = ['UAT', 'uat'];

export const initialTabPage = {
  current: 1,
  pageSize: 5,
  pageSizeOptions: [],
};

import {
  Button,
  Popover,
  Space,
  Table,
  TableExtraConfig,
  TableFiltersConfig,
  TableSorterConfig,
  Tag,
  Typography,
} from '@ui';
import { getFormattedDatetime } from '@shared/utils/Date';
import { StackDatasetLogStatus } from '@modules/dataset/modals/DatasetModalsConstants';
import { IDatasetLogResponse } from '@modules/dataset/DatasetTypes';
import { useColumnSearch, useTablePaginationState } from '@components/ui/table/tableHooks';
import { getJsonFromString } from '@modules/library/analysisPackage/duck/analysisPackageUtils';
import { QuestionMark } from '@components/icons';
import { Loader } from '@components/Loader';
import { TFunction } from 'i18next';
import { TableColumnsType, TablePaginationConfig } from 'antd';
import { CSSProperties, useMemo } from 'react';
import { CSSObject } from '@emotion/react';

const initialPage = {
  current: 1,
  pageSize: 10,
  pageSizeOptions: [],
};

export const StackDatasetLogs = ({ logs, isFetching, commonError, t }: StackDatasetLogsProps) => {
  const { getColumnSearchProps, locale } = useColumnSearch<LogsTable>();
  const { setPagination, getPagination } = useTablePaginationState(initialPage);

  const logsData = logs?.log ?? '';

  const columns: TableColumnsType<LogsTable> = useMemo(() => {
    const statusOptions = Object.entries(StackDatasetLogStatus).map(([key, value]) => ({
      text: t(`stackDatasetModal.logs.status.${value}`),
      value: key,
    }));

    return [
      {
        title: t('stackDatasetModal.logs.table.studyName'),
        dataIndex: 'study_name',
        ...getColumnSearchProps('study_name'),
        ellipsis: true,
      },
      {
        width: 150,
        title: t('stackDatasetModal.logs.table.status'),
        dataIndex: 'status',
        filters: statusOptions,
        onFilter: (status, record) => record.status === status,
        render: (_, item) => {
          return (
            <Space full align="center" css={cssObjectItemsContainer}>
              <Tag color={item?.status?.toLowerCase()}>{item?.status}</Tag>
              {item?.error && (
                <Popover content={item?.error} placement="right" styles={{ root: cssLogErrorDetail }}>
                  <Button css={cssErrorButton} type="text" icon={<QuestionMark css={cssIcon} />} />
                </Popover>
              )}
            </Space>
          );
        },
      },
    ];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const pagination = getPagination();
  const onTableChange = (
    tablePagination: TablePaginationConfig,
    filters: TableFiltersConfig,
    sorter: TableSorterConfig<LogsTable>,
    extra: TableExtraConfig<LogsTable>,
  ) => {
    setPagination(tablePagination.current!, tablePagination.pageSize, extra.currentDataSource.length);
  };

  const logsParsed = getJsonFromString(logsData);

  if (logsData && logsParsed === false) {
    return (
      <Typography.Paragraph>
        {isFetching && <Loader mode="absolute" size="small" />}
        <pre>{logsData}</pre>
      </Typography.Paragraph>
    );
  }

  if (!Array.isArray(logsParsed)) {
    return (
      <Typography.Paragraph>
        {isFetching && <Loader mode="absolute" size="small" />}
        <pre>{commonError || t('noDataDescription')}</pre>
      </Typography.Paragraph>
    );
  }

  const sortedLogs = (logsParsed as LogsTable[]).sort((a, b) => a.study_name.localeCompare(b.study_name));

  const generalStatistic =
    sortedLogs &&
    sortedLogs.reduce((acc, el) => {
      acc[el.status] = (acc[el.status] || 0) + 1;
      return acc;
    }, {} as Record<StackDatasetLogStatus, number>);

  const resultString = Object.entries(generalStatistic ?? {})
    .sort(([a], [b]) => a.localeCompare(b))
    .map(([key, value]) => `${key}: ${value}`)
    .join(', ');

  const scrollY = initialPage.pageSize > 10 ? 'calc(100vh - 420px)' : undefined;

  return (
    <>
      <Space direction="vertical" full>
        <Space direction="vertical" full>
          <Space>
            <Typography.Text>{t('stackDatasetModal.logs.started_at')}:</Typography.Text>
            <Typography.Text type="secondary">
              {getFormattedDatetime(logs?.started_at, {
                format: 'YYYY-MM-DD HH:mm:ss UTCZ',
                noDataSymbol: '-',
              })}
            </Typography.Text>
          </Space>
          <Space>
            <Typography.Text>{t('stackDatasetModal.logs.finished_at')}:</Typography.Text>
            <Typography.Text type="secondary">
              {getFormattedDatetime(logs?.finished_at, {
                format: 'YYYY-MM-DD HH:mm:ss UTCZ',
                noDataSymbol: '-',
              })}
            </Typography.Text>
          </Space>
        </Space>
      </Space>
      <Space direction="vertical" full css={{ marginTop: 16 }}>
        {resultString && (
          <Space>
            <Typography.Text type={'secondary'} italic>
              {t('stackDatasetModal.logs.total')}: {(logsParsed as LogsTable[]).length}
            </Typography.Text>
            <Typography.Text type={'secondary'} italic>
              ({resultString})
            </Typography.Text>
          </Space>
        )}
        <Table
          size="small"
          locale={locale}
          columns={columns}
          dataSource={sortedLogs}
          loading={isFetching}
          rowKey={(item) => item.study_name}
          onChange={onTableChange}
          tableLayout="fixed"
          scroll={scrollY ? { y: scrollY } : undefined}
          pagination={pagination}
        />
      </Space>
    </>
  );
};

const cssErrorButton = (): CSSObject => ({
  '&&': {
    width: 'auto',

    '&&.ant-btn-icon-only': {
      padding: 0,
    },
  },
});

const cssIcon = (): CSSObject => ({
  fontSize: 20,
  cursor: 'pointer',
});

const cssObjectItemsContainer = (): CSSObject => ({
  gap: 0,
});

const cssLogErrorDetail: CSSProperties = { width: '400px', wordWrap: 'break-word', zIndex: 1200 };

export interface LogsTable {
  study_name: string;
  status: StackDatasetLogStatus;
  error?: string;
}

interface StackDatasetLogsProps {
  logs: IDatasetLogResponse;
  isFetching: boolean;
  commonError?: string;
  t: TFunction;
}

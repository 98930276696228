import { ChevronDownIcon } from '@components/icons';
import Tree, { DirectoryTreeProps } from 'antd/es/tree';
import { CSSObject, Theme } from '@emotion/react';
import { ForwardedRef, forwardRef } from 'react';
import { Skeleton } from 'antd';

const { DirectoryTree } = Tree;
interface CustomDirectoryTreeProps extends DirectoryTreeProps {
  isLoading?: boolean;
  withSwitcherIcon?: boolean;
}

const CustomDirectoryTree = forwardRef(
  ({ withSwitcherIcon = true, isLoading = false, ...rest }: CustomDirectoryTreeProps, ref: ForwardedRef<any>) => {
    const switcherIcon: DirectoryTreeProps['switcherIcon'] = withSwitcherIcon
      ? ({ expanded = false }) => <ChevronDownIcon css={cssSwitcherIcon(expanded)} />
      : undefined;

    return (
      <div css={cssTreeContainer}>
        {isLoading ? (
          <Skeleton css={cssSkeleton} active />
        ) : (
          <DirectoryTree {...rest} ref={ref} css={cssTree(withSwitcherIcon)} switcherIcon={switcherIcon} />
        )}
      </div>
    );
  },
);

export { CustomDirectoryTree as DirectoryTree };

const cssTreeContainer = (theme: Theme): CSSObject => ({
  height: '100%',
  overflowY: 'auto',
  scrollbarWidth: 'thin',
  scrollbarColor: `${theme['color-grey-300']} transparent`,
  width: '100%',
});

const cssSkeleton = (): CSSObject => ({
  padding: '8px',
});

const cssTree =
  (withSwitcherIcon: boolean) =>
  (theme: Theme): CSSObject => ({
    height: '100%',
    width: '100%',

    '&&.ant-tree': {
      '&& .ant-tree-treenode': {
        padding: '0 0 4px 0',
        margin: 0,

        '&:before': {
          display: 'none',
        },
      },
      '&& .ant-tree-node-selected': {
        backgroundColor: theme['color-brand-blue-200'],
        color: theme['color-brand-blue-500'],
      },
      '&& .ant-tree-iconEle': {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-evenly',
        marginRight: '10px',
        lineHeight: '24px',
        height: '24px',
        width: 'auto',
        color: 'inherit',

        svg: {
          width: '24px',
          height: '24px',
          color: 'inherit',
        },
      },
      '&& .ant-tree-node-content-wrapper': {
        display: 'flex',
        alignItems: 'center',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        height: '30px',
        padding: '8px',
        borderRadius: '4px',

        '&:before': {
          display: 'none',
        },

        '&:hover': {
          background: theme['color-grey-200'],
        },
      },
      '&& .ant-tree-title': {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        width: '100%',
        lineHeight: '21px',
        paddingRight: '24px',
      },
      '&& .ant-tree-indent-unit': {
        marginLeft: 0,

        ':before': {
          display: 'inline-block',
          borderInlineEnd: `3px solid ${theme['color-grey-200']}`,
        },
      },
      '&& .ant-tree-switcher': {
        display: withSwitcherIcon ? 'flex' : 'none',
        zIndex: 2,
        position: 'absolute',
        right: 0,
        top: 0,
        alignItems: 'center',
        justifyContent: 'center',
        height: '40px',
        width: '40px',

        ':before': {
          borderRadius: '4px',
        },

        '.ant-tree-switcher-leaf-line': {
          display: 'none',
        },
      },
    },
  });

const cssSwitcherIcon = (expanded: boolean) => (): CSSObject => ({
  height: '24px',
  width: '24px',
  color: 'inherit',
  transition: '0.3s all',
  transform: expanded ? 'rotate(180deg)' : undefined,
});

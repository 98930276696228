import AntdSpace, { SpaceProps } from 'antd/es/space';
import AntdSpaceCompact, { SpaceCompactProps } from 'antd/lib/space/Compact';
import { forwardRef } from 'react';

export const Space = forwardRef<HTMLDivElement, SpaceProps & SpaceExtraProps>(
  ({ full, justify, block, align, ...props }, ref) => (
    <AntdSpace css={cssSpace({ full, justify, block, align })} {...props} ref={ref} />
  ),
);

export const SpaceCompact = (props: SpaceCompactProps) => <AntdSpaceCompact {...props} />;

const cssSpace = (props: SpaceExtraProps) => () => ({
  width: props.full ? '100%' : 'auto',
  justifyContent: props.justify,
  alignItems: props.align,
  display: props.block ? 'flex' : 'inline-flex',
  '& .ant-space-item': {
    display: 'inline-flex',
    flex: '0 1 auto',
  },
});

interface SpaceExtraProps {
  full?: boolean;
  justify?: 'start' | 'end' | 'center' | 'space-between' | 'space-evenly';
  align?: 'start' | 'end' | 'center';
  block?: boolean;
}

import { useTranslation } from 'react-i18next';
import { QueryError } from './QueryError';

export const PermissionDeniedPage = () => {
  const { t } = useTranslation();

  return (
    <QueryError
      error={{ status: 403, data: { userMsg: t('errors.permissionDenied.userMsg') } }}
      title={t('errors.permissionDenied.pageTitle')}
    />
  );
};

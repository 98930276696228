import { FallbackRender } from '@app/components';
import { Modal, Typography } from '@components/ui';
import { ModalDivider } from '@components';
import { useEffect, useRef, useState } from 'react';
import { ModalProps } from 'antd';
import type { DraggableData, DraggableEvent } from 'react-draggable';
import Draggable from 'react-draggable';
import { ErrorBoundary } from 'react-error-boundary';
import { CSSObject } from '@emotion/react';
import { isNull } from 'lodash';

export const DraggableModal = ({
  title,
  isMinimized,
  maskClosable = true,
  children,
  okButtonProps,
  cancelButtonProps,
  withActionDivider = true,
  customContainerStyles,
  customContentStyles,
  footerInContent,
  ...props
}: DraggableModalProps) => {
  const [disabled, setDisabled] = useState(true);
  const [bounds, setBounds] = useState({ left: 0, top: 0, bottom: 0, right: 0 });
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const draggableRef = useRef<HTMLDivElement>(null);

  const withFooter = !isNull(props.footer);

  const onStart = (_event: DraggableEvent, uiData: DraggableData) => {
    const { clientWidth, clientHeight } = window.document.documentElement;
    const targetRect = draggableRef.current?.getBoundingClientRect();
    if (!targetRect) {
      return;
    }
    setBounds({
      left: -targetRect.left + uiData.x,
      right: clientWidth - (targetRect.right - uiData.x),
      top: -targetRect.top + uiData.y,
      bottom: clientHeight - (targetRect.bottom - uiData.y),
    });
  };

  const onDrag = (_event: DraggableEvent, uiData: DraggableData) => {
    setPosition({ x: uiData.x, y: uiData.y });
  };

  useEffect(() => {
    if (!isMinimized) {
      setPosition({ x: 0, y: 0 });
    }
  }, [isMinimized]);

  return (
    <Modal
      title={
        <Typography.Title
          level={4}
          style={{
            width: '100%',
            cursor: 'move',
            padding: '4px 0',
            fontVariant: 'lining-nums',
          }}
          onMouseOver={() => {
            if (disabled) {
              setDisabled(false);
            }
          }}
          onMouseOut={() => {
            setDisabled(true);
          }}
        >
          {title}
        </Typography.Title>
      }
      modalRender={(modal) => (
        <Draggable
          disabled={disabled}
          bounds={bounds}
          onStart={(event, uiData) => onStart(event, uiData)}
          nodeRef={draggableRef}
          position={isMinimized ? position : { x: 0, y: 0 }}
          onDrag={isMinimized ? onDrag : undefined}
        >
          <div ref={draggableRef}>{modal}</div>
        </Draggable>
      )}
      mask={!isMinimized}
      maskClosable={maskClosable}
      okButtonProps={{ size: 'large', ...okButtonProps }}
      cancelButtonProps={{ size: 'large', ...cancelButtonProps }}
      {...props}
      css={cssModal}
    >
      <ErrorBoundary FallbackComponent={FallbackRender}>
        <div css={{ ...cssContainer(withFooter, footerInContent), ...customContainerStyles }}>
          <div css={{ ...cssContent(withFooter, props?.height, footerInContent), ...customContentStyles }}>
            {children}
          </div>
        </div>
        {withFooter && withActionDivider && <ModalDivider />}
      </ErrorBoundary>
    </Modal>
  );
};

interface DraggableModalProps extends Exclude<ModalProps, 'modalRender'> {
  isMinimized?: boolean;
  maskClosable?: boolean;
  height?: number;
  withActionDivider?: boolean;
  customContainerStyles?: CSSObject;
  customContentStyles?: CSSObject;
  footerInContent?: boolean;
}
const cssContainer = (withFooter: boolean, footerInContent?: boolean): CSSObject => ({
  paddingBottom: footerInContent ? 12 : withFooter ? 16 : 48,
});

const cssContent = (withFooter: boolean, height?: number, footerInContent?: boolean): CSSObject => ({
  minHeight: height ?? 100,
  maxHeight: height ?? footerInContent ? 'calc(100vh - 50px)' : `calc(100vh - ${withFooter ? 350 : 300}px)`,
  overflowY: footerInContent ? 'visible' : 'auto',
});

const cssModal = (): CSSObject => ({
  '& .ant-modal-close': {
    top: 24,
    width: 40,
    height: 40,
  },
  '& .ant-modal-content': {
    paddingTop: '24px',
    paddingBottom: 0,

    // minWidth: 800,
  },
  '& .ant-modal-header': {
    marginBottom: '16px',
  },
  '&& .ant-modal-footer': {
    paddingBottom: '12px',
  },
});

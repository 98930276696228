import { QueryErrorType } from '@shared/utils/Error';
import { Button, Result, Space } from '@ui';
import { ChevronDownIcon, ChevronUpIcon } from '@components/icons';
import { SerializedError } from '@reduxjs/toolkit';
import { ResultProps } from 'antd/lib/result';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

interface QueryErrorProps {
  error?: QueryErrorType | SerializedError;
  title?: string | null;
  extra?: ResultProps['extra'];
  subTitle?: string | null;
  devMessage?: string | null;
}

export const QueryError = ({ error, title, extra, subTitle, devMessage }: QueryErrorProps) => {
  const { t } = useTranslation();
  const currentError = error as QueryErrorType;
  const [showDetails, setShowDetails] = useState(false);

  const toggleDetails = () => {
    setShowDetails((prev) => !prev);
  };

  return (
    <Result
      status={
        ['success', 'error', 'info', 'warning', 404, 403, 500, '404', '403', '500'].includes(currentError.status as any)
          ? (currentError.status as any)
          : 'error'
      }
      title={title}
      subTitle={subTitle || currentError.data.userMsg?.toString()}
      extra={
        <Space direction="vertical" full align="center">
          {extra}
          {(devMessage || currentError.data.devMsg) && (
            <Button type="text" onClick={toggleDetails}>
              {showDetails ? (
                <Space>
                  {t('hideDetails')} <ChevronUpIcon />
                </Space>
              ) : (
                <Space>
                  {t('showDetails')} <ChevronDownIcon />
                </Space>
              )}
            </Button>
          )}
        </Space>
      }
      children={showDetails && (devMessage || currentError.data.devMsg)}
    />
  );
};

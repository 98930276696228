import { BaseIcon, SvgIconProps } from '@components/icons/BaseIcon';
import { forwardRef, ForwardRefExoticComponent } from 'react';

export const ServerIcon: ForwardRefExoticComponent<SvgIconProps> = forwardRef((props, ref) => (
  <BaseIcon {...props} forwardRef={ref} width={24} height={24} fill="none" viewBox="0 0 24 24">
    <path
      d="M5 12H19M5 12C3.89543 12 3 11.1046 3 10V6C3 4.89543 3.89543 4 5 4H19C20.1046 4 21 4.89543 21 6V10C21 11.1046 20.1046 12 19 12M5 12C3.89543 12 3 12.8954 3 14V18C3 19.1046 3.89543 20 5 20H19C20.1046 20 21 19.1046 21 18V14C21 12.8954 20.1046 12 19 12M17 8H17.01M17 16H17.01"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </BaseIcon>
));

import { selectLibrarySqlLabModals } from '@modules/library/libraryObject/duck/libraryObjectSelectors';
import { libraryObjectActions } from '@modules/library/libraryObject/duck/libraryObjectSlice';
import { ELibraryObjectModalsType } from '@modules/library/libraryObject/libraryObjectTypes';
import { useDispatch, useSelector } from 'react-redux';
import {
  ILibraryObjectModalImportCDRReportProps,
  LibraryObjectModalImportCDRReport,
} from './LibraryObjectModalImportCDRReport';
import {
  ILibraryObjectModalImportCodeLabProps,
  LibraryObjectModalImportCodeLab,
} from './LibraryObjectModalImportCodeLab';
import { ILibraryObjectModalImportSqlLabProps, LibraryObjectModalImportSqlLab } from './LibraryObjectModalImportSqlLab';

export const LibraryObjectModalsController = () => {
  const dispatch = useDispatch();
  const { data, selectedModal } = useSelector(selectLibrarySqlLabModals);

  const closeModal = () => {
    dispatch(libraryObjectActions.dropModal());
  };

  return (
    <>
      <LibraryObjectModalImportSqlLab
        open={selectedModal === ELibraryObjectModalsType.copySqlLab}
        data={data as ILibraryObjectModalImportSqlLabProps['data']}
        onClose={closeModal}
      />
      <LibraryObjectModalImportCodeLab
        open={selectedModal === ELibraryObjectModalsType.importCodeLab}
        data={data as ILibraryObjectModalImportCodeLabProps['data']}
        onClose={closeModal}
      />
      <LibraryObjectModalImportCDRReport
        open={selectedModal === ELibraryObjectModalsType.importCDRReport}
        data={data as ILibraryObjectModalImportCDRReportProps['data']}
        onClose={closeModal}
      />
    </>
  );
};

import { Button, DraggableModal, Space, Typography } from '@ui';
import { Loader, PageSkeleton, QueryError } from '@components';
import { useCodeLabJobLogsQuery } from '@modules/codeLab/duck/codeLabApi';
import { getFormattedDatetime } from '@shared/utils/Date';
import { Refetch } from '@components/icons';
import { useTranslation } from 'react-i18next';
import { TFunction } from 'i18next';
import { CSSObject } from '@emotion/react';
import Scrollbars from 'react-custom-scrollbars-2';
import { isEmpty } from 'lodash';

const CodeLabModalsLogContent = ({ queryData, t }: CodeLabModalsLogContentProps) => {
  const isCodeLabLogsAvailable = !isEmpty(queryData.data);

  if (!queryData.data) {
    return <PageSkeleton />;
  }

  if (queryData.error) {
    return <QueryError error={queryData.error} title={t('modals.logs.logErrorTitle')} />;
  }

  return (
    <div>
      <Space full direction="vertical">
        <Space block>
          <Typography.Text strong>Started:</Typography.Text>
          <Typography.Text type="secondary">
            {getFormattedDatetime(queryData.data?.started_at, {
              format: 'YYYY-MM-DD HH:mm:ss UTCZ',
              noDataSymbol: '-',
            })}
          </Typography.Text>
        </Space>
        <Space block>
          <Typography.Text strong>Finished:</Typography.Text>
          <Typography.Text type="secondary">
            {getFormattedDatetime(queryData.data?.finished_at, {
              format: 'YYYY-MM-DD HH:mm:ss UTCZ',
              noDataSymbol: '-',
            })}
          </Typography.Text>
        </Space>
      </Space>
      <Typography.Paragraph>
        <pre style={{ width: '100%', position: 'relative', minHeight: '100px' }}>
          {queryData.isFetching && <Loader mode="absolute" size="small" />}
          <Scrollbars css={cssLogsLayout} autoHide={false} autoHeight autoHeightMax={500}>
            {isCodeLabLogsAvailable ? queryData.data.log : 'Logs is not available yet, please wait'}
          </Scrollbars>
        </pre>
      </Typography.Paragraph>
    </div>
  );
};

export const CodeLabModalsLog = ({ open, data, onClose }: CodeLabModalsLogProps) => {
  const { t } = useTranslation(['codeLab']);
  const codeLabJobLogsQuery = useCodeLabJobLogsQuery(data?.codeLabJobId!, { skip: !open });

  const footerActions = (
    <div css={cssFooter}>
      <Button key="close" onClick={onClose} size="large">
        {t('close')}
      </Button>
      <Button
        key="refresh"
        size="large"
        disabled={codeLabJobLogsQuery.isFetching}
        onClick={codeLabJobLogsQuery.refetch}
        icon={<Refetch css={{ color: 'inherit', fontSize: 24 }} />}
      >
        {t('refresh')}
      </Button>
    </div>
  );

  return (
    <DraggableModal
      width="80%"
      open={open}
      onCancel={onClose}
      title={t('modals.logs.title')}
      destroyOnClose
      footer={footerActions}
    >
      <CodeLabModalsLogContent queryData={codeLabJobLogsQuery} t={t} />
    </DraggableModal>
  );
};

export interface CodeLabModalsLogProps {
  open: boolean;
  data: Partial<{ codeLabJobId: number }>;
  onClose: () => void;
}

export interface CodeLabModalsLogContentProps {
  queryData: ReturnType<typeof useCodeLabJobLogsQuery>;
  t: TFunction;
}

const cssLogsLayout = (): CSSObject => ({
  maxHeight: 500,
  marginTop: '12px',
});

const cssFooter = (): CSSObject => ({
  display: 'flex',
  justifyContent: 'end',
  columnGap: 12,
});

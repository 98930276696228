import { DraggableModal } from '@ui';

import { LibraryStatus } from '@modules/library/root/duck/libraryConstants';
import {
  CDRReportObjectSelectForm,
  ICDRReportObjectSelectFormProps,
} from '@modules/dnaObject/CDR/components/CDRReportObjectSelectForm';
import { ICDRReportObject } from '@modules/dnaObject/CDR/CDRObjectTypes';
import { prepareCDRReportObjectForImport } from '@modules/dnaObject/CDR/duck/cdrReportUtils';
import { ICDRReport } from '@modules/cdrReport/CDRReportTypes';
import { useTranslation } from 'react-i18next';
import { TFunction } from 'i18next';
import { useImportAnalysisObjectCDRReportMutation } from '../duck/analysisObjectApi';

const AnalysisObjectCDRReportModalsImportContent = ({ onClose }: IAnalysisObjectCDRReportModalsImportContentProps) => {
  const [importAOCDRReport] = useImportAnalysisObjectCDRReportMutation();

  const onImport: ICDRReportObjectSelectFormProps['onSubmit'] = async (
    values: (ICDRReportObject | ICDRReport)[],
    { systemInfo, kind },
  ) => {
    return await importAOCDRReport({
      data: prepareCDRReportObjectForImport(values, kind, systemInfo),
      source: kind,
    }).unwrap();
  };

  return (
    <CDRReportObjectSelectForm
      onClose={onClose}
      onSubmit={onImport}
      hideOverwriteHandles
      onlyCurrentEnv
      libraryStatuses={[LibraryStatus.Active]}
    />
  );
};

export const AnalysisObjectCDRReportModalsImport = ({
  open,
  data,
  onClose,
}: IAnalysisObjectCDRReportModalsImportProps) => {
  const { t } = useTranslation(['analysisObjects']);

  return (
    <DraggableModal
      width={800}
      open={open}
      onCancel={onClose}
      title={t('cdrReportObject.importForm.title')}
      footer={null}
      destroyOnClose
      footerInContent={true}
    >
      <AnalysisObjectCDRReportModalsImportContent data={data} onClose={onClose} t={t} />
    </DraggableModal>
  );
};

export interface IAnalysisObjectCDRReportModalsImportProps {
  open: boolean;
  data: any;
  onClose: () => void;
}

interface IAnalysisObjectCDRReportModalsImportContentProps
  extends Pick<IAnalysisObjectCDRReportModalsImportProps, 'data' | 'onClose'> {
  t: TFunction;
}

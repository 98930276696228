import {
  SelectValue,
  SetPartionallyFieldsFn,
  SchedulerRunFormValues,
  PrepareUpdateSchedulerRunData,
  TRunPeriodically,
  RUN_TYPE,
} from '@components/form';
import dayjs from 'dayjs';
import * as cronConverter from 'cron-converter';
import {
  DEFAULT_SCHEDULER_TIME_FROM,
  DEFAULT_SCHEDULER_TIME_TO,
  PERIODICALLY_DAYS_ALL_VALUES,
  PERIODICALLY_EVERY_OPTIONS,
  PERIODICALLY_MONTHS,
  PERIODICALLY_WEEKDAYS,
  STACK_DATASET_RUN_ONCE_HOURS_TIME,
} from './schedulerRunFormConstants';

const checkWeekdaysInterval = (weekdays?: string[]) => (!weekdays?.length ? PERIODICALLY_DAYS_ALL_VALUES : weekdays);

const setHoursFields: SetPartionallyFieldsFn = (values, field) => {
  const computedValues = { ...values, [field.name]: field.value };
  return {
    every: computedValues.every || PERIODICALLY_EVERY_OPTIONS.hour.value,
    hour: computedValues.hour || 1,
    weekdays: field.name === 'every' ? PERIODICALLY_DAYS_ALL_VALUES : checkWeekdaysInterval(computedValues.weekdays),
    fromTime: computedValues.fromTime || DEFAULT_SCHEDULER_TIME_FROM,
    toTime: computedValues.toTime || DEFAULT_SCHEDULER_TIME_TO,
  };
};

const setWeekAndFortnightFields: SetPartionallyFieldsFn = (values, field) => {
  const computedValues = { ...values, [field.name]: field.value };
  return {
    every: computedValues.every || PERIODICALLY_EVERY_OPTIONS.week.value,
    weekdays:
      field.name === 'every' ? [((dayjs().day() + 6) % 7).toString()] : checkWeekdaysInterval(computedValues.weekdays), // Days should start from Manday due to Cron
    atTime: computedValues.atTime || DEFAULT_SCHEDULER_TIME_FROM,
  };
};

const setMonthFields: SetPartionallyFieldsFn = (values, field) => {
  const computedValues = { ...values, [field.name]: field.value };
  return {
    every: computedValues.every || PERIODICALLY_EVERY_OPTIONS.month.value,
    weekdays: field.name === 'every' ? PERIODICALLY_DAYS_ALL_VALUES : checkWeekdaysInterval(computedValues.weekdays),
    onDays: computedValues.onDays || [],
    atTime: computedValues.atTime || DEFAULT_SCHEDULER_TIME_FROM,
  };
};

const setQuoterAndHalfYearAndYearFields: SetPartionallyFieldsFn = (values, field) => {
  const computedValues = { ...values, [field.name]: field.value };
  return {
    every: computedValues.every || PERIODICALLY_EVERY_OPTIONS.year.value,
    weekdays: field.name === 'every' ? PERIODICALLY_DAYS_ALL_VALUES : checkWeekdaysInterval(computedValues.weekdays),
    onDays: computedValues.onDays || [],
    inMonth: computedValues.inMonth || [],
    atTime: computedValues.atTime || DEFAULT_SCHEDULER_TIME_FROM,
  };
};

const setDayFields: SetPartionallyFieldsFn = (values, field) => {
  const computedValues = { ...values, [field.name]: field.value };
  return {
    every: computedValues.every || PERIODICALLY_EVERY_OPTIONS.day.value,
    weekdays: field.name === 'every' ? PERIODICALLY_DAYS_ALL_VALUES : checkWeekdaysInterval(computedValues.weekdays),
    atTime: computedValues.atTime || DEFAULT_SCHEDULER_TIME_FROM,
  };
};

export const getDaysInMonths = (months: number[]) => {
  let maxDaysInMonth = 0;

  months.forEach((month) => {
    const nextDaysInMonth = dayjs()
      .set('month', month - 1)
      .daysInMonth();
    if (nextDaysInMonth > maxDaysInMonth) {
      maxDaysInMonth = nextDaysInMonth;
    }
  });

  return Array.from({ length: maxDaysInMonth || 31 }).map((v, i) => i + 1);
};

// todo: remove later, if not needed
export const getReportName = (report: SelectValue[] | SelectValue) => {
  let reports = report;
  if (reports && !Array.isArray(reports)) {
    reports = [reports];
  }

  const symbol = reports?.length ? ': ' : '';
  const listOfReportLabels = (reports || []).map((report) => report.label).join(', ');

  return `DnA-reporting${symbol}${listOfReportLabels}`;
};

export const changePartionallyFields: SetPartionallyFieldsFn = (values, field) => {
  const currentPeriodicallyField = field.name === 'every' ? field.value : values?.every;
  let changeFunction = setQuoterAndHalfYearAndYearFields;
  if (currentPeriodicallyField === PERIODICALLY_EVERY_OPTIONS.hour.value) {
    changeFunction = setHoursFields;
  } else if (currentPeriodicallyField === PERIODICALLY_EVERY_OPTIONS.day.value) {
    changeFunction = setDayFields;
  } else if (PERIODICALLY_EVERY_OPTIONS.week.value === currentPeriodicallyField) {
    changeFunction = setWeekAndFortnightFields;
  } else if (currentPeriodicallyField === PERIODICALLY_EVERY_OPTIONS.month.value) {
    changeFunction = setMonthFields;
  }

  return changeFunction(values, field);
};

export const setCronValue = (values: TRunPeriodically) => {
  let minutes: number[] = [];
  let hours: number[] = [];
  let days: number[] = getDaysInMonths([]);
  let months: number[] = PERIODICALLY_MONTHS.map((v) => v.value);
  let weekdays: number[] = [];

  if (values?.fromTime && values.toTime) {
    const fromTime = toUTCTime(values?.fromTime);
    const fromHour = fromTime.hour();
    const fromMinute = fromTime.minute();
    const toTime = toUTCTime(values?.toTime);
    const toHour = toTime.hour();
    const toMinute = toTime.minute();

    minutes = [fromMinute];
    for (let i = 0; i < 24; i += values.hour!) {
      let nextHour = fromHour + i;

      if (nextHour >= 24) {
        nextHour -= 24;
      }

      if (fromHour < toHour && fromHour <= nextHour && nextHour < toHour) {
        hours.push(nextHour);
      }

      if (fromHour > toHour && (nextHour >= fromHour || nextHour < toHour)) {
        hours.push(nextHour);
      }

      if (nextHour === toHour && fromMinute <= toMinute) {
        hours.push(nextHour);
      }
    }
  }

  if (values?.atTime) {
    const atTime = toUTCTime(values?.atTime);
    const atHour = atTime.hour();
    const atMinute = atTime.minute();

    minutes = [atMinute];
    hours = [atHour];
  }

  if (values?.onDays && values?.onDays.length) {
    days = values.onDays;
  }

  if (values?.inMonth && values?.inMonth.length) {
    months = values.inMonth;
  }

  weekdays = values!.weekdays!.map((v) => Number(v));

  return cronConverter.arrayToString([minutes, hours, days, months, weekdays]);
};

const toUTCTime = (date: dayjs.ConfigType) => {
  return dayjs(date).utc();
};

const fromUTCTime = (date: dayjs.ConfigType) => {
  return dayjs.utc(date).local();
};

export const setCronValueFromDate = (date: number) => {
  const currentDate = toUTCTime(date);
  const minutes: number[] = [currentDate.minute()];
  const hours: number[] = [currentDate.hour()];
  const days: number[] = [currentDate.date()];
  const months: number[] = [currentDate.month() + 1];
  const weekdays: number[] = PERIODICALLY_WEEKDAYS.map((v) => v.value);

  return cronConverter.arrayToString([minutes, hours, days, months, weekdays]);
};

export const getDateValueFromCron = (crontab: string | null) => {
  if (!crontab) {
    return dayjs().valueOf();
  }
  const data = cronConverter.stringToArray(crontab);

  return fromUTCTime([dayjs().year(), (data[3][0] || 1) - 1, data[2][0], data[1][0], data[0][0]]).valueOf();
};

export const getPeriodicallyFieldsFromCron = (crontab_ui: string | null): TRunPeriodically | undefined => {
  try {
    const data = JSON.parse(crontab_ui || '{}');

    if (Object.keys(data).length === 0) {
      return undefined;
    }

    return data as TRunPeriodically;
  } catch (e) {
    console.error(e);
  }

  return undefined;
};

export const prepareUpdateData = ({ values }: PrepareUpdateSchedulerRunData) => {
  const { runOnceAt, runPeriodically, run, isStackDataset, runOnceAtStackDataset, ...restValues } = values;

  const getRunOnceValue = isStackDataset
    ? `0 ${STACK_DATASET_RUN_ONCE_HOURS_TIME} * * *`
    : setCronValueFromDate(runOnceAt!);

  const crontab =
    run === RUN_TYPE.once ? getRunOnceValue : run === RUN_TYPE.periodically ? setCronValue(runPeriodically!) : '';

  return {
    ...restValues,
    crontab,
    automatic: run === RUN_TYPE.automatic,
    run_once: run === RUN_TYPE.once,
    timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    crontab_ui_repr: run === RUN_TYPE.periodically ? JSON.stringify(runPeriodically) : '',
  };
};

export const prepareFields = (values: {
  run_once: boolean;
  crontab: string | null;
  crontab_ui_repr: string | null;
  active: boolean;
  [key: string]: any;
}): SchedulerRunFormValues => {
  const { run_once, automatic, crontab, crontab_ui_repr, active, ...restValues } = values;

  return {
    run: automatic ? RUN_TYPE.automatic : run_once ? RUN_TYPE.once : RUN_TYPE.periodically,
    runOnceAt: run_once && !automatic ? getDateValueFromCron(values.crontab) : undefined,
    runOnceAtStackDataset: getRunOnceStackDatasetValue,
    runPeriodically: !run_once && !automatic ? getPeriodicallyFieldsFromCron(values.crontab_ui_repr) : undefined,
    active,
    ...restValues,
  };
};

const getRunOnceStackDatasetValue = `Every day at ${dayjs
  .utc()
  .hour(STACK_DATASET_RUN_ONCE_HOURS_TIME)
  .minute(0)
  .second(0)
  .millisecond(0)
  .local()
  .format('HH:mm A')} (${STACK_DATASET_RUN_ONCE_HOURS_TIME}:00 UTC)`;

export const getDefaultInitialValue = (initRunType = RUN_TYPE.once): SchedulerRunFormValues => ({
  run: initRunType,
  runOnceAt: +dayjs().add(1, 'hours'),
  active: false,
  runOnceAtStackDataset: getRunOnceStackDatasetValue,
});

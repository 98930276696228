import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { dataMapperLocales } from './datamapper';
import { auditLocales } from './audit';
import { sharedLocales } from './shared';
import { dataStoreLocales } from './datastore';
import { dataSourceLocales } from './datasource';
import { modelLocales } from './model';
import { jobLocales } from './job';
import { viewerLocales } from './viewer';
import { studyLocales } from './study';
import { libraryRootLocales } from './libraryRoot';
import { libraryModelLocales } from './libraryModel';
import { librarySqlQueryLocales } from './librarySqlQuery';
import { libraryNotebookLocales } from './libraryNotebook';
import { analysisPackageLocales } from './analysisPackage';
import { libraryPscLocales } from './libraryPsc';
import { snapshotLocales } from './snapshot';
import { gpdipLocales } from './gpdip';
import { datasetLocales } from './dataset';
import { appHeaderLocales } from './appHeader';
import { analysisObjectsLocales } from './analysisObjects';
import { dnaObjectLocales } from './dnaObject';
import { libraryObjectLocales } from './libraryObject';
import { codeLabLocales } from './codeLab';

export const i18nResources = {
  en: {
    audit: auditLocales.en,
    datamapper: dataMapperLocales.en,
    shared: sharedLocales.en,
    datastore: dataStoreLocales.en,
    datasource: dataSourceLocales.en,
    job: jobLocales.en,
    model: modelLocales.en,
    viewer: viewerLocales.en,
    study: studyLocales.en,
    libraryRoot: libraryRootLocales.en,
    libraryModel: libraryModelLocales.en,
    librarySqlQuery: librarySqlQueryLocales.en,
    libraryNotebook: libraryNotebookLocales.en,
    libraryPsc: libraryPscLocales.en,
    analysisObjects: analysisObjectsLocales.en,
    analysisPackage: analysisPackageLocales.en,
    appHeader: appHeaderLocales.en,
    snapshot: snapshotLocales.en,
    gpdip: gpdipLocales.en,
    dataset: datasetLocales.en,
    codeLab: codeLabLocales.en,
    dnaObject: dnaObjectLocales.en,
    libraryObject: libraryObjectLocales.en,
  },
};

// noinspection JSIgnoredPromiseFromCall
i18n.use(LanguageDetector).init({
  resources: i18nResources,
  fallbackLng: 'en',
  defaultNS: 'shared',
  fallbackNS: ['shared'],
  ns: [
    'shared',
    'datamapper',
    'audit',
    'datastore',
    'datasource',
    'job',
    'model',
    'viewer',
    'study',
    'libraryRoot',
    'libraryModel',
    'librarySqlQuery',
    'libraryNotebook',
    'analysisPackage',
    'libraryDeployment',
    'appHeader',
    'snapshot',
    'gpdip',
    'dataset',
    'dnaObject',
    'codeLab',
    'libraryObject',
  ],
  keySeparator: '.',
  nsSeparator: '.',

  interpolation: {
    escapeValue: false,
  },
});

export default i18n;

export type I18nLocales = 'en';

import { Button, DraggableModal, notification, Space } from '@ui';
import { DuplicateIcon } from '@components/icons';
import { ViewerCellValueParams } from '@modules/viewer/ViewerTypes';
import { useTranslation } from 'react-i18next';
import { TFunction } from 'i18next';
import { CSSObject, Theme } from '@emotion/react';

const ViewerModalsCellValueContent = ({ data, t }: ViewerModalsCellValueContentProps) => {
  const copyToClipboard = async () => {
    try {
      await navigator.clipboard.writeText(data.value);
      notification.success({ message: t('copyToClipboardSuccess') });
    } catch (err) {
      console.error('Error while copying the value: ', err);
    }
  };

  return (
    <Space direction="horizontal" full align="start">
      {data.value || t('noDataDescription')}
      <Button
        css={cssIcon}
        type="text"
        tip={t('copy')}
        size="large"
        icon={<DuplicateIcon />}
        onClick={copyToClipboard}
      />
    </Space>
  );
};

export const ViewerModalsCellValue = ({ open, data, onClose }: ViewerModalsCellValueProps) => {
  const { t } = useTranslation(['viewer']);

  return (
    <DraggableModal
      width={600}
      open={open}
      onCancel={onClose}
      title={t('cell.title')}
      okText={t('ok')}
      onOk={onClose}
      cancelButtonProps={{ hidden: true }}
      destroyOnClose
    >
      <ViewerModalsCellValueContent data={data} t={t} />
    </DraggableModal>
  );
};

export interface ViewerModalsCellValueProps {
  open: boolean;
  data: ViewerCellValueParams;
  onClose: () => void;
}

interface ViewerModalsCellValueContentProps extends Pick<ViewerModalsCellValueProps, 'data'> {
  t: TFunction;
}

const cssIcon = (theme: Theme): CSSObject => ({
  fontSize: 24,
  color: theme['color-grey-500'],
});

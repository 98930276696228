import { BaseIcon, SvgIconProps } from '@components/icons/BaseIcon';
import { forwardRef, ForwardRefExoticComponent } from 'react';

export const ArrowOutlineIcon: ForwardRefExoticComponent<SvgIconProps> = forwardRef((props, ref) => (
  <BaseIcon {...props} forwardRef={ref} width={24} height={24} fill="none" viewBox="0 0 24 24">
    <path d="M16 4H20V8" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M14 10L20 4" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M8 20H4V16" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M4 20L10 14" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
  </BaseIcon>
));

import { IStructure, TableDataRowProps, TabProps } from '@modules/job/JobTypes';
import { COLUMN_TYPES, CRF_METADATA_COLUMNS_PREFIX, SYSTEM_COLUMNS } from '@modules/job/duck/constants';
import { DATETIME_FORMATS, DEFAULT_PK } from '@modules/job/modals/components/upload/duck/uploadConstants';
import { MandatoryColumnsWithAutogeneration, MandatoryUploadDateTimeColumns } from '@config/constants';
import dayjs from 'dayjs';

export const initValues = (tabs: TabProps[]) =>
  tabs.reduce(
    (acc, val) => ({
      ...acc,
      [val.tableName]: val,
    }),
    {},
  );

export const typeOptions = Object.entries(COLUMN_TYPES).map(([key, value]) => ({ label: value, value: key }));

const checkIfExistsInFile = (structure: IStructure, field: string) =>
  !!Object.keys(structure).find((el) => el === field);

const getMandatoryFields = (structure: IStructure, fields: string[]) =>
  fields.map((field) => ({
    sourceColumn: field,
    sourceMappingColumn: field,
    targetColumn: field,
    type: '',
    nullable: false,
    primaryKey: false,
    skip: false,
    existInFile: checkIfExistsInFile(structure, field),
  }));

export const getMapping = (structure: IStructure, tabMapping?: TableDataRowProps[]) => {
  const mappingFromFile = Object.keys(structure).map((key) => ({
    sourceColumn: key,
    targetColumn: key,
    type: structure[key],
    nullable: !(SYSTEM_COLUMNS.includes(key) || key.startsWith(CRF_METADATA_COLUMNS_PREFIX)),
    primaryKey: key === DEFAULT_PK,
  }));

  // create array with mandatory fields in appropriate format
  const mandatoryUploadDateTimeColumnsUpdated = getMandatoryFields(structure, MandatoryUploadDateTimeColumns);

  if (tabMapping && !!tabMapping.length) {
    const updateFileMapping = mappingFromFile.map((el) => {
      // if field from file is mandatory we should not check it in the uploaded tab mapping
      if (!isMandatoryUploadDateTimeColumns(el.sourceColumn)) {
        const findTabMappingColumn = tabMapping.find((col) => col.sourceColumn === el.sourceColumn);

        if (findTabMappingColumn) {
          return { ...el, ...findTabMappingColumn, sourceMappingColumn: el.sourceColumn, skip: false };
        }
        return { ...el, sourceMappingColumn: '', skip: true };
      }

      return { ...el, sourceMappingColumn: el.sourceColumn, skip: false, existInFile: true };
    });

    // Add fields from uploaded mapping (ignore mandatory fields) that was not found in the file
    const remainTabMappingColumns = tabMapping
      .filter(
        (el) => !isMandatoryUploadDateTimeColumns(el.sourceColumn!) && !mappingFromFile.some((el) => el.sourceColumn),
      )
      .map((item) => ({
        ...item,
        sourceMappingColumn: item.sourceColumn,
        sourceColumn: '',
      }));

    // Add mandatory fields that was not found in the file
    const missingMandatoryUploadDateTimeColumnsFields = mandatoryUploadDateTimeColumnsUpdated.filter(
      (el) => !mappingFromFile.some((item) => item.sourceColumn === el.sourceColumn),
    );

    return [...updateFileMapping, ...remainTabMappingColumns, ...missingMandatoryUploadDateTimeColumnsFields];
  }

  // If there is no mapping in the discovery response
  // take all info from file (including mandatory) and add all required fields that are missing in the file
  const mappingFromFileUpdated = mappingFromFile.map((el) => ({
    ...el,
    sourceMappingColumn: el.sourceColumn,
    skip: false,
    existInFile: true,
  }));
  const missingMandatoryUploadDateTimeColumnsFields = mandatoryUploadDateTimeColumnsUpdated.filter(
    (el) => !mappingFromFile.some((item) => item.sourceColumn === el.sourceColumn),
  );

  return [...mappingFromFileUpdated, ...missingMandatoryUploadDateTimeColumnsFields];
};

export const generatedMappingTypes = (mapping: TableDataRowProps[]) =>
  mapping.reduce((acc, item) => ({ ...acc, [item.sourceColumn!]: item.type }), {});

export const findTabObject = (tabName: string, tabs: TabProps[]) => tabs.find((tab) => tab.tableName === tabName);

export const removeSkipColumns = (mapping: TableDataRowProps[]) => mapping.filter((el) => !el.skip);

export const isNoMapping = (tab: TabProps) => !tab.mapping.length;

export const isNoSourceColumn = (tab: TabProps) =>
  tab.mapping
    .filter((item) => !item.skip && !item.sourceColumn)
    .map((item) => item.sourceMappingColumn)
    .join(', ');

export const isDateTimeType = (type: TabProps['mapping'][0]['type']) => type?.toLowerCase().includes('datetime');

export const isIncorrectRequiredDateTimeColumn = (tab: TabProps) =>
  tab.mapping
    .filter(
      ({ sourceColumn, type, value }) =>
        MandatoryUploadDateTimeColumns.includes(sourceColumn!) &&
        (!type || !type.toLowerCase().includes('datetime')) &&
        !value,
    )
    .map((el) => el.sourceColumn)
    .join(', ');

export const missingColumns = (tab: TabProps) =>
  tab.missingRequiredColumns.filter((item) => !MandatoryColumnsWithAutogeneration.includes(item)).join(', ');

export const isEmptyPK = (tab: TabProps) => tab.mapping.every((item) => !item.primaryKey);

export const isAllTabConfirmed = (tab: TabProps) => tab.confirmed;

export const isNoColumnInUploadedMapping = (tabs: TabProps[]) =>
  tabs.some((el) => el.mapping.some((item) => !item.sourceMappingColumn));

export const isSystemColumn = (column: string) =>
  SYSTEM_COLUMNS.includes(column) || column.startsWith(CRF_METADATA_COLUMNS_PREFIX);

export const isMandatoryUploadDateTimeColumns = (column: string) => MandatoryUploadDateTimeColumns.includes(column);

export const isAutoConfirmTab = (tab: any) => !isNoMapping(tab) && !isNoSourceColumn(tab) && !isEmptyPK(tab);

export const truncateString = (str: string, countSymbols: number) =>
  str.length > countSymbols ? str.substring(0, countSymbols) + '...' : str;

export const isNoRT = (tab: TabProps) => !tab.referenceTable;

export const isFormatValid = (value: string | number, formatItem: string) => dayjs(value, formatItem, true).isValid();

export const filterDateFormats = (tabSampleData: TabProps['sample'], field: string) =>
  DATETIME_FORMATS.filter((item) => tabSampleData.some((el) => isFormatValid(el[field], item)));

export const getDateTimeOptions = (tabSampleData: TabProps['sample'], field: string) => {
  const filterFormats = filterDateFormats(tabSampleData, field);

  return filterFormats.map((el) => ({ label: el, value: el }));
};

export const checkFieldFormatValue = (data: TabProps['sample'], field: string, format: string) =>
  data.every((el) => isFormatValid(el[field], format));

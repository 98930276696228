import { Table } from '@ui';
import { useTablePaginationState } from '@components/ui/table/tableHooks';
import { ELibraryEntityKind, ESelectedActions } from '@modules/library/root/LibraryTypes';
import React, { useMemo, useState } from 'react';
import { TableColumnsType } from 'antd';

export const ObjectTable = <T extends IBaseColumnProps>({
  columns,
  locale,
  tableDataFetching,
  tableData = [],
  totalItems = 0,
  selectedTableItems,
  setSelectedTableItems,
  kind,
  hideCheckboxes,
  getPagination,
  onTableChange,
}: IObjectTableProps<T>) => {
  const [selectedFilter, setSelectedFilter] = useState<ESelectedActions>(ESelectedActions.SHOW_ALL);

  const pagination = getPagination(totalItems);

  const filteredData = useMemo(() => {
    if (selectedFilter === ESelectedActions.HIDE_SELECTED) {
      return tableData?.filter((item: T) => !selectedTableItems[kind]?.map((el: T) => el?.id).includes(item.id));
    }
    return tableData;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedFilter, tableData, selectedTableItems]);

  const rowSelection = {
    onChange: (selectedRowKeys: React.Key[], selectedRows: T[]) => {
      const preparedSelectedRows = selectedRows.map((el) => ({ id: el.id, version_id: el.version_id }));

      setSelectedTableItems((prev: T) => ({
        ...prev,
        [kind]: preparedSelectedRows,
      }));
    },
  };

  return (
    <Table
      scroll={{ x: 'max-content' }}
      size="small"
      columns={columns}
      locale={locale}
      loading={tableDataFetching}
      rowKey="id"
      dataSource={filteredData}
      pagination={pagination}
      onChange={onTableChange}
      rowSelection={
        hideCheckboxes
          ? undefined
          : {
              preserveSelectedRowKeys: true,
              type: 'checkbox',
              ...rowSelection,
              selectedRowKeys: selectedTableItems[kind]?.map((el: any) => el.id),
              selections: [
                Table.SELECTION_INVERT,
                {
                  key: ESelectedActions.SHOW_ALL,
                  text: 'Show all',
                  onSelect: () => setSelectedFilter(ESelectedActions.SHOW_ALL),
                },
                {
                  key: ESelectedActions.HIDE_SELECTED,
                  text: 'Hide selected',
                  onSelect: () => setSelectedFilter(ESelectedActions.HIDE_SELECTED),
                },
              ],
            }
      }
    />
  );
};

interface IObjectTableProps<T> {
  columns: TableColumnsType<T>;
  locale: Record<string, any>;
  tableDataFetching?: boolean;
  tableData: T[];
  selectedTableItems: Record<string, any>;
  setSelectedTableItems: (val: any) => void;
  kind: ELibraryEntityKind;
  hideCheckboxes?: boolean;
  totalItems?: number;
  getPagination: ReturnType<typeof useTablePaginationState>['getPagination'];
  onTableChange: ReturnType<typeof useTablePaginationState>['onTableChange'];
}

export interface IBaseColumnProps {
  id: number | string;
  name: string;
  version_id?: number;
}

import { IDatasetItem, ISparkJobQueueItem, ITask } from '@modules/task/TaskTypes';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface ITasksStore {
  runningTasks: ITask[];
  sparkJobQueue: ISparkJobQueueItem[];
  runningDatasets: IDatasetItem[];
}

const initialState: ITasksStore = {
  runningTasks: [],
  sparkJobQueue: [],
  runningDatasets: [],
};

export const tasksSlice = createSlice({
  name: 'tasks',
  initialState,
  reducers: {
    setRunningTasks: (state, action: PayloadAction<ITask[]>) => {
      state.runningTasks = action.payload;
    },
    setSparkJobQueue: (state, action: PayloadAction<ISparkJobQueueItem[]>) => {
      state.sparkJobQueue = action.payload;
    },
    setRunningDatasets: (state, action: PayloadAction<IDatasetItem[]>) => {
      state.runningDatasets = action.payload;
    },
  },
});

export const tasksActions = tasksSlice.actions;

export const tasksReducer = tasksSlice.reducer;

import { Alert, Col, DraggableModal, Form, FormItem, FormLayout, Row, Select, Space } from '@ui';
import { ModelEditorNodePivot, ModelEditorNodePivotAggregateFnOperations } from '@modules/modelEditor/ModelEditorTypes';
import { tableInfoMetaToOptions } from '@modules/modelEditor/modals/utils';
import { listToOptions } from '@shared/utils/Form';
import { useSourceTableInfoAnalyzer } from '@modules/modelEditor/duck/modelEditorSourceTableInfoAnalyzer';
import { selectModelEditorReadOnly } from '@modules/modelEditor/duck/modelEditorSelectors';
import { useTranslation } from 'react-i18next';
import { TFunction } from 'i18next';
import { CSSObject } from '@emotion/react';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useSaveStage } from './modelEditorModalsHooks';

const operatorOptions = listToOptions(Object.values(ModelEditorNodePivotAggregateFnOperations));

const ModelEditorModalsPivotSettingsContent = ({ data, onClose, t }: ModelEditorModalsPivotSettingsContentProps) => {
  const [form] = Form.useForm();

  const readOnly = useSelector(selectModelEditorReadOnly);
  const { onSubmit } = useSaveStage(data.nodeId, onClose);
  const { loading, sourceColumns } = useSourceTableInfoAnalyzer(data.nodeId);

  const fieldOptions = useMemo(() => {
    const columnsNames = tableInfoMetaToOptions(sourceColumns[0]);

    return {
      columnsNames,
      variables: columnsNames,
    };
  }, [sourceColumns]);

  const nodeErrors = [];

  if (!loading) {
    if (sourceColumns[0] === undefined) {
      nodeErrors.push(t('pivot.errors.missingSource'));
    }
  }

  const initValues = data.initData || {};

  return (
    <FormLayout
      css={cssForm}
      readOnly={readOnly}
      labelCol={{ span: 5 }}
      wrapperCol={{ span: 19 }}
      form={form}
      layout="horizontal"
      onCancel={onClose}
      onSubmit={onSubmit}
      okText={t('save')}
      initialValues={initValues}
      submitIsDisabled={!!nodeErrors.length || loading}
    >
      <Row style={{ marginBottom: 40 }}>
        <Col span={24}>
          <FormItem
            name="groupingColumns"
            label={t('pivot.groupingColumns')}
            rules={[
              {
                required: true,
                validator: (rule, value: string[] = [], callback) => {
                  if (
                    !loading &&
                    value.filter((v) => !fieldOptions.columnsNames.some((item) => item.value === v)).length
                  ) {
                    return Promise.reject(new Error(t('pivot.errors.wrongColumns')));
                  }
                  if (!value.length) {
                    return Promise.reject(new Error(t('pivot.errors.missingGroupingColumns')));
                  }
                  return Promise.resolve();
                },
              },
            ]}
          >
            <Select
              mode="multiple"
              placeholder={t('pivot.groupingColumnsPlaceholder')}
              options={fieldOptions.columnsNames}
              loading={loading}
            />
          </FormItem>

          <FormItem
            name="pivotColumn"
            label={t('pivot.pivotColumn')}
            rules={[
              {
                required: true,
                validator: (rule, value: string, callback) => {
                  if (!loading && !fieldOptions.columnsNames.some((item) => item.value === value)) {
                    return Promise.reject(new Error(t('pivot.errors.wrongColumns')));
                  }
                  if (!value.length) {
                    return Promise.reject(new Error(t('pivot.errors.missingGroupingColumns')));
                  }
                  return Promise.resolve();
                },
              },
            ]}
          >
            <Select
              placeholder={t('pivot.pivotColumnPlaceholder')}
              options={fieldOptions.columnsNames}
              loading={loading}
            />
          </FormItem>

          <FormItem label={t('pivot.aggregateFn')} asInfo rules={[{ required: true }]}>
            <Space css={cssAggFnForm} size="large" full>
              <FormItem
                full
                name="aggregateFn"
                rules={[{ required: true, message: t('pivot.errors.missingFunction') }]}
              >
                <Select options={operatorOptions} />
              </FormItem>
              <FormItem
                full
                name="columns"
                rules={[
                  {
                    required: true,
                    validator: (rule, value: string[] = [], callback) => {
                      if (
                        !loading &&
                        value.filter((v) => !fieldOptions.columnsNames.some((item) => item.value === v)).length
                      ) {
                        return Promise.reject(new Error(t('pivot.errors.wrongColumns')));
                      }
                      if (!value.length) {
                        return Promise.reject(new Error(t('pivot.errors.missingAggregatedColumn')));
                      }
                      return Promise.resolve();
                    },
                  },
                ]}
              >
                <Select
                  mode="multiple"
                  placeholder={t('pivot.columnsPlaceholder')}
                  options={fieldOptions.columnsNames}
                  loading={loading}
                />
              </FormItem>
            </Space>
          </FormItem>
          <FormItem
            style={{ marginBottom: 40 }}
            name="translatedValues"
            label={t('pivot.translatedValues')}
            extra={t('pivot.translatedValuesHint')}
            rules={[{ required: true }]}
          >
            <Select mode="tags" placeholder={t('pivot.translatedValuesPlaceholder')} />
          </FormItem>
          {!!nodeErrors.length && (
            <FormItem wrapperCol={{ span: 24 }}>
              <Alert message={<Space direction="vertical" children={nodeErrors} />} type="error" />
            </FormItem>
          )}
        </Col>
      </Row>
    </FormLayout>
  );
};

export const ModelEditorModalsPivotSettings = ({ open, data, onClose }: ModelEditorModalsPivotSettingsProps) => {
  const { t } = useTranslation(['model']);

  return (
    <DraggableModal
      width={880}
      open={open}
      onCancel={onClose}
      title={t('pivot.title')}
      footer={null}
      destroyOnClose
      footerInContent
    >
      {open && <ModelEditorModalsPivotSettingsContent data={data} onClose={onClose} t={t} />}
    </DraggableModal>
  );
};

interface ModelEditorModalsPivotSettingsContentProps
  extends Pick<ModelEditorModalsPivotSettingsProps, 'data' | 'onClose'> {
  t: TFunction;
}

export interface ModelEditorModalsPivotSettingsProps {
  open: boolean;
  data: { nodeId: string; initData?: ModelEditorNodePivot };
  onClose: () => void;
}

const cssAggFnForm = (): CSSObject => ({
  '& .ant-space-item:first-child': {
    width: '30%',
  },
  '& .ant-space-item:last-child': {
    width: '70%',
  },
});

const cssForm = (): CSSObject => ({
  '& .ant-row': {
    gap: 8,
  },
});

import { EMPTY_ENV } from '@modules/viewer/duck/viewerConstants';
import { SupportedEnvs } from '@app/AppTypes';

export const prepareCurrentEnvironment = (
  userCurrentEnv: string,
  allUserEnvironments: SupportedEnvs,
  selectedEnv?: string,
) => ({
  env: selectedEnv || userCurrentEnv,
  ...(allUserEnvironments[selectedEnv || userCurrentEnv] || EMPTY_ENV),
  current: selectedEnv ? selectedEnv === userCurrentEnv : true,
});

export const getReviewSourceTableName = (tableName: string, sourceEnvLabel: string) =>
  `${tableName}_ENV_${sourceEnvLabel.toUpperCase()}_TEMP`;

export const checkReviewTableName = (tableId: string) => /.+_ENV_.+_TEMP/.test(tableId);

export const getApiTablesCacheKey = (studyId: number, roleId?: number, env?: string) =>
  [env, studyId, roleId].filter((v) => v).join('~');

import { IDatasetItem, ISparkJobQueueItem, ITask } from '@modules/task/TaskTypes';
import { TaskItem } from '@modules/task/components/TaskItem';
import { NoTasksData } from '@modules/task/components/NoTasksData';
import { SparkJobItem } from '@modules/task/components/SparkJobItem';
import { DatasetItem } from '@modules/task/components/DatasetItem';
import { Tabs } from '@ui';
import { useAppPermissions } from '@modules/user/duck/userHooks';
import { useTranslation } from 'react-i18next';
import { CSSObject } from '@emotion/react';
import { ComponentType } from 'react';
import { TabsProps } from 'antd';

const tabs = {
  tasks: {
    id: 'tasks',
  },
  datasets: {
    id: 'datasets',
  },
  sparkJobQueue: {
    id: 'sparkJobQueue',
  },
};

const TaskPanelContent = <T extends { id: string | number }>({
  Component,
  data,
}: {
  Component: ComponentType<{ item: T }>;
  data: T[];
}) => {
  return data.length ? (
    <>
      {data.map((item) => (
        <Component key={item.id} item={item} />
      ))}
    </>
  ) : (
    <NoTasksData />
  );
};

export const TaskPanel = ({
  tasks,
  sparkJobQueue,
  datasets,
}: {
  tasks: ITask[];
  sparkJobQueue: ISparkJobQueueItem[];
  datasets: IDatasetItem[];
}) => {
  const { t } = useTranslation(['shared']);
  const {
    appPermissions: { canCrossSetupRead },
  } = useAppPermissions();
  return (
    <div css={cssPanel} onClick={(e) => e.stopPropagation()}>
      <Tabs
        defaultActiveKey={tabs.tasks.id}
        size="small"
        items={
          [
            {
              key: tabs.tasks.id,
              label: t(`task.tabs.${tabs.tasks.id}`) + ` (${tasks.length})`,
              children: <TaskPanelContent<ITask> Component={TaskItem} data={tasks} />,
            },
            canCrossSetupRead && {
              key: tabs.datasets.id,
              label: t(`task.tabs.${tabs.datasets.id}`) + ` (${datasets.length})`,
              children: <TaskPanelContent<IDatasetItem> Component={DatasetItem} data={datasets} />,
            },
            {
              key: tabs.sparkJobQueue.id,
              label: t(`task.tabs.${tabs.sparkJobQueue.id}`) + ` (${sparkJobQueue.length})`,
              children: <TaskPanelContent<ISparkJobQueueItem> Component={SparkJobItem} data={sparkJobQueue} />,
            },
          ].filter(Boolean) as TabsProps['items']
        }
      />
    </div>
  );
};

const cssPanel = (): CSSObject => ({
  width: '630px',
  paddingTop: 8,
  minHeight: 512,

  '&& .ant-tabs-nav': {
    margin: 0,
  },
  '&& .ant-tabs-nav::before': {
    left: -16,
    right: -16,
  },
});

import { I18nLocales } from '@i18n';
import en from './dnaObject_en.json';

export const dnaObjectLocales: Record<I18nLocales, I18nDnAObjectTranslations> = {
  en,
};

export interface I18nDnAObjectTranslations {
  sqlLab: {
    rootTable: {
      name: string;
      created: string;
      updated: string;
      active: string;
      version: string;
      libraryVersion: string;
      sourceVersion: string;
      source: string;
    };
  };
  codeLabObject: {
    rootTable: {
      name: string;
      created: string;
      updated: string;
      active: string;
      version: string;
      libraryVersion: string;
      sourceVersion: string;
      filePath: string;
    };
  };
  stackDataset: {
    rootTable: {
      name: string;
      created: string;
      updated: string;
      version: string;
      sourceVersion: string;
      source: string;
      scope: string;
    };
  };
  cdrReportObject: {
    rootTable: {
      name: string;
      created: string;
      updated: string;
      active: string;
      version: string;
      libraryVersion: string;
      sourceVersion: string;
      filePath: string;
    };
  };
  importForm: {
    sourceKind: string;
    sourceKindPlaceholder: string;
    sourceId: string;
    sourceIdPlaceholder: string;
    createNewName: string;
    duplicateLabel: {
      label: string;
      createCopy: string;
      useExisting: string;
    };
    successMessage: string;
  };
}

import { Button } from '@ui';
import { Refetch } from '@components/icons';
import { useTranslation } from 'react-i18next';

export const RefreshButton = ({ onClick, iconOnly }: RefreshButtonProps) => {
  const { t } = useTranslation(['shared']);

  return (
    <Button
      tip={t('refresh')}
      size="large"
      icon={<Refetch />}
      children={iconOnly ? null : t('refresh')}
      onClick={onClick}
    />
  );
};

interface RefreshButtonProps {
  onClick: () => void;
  iconOnly?: boolean;
}

import { dateToString } from '@shared/utils/Date';
import { ActorAndInfo, ButtonWithConfirmation } from '@components';
import { useColumnSearch } from '@components/ui/table/tableHooks';
import { getListOption, tableListToOptions } from '@shared/utils/Table';
import { Trash } from '@components/icons';
import { Table } from '@ui';
import { SupportedEnvs } from '@app/AppTypes';
import { getReportVersionLabel } from '@shared/utils/common';
import { useMemo } from 'react';
import { TableColumnsType, TableProps } from 'antd';
import { TFunction } from 'i18next';
import { ICDRReportObject, ICDRReportObjectListResponse } from '../CDRObjectTypes';

export const CDRReportObjectList = ({
  data,
  loading,
  pagination,
  filterOptions,
  loadingDelete,
  supportedEnvs,
  onChange,
  onDelete,
  libraryId,
  t,
}: CDRReportObjectListProps) => {
  const { getColumnSearchProps, locale } = useColumnSearch<ICDRReportObject>();

  const columns = useMemo(() => {
    const sourceListOption = filterOptions?.sources
      ? getListOption(filterOptions?.sources)
      : tableListToOptions(data?.items?.map((el) => ({ name: el.source!, id: el.source })));

    return (
      [
        {
          width: 'max-content',
          title: t('cdrReportObject.rootTable.name'),
          dataIndex: 'name',
          ...getColumnSearchProps('name'),
          onFilter: undefined,
        },
        {
          width: 150,
          title: t('cdrReportObject.rootTable.sourceVersion'),
          render: (_, record) => getReportVersionLabel(record?.configuration?.version_label),
        },
        {
          width: 150,
          title: t('cdrReportObject.rootTable.libraryVersion'),
          dataIndex: 'version',
        },
        {
          title: t('cdrReportObject.rootTable.source'),
          customKey: 'narrow',
          dataIndex: 'source',
          filters: sourceListOption,
          onFilter: undefined,
          render: (_, record) =>
            record.source && (
              <ActorAndInfo
                info={(supportedEnvs || {})[record.env]?.label || t('na')}
                actor={record.source}
                actorSize={'sm'}
              />
            ),
        },
        {
          title: t('cdrReportObject.rootTable.created'),
          customKey: 'narrow',
          dataIndex: libraryId ? 'created_at' : 'linked_at',
          sorter: () => 0,
          sortDirections: ['ascend', 'descend'],
          render: (_, record) => (
            <ActorAndInfo info={dateToString(record.linked_at || record.created_at)} actor={record.created_by} />
          ),
        },
        libraryId && {
          title: t('cdrReportObject.rootTable.updated'),
          customKey: 'narrow',
          dataIndex: 'updated_at',
          sorter: () => 0,
          sortDirections: ['ascend', 'descend'],
          render: (updatedAt: number, record) => (
            <ActorAndInfo info={dateToString(updatedAt)} actor={record.updated_by} />
          ),
        },
        !!onDelete && {
          width: 100,
          title: t('delete'),
          dataIndex: 'actionDelete',
          render: (_, { version_id, name, id }) => (
            <ButtonWithConfirmation
              icon={<Trash color="lightGrey" />}
              type="text"
              submitFunc={() => onDelete({ id, version_id, name })}
              confirmContent={t('cdrReportObject.confirmation.description', { name })}
              confirmTitle={t('cdrReportObject.confirmation.title')}
              loading={loadingDelete}
            />
          ),
        },
      ] as TableColumnsType<ICDRReportObject>
    ).filter(Boolean);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, filterOptions, loadingDelete, supportedEnvs]);

  return (
    <Table
      locale={locale}
      columns={columns}
      dataSource={data?.items}
      loading={loading}
      rowKey={(item) => item.id}
      onChange={onChange}
      tableLayout="fixed"
      scroll={{ x: 900 }}
      pagination={pagination}
    />
  );
};

export interface CDRReportObjectListProps {
  data?: ICDRReportObjectListResponse;
  pagination?: TableProps<ICDRReportObject>['pagination'];
  onChange?: TableProps<ICDRReportObject>['onChange'];
  loading?: boolean;
  loadingDelete?: boolean;
  filterOptions?: { sources: string[] };
  onDelete?: (data: { id: number; version_id?: number; name: string }) => void;
  supportedEnvs?: SupportedEnvs;
  t: TFunction;
  libraryId?: number;
}

import { appApi } from '@config/appApi';
import {
  ICDRReportObjectListResponse,
  ICDRReportObjectQuery,
  ICDRReportObjectValidateQuery,
} from '@modules/dnaObject/CDR/CDRObjectTypes';
import {
  ICodeLabObjectListResponse,
  ICodeLabObjectQuery,
  ICodeLabObjectValidateQuery,
} from '@modules/dnaObject/CodeLab/CodeLabObjectTypes';
import { IDnAObjectListQueryParams } from '@modules/dnaObject/DnAObjectTypes';
import {
  ISQLLabObjectListResponse,
  ISQLLabObjectQuery,
  ISQLLabObjectValidateQuery,
} from '@modules/dnaObject/SQLLab/SQLLabObjectTypes';
import { TagDescription } from '@reduxjs/toolkit/query/react';
import { LIBRARY_OBJECT_TAG_DESCRIPTION } from './libraryObjectConstants';

export const LibraryObjectApiRoutes = {
  sqlLabList: `api/library/libraries/sql-lab`,
  sqlLabItem: (sqlLabId: number) => `api/library/libraries/sql-lab/${sqlLabId}`,
  sqlLabValidate: `/api/library/libraries/sql-lab/validate`,

  codeLabList: 'api/library/libraries/python-notebooks',
  codeLabItem: (codeLabId: number) => `api/library/libraries/python-notebooks/${codeLabId}`,
  codeLabValidate: '/api/library/libraries/python-notebooks/validate',

  cdrReportList: `api/library/libraries/cdr`,
  cdrReportItem: (libraryObjectCDRReportId: number) => `api/library/libraries/cdr/${libraryObjectCDRReportId}`,
  cdrReportValidate: `/api/library/libraries/cdr/validate`,
};

const LibraryObjectInvalidations: {
  SQL_LAB_LIST: TagDescription<LIBRARY_OBJECT_TAG_DESCRIPTION.SQL_LAB_LIST>;
  SQL_LAB_ID: (sqlId: number) => TagDescription<LIBRARY_OBJECT_TAG_DESCRIPTION.SQL_LAB_ID>;

  CODE_LAB_LIST: TagDescription<LIBRARY_OBJECT_TAG_DESCRIPTION.CODE_LAB_LIST>;
  CODE_LAB_ID: (sqlId: number) => TagDescription<LIBRARY_OBJECT_TAG_DESCRIPTION.CODE_LAB_ID>;

  CDR_REPORT_LIST: TagDescription<LIBRARY_OBJECT_TAG_DESCRIPTION.CDR_REPORT_LIST>;
  CDR_REPORT_ID: (sqlId: number) => TagDescription<LIBRARY_OBJECT_TAG_DESCRIPTION.CDR_REPORT_ID>;
} = {
  SQL_LAB_LIST: { type: LIBRARY_OBJECT_TAG_DESCRIPTION.SQL_LAB_LIST, id: 'SQL_LAB_LIST' },
  SQL_LAB_ID: (sqlLabId: number) => ({ type: LIBRARY_OBJECT_TAG_DESCRIPTION.SQL_LAB_ID, sqlLabId }),

  CODE_LAB_LIST: { type: LIBRARY_OBJECT_TAG_DESCRIPTION.CODE_LAB_LIST, id: 'CODE_LAB_LIST' },
  CODE_LAB_ID: (codeLabId: number) => ({ type: LIBRARY_OBJECT_TAG_DESCRIPTION.CODE_LAB_ID, codeLabId }),

  CDR_REPORT_LIST: { type: LIBRARY_OBJECT_TAG_DESCRIPTION.CDR_REPORT_LIST, id: 'CDR_REPORT_LIST' },
  CDR_REPORT_ID: (codeLabId: number) => ({ type: LIBRARY_OBJECT_TAG_DESCRIPTION.CDR_REPORT_ID, codeLabId }),
};

export const LibraryObjectApi = appApi.injectEndpoints({
  endpoints: (builder) => ({
    libraryObjectSqlLabList: builder.query<ISQLLabObjectListResponse, IDnAObjectListQueryParams | void>({
      providesTags: [LibraryObjectInvalidations.SQL_LAB_LIST],
      query: (params) => ({
        params,
        url: LibraryObjectApiRoutes.sqlLabList,
      }),
    }),
    libraryObjectDeleteSqlLab: builder.mutation<void, number>({
      invalidatesTags: (request, error, sqlLabId) => [
        LibraryObjectInvalidations.SQL_LAB_LIST,
        LibraryObjectInvalidations.SQL_LAB_ID(sqlLabId),
      ],
      query: (sqlId) => ({
        method: 'DELETE',
        url: LibraryObjectApiRoutes.sqlLabItem(sqlId),
      }),
    }),
    libraryObjectImportSqlLab: builder.mutation<void, ISQLLabObjectQuery>({
      invalidatesTags: [LibraryObjectInvalidations.SQL_LAB_LIST],
      query: ({ data, overwrite, cascade_update }) => ({
        data: { data, cascade_update },
        params: { overwrite },
        method: 'POST',
        url: LibraryObjectApiRoutes.sqlLabList,
      }),
    }),
    libraryObjectvalidateSqlLab: builder.mutation<void, ISQLLabObjectValidateQuery>({
      query: (data) => ({
        data,
        method: 'POST',
        url: LibraryObjectApiRoutes.sqlLabValidate,
      }),
    }),
    // Code Lab endpoints
    libraryObjectCodeLabList: builder.query<ICodeLabObjectListResponse, IDnAObjectListQueryParams | void>({
      providesTags: [LibraryObjectInvalidations.CODE_LAB_LIST],
      query: (params) => ({
        params,
        url: LibraryObjectApiRoutes.codeLabList,
      }),
    }),
    libraryObjectDeleteCodeLab: builder.mutation<void, number>({
      invalidatesTags: (request, error, codeLabId) => [
        LibraryObjectInvalidations.CODE_LAB_LIST,
        LibraryObjectInvalidations.CODE_LAB_ID(codeLabId),
      ],
      query: (sqlId) => ({
        method: 'DELETE',
        url: LibraryObjectApiRoutes.codeLabItem(sqlId),
      }),
    }),
    libraryObjectImportCodeLab: builder.mutation<void, ICodeLabObjectQuery>({
      invalidatesTags: [LibraryObjectInvalidations.CODE_LAB_LIST],
      query: ({ data, overwrite, cascade_update }) => ({
        data: { data, cascade_update },
        params: { overwrite },
        method: 'POST',
        url: LibraryObjectApiRoutes.codeLabList,
      }),
    }),
    libraryObjectValidateCodeLab: builder.mutation<void, ICodeLabObjectValidateQuery>({
      query: (data) => ({
        data,
        method: 'POST',
        url: LibraryObjectApiRoutes.codeLabValidate,
      }),
    }),
    // CDR Report endpoints
    libraryObjectCDRReportList: builder.query<
      ICDRReportObjectListResponse,
      (IDnAObjectListQueryParams & { name?: string }) | void
    >({
      providesTags: [LibraryObjectInvalidations.CDR_REPORT_LIST],
      query: (params) => ({
        params,
        url: LibraryObjectApiRoutes.cdrReportList,
      }),
    }),
    libraryObjectDeleteCDRReport: builder.mutation<void, number>({
      invalidatesTags: (request, error, cdrReportId) => [
        LibraryObjectInvalidations.CDR_REPORT_LIST,
        LibraryObjectInvalidations.CDR_REPORT_ID(cdrReportId),
      ],
      query: (libraryObjectCDRReportId) => ({
        method: 'DELETE',
        url: LibraryObjectApiRoutes.cdrReportItem(libraryObjectCDRReportId),
      }),
    }),
    libraryObjectImportCDRReport: builder.mutation<void, ICDRReportObjectQuery>({
      invalidatesTags: [LibraryObjectInvalidations.CDR_REPORT_LIST],
      query: ({ data, overwrite, cascade_update }) => ({
        data: { data, cascade_update },
        params: { overwrite },
        method: 'POST',
        url: LibraryObjectApiRoutes.cdrReportList,
      }),
    }),
    libraryObjectValidateCDRReport: builder.mutation<void, ICDRReportObjectValidateQuery>({
      query: (data) => ({
        data,
        method: 'POST',
        url: LibraryObjectApiRoutes.cdrReportValidate,
      }),
    }),
  }),
});

export const {
  useLibraryObjectSqlLabListQuery,
  useLazyLibraryObjectSqlLabListQuery,
  useLibraryObjectImportSqlLabMutation,
  useLibraryObjectDeleteSqlLabMutation,
  useLibraryObjectvalidateSqlLabMutation,

  useLibraryObjectCodeLabListQuery,
  useLazyLibraryObjectCodeLabListQuery,
  useLibraryObjectImportCodeLabMutation,
  useLibraryObjectDeleteCodeLabMutation,
  useLibraryObjectValidateCodeLabMutation,

  useLibraryObjectCDRReportListQuery,
  useLazyLibraryObjectCDRReportListQuery,
  useLibraryObjectImportCDRReportMutation,
  useLibraryObjectDeleteCDRReportMutation,
  useLibraryObjectValidateCDRReportMutation,
} = LibraryObjectApi;

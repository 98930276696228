import { ILibraryObjectModalImportCDRReportProps } from './modals/LibraryObjectModalImportCDRReport';
import { ILibraryObjectModalImportCodeLabProps } from './modals/LibraryObjectModalImportCodeLab';
import { ILibraryObjectModalImportSqlLabProps } from './modals/LibraryObjectModalImportSqlLab';

export enum ELibraryObjectModalsType {
  'copySqlLab' = 'copySqlLab',
  'importCodeLab' = 'importCodeLab',
  'importCDRReport' = 'importCDRReport',
}

export type TLibrarySqlLabModalsPushModalPayload =
  | {
      type: ELibraryObjectModalsType.copySqlLab;
      data?: ILibraryObjectModalImportSqlLabProps['data'];
    }
  | {
      type: ELibraryObjectModalsType.importCodeLab;
      data?: ILibraryObjectModalImportCodeLabProps['data'];
    }
  | {
      type: ELibraryObjectModalsType.importCDRReport;
      data?: ILibraryObjectModalImportCDRReportProps['data'];
    };

export type TLibrarySqlLabModalsState = {
  selectedModal: ELibraryObjectModalsType | null;
  data?:
    | ILibraryObjectModalImportSqlLabProps['data']
    | ILibraryObjectModalImportCodeLabProps['data']
    | ILibraryObjectModalImportCDRReportProps['data'];
};

import { IAnalysisPackage } from '@modules/library/analysisPackage/AnalysisPackageTypes';
import { FormLayout, DraggableModal, Form, FormItem, Input, Typography, notification, Alert, Radio } from '@ui';
import {
  useAnalysisPackageByIdQuery,
  useNewPackageVersionQuery,
  useSaveAnalysisPackageMutation,
} from '@modules/library/analysisPackage/duck/analysisPackageApi';
import { DataModelObjects } from '@modules/library/analysisPackage/modals/components/save/tabComponents/DataModelObjects';
import { CdrObjects } from '@modules/library/analysisPackage/modals/components/save/tabComponents/CdrObjects';
import { SqlDatasetsObjects } from '@modules/library/analysisPackage/modals/components/save/tabComponents/SqlDatasetsObjects';
import { CodeLabObjects } from '@modules/library/analysisPackage/modals/components/save/tabComponents/CodeLabObjects';
import { PscObjects } from '@modules/library/analysisPackage/modals/components/save/tabComponents/PscObjects';
import { StackDatasetsObjects } from '@modules/library/analysisPackage/modals/components/save/tabComponents/StackDatasetsObjects';
import { Tabs } from '@components/ui/tabs';
import { ELibraryEntityKind } from '@modules/library/root/LibraryTypes';
import { useAppPermissions, useStudyPermissions } from '@modules/user/duck/userHooks';
import { selectGlobalStudy } from '@app/duck/appSelectors';
import { isCrossStudy } from '@shared/utils/common';
import { useTranslation } from 'react-i18next';
import { TFunction } from 'i18next';
import React, { ComponentType, useEffect, useState } from 'react';
import { TabsProps } from 'antd';
import { useSelector } from 'react-redux';

const wrapMixPermissions = (Component: ComponentType<AnalysisPackageModalsSaveAnalysisPackageContentProps>) => {
  const WrapperComponent = (props: AnalysisPackageModalsSaveAnalysisPackageContentProps) => {
    const globalStudy = useSelector(selectGlobalStudy);
    const {
      userPermissions: { canGlDmAoRead, canGlCdrAoRead, canGlSqlAoRead, canGlPscAoRead, canGlClAoRead },
    } = useStudyPermissions();
    const {
      appPermissions: { canCrossDmAoRead, canCrossSqlAoRead, canCrossClAoRead, canCrossStackDatasetsAoRead },
    } = useAppPermissions();
    const crossStudy = isCrossStudy(globalStudy?.id);

    return (
      <Component
        {...props}
        mixСanDmAoRead={crossStudy ? canCrossDmAoRead : canGlDmAoRead}
        mixСanCdrAoRead={crossStudy ? false : canGlCdrAoRead}
        mixCanSqlAoRead={crossStudy ? canCrossSqlAoRead : canGlSqlAoRead}
        mixCanPscAoRead={crossStudy ? false : canGlPscAoRead}
        mixCanClAoRead={crossStudy ? canCrossClAoRead : canGlClAoRead}
        mixCanStackDatasetAoRead={crossStudy ? canCrossStackDatasetsAoRead : false}
      />
    );
  };

  return WrapperComponent;
};

const AnalysisPackageModalsSaveAnalysisPackageContent = wrapMixPermissions(
  ({
    data,
    onClose,
    t,
    isView,
    mixCanClAoRead,
    mixCanPscAoRead,
    mixCanSqlAoRead,
    mixСanCdrAoRead,
    mixСanDmAoRead,
    mixCanStackDatasetAoRead,
  }: AnalysisPackageModalsSaveAnalysisPackageContentProps) => {
    const [form] = Form.useForm();
    const newPackageVersionQuery = useNewPackageVersionQuery(undefined, { skip: isView });
    const [saveAnalysisPackage, saveAnalysisPackageQuery] = useSaveAnalysisPackageMutation();
    const analysisPackageById = useAnalysisPackageByIdQuery(data?.id!, { skip: !data?.id });

    const [selectedTableItems, setSelectedTableItems] = useState<Record<string, any>>({});

    useEffect(() => {
      if (analysisPackageById.data) {
        setSelectedTableItems(analysisPackageById.data?.objects!);

        form.setFieldsValue({
          version: analysisPackageById.data.version,
          description: analysisPackageById.data.description,
        });
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [analysisPackageById.data]);

    const initValues =
      isView && data
        ? {
            version: data.version,
            description: data.description,
          }
        : {
            version: newPackageVersionQuery.data?.minor_version,
            description: '',
          };

    const isSaveDisabled =
      Object.values(selectedTableItems).every((v) => v.length === 0) || newPackageVersionQuery.isFetching;

    const errorMessage = [saveAnalysisPackageQuery, analysisPackageById]
      .map(
        (result) =>
          result.isError &&
          result.error &&
          ('data' in result.error ? result.error.data.userMsg : result.error?.message),
      )
      .filter(Boolean)
      .join(';');

    useEffect(() => {
      if (newPackageVersionQuery.isSuccess) {
        form.resetFields();
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [newPackageVersionQuery.isSuccess]);

    const onSubmit = async (values: IAnalysisPackageSaveFieldFormValues) => {
      try {
        await saveAnalysisPackage({
          ...values,
          objects: selectedTableItems,
        }).unwrap();
        notification.success({
          message: t('saveForm.successMessageCreate', { name: values.version }),
        });
        onClose();
      } catch (e) {
        notification.error({
          message: t('saveForm.errorMessageCreate', { name: values.version }),
        });
      }
    };

    const tabs: TabsProps['items'] = [
      mixСanDmAoRead && {
        key: ELibraryEntityKind.Model,
        label: t('Data Models'),
        children: (
          <DataModelObjects
            kind={ELibraryEntityKind.Model}
            isView={isView}
            data={data}
            analysisPackageById={analysisPackageById.data?.objects.data_model}
            analysisPackageByIdFetching={analysisPackageById.isFetching}
            selectedTableItems={selectedTableItems}
            setSelectedTableItems={setSelectedTableItems}
          />
        ),
      },
      mixСanCdrAoRead && {
        key: ELibraryEntityKind.CDR,
        label: t('CDR'),
        children: (
          <CdrObjects
            kind={ELibraryEntityKind.CDR}
            isView={isView}
            data={data}
            analysisPackageById={analysisPackageById.data?.objects.cdr_report}
            analysisPackageByIdFetching={analysisPackageById.isFetching}
            selectedTableItems={selectedTableItems}
            setSelectedTableItems={setSelectedTableItems}
          />
        ),
      },
      mixCanSqlAoRead && {
        key: ELibraryEntityKind.SQL_Lab,
        label: t('SQL Lab'),
        children: (
          <SqlDatasetsObjects
            kind={ELibraryEntityKind.SQL_Lab}
            isView={isView}
            data={data}
            analysisPackageById={analysisPackageById.data?.objects.sql_lab}
            analysisPackageByIdFetching={analysisPackageById.isFetching}
            selectedTableItems={selectedTableItems}
            setSelectedTableItems={setSelectedTableItems}
          />
        ),
      },
      mixCanClAoRead && {
        key: ELibraryEntityKind.Notebook,
        label: t('Code Lab'),
        children: (
          <CodeLabObjects
            kind={ELibraryEntityKind.Notebook}
            isView={isView}
            data={data}
            analysisPackageById={analysisPackageById.data?.objects.python_notebook}
            analysisPackageByIdFetching={analysisPackageById.isFetching}
            selectedTableItems={selectedTableItems}
            setSelectedTableItems={setSelectedTableItems}
          />
        ),
      },
      mixCanPscAoRead && {
        key: ELibraryEntityKind.Psc,
        label: t('PSC Filters'),
        children: (
          <PscObjects
            kind={ELibraryEntityKind.Psc}
            isView={isView}
            data={data}
            analysisPackageById={analysisPackageById.data?.objects.psc}
            analysisPackageByIdFetching={analysisPackageById.isFetching}
            selectedTableItems={selectedTableItems}
            setSelectedTableItems={setSelectedTableItems}
          />
        ),
      },
      mixCanStackDatasetAoRead && {
        key: ELibraryEntityKind.StackDataset,
        label: t('Stack Datasets'),
        children: (
          <StackDatasetsObjects
            kind={ELibraryEntityKind.StackDataset}
            isView={isView}
            data={data}
            analysisPackageById={analysisPackageById.data?.objects.stack_dataset}
            analysisPackageByIdFetching={analysisPackageById.isFetching}
            selectedTableItems={selectedTableItems}
            setSelectedTableItems={setSelectedTableItems}
          />
        ),
      },
    ].filter((item) => typeof item !== 'boolean') as TabsProps['items'];

    return (
      <FormLayout
        hideFooter={isView}
        form={form}
        onCancel={onClose}
        initialValues={initValues}
        onSubmit={onSubmit}
        cancelText={isView ? t('close') : ''}
        okText={t('save')}
        submitIsDisabled={isSaveDisabled || isView}
        hideOkBtn={isView}
      >
        <FormItem name="version" label={t('saveForm.version')} rules={[{ required: true }]}>
          {isView ? (
            <Input disabled={isView || newPackageVersionQuery.isLoading} />
          ) : (
            <Radio.Group>
              <Radio value={newPackageVersionQuery.data?.major_version || 0}>
                {t('saveForm.majorVersion', { value: newPackageVersionQuery.data?.major_version || 0 })}
              </Radio>
              <Radio value={newPackageVersionQuery.data?.minor_version || 0}>
                {t('saveForm.minorVersion', { value: newPackageVersionQuery.data?.minor_version || 0 })}
              </Radio>
            </Radio.Group>
          )}
        </FormItem>
        <FormItem name="description" label={t('saveForm.description')} rules={[{ required: true }]}>
          <Input disabled={isView || newPackageVersionQuery.isLoading} />
        </FormItem>

        {!tabs?.length ? (
          <Alert message={t('saveForm.noAccessToAllAp')} type="warning" />
        ) : (
          <Tabs style={{ marginTop: 16 }} defaultActiveKey={tabs[0].key} items={tabs} />
        )}

        {errorMessage && <Typography.Text type="danger">{errorMessage}</Typography.Text>}
      </FormLayout>
    );
  },
);

export const AnalysisPackageModalsSaveAnalysisPackage = ({
  open,
  data,
  onClose,
}: AnalysisPackageModalsSaveAnalysisPackageProps) => {
  const { t } = useTranslation(['analysisPackage']);
  const isView = !!(data as IAnalysisPackage)?.id;

  return (
    <DraggableModal
      width="50%"
      open={open}
      onCancel={onClose}
      title={isView ? t('saveForm.titleView') : t('saveForm.title')}
      footer={null}
      destroyOnClose
      footerInContent={!isView}
    >
      <AnalysisPackageModalsSaveAnalysisPackageContent data={data} onClose={onClose} t={t} isView={isView} />
    </DraggableModal>
  );
};

export interface AnalysisPackageModalsSaveAnalysisPackageProps {
  open: boolean;
  data: Partial<Pick<IAnalysisPackage, 'id' | 'version' | 'description'>>;
  onClose: () => void;
}

interface AnalysisPackageModalsSaveAnalysisPackageContentProps
  extends Pick<AnalysisPackageModalsSaveAnalysisPackageProps, 'data' | 'onClose'> {
  t: TFunction;
  isView: boolean;
  mixСanDmAoRead?: boolean;
  mixСanCdrAoRead?: boolean;
  mixCanSqlAoRead?: boolean;
  mixCanPscAoRead?: boolean;
  mixCanClAoRead?: boolean;
  mixCanStackDatasetAoRead?: boolean;
}

export interface TableComponentProps extends Pick<AnalysisPackageModalsSaveAnalysisPackageProps, 'data'> {
  isView: boolean;
  kind: ELibraryEntityKind;
  selectedTableItems: Record<string, any>;
  setSelectedTableItems: (val: any) => void;
  analysisPackageByIdFetching?: boolean;
}

interface IAnalysisPackageSaveFieldFormValues {
  id?: number;
  version: string;
  description: string;
  objects: IAnalysisPackage['objects'];
}

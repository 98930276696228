export enum EDatasetModalsType {
  'createStackDataset' = 'createStackDataset',
  'showLogs' = 'showLogs',
}

export enum StackDatasetLogStatus {
  'Success' = 'Success',
  'Error' = 'Error',
  'Skipped' = 'Skipped',
}

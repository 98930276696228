import { ICDRReport } from '@modules/cdrReport/CDRReportTypes';
import { prepareLibraryObject } from '@modules/library/root/duck/libraryUtils';
import { ELibrarySourceType, IBaseLibraryEntitySystemInfo } from '@modules/library/root/LibraryTypes';
import { IPrepareCDRReportTableData, ICDRReportObject, ICDRReportObjectConfiguration } from '../CDRObjectTypes';

export function prepareCDRReportTableData(
  props: IPrepareCDRReportTableDataFromSource,
): IPrepareCDRReportTableData<ICDRReport>;
export function prepareCDRReportTableData(
  props: IPrepareCDRReportTableDataFromDnAObject,
): IPrepareCDRReportTableData<ICDRReportObject>;
export function prepareCDRReportTableData(
  props: IPrepareCDRReportTableDataFromDnAObject | IPrepareCDRReportTableDataFromSource,
) {
  if (!Array.isArray(props.data)) return [];

  if (props.sourceType === ELibrarySourceType.Library) {
    return props.data.map((item) => ({
      id: item.id,
      version_id: item.version_id,
      name: item.name,
      libraryVersion: Array.isArray(item.versions) ? item.version : undefined,
      rawData: item,
    })) as IPrepareCDRReportTableData<ICDRReportObject>;
  }

  return props.data.map((item) => ({
    id: item.id,
    name: item.name,
    rawData: item,
  })) as IPrepareCDRReportTableData<ICDRReport>;
}

export function prepareCDRReportObjectForImport(
  values: (ICDRReportObject | ICDRReport)[],
  sourceType: ELibrarySourceType,
  systemInfo?: IBaseLibraryEntitySystemInfo,
) {
  return prepareLibraryObject<ICDRReportObjectConfiguration>(
    values.map((item) => {
      if (sourceType === ELibrarySourceType.Library) {
        const objectItem = item as ICDRReportObject;
        return {
          ...objectItem,
          configuration: objectItem.configuration!,
          source: objectItem.source || systemInfo?.source,
          env: objectItem.env || systemInfo?.env,
        };
      }

      const { id, ..._cdrReport } = item as ICDRReport;

      return {
        name: _cdrReport.name,
        object_type: _cdrReport.type || 'Report',
        configuration: _cdrReport as ICDRReportObjectConfiguration,
      };
    }),
    systemInfo,
  ).map((item) => ({ ...item }));
}

interface IPrepareCDRReportTableDataFromDnAObject {
  data: ICDRReportObject[];
  sourceType: ELibrarySourceType.Library;
}

interface IPrepareCDRReportTableDataFromSource {
  data: ICDRReport[];
  sourceType: ELibrarySourceType.Study;
}

import { Reducer } from 'redux';
import { libraryAuditReducer } from './audit/duck/libraryAuditSlice';
import { librarySqlQueryReducer } from './sqlQuery/duck/librarySqlQuerySlice';
import { libraryModelReducer } from './model/duck/libraryModelSlice';
import { libraryPscReducer } from './psc/duck/libraryPscSlice';
import { libraryReducer } from './root/duck/librarySlice';
import { analysisPackageReducer } from './analysisPackage/duck/analysisPackageSlice';
import { analysisObjectModelReducer } from './analysisObjects/model/duck/analysisObjectModelSlice';
import { analysisObjectPscReducer } from './analysisObjects/psc/duck/analysisObjectPscSlice';
import { libraryObjectReducer } from './libraryObject/duck/libraryObjectSlice';
import { analysisObjectReducer } from './analysisObjects/duck/analysisObjectSlice';

export const libraryReducers = {
  library: libraryReducer,
  libraryModel: libraryModelReducer,
  librarySqlQuery: librarySqlQueryReducer,
  libraryPsc: libraryPscReducer,
  libraryAudit: libraryAuditReducer,
  libraryObject: libraryObjectReducer,
  analysisObject: analysisObjectReducer,
  analysisPackage: analysisPackageReducer,
  analysisObjectModel: analysisObjectModelReducer,
  analysisObjectPsc: analysisObjectPscReducer,
};
export type LibraryRootStage = ReturnType<
  Reducer<{
    libraryObject: ReturnType<typeof libraryObjectReducer>;
    analysisObject: ReturnType<typeof analysisObjectReducer>;
    library: ReturnType<typeof libraryReducer>;
    libraryModel: ReturnType<typeof libraryModelReducer>;
    librarySqlQuery: ReturnType<typeof librarySqlQueryReducer>;
    libraryPsc: ReturnType<typeof libraryPscReducer>;
    libraryAudit: ReturnType<typeof libraryAuditReducer>;
    analysisPackage: ReturnType<typeof analysisPackageReducer>;
    analysisObjectModel: ReturnType<typeof analysisObjectModelReducer>;
    analysisObjectPsc: ReturnType<typeof analysisObjectPscReducer>;
  }>
>;

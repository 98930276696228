import { Button, Space, Spin } from '@ui';
import { useStudyPermissions } from '@modules/user/duck/userHooks';
import { useTestConnectionMutation } from '@modules/source/duck/sourceApi';
import { CustomVolumeNameField } from '@modules/source/modals/components/save/components/CustomVolumeNameField';
import { DATABRICKS_FIELD } from '@modules/source/modals';
import { VolumeOptions } from '@modules/source/SourceTypes';
import { CSSObject, Theme, useTheme } from '@emotion/react';
import { RefObject } from 'react';
import { AutoForm, IFormControlProps, IFormDefinition, IFormValue, validateRequiredProperties } from 'react-auto-form';
import 'react-auto-form/dist/auto-form.css';
import { useTranslation } from 'react-i18next';

export const SourceModalsSaveConnection = ({
  source_id,
  definition,
  initValues,
  onSave,
  isEdit,
  innerRef,
  isDatabricks,
  volumesOptions,
  isLoadingOptions,
}: SourceModalsSaveConnectionProps) => {
  const theme = useTheme();
  const { t } = useTranslation('datasource');
  const [testConnection, { data, isLoading, isError, error, isSuccess }] = useTestConnectionMutation();

  const {
    userPermissions: { canDataSourceUpdate },
  } = useStudyPermissions();

  const testConnectionResultMessage =
    (isError && error && 'data' in error && error.data.rawData?.message) || (isSuccess && data?.message) || null;

  const validate = (values: IFormValue) =>
    validateRequiredProperties(definition!, (name: string) => t('shared.fieldRequired', { field: name }), values);

  const testConnectionHandler = (values: IFormValue) => testConnection({ ...values, id: source_id }).unwrap();

  return (
    <AutoForm<IFormValue>
      definition={definition}
      initialValue={initValues}
      i18nRequiredProperty="Required"
      validate={validate}
      isInitialValid
      onSave={onSave}
      enableReinitialize
      innerRef={innerRef}
      renderCustomField={(props: Exclude<IFormControlProps, 'renderCustomField'>) => {
        if (props?.property.name === DATABRICKS_FIELD) {
          return <CustomVolumeNameField {...props} options={volumesOptions} isLoading={isLoadingOptions} />;
        }

        return null;
      }}
    >
      {({ fields, handleSubmit, submitForm, isSubmitting, isValid, values, isValidating }) => (
        <div css={cssBody}>
          {fields}
          {!isDatabricks && (
            <Space full justify="end">
              {isLoading ? (
                <Spin size="small" />
              ) : (
                <div css={cssTestMsg(isError, theme)}>{testConnectionResultMessage}</div>
              )}
              <Button
                disabled={!isValid || isLoading || (isEdit && !canDataSourceUpdate)}
                onClick={() => testConnectionHandler(values)}
              >
                {t('buttons.testConnection')}
              </Button>
            </Space>
          )}
        </div>
      )}
    </AutoForm>
  );
};

const cssBody = (theme: Theme): CSSObject => ({
  width: '100%',

  '&& .AutoForm_FormGroup--disableMargin': {
    marginBottom: 0,
  },

  '.AutoForm_FormGroup': {
    lineHeight: 1.5,
    alignItems: 'center',
    marginBottom: 16,
  },

  '.AutoForm_FormGroup-formLabel__text': {
    fontWeight: 500,
  },

  '&& .AutoForm_FormGroup-helperText--error': {
    color: theme['color-error-red-primary'],
  },

  '.AutoForm_FormGroup-helperText': {
    fontSize: '14px',
    lineHeight: 1.5,
    color: theme['color-grey-600'],
    paddingTop: '8px',
  },
  // TODO not working
  '&& .AutoForm_FormGroup-formLabel__required': {
    content: "'...'",
  },
});

const cssTestMsg = (isError: boolean, theme: Theme): CSSObject => ({
  color: isError ? theme['color-error-red-primary'] : theme['color-green-text'],
});

interface SourceModalsSaveConnectionProps {
  source_id?: number;
  initValues: any;
  definition: IFormDefinition;
  onSave: (values: IFormDefinition) => void;
  innerRef: RefObject<any>;
  isEdit?: boolean;
  isDatabricks?: boolean;
  isLoadingOptions?: boolean;
  volumesOptions?: VolumeOptions[];
}

import { useGlobalTablesQuery, useTablesQuery } from '@modules/viewer/duck/viewerApi';
import { ViewerGroupType } from '@modules/viewer/ViewerTypes';
import { viewerActions, ViewerGroup } from '@modules/viewer/duck/viewerSlice';
import { selectApiTablesCacheById, selectExpandedGroupsMemo } from '@modules/viewer/duck/viewerSelectors';
import { DEFAULT_EXTERNAL_STORES, EXTERNAL_STORES } from '@modules/stores/duck/storeConstants';
import { filteredTreeData, generateDataViewerTableId, getTableNameWithSchema } from '@shared/utils/Viewer';
import { selectAppliedENVSwitch, selectGlobalStudy } from '@app/duck/appSelectors';
import { selectStudyActiveUserRole } from '@modules/study/duck/studySelectors';
import { ModelEditorNodeType } from '@modules/modelEditor/ModelEditorTypes';
import { getOnMouseClickCoordinates } from '@modules/modelEditor/components/builder/Utils';
import { IFlatMetadata } from 'react-accessible-treeview/dist/TreeView/utils';
import { getApiTablesCacheKey } from '@modules/viewer/duck/viewerUtils';
import { useEffect, useMemo, useRef, DragEvent, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { DataNode } from 'antd/es/tree';
import {
  INode,
  ITreeViewOnExpandProps,
  ITreeViewOnNodeSelectProps,
  ITreeViewProps,
  NodeId,
} from 'react-accessible-treeview';

const storeTablesFallback: ViewerGroup[] = [];

export const useTableListSider = ({
  selectedTable,
  defaultTableName,
  defaultFolderName,
  defaultFolderId,
  onSelectTable,
  globalStudyId,
  treeData,
}: IUseTaleListSiderProps) => {
  const dispatch = useDispatch();
  const appliedENVSwitch = useSelector(selectAppliedENVSwitch) || '';
  const expandedGroups = useSelector(selectExpandedGroupsMemo(globalStudyId));
  const [searchText, setSearchText] = useState('');

  const filteredTree = searchText ? filteredTreeData(treeData, searchText) : treeData;

  const defaultTableNameProcessed = useRef(false);

  useEffect(() => {
    defaultTableNameProcessed.current = false;
  }, [appliedENVSwitch]);

  useEffect(() => {
    if (defaultTableName && !defaultTableNameProcessed.current) {
      let defaultTable: (DataNode & { folderId: NodeId | null }) | undefined;

      filteredTree?.some((parent) =>
        parent.children?.some((child) => {
          if (defaultFolderName !== null) {
            if (
              child.metadata?.folderName === defaultFolderName &&
              child.metadata?.originalId?.toString().match(new RegExp(`[.:]${defaultTableName}$`))
            ) {
              defaultTable = { key: child.id, title: child.name, folderId: child.metadata?.folderId ?? null };
              return true;
            }
          } else if (defaultFolderId !== null) {
            if (
              // eslint-disable-next-line eqeqeq
              child.metadata?.folderId == defaultFolderId &&
              child.metadata?.originalId?.toString().match(new RegExp(`[.:]${defaultTableName}$`))
            ) {
              defaultTable = { key: child.id, title: child.name, folderId: child.metadata.folderId ?? null };
              return true;
            }
          } else if (
            child.metadata?.originalId &&
            child.metadata?.originalId?.toString().match(new RegExp(`[.:]${defaultTableName}$`))
          ) {
            defaultTable = { key: child.id, title: child.name, folderId: child.metadata?.folderId ?? null };
            return true;
          }
          return false;
        }),
      );

      if (defaultTable) {
        const key = parseInt((defaultTable['key'] as string).split(':').at(0) ?? '0', 10);

        if (key && !expandedGroups.includes(key)) {
          dispatch(
            viewerActions.updateExpanded({
              studyId: globalStudyId,
              keys: [...expandedGroups, key],
            }),
          );
        }

        defaultTableNameProcessed.current = true;
        onSelectTable(defaultTable.key as string, defaultTable.title as string, defaultTable.folderId);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultTableName, treeData, expandedGroups, defaultFolderId, defaultFolderName, globalStudyId, appliedENVSwitch]);

  const onDragStart: any = (event: DragEvent, nodeProps: INode) => {
    const { isBranch, name, metadata } = nodeProps;

    if (!isBranch) {
      event.dataTransfer.setData('application/reactflow', ModelEditorNodeType.domain);
      event.dataTransfer.setData('application/reactflow/name', name?.toString()!);
      event.dataTransfer.setData('application/reactflow/tableName', metadata?.originalId as string);
      event.dataTransfer.setData(
        'application/reactflow/mouse_click_coordinates',
        JSON.stringify(getOnMouseClickCoordinates(event)),
      );
      event.dataTransfer.effectAllowed = 'move';
    } else {
      event.preventDefault();
    }
  };

  const onSelect: ITreeViewProps['onNodeSelect'] = (props: ITreeViewOnNodeSelectProps, ...t) => {
    const { isBranch } = props.element;
    if (!isBranch && props.isSelected) {
      onSelectTable(props.element.id as string, props.element.name, props.element.parent);
    }
  };

  const onExpand: ITreeViewProps['onExpand'] = (props: ITreeViewOnExpandProps) => {
    const keys = Array.from(props.treeState.expandedIds);
    dispatch(
      viewerActions.updateExpanded({
        studyId: globalStudyId,
        keys: keys ?? [],
      }),
    );
  };

  let selectedKeys: string[] = [];
  if (selectedTable && expandedGroups.some((group) => selectedTable?.startsWith(group as string))) {
    selectedKeys = [selectedTable];
  }

  return {
    filteredTree,
    expandedGroups,
    selectedKeys,
    onDragStart,
    searchText,
    onSelect,
    onExpand,
    setSearchText,
  };
};

export const useStoreAggregation = (draggable?: boolean) => {
  const dispatch = useDispatch();
  const globalStudy = useSelector(selectGlobalStudy);
  const appliedENVSwitch = useSelector(selectAppliedENVSwitch);

  const studyActiveRole = useSelector(selectStudyActiveUserRole);
  const tablesQueryParams = {
    study_id: globalStudy!.id,
    source_env: appliedENVSwitch,
    role_id: studyActiveRole?.role_id,
  };

  const apiTablesCachedData = useSelector((state) => selectApiTablesCacheById(state, tablesQueryParams));

  const tablesQuery = useTablesQuery(tablesQueryParams);

  // Save previous Table data in parallel
  useEffect(() => {
    if (tablesQuery.currentData) {
      dispatch(
        viewerActions.setApiTablesCache({
          response: { data: tablesQuery.currentData, fulfilledTimeStamp: tablesQuery.fulfilledTimeStamp },
          roleId: tablesQueryParams.role_id,
          studyId: tablesQueryParams.study_id,
          env: tablesQueryParams.source_env,
        }),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tablesQuery.currentData]);

  const treeData = useMemo(() => {
    const queryData = tablesQuery.currentData || apiTablesCachedData?.data;

    const groupsData = {
      rccGroups: storeTablesFallback,
      internal: storeTablesFallback,
      external: storeTablesFallback,
      studyRT: storeTablesFallback,
      crossStudyRT: storeTablesFallback,
      globalDS: storeTablesFallback,
      globalStackDS: storeTablesFallback,
      globalOperational: storeTablesFallback,
    };

    if (queryData) {
      groupsData.external = (
        queryData?.filter((store) => store.type === ViewerGroupType.external) ?? Object.values(DEFAULT_EXTERNAL_STORES)
      ).map(({ name, tables }) => ({
        name,
        tables: tables.map((tableName) => ({
          id: tableName,
          name: getTableNameWithSchema(tableName).name || tableName,
        })),
        id: DEFAULT_EXTERNAL_STORES[name as EXTERNAL_STORES].id ?? 0,
      }));

      groupsData.rccGroups = (queryData?.filter((store) => store.type === ViewerGroupType.rccGroups) ?? []).map(
        (group, index) => ({
          id: -index - 100, // to not conflict with data stores and default data stores
          name: group.name!,
          tables: group.tables.map((tableName) => ({
            id: tableName,
            name: getTableNameWithSchema(tableName).name || tableName,
          })),
        }),
      );

      groupsData.internal = (queryData?.filter((store) => store.type === ViewerGroupType.internal) ?? []).map(
        (store) => ({
          id: store.id!,
          name: store.name,
          tables: store.tables.map((tableName) => ({
            id: tableName,
            name: getTableNameWithSchema(tableName).name || tableName,
          })),
        }),
      );

      groupsData.studyRT = (queryData?.filter((store) => store.type === ViewerGroupType.studyRT) ?? []).map(
        (store) => ({
          id: store.id!,
          name: store.name,
          tables: store.tables.map((tableName) => ({
            id: tableName,
            name: getTableNameWithSchema(tableName).name || tableName,
          })),
        }),
      );

      groupsData.crossStudyRT = (queryData?.filter((store) => store.type === ViewerGroupType.crossStudyRT) ?? []).map(
        (store) => ({
          id: DEFAULT_EXTERNAL_STORES[store.name as EXTERNAL_STORES].id ?? 0,
          name: store.name,
          tables: store.tables.map((tableName) => ({
            id: tableName,
            name: getTableNameWithSchema(tableName).name || tableName,
          })),
        }),
      );

      groupsData.globalDS = (queryData?.filter((store) => store.type === ViewerGroupType.globalDS) ?? []).map(
        (store) => ({
          id: DEFAULT_EXTERNAL_STORES[store.name as EXTERNAL_STORES].id ?? 0,
          name: store.name,
          tables: store.tables.map((tableName) => ({
            id: tableName,
            name: getTableNameWithSchema(tableName).name || tableName,
          })),
        }),
      );

      groupsData.globalStackDS = (queryData?.filter((store) => store.type === ViewerGroupType.globalStackDS) ?? []).map(
        (store) => ({
          id: DEFAULT_EXTERNAL_STORES[store.name as EXTERNAL_STORES].id ?? 0,
          name: store.name,
          tables: store.tables.map((tableName) => ({
            id: tableName,
            name: getTableNameWithSchema(tableName).name || tableName,
          })),
        }),
      );

      groupsData.globalOperational = (
        queryData?.filter((store) => store.type === ViewerGroupType.globalOperational) ?? []
      ).map((store) => ({
        id: DEFAULT_EXTERNAL_STORES[store.name as EXTERNAL_STORES].id ?? 0,
        name: store.name,
        tables: store.tables.map((tableName) => ({
          id: tableName,
          name: getTableNameWithSchema(tableName).name || tableName,
        })),
      }));
    }

    const getTreeData = (data: ViewerGroup[], groupType: ViewerGroupType) =>
      data.map((store) => ({
        id: store.id,
        name: store.name,
        isBranch: true,
        metadata: {
          groupType,
        },
        children: (store.tables || []).map((table, tableIndex) => ({
          id: generateDataViewerTableId(store.id, tableIndex, table.id),
          name: table.name,
          metadata: {
            originalId: table.id,
            folderName: store.name,
            folderId: store.id,
            dataViewerTableId: generateDataViewerTableId(store.id, tableIndex, table.id),
          },
        })),
      }));

    return {
      data: [
        ...getTreeData(groupsData.external, ViewerGroupType.external),
        ...getTreeData(groupsData.studyRT, ViewerGroupType.studyRT),
        ...getTreeData(groupsData.crossStudyRT, ViewerGroupType.crossStudyRT),
        ...getTreeData(groupsData.globalDS, ViewerGroupType.globalDS),
        ...getTreeData(groupsData.globalStackDS, ViewerGroupType.globalStackDS),
        ...getTreeData(groupsData.globalOperational, ViewerGroupType.globalOperational),
        ...getTreeData(groupsData.internal, ViewerGroupType.internal),
        ...getTreeData(groupsData.rccGroups, ViewerGroupType.rccGroups),
      ],
      treeKey: `${getApiTablesCacheKey(
        tablesQueryParams.study_id,
        tablesQueryParams.role_id,
        tablesQueryParams.source_env,
      )}~${tablesQuery.fulfilledTimeStamp || apiTablesCachedData?.fulfilledTimeStamp || 'na'}`,
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tablesQuery.currentData]);

  return { treeData: treeData.data, tablesQuery, globalStudy, tablesQueryTreeKey: treeData.treeKey };
};

export const useCrossStoreAggregation = () => {
  const dispatch = useDispatch();
  const globalStudy = useSelector(selectGlobalStudy);
  const appliedENVSwitch = useSelector(selectAppliedENVSwitch);

  const tablesQueryParams = {
    study_id: globalStudy!.id,
    source_env: appliedENVSwitch,
  };

  const apiTablesCachedData = useSelector((state) => selectApiTablesCacheById(state, tablesQueryParams));
  const globalTablesQuery = useGlobalTablesQuery(tablesQueryParams);

  // Save previous Table data in parallel
  useEffect(() => {
    if (globalTablesQuery.currentData) {
      dispatch(
        viewerActions.setApiTablesCache({
          response: { data: globalTablesQuery.currentData, fulfilledTimeStamp: globalTablesQuery.fulfilledTimeStamp },
          studyId: tablesQueryParams.study_id,
          env: tablesQueryParams.source_env,
        }),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [globalTablesQuery.currentData]);

  const treeData = useMemo(() => {
    const queryData = globalTablesQuery.currentData || apiTablesCachedData?.data;

    const groupsData = {
      crossStudyRT: storeTablesFallback,
      globalDS: storeTablesFallback,
      globalStackDS: storeTablesFallback,
      globalOperational: storeTablesFallback,
    };

    if (queryData) {
      queryData.forEach((store) => {
        if (groupsData[store.type as keyof typeof groupsData]) {
          groupsData[store.type as keyof typeof groupsData] = [
            {
              id: DEFAULT_EXTERNAL_STORES[store.name as EXTERNAL_STORES].id ?? 0,
              name: store.name,
              tables: store.tables.map((tableName) => ({
                id: tableName,
                name: getTableNameWithSchema(tableName).name || tableName,
              })),
            },
          ];
        }
      });
    }

    const getTreeData = (data: ViewerGroup[], groupType: ViewerGroupType) =>
      data.map((store) => ({
        id: store.id,
        name: store.name,
        isBranch: true,
        metadata: {
          groupType,
        },
        children: (store.tables || []).map((table, tableIndex) => ({
          id: generateDataViewerTableId(store.id, tableIndex, table.id),
          name: table.name,
          metadata: {
            originalId: table.id,
            folderName: store.name,
            folderId: store.id,
            dataViewerTableId: generateDataViewerTableId(store.id, tableIndex, table.id),
          },
        })),
      }));

    return {
      data: [
        ...getTreeData(groupsData.crossStudyRT, ViewerGroupType.crossStudyRT),
        ...getTreeData(groupsData.globalDS, ViewerGroupType.globalDS),
        ...getTreeData(groupsData.globalStackDS, ViewerGroupType.globalStackDS),
        ...getTreeData(groupsData.globalOperational, ViewerGroupType.globalOperational),
      ],
      treeKey: `${getApiTablesCacheKey(tablesQueryParams.study_id, undefined, tablesQueryParams.source_env)}~${
        globalTablesQuery.fulfilledTimeStamp || apiTablesCachedData?.fulfilledTimeStamp || 'na'
      }`,
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [globalTablesQuery.currentData]);

  return { treeData: treeData.data, globalTablesQuery, globalStudy, tablesQueryTreeKey: treeData.treeKey };
};

export interface IMetadata extends IFlatMetadata {
  groupType?: ViewerGroupType;
  originalId?: string;
  folderName?: string;
  folderId?: string | number;
}

export interface NodeData {
  id: NodeId;
  name: string;
  children?: NodeData[];
  isBranch?: boolean;
  metadata?: IMetadata;
}

interface IUseTaleListSiderProps {
  onSelectTable: (tableKey: string, tableName: string, tableFolderId: NodeId | null) => void;
  selectedTable?: string | null;
  defaultTableName?: string | null;
  defaultFolderName?: string | null;
  defaultFolderId?: number | string | null;
  treeData: NodeData[];
  globalStudyId: number;
}

import { LibraryModelModalsController } from '@modules/library/model/modals';
import { LibrarySqlQueryModalsController } from '@modules/library/sqlQuery/modals';
import { LibraryPscModalsController } from '@modules/library/psc/modals';
import { FallbackRender } from '@app/components';
import { CSSObject } from '@emotion/react';
import { Outlet } from 'react-router-dom';
import { ErrorBoundary } from 'react-error-boundary';
import { LibraryObjectModalsController } from '../libraryObject/modals/LibraryObjectModalsController';

export const LibraryApp = () => {
  return (
    <div css={cssBody}>
      <div css={cssContent}>
        <ErrorBoundary FallbackComponent={FallbackRender}>
          <Outlet />
        </ErrorBoundary>
      </div>
      <LibraryModelModalsController />
      <LibrarySqlQueryModalsController />
      <LibraryPscModalsController />
      <LibraryObjectModalsController />
    </div>
  );
};

const cssBody = (): CSSObject => ({
  display: 'flex',
  width: '100%',
  height: '100%',
  position: 'relative',
});

const cssContent = (): CSSObject => ({
  width: '100%',
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'start',
  overflowX: 'hidden',
  overflowY: 'auto',
});

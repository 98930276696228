import { selectGlobalStudy } from '@app/duck/appSelectors';
import { useFeatures } from '@modules/user/duck/userHooks';
import { isCrossStudy } from '@shared/utils/common';
import { Fragment, ReactElement } from 'react';
import { useSelector } from 'react-redux';

enum AppGatewaySectionMode {
  'onlyCrossStudy' = 'onlyCrossStudy',
  'onlyStudy' = 'onlyStudy',
  'onlyGLMode' = 'onlyGLMode',
}

const checkDeniedRender = (hasGL: boolean, globalStudyId: number, modes: Omit<AppGatewaySectionProps, 'children'>) => {
  return Object.keys(modes).some((key) => {
    if (modes[AppGatewaySectionMode[key as keyof typeof AppGatewaySectionMode]] !== true) {
      return false;
    }
    switch (key as keyof typeof AppGatewaySectionMode) {
      case AppGatewaySectionMode.onlyCrossStudy:
        return !isCrossStudy(globalStudyId);
      case AppGatewaySectionMode.onlyStudy:
        return isCrossStudy(globalStudyId);
      case AppGatewaySectionMode.onlyGLMode:
        return !hasGL;
      default:
        return false;
    }
  });
};

export const AppGatewaySection = (props: AppGatewaySectionProps): ReactElement => {
  const globalStudy = useSelector(selectGlobalStudy);
  const { hasGL } = useFeatures();

  if (checkDeniedRender(hasGL, globalStudy?.id!, props)) {
    return <Fragment />;
  }

  return props.children as ReactElement;
};

export const AppGatewaySectionRenderProps = (props: AppGatewaySectionRenderPropsProps): ReactElement => {
  const globalStudy = useSelector(selectGlobalStudy);
  const { hasGL } = useFeatures();

  return props.children(!checkDeniedRender(hasGL, globalStudy?.id!, props));
};

type AppGatewaySectionModeType = {
  [key in AppGatewaySectionMode]?: boolean;
};

interface AppGatewaySectionProps extends AppGatewaySectionModeType {
  children: ReactElement | undefined | boolean;
}

interface AppGatewaySectionRenderPropsProps extends AppGatewaySectionModeType {
  children: (allowToRender: boolean) => ReactElement;
}

import { DraggableModal } from '@ui';
import { ICodeLabObjectSelectFormProps, CodeLabObjectSelectForm } from '@modules/dnaObject/CodeLab/components';
import { ICodeLabObject } from '@modules/dnaObject/CodeLab/CodeLabObjectTypes';
import { prepareCodeLabObjectForImport } from '@modules/dnaObject/CodeLab/duck/codeLabUtils';
import { LibraryStatus } from '@modules/library/root/duck/libraryConstants';
import { useTranslation } from 'react-i18next';
import { TFunction } from 'i18next';
import { useImportAnalysisObjectCodeLabMutation } from '../duck/analysisObjectApi';

const AnalysisObjectCodeLabModalsImportContent = ({ onClose }: IAnalysisObjectCodeLabModalsImportContentProps) => {
  const [importAOCodeLab] = useImportAnalysisObjectCodeLabMutation();

  const onImport: ICodeLabObjectSelectFormProps['onSubmit'] = async (
    values: ICodeLabObject[],
    { systemInfo, kind },
  ) => {
    return await importAOCodeLab({
      data: prepareCodeLabObjectForImport(values, kind, systemInfo),
      source: kind,
    }).unwrap();
  };

  return (
    <CodeLabObjectSelectForm
      onClose={onClose}
      onSubmit={onImport}
      hideOverwriteHandles
      onlyCurrentEnv
      libraryStatuses={[LibraryStatus.Active]}
    />
  );
};

export const AnalysisObjectCodeLabModalsImport = ({ open, data, onClose }: IAnalysisObjectCodeLabModalsImportProps) => {
  const { t } = useTranslation(['analysisObjects']);

  return (
    <DraggableModal
      width="50%"
      open={open}
      onCancel={onClose}
      title={t('codeLabObject.importForm.title')}
      footer={null}
      destroyOnClose
      footerInContent={true}
    >
      <AnalysisObjectCodeLabModalsImportContent data={data} onClose={onClose} t={t} />
    </DraggableModal>
  );
};

export interface IAnalysisObjectCodeLabModalsImportProps {
  open: boolean;
  data: any;
  onClose: () => void;
}

interface IAnalysisObjectCodeLabModalsImportContentProps
  extends Pick<IAnalysisObjectCodeLabModalsImportProps, 'data' | 'onClose'> {
  t: TFunction;
}

import { SupportedEnvs, SupportedEnvsValues } from '@app/AppTypes';
import { ProdLabels, UatLabels } from '@modules/library/analysisPackage/modals';

export const sortingEnvs = (data?: SupportedEnvsValues) => {
  const labelOrder: { [key: string]: number } = {
    DEV: 1,
    ...UatLabels.reduce((acc, label) => ({ ...acc, [label]: 2 }), {}),
    ...ProdLabels.reduce((acc, label) => ({ ...acc, [label]: 3 }), {}),
  };

  const sortedData =
    (data &&
      !!data.length &&
      data.sort((a, b) => {
        const labelA = a[1]?.label ?? '';
        const labelB = b[1]?.label ?? '';

        if (labelOrder[labelA] && labelOrder[labelB]) {
          return labelOrder[labelA] - labelOrder[labelB];
        }

        if (labelOrder[labelA]) return -1;
        if (labelOrder[labelB]) return 1;

        return 0;
      })) ||
    [];

  return sortedData;
};

export const filterEnvsQuery = (currentEnvLabel: string, data?: SupportedEnvs) => {
  if (!data) return [];

  const dataEntries = Object.entries(data);
  const isUatEnvExists = dataEntries.find(([_, v]) => UatLabels.includes(v?.label ?? ''));

  const getEnvLabels = () => {
    if (UatLabels.includes(currentEnvLabel)) {
      // if current UAT - show only PROD envs (UAT->EXEC)
      return ProdLabels;
    }

    if (ProdLabels.includes(currentEnvLabel)) {
      // if current EXEC - show all devs and other envs without UAT (EXEC->DEV switch on/off flag?)
      return dataEntries
        .filter(([_, v]) => ![...UatLabels, ...ProdLabels].includes(v?.label ?? ''))
        .map(([_, v]) => v?.label ?? '');
    }

    if (isUatEnvExists) {
      // if current other env and UAT is in supported envs show all envs without EXEC (DEV->DEV | DEV->UAT)
      return dataEntries.filter(([_, v]) => !ProdLabels.includes(v?.label ?? '')).map(([_, v]) => v?.label ?? '');
    }

    // if current dev or other env and UAT is in NOT supported envs show all envs (DEV->DEV | DEV->EXEC)
    return dataEntries.map(([_, v]) => v?.label ?? '');
  };

  const envLabels = getEnvLabels();

  // if current other env and UAT is NOT in supported envs show all envs
  return dataEntries.filter(([_, v]) => envLabels.includes(v?.label ?? ''));
};

export const getJsonFromString = (str: string): false | Record<any, any> => {
  try {
    return JSON.parse(str);
  } catch (e) {
    return false;
  }
};

import { Badge, Dropdown } from '@ui';
import { TasksIcon } from '@components/icons';
import { TaskPanel } from '@modules/task/components/TaskPanel';
import { selectDatasetsRunning, selectSparkJobQueue, selectTasksRunning } from '@modules/task/duck/tasksSelector';
import { useSelector } from 'react-redux';
import { css } from '@emotion/react';
import { CSSProperties } from 'react';

export const TasksButton = () => {
  const runningTasks = useSelector(selectTasksRunning);
  const sparkJobQueue = useSelector(selectSparkJobQueue);
  const runningDatasets = useSelector(selectDatasetsRunning);

  return (
    <Dropdown
      placement="bottomRight"
      trigger={['click']}
      menu={{
        items: [
          {
            key: 'taskPanel',
            label: <TaskPanel tasks={runningTasks} sparkJobQueue={sparkJobQueue} datasets={runningDatasets} />,
            style: cssDropdownItem,
          },
        ],
      }}
    >
      <Badge
        count={runningTasks.length + sparkJobQueue.length + runningDatasets.length}
        size="small"
        css={cssBadge(!!runningTasks.length || !!sparkJobQueue.length || !!runningDatasets.length)}
      >
        <TasksIcon css={cssTasksButton} />
      </Badge>
    </Dropdown>
  );
};

const cssBadge = (hasNotifications: boolean) => css`
  .ant-badge-count {
    height: 8px;
    min-width: 8px;
    padding: 0;
    top: 8px;
    right: 4px;
    display: ${hasNotifications ? 'block' : 'none'};

    .ant-scroll-number-only {
      display: none;
    }
  }
`;

const cssTasksButton = css`
  font-size: 32px;
`;

const cssDropdownItem: CSSProperties = {
  backgroundColor: 'transparent',
};

import { Button, DraggableModal, Space, Tooltip, Typography, Divider, Alert } from '@ui';
import { ColumnType, ModelEditorNodeSql } from '@modules/modelEditor/ModelEditorTypes';
import { useLazyRunSqlQuery } from '@modules/model/duck/modelApi';
import { useTableInfoQuery, useTablesQuery } from '@modules/viewer/duck/viewerApi';
import { selectViewerDataFormats } from '@modules/viewer/duck/viewerSelectors';
import { isContainSpecialCharOrSpace } from '@modules/modelEditor/modals/utils';
import { selectStudyActiveUserRole } from '@modules/study/duck/studySelectors';
import { useStudyListQuery } from '@modules/study/duck/studyApi';
import { DataTable, DataTableProps, Loader, NoDataFound, renderCellTypedValue } from '@components';
import { useSaveStage } from '@modules/modelEditor/modals/components/modelEditorModalsHooks';
import { SqlBuilder } from '@modules/modelEditor/components/sqlBuilder';
import { Tabs } from '@components/ui/tabs';
import { ExpressionVarsList } from '@modules/modelEditor/components/expressionBuilder/ExpressionVarsList';
import { selectGlobalStudy } from '@app/duck/appSelectors';
import { MenuSelector } from '@components/MainMenu/components/MenuSelector';
import { Beaker, InfoIcon, PlusCircle, StudySwitcherIcon } from '@components/icons';
import { selectModelEditorReadOnly } from '@modules/modelEditor/duck/modelEditorSelectors';
import { useEnvironments } from '@modules/viewer/duck/viewerHooks';
import { StudyResponse } from '@modules/study/StudyTypes';
import { extractType } from '@modules/modelEditor/components/schemaEditor/ModelEditorSchemaEditorUtils';
import CustomSqlHighlightMode from '@modules/modelEditor/components/customSql/Highlights';
import { Ace } from 'ace-builds/ace';
import { isCrossStudy } from '@shared/utils/common';
import { TFunction } from 'i18next';
import { useTranslation } from 'react-i18next';
import { CSSObject, Theme } from '@emotion/react';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import AceEditor from 'react-ace';
import { TreeDataNode, TreeProps } from 'antd';
import { RenderCellProps } from 'react-data-grid';

const PREVIEW_SIZE = 10;
export const STUDY_PLACEHOLDER = '<study_placeholder>';
export const ENV_PLACEHOLDER = '<env_placeholder>';

const initData = {
  sqlStatement: '',
  columns: [],
};

const ModelEditorModalsCustomSqlContent = ({ data, onClose, t }: ModelEditorModalsCustomSqlContentProps) => {
  const readOnly = useSelector(selectModelEditorReadOnly);
  const globalStudy = useSelector(selectGlobalStudy);
  const dataFormats = useSelector(selectViewerDataFormats);
  const studyActiveRole = useSelector(selectStudyActiveUserRole);
  const refEditor = React.createRef<AceEditor>();
  const defaultStudy = { title: globalStudy?.name!, key: globalStudy?.id! };
  const [selectedStudy, setSelectedStudy] = useState(defaultStudy);
  const studiesQuery = useStudyListQuery({ roles: 1 });
  const { currentAppEnv, checkExternalSource } = useEnvironments();
  const tablesQuery = useTablesQuery(
    { study_id: selectedStudy.key!, role_id: studyActiveRole?.role_id },
    { skip: selectedStudy.key === undefined },
  );
  const [runSql, runSqlQuery] = useLazyRunSqlQuery();
  const { onSubmit } = useSaveStage(data?.nodeId, onClose);

  const [values, setValues] = useState<SqlProps>(initData);
  const [selectedTable, setSelectedTable] = useState<TablesSourceProps['rawData'] | null>(null);
  const [isDisabledSave, setIsDisabledSave] = useState(true);
  const [customErrorMsg, setCustomErrorMsg] = useState('');
  const [latestSuccessfulQuery, setLatestSuccessfulQuery] = useState<string>('');

  const { currentData: previewResult, isFetching, isError } = runSqlQuery;
  const [db = '', tableId = ''] = selectedTable?.value.split('.') || '';
  const tableInfo = useTableInfoQuery({ tableName: tableId, fallbackCHDB: db }, { skip: !selectedTable });

  const getErrorMsq = (result: any) => {
    if (result.isError && result.error && 'data' in result.error) {
      const { status, data } = result.error;

      if (status === 403) {
        return t('sql.errors.forbiddenError');
      } else if (status === 500) {
        return t('sql.errors.serverError');
      } else {
        return data.devMsg ? `${data.userMsg}: ${data.devMsg}` : data.userMsg;
      }
    }
    return;
  };

  const queryTableFieldsErrorMessage = getErrorMsq(tableInfo);
  const queryRunSqlErrorMessage = getErrorMsq(runSqlQuery);

  useEffect(() => {
    if (data?.initData?.sql_statement) {
      setValues((prev) => ({
        ...prev,
        sqlStatement: data?.initData?.sql_statement || '',
        columns: data?.initData?.sql_schema || [],
      }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data?.initData?.sql_statement]);

  useEffect(() => {
    refEditor.current?.editor.focus();

    const customMode = new CustomSqlHighlightMode() as Ace.SyntaxMode;
    refEditor.current?.editor.getSession().setMode(customMode);
  }, [refEditor]);

  const studiesTree = useMemo(() => {
    const studies = !studiesQuery.data
      ? []
      : studiesQuery.data.map((study) => ({
          title: study.name,
          key: study.id,
          isLeaf: true,
          disabled: isCrossStudy(globalStudy?.id)
            ? false
            : study?.roles?.findIndex((role) => role === studyActiveRole!.name) === -1,
          icon: <Beaker />,
        }));

    if (isCrossStudy(globalStudy?.id)) {
      studies?.unshift({
        title: globalStudy?.name!,
        key: globalStudy?.id!,
        disabled: false,
        isLeaf: true,
        icon: <Beaker />,
      });
    }

    return studies;
  }, [studiesQuery.data, studyActiveRole, globalStudy]);

  const sourceTables = useMemo(
    () =>
      tablesQuery.currentData
        ? tablesQuery.currentData
            .flatMap((el) =>
              el.tables?.map((item) => ({
                name: item.split('.')[1],
                description: el.name,
                rawData: { value: item, id: `${el.name}.${item}` },
              })),
            )
            .filter((el) => el)
        : [],
    [tablesQuery.currentData],
  );

  const sourceTableFields = useMemo(
    () =>
      tableInfo.currentData
        ? tableInfo.currentData?.columns.map((item) => ({
            name: item.name,
            description: item.type,
            rawData: { value: item.name },
          }))
        : [],
    [tableInfo.currentData],
  );

  const dataViewerTableSource = useMemo((): DataTableProps<any> => {
    const data = previewResult || { meta: [], data: [] };

    const columns =
      (data.meta &&
        data.meta?.map((item) => {
          const extractedItemType = extractType(item.type);

          return {
            key: item.name,
            name: item.name,
            renderCell: ({ row }: RenderCellProps<any>) =>
              renderCellTypedValue(row[item.name], extractedItemType, dataFormats),
          };
        })) ||
      [];

    const rows = data.data || [];

    return {
      columns,
      rows,
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [previewResult]);

  const onChange = (val: string) => {
    setValues((prev) => ({ ...prev, sqlStatement: val, columns: [] }));
    if (latestSuccessfulQuery === val) {
      setIsDisabledSave(false);
    }
    setIsDisabledSave(true);
    setCustomErrorMsg('');
  };

  const clearStatement = (val: string) => val.replace(/;+$/g, '');

  const onRun = async () => {
    try {
      const externalSourceEnv = checkExternalSource({ targetSource: currentAppEnv?.env });
      const { meta } = await runSql({
        sql_statement: clearStatement(values.sqlStatement),
        size: PREVIEW_SIZE,
        sourceEnv: externalSourceEnv.isExternalSource === true ? externalSourceEnv.targetSourceEnv : undefined,
      }).unwrap();
      if (meta !== undefined && meta.length > 0) {
        setValues((prev) => ({
          ...prev,
          columns: meta,
          sqlStatement: clearStatement(prev.sqlStatement),
          isValid: true,
        }));
        setIsDisabledSave(false);
      }
      setLatestSuccessfulQuery(clearStatement(values.sqlStatement));
      setCustomErrorMsg('');
    } catch (e) {
      console.error('Error when run preview', e);
      setIsDisabledSave(true);
    }
  };

  const onCancel = () => {
    onClose();
  };

  const onSave = () => {
    const { sqlStatement = '', columns = [], isValid } = values;
    const sourcedColumns = columns.map((column) => ({
      ...column,
      source: previewResult?.lineage[column.name] ?? [],
    }));
    const updatedValues = {
      sql_statement: sqlStatement.trim(),
      preprocessed_sql: previewResult?.sql,
      sql_schema: sourcedColumns,
      isValid,
    };
    onSubmit(updatedValues);
  };

  const onClickTableHandler = (data: TablesSourceProps['rawData']) => setSelectedTable(data);

  const onClickStudy: TreeProps<StudyDataNode>['onSelect'] = (_, { node }) => {
    const { isLeaf } = node;
    if (isLeaf) {
      setSelectedStudy({ title: node?.title as string, key: node.key as number });
      setSelectedTable(null);
    }
  };

  const wrapValue = (value: string) => (isContainSpecialCharOrSpace(value) ? `"${value}"` : value);

  const addDataToEditor = (data: TablesSourceProps['rawData']) => {
    refEditor.current?.editor.insert(wrapValue(data?.value));
  };

  const onDragStart = (data: TablesSourceProps['rawData'], event: React.DragEvent) => {
    event.dataTransfer.setData('application/aceEditor/value', wrapValue(data.value));
  };

  const onDrop = useCallback(
    (event: any) => {
      event.preventDefault();
      const value = event.dataTransfer.getData('application/aceEditor/value');
      if (typeof value === 'undefined' || !value) {
        return;
      }
      const editor = refEditor.current?.editor;
      if (editor) {
        editor.session.insert(editor.getCursorPosition(), value);
      }
    },
    [refEditor],
  );

  const onDragOver = useCallback(
    (event: any) => {
      event.preventDefault();
      const aceEditor = refEditor.current?.editor;
      if (aceEditor) {
        const { row, column } = aceEditor.renderer.pixelToScreenCoordinates(event.clientX, event.clientY);
        const cursorPosition = aceEditor.session.screenToDocumentPosition(row, column);
        aceEditor.moveCursorTo(cursorPosition.row, cursorPosition.column);
      }
    },
    [refEditor],
  );

  const addPlaceholderToEditor = (placeholder: string) => () => {
    const editor = refEditor.current?.editor;
    if (editor) {
      const selection = editor.getSelectionRange();
      if (selection.isEmpty()) {
        editor.session.insert(editor.getCursorPosition(), placeholder);
      } else {
        editor.session.replace(selection, placeholder);
      }
    }
  };

  const renderLeftPanelWithSourceData = (
    <>
      <Space direction="horizontal" full css={cssStudyContainer}>
        {/* TODO add search to the common component? No this functionality on mockup and DNA for now*/}
        <MenuSelector
          treeData={studiesTree}
          selectedKeys={globalStudy ? [globalStudy.id] : []}
          onSelect={onClickStudy}
          title={globalStudy?.name ?? ''}
          category={
            <Space full>
              Study
              <InfoIcon css={cssIcon} tip={t('sql.studyMenuTooltip')} tooltip={{ placement: 'rightTop' }} />
            </Space>
          }
          noDataText={t('selector.noStudies')}
          isLoading={studiesQuery.isLoading}
          Icon={StudySwitcherIcon}
        />
      </Space>
      <Space css={{ gap: 24 }} direction="vertical">
        <ExpressionVarsList
          notDisableSingleClick
          disabled={readOnly}
          loading={tablesQuery.isFetching}
          dataSource={sourceTables}
          onClick={onClickTableHandler}
          onDoubleClick={(data) => {
            setSelectedTable(data);
            addDataToEditor(data);
          }}
          searchPlaceholder={t('sql.search.tables')}
          t={t}
          selectedItem={selectedTable}
          onDragStart={onDragStart}
          spaceSize="small"
        />
        <ExpressionVarsList
          disabled={readOnly}
          dataSource={sourceTableFields}
          onDoubleClick={addDataToEditor}
          searchPlaceholder={t('sql.search.fields')}
          t={t}
          loading={tableInfo.isFetching}
          onDragStart={onDragStart}
          spaceSize="small"
        />
      </Space>
    </>
  );

  const renderToolbar = (
    <Space justify="space-between" full css={{ gap: 16 }}>
      <Space>
        <Button
          size="large"
          icon={<PlusCircle />}
          children={t('sql.studyPlaceholder')}
          onClick={addPlaceholderToEditor(STUDY_PLACEHOLDER)}
        />
        <Button
          size="large"
          icon={<PlusCircle />}
          children={t('sql.envPlaceholder')}
          onClick={addPlaceholderToEditor(ENV_PLACEHOLDER)}
        />
      </Space>
      <Button type="primary" children={t('sql.run')} onClick={onRun} disabled={!values.sqlStatement} />
    </Space>
  );

  const renderPreview = (
    <Tabs
      css={cssTabs}
      defaultActiveKey="1"
      size="small"
      items={[
        {
          label: t('sql.result'),
          key: 'preview',
          children: (
            <>
              {isFetching && <Loader mode="absolute" />}
              {isError && <Alert message={queryRunSqlErrorMessage} type="error" />}
              {!previewResult && !isError && !isFetching && <NoDataFound description={t('sql.noDataDescription')} />}
              {previewResult && (
                <>
                  <DataTable {...dataViewerTableSource} rowHeight={25} />
                  <Typography.Text type="secondary">
                    {t('sql.noticePreview', {
                      rowsCount: previewResult.data.length,
                      limit: PREVIEW_SIZE,
                    })}
                  </Typography.Text>
                </>
              )}
            </>
          ),
        },
      ]}
    />
  );

  const renderFooter = readOnly ? (
    <Button children={t('close')} onClick={onCancel} />
  ) : (
    <Space>
      <Button children={t('cancel')} onClick={onCancel} />
      {isDisabledSave ? (
        <Tooltip title={t('sql.tooltipSaveBtn')}>
          <Button type="primary" children={t('save')} onClick={onSave} disabled={isDisabledSave} />
        </Tooltip>
      ) : (
        <Button type="primary" children={t('save')} onClick={onSave} disabled={isDisabledSave} />
      )}
    </Space>
  );

  return (
    <>
      <div css={cssContainerBody}>
        <div css={cssContainerTables}>
          {renderLeftPanelWithSourceData}
          {tableInfo.isError && (
            <div style={{ overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>
              <Typography.Text type="danger" title={queryTableFieldsErrorMessage}>
                {queryTableFieldsErrorMessage}
              </Typography.Text>
            </div>
          )}
        </div>

        <Space direction="vertical" full css={cssRight} block>
          {renderToolbar}
          <Space direction="vertical" css={cssContainerSql} full block>
            <SqlBuilder
              ref={refEditor}
              readOnly={readOnly}
              defaultValue={data?.initData?.sql_statement ?? ''}
              addDataToEditor={addDataToEditor}
              onChange={onChange}
              onDrop={onDrop}
              onDragOver={onDragOver}
              t={t}
            />
            {customErrorMsg && <Typography.Text type="danger">{customErrorMsg}</Typography.Text>}
            {renderPreview}
          </Space>
        </Space>
      </div>

      <div css={cssDividerContainer}>
        <Divider css={cssDivider} />
      </div>
      <Space full justify="end">
        {renderFooter}
      </Space>
    </>
  );
};

export const ModelEditorModalsCustomSqlSettings = ({ open, data, onClose }: ModelEditorModalsCustomSqlProps) => {
  const { t } = useTranslation(['model']);

  const renderHeader = () => (
    <div css={cssTitle}>
      <div>{t('sql.title')}</div>
      <InfoIcon css={{ fontSize: 24 }} tip={t('sql.tooltipTitle')} tooltip={{ placement: 'rightTop' }} />
    </div>
  );

  return (
    <DraggableModal
      width="80%"
      css={cssModal}
      open={open}
      onCancel={onClose}
      title={renderHeader()}
      footer={null}
      destroyOnClose
      footerInContent
    >
      {open && <ModelEditorModalsCustomSqlContent data={data} onClose={onClose} t={t} />}
    </DraggableModal>
  );
};

const cssRight = () => ({
  flex: 1,
  display: 'flex',
  overflow: 'auto',
  gap: 16,
  '& .ant-space-item:last-child': {
    flex: '1 0 auto',
  },
});

const cssModal = () => ({
  top: 40,
  minWidth: '400px',
  '&& .ant-modal-footer': {
    display: 'none',
  },
});

const cssContainerBody = (): CSSObject => ({
  display: 'flex',
  gap: 24,
  marginTop: '16px',
});

const cssTitle = (): CSSObject => ({
  display: 'flex',
  gap: 8,
  alignItems: 'center',
});

const cssContainerSql = (): CSSObject => ({
  flex: 1,
  gap: 24,
});

const cssTabs = (): CSSObject => ({
  color: 'red',
  flex: 1,
  overflow: 'auto',

  '&& .ant-tabs-content-holder': {
    justifyContent: 'center',
    display: 'flex',
  },
});

const cssContainerTables = (): CSSObject => ({
  width: '300px',
  display: 'flex',
  flexDirection: 'column',
  gap: '8px',
});

const cssStudyContainer = () => ({
  '& > .ant-space-item:first-child': {
    flex: 1,
    display: 'inline',
    marginTop: -8,
  },
});

const cssDividerContainer = (): CSSObject => ({
  margin: '24px -24px 12px',
});

const cssIcon = (theme: Theme): CSSObject => ({
  fontSize: theme.fontSize,
  color: theme['color-grey-500'],
  pointerEvents: 'all',
});

const cssDivider = (): CSSObject => ({
  margin: 0,
});

interface ModelEditorModalsCustomSqlContentProps extends Pick<ModelEditorModalsCustomSqlProps, 'data' | 'onClose'> {
  t: TFunction;
}

interface TablesSourceProps {
  name: string;
  description?: string;
  rawData: { value: string; id?: string };
}

export interface ModelEditorModalsCustomSqlProps {
  open: boolean;
  data: {
    nodeId: string;
    initData?: ModelEditorNodeSql;
  };
  onClose: () => void;
}

interface SqlProps {
  sqlStatement: string;
  columns: ColumnType[];
  isValid?: boolean;
}

interface StudyDataNode extends TreeDataNode {
  rawData?: StudyResponse;
}

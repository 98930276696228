export interface ITask {
  file_name?: string;
  hash: string;
  id: number;
  last_update_time: string;
  name: TaskType;
  status: string;
  result: any;
  study_id: number;
  user_id: number;
  table_id?: string;
  study_name: string;
  started_at: string;
  finished_at: string | null;
  package_version?: string;
}

export interface ISparkJobQueueItem {
  id: string;
  name?: string;
  study_name?: string;
  added_at: string;
  started_at: string;
}

export interface IDatasetItem {
  id: number;
  dataset_id: number;
  name: string;
  started_at?: string;
}

export interface IUserQueue {
  tasks: ITask[];
  models: ISparkJobQueueItem[];
  datasets: IDatasetItem[];
}

export interface TaskPanelProps {
  tasks: ITask[];
}

export enum TaskType {
  Deployment = 'deployment',
  Migration = 'migration',
  Import = 'import',
  Export = 'export',
  Stack = 'stack',
  Discovery = 'discovery',
  Datasets = 'datasets',
}

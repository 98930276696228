import {
  DatasetModalsLogsStackDataset,
  DatasetModalsLogsStackDatasetProps,
} from '@modules/dataset/modals/components/DatasetModalsLogsStackDataset';
import { useDispatch, useSelector } from 'react-redux';
import { DatasetModalsEditStackDataset, IDatasetModalsEditStackDatasetProps } from './components';
import { EDatasetModalsType } from './DatasetModalsConstants';
import { datasetActions } from '../duck/datasetSlice';
import { selectDatasetModals } from '../duck/datasetSelectors';

export const DatasetModalsController = () => {
  const dispatch = useDispatch();
  const { data, selectedModal } = useSelector(selectDatasetModals);

  const closeModal = () => {
    dispatch(datasetActions.dropModal());
  };

  return (
    <>
      <DatasetModalsEditStackDataset
        open={selectedModal === EDatasetModalsType.createStackDataset}
        data={data as IDatasetModalsEditStackDatasetProps['data']}
        onClose={closeModal}
      />
      <DatasetModalsLogsStackDataset
        open={selectedModal === EDatasetModalsType.showLogs}
        data={data as DatasetModalsLogsStackDatasetProps['data']}
        onClose={closeModal}
      />
    </>
  );
};

import { dateToString } from '@shared/utils/Date';
import { ActorAndInfo, ButtonWithConfirmation, InformationModal } from '@components';
import { useColumnSearch } from '@components/ui/table/tableHooks';
import { tableListToOptions, getListOption } from '@shared/utils/Table';
import { Trash } from '@components/icons';
import { Table } from '@ui';
import { SupportedEnvs } from '@app/AppTypes';
import { Scope } from '@modules/dataset/DatasetTypes';
import { DatasetScopeList, DatasetScopeListScope, renderInfoModalContent } from '@modules/dataset/components';
import { ISQLLabObject, ISQLLabObjectListResponse } from '@modules/dnaObject/SQLLab/SQLLabObjectTypes';
import { useMemo, useState } from 'react';
import { TableColumnsType, TableProps } from 'antd';
import { TFunction } from 'i18next';

export const SQLLabObjectList = ({
  data,
  loading,
  pagination,
  onChange,
  loadingDelete,
  supportedEnvs,
  filterOptions,
  onDelete,
  libraryId,
  t,
}: SQLLabObjectListProps) => {
  const [studiesDetailsModal, setStudiesDetailsModal] = useState<DatasetScopeListScope>();
  const { getColumnSearchProps, locale } = useColumnSearch<ISQLLabObject>();

  const columns = useMemo(() => {
    const sourceListOption = filterOptions?.sources
      ? getListOption(filterOptions?.sources)
      : tableListToOptions(data?.items?.map((el) => ({ name: el.source!, id: el.source })));

    return (
      [
        {
          title: t('sqlLab.rootTable.name'),
          dataIndex: 'name',
          ...getColumnSearchProps('name'),
          onFilter: undefined,
        },
        {
          title: t('sqlLab.rootTable.scope'),
          dataIndex: 'object_scope',
          render: (object_scope: Scope) => (
            <DatasetScopeList currentStudyName="-" onMoreBtnClick={setStudiesDetailsModal} scope={object_scope} />
          ),
        },
        {
          title: t('sqlLab.rootTable.libraryVersion'),
          dataIndex: 'version',
          render: (_, record) =>
            Array.isArray(record.library_id || record.versions) && (record.library_id || record.versions)?.length
              ? record.version
              : '-',
        },
        {
          title: t('sqlLab.rootTable.source'),
          customKey: 'narrow',
          dataIndex: 'source',
          filters: sourceListOption,
          onFilter: undefined,
          render: (_, record) =>
            record.source && (
              <ActorAndInfo info={(supportedEnvs || {})[record.env]?.label || t('na')} actor={record.source} />
            ),
        },
        {
          title: t('sqlLab.rootTable.created'),
          customKey: 'narrow',
          dataIndex: libraryId ? 'created_at' : 'linked_at',
          sorter: () => 0,
          sortDirections: ['ascend', 'descend'],
          render: (_, record) => (
            <ActorAndInfo info={dateToString(record.linked_at || record.created_at)} actor={record.created_by} />
          ),
        },
        libraryId && {
          title: t('sqlLab.rootTable.updated'),
          customKey: 'narrow',
          dataIndex: 'updated_at',
          sorter: () => 0,
          sortDirections: ['ascend', 'descend'],
          render: (updatedAt, record) => <ActorAndInfo info={dateToString(updatedAt)} actor={record.updated_by} />,
        },
        !!onDelete && {
          width: 100,
          title: t('delete'),
          dataIndex: 'actionDelete',
          render: (_, { id, version_id, name }) => (
            <ButtonWithConfirmation
              icon={<Trash color="lightGrey" />}
              type="text"
              submitFunc={() => onDelete({ id, version_id: version_id!, name })}
              confirmContent={t('sqlLab.confirmation.description', { name })}
              confirmTitle={t('sqlLab.confirmation.title')}
              loading={loadingDelete}
            />
          ),
        },
      ] as TableColumnsType<ISQLLabObject>
    ).filter(Boolean);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, filterOptions, loadingDelete, supportedEnvs]);

  return (
    <>
      <Table
        locale={locale}
        columns={columns}
        dataSource={data?.items}
        loading={loading}
        rowKey={(item) => item.id}
        onChange={onChange}
        tableLayout="fixed"
        scroll={{ x: 900 }}
        pagination={pagination}
      />
      {studiesDetailsModal && (
        <InformationModal
          message={renderInfoModalContent(studiesDetailsModal)}
          onClose={() => setStudiesDetailsModal(null)}
          width="500px"
          autoHeightMin={50}
        />
      )}
    </>
  );
};

export interface SQLLabObjectListProps {
  data?: ISQLLabObjectListResponse;
  filterOptions?: { sources: string[] };
  pagination?: TableProps<ISQLLabObject>['pagination'];
  onChange?: TableProps<ISQLLabObject>['onChange'];
  loading?: boolean;
  loadingDelete?: boolean;
  onDelete?: (data: { id: number; version_id: number; name: string }) => void;
  supportedEnvs?: SupportedEnvs;
  t: TFunction;
  libraryId?: number;
}

import { Tooltip } from '@ui';
import { forwardRef, JSXElementConstructor, ReactNode, Ref } from 'react';
import { TooltipProps } from 'antd';
import { Theme } from '@emotion/react';

export const BaseIcon = forwardRef<any, BaseSvgProps>(
  (
    {
      children,
      clickable,
      tip,
      tooltip,
      width,
      height,
      className,
      viewBox,
      color,
      IconElement = 'svg',
      rotate,
      onClick,
      fill,
    },
    ref,
  ) => {
    const hasDimensions = width && height;
    const content = (
      <IconElement
        css={cssBody({ clickable, color, rotate })}
        className={className}
        ref={ref}
        width={hasDimensions ? `${Number(width / height).toFixed(3) || 1}em` : undefined}
        height={hasDimensions ? '1em' : undefined}
        viewBox={viewBox}
        onClick={onClick}
        fill={fill}
        children={children}
      />
    );

    if (tip) {
      return (
        <Tooltip {...tooltip} title={tip}>
          <div className={className}>{content}</div>
        </Tooltip>
      );
    }

    return content;
  },
);

export interface BaseSvgProps {
  children?: ReactNode;
  width?: number;
  height?: number;
  viewBox?: string;
  forwardRef?: Ref<any>;
  tooltip?: TooltipProps;
  tip?: string;
  clickable?: boolean;
  color?: 'primary' | 'warning' | 'success' | 'danger' | 'white' | 'grey' | 'darkGrey' | 'lightGrey' | 'linkActive';
  className?: string;
  IconElement?: JSXElementConstructor<any> | 'svg';
  rotate?: number;
  onClick?: () => void;
  fill?: string;
  id?: string;
}

export type SvgIconProps = Pick<
  BaseSvgProps,
  'tooltip' | 'tip' | 'className' | 'clickable' | 'color' | 'rotate' | 'onClick' | 'id'
>;

enum colorMap {
  'primary' = 'colorPrimary',
  'danger' = 'colorError',
  'warning' = 'colorWarning',
  'success' = 'colorSuccess',
  'white' = 'colorWhite',
  'grey' = 'color-grey-300',
  'darkGrey' = 'color-grey-600',
  'lightGrey' = 'color-grey-500',
  'linkActive' = 'color-brand-blue-500',
}

const cssBody =
  ({ clickable, color, rotate }: Pick<BaseSvgProps, 'clickable' | 'color' | 'rotate'>) =>
  (theme: Theme) => ({
    display: 'inline-block',
    '&&&': { color: color ? theme[colorMap[color]] : 'inherit' },
    '&&': {
      rotate: rotate ? `${rotate}deg` : undefined,
    },
    '&': clickable
      ? {
          transition: 'opacity 0.3s',
          opacity: 0.8,
          cursor: 'pointer',
          padding: '5px',
          margin: '-5px',
          '&:hover': {
            opacity: 1,
          },
        }
      : {},
  });

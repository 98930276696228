import { Button, Checkbox, DraggableModal, Form, FormItem, FormLayout, Select, SelectWithAddItem } from '@ui';
import {
  ModelEditorNodeGroupBy,
  ModelEditorNodeGroupByOperations,
  ModelEditorNodeGroupByRelation,
} from '@modules/modelEditor/ModelEditorTypes';
import { MinusCircle, PlusCircle } from '@components/icons';
import { tableInfoMetaToOptions } from '@modules/modelEditor/modals/utils';
import { listToOptions } from '@shared/utils/Form';
import { useSourceTableInfoAnalyzer } from '@modules/modelEditor/duck/modelEditorSourceTableInfoAnalyzer';
import { selectModelEditorReadOnly } from '@modules/modelEditor/duck/modelEditorSelectors';
import { useTranslation } from 'react-i18next';
import { TFunction } from 'i18next';
import { Row, Col } from 'antd';
import { css, CSSObject } from '@emotion/react';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useSaveStage } from './modelEditorModalsHooks';

const operatorOptions = listToOptions(Object.values(ModelEditorNodeGroupByOperations));

const ModelEditorModalsGroupBySettingsContent = ({
  data,
  onClose,
  t,
}: ModelEditorModalsGroupBySettingsContentProps) => {
  const [form] = Form.useForm();
  const readOnly = useSelector(selectModelEditorReadOnly);
  const { onSubmit } = useSaveStage(data.nodeId, onClose);
  const { loading, sourceColumns } = useSourceTableInfoAnalyzer(data.nodeId);

  const fieldOptions = useMemo(() => {
    const columnsNames = tableInfoMetaToOptions(sourceColumns[0]);

    return {
      columnsNames,
      variables: columnsNames.concat({ label: '*', value: '*' }),
    };
  }, [sourceColumns]);

  const initValues = data.initData || {};

  return (
    <FormLayout
      labelCol={{ span: 6 }}
      wrapperCol={{ span: 18 }}
      form={form}
      readOnly={readOnly}
      layout="horizontal"
      onCancel={onClose}
      onSubmit={onSubmit}
      okText={t('save')}
      initialValues={initValues}
    >
      <Row style={{ marginBottom: 40 }}>
        <Col span={24}>
          <FormItem name="dropGroupingColumns" label={t('groupBy.dropGroupingColumns')} valuePropName="checked">
            <Checkbox css={cssCheckbox} />
          </FormItem>
          <FormItem
            name="groupBy"
            label={t('groupBy.groupByColumns')}
            rules={[
              {
                required: true,
                validator: (rule, value: string[] = [], callback) => {
                  if (
                    !loading &&
                    value.filter((v) => !fieldOptions.columnsNames.some((item) => item.value === v)).length
                  ) {
                    return Promise.reject(new Error(t('groupBy.errors.wrongColumns')));
                  }
                  if (!value.length) {
                    return Promise.reject(new Error(t('groupBy.errors.missingGroupBy')));
                  }
                  return Promise.resolve();
                },
              },
            ]}
          >
            <Select
              mode="multiple"
              placeholder={t('groupBy.groupByColumnsPlaceholder')}
              options={fieldOptions.columnsNames}
              loading={loading}
            />
          </FormItem>
          <FormItem label={t('groupBy.aggregateFn')} required>
            <Form.List
              name="aggregateFn"
              rules={[
                {
                  validator: (_, value: Array<ModelEditorNodeGroupByRelation | undefined>) => {
                    if (value && value.length && value.some((item) => item?.operation && item?.variable)) {
                      return Promise.resolve();
                    }
                    return Promise.reject(new Error(t('groupBy.errors.missingAggregatedFn')));
                  },
                },
              ]}
            >
              {(fields, { add, remove }, { errors }) => (
                <>
                  {fields.map(({ key, name, ...restField }) => (
                    <Row key={key} gutter={8}>
                      <Col span={11}>
                        <FormItem
                          {...restField}
                          name={[name, 'operation']}
                          rules={[
                            {
                              required: true,
                              validator: (_, value: string) => {
                                if (
                                  form.getFieldValue(['aggregateFn', name, 'variable']) === '*' &&
                                  value !== 'COUNT'
                                ) {
                                  return Promise.reject(new Error(t('groupBy.errors.mustBeCount')));
                                }
                                if (!value) {
                                  return Promise.reject(new Error(t('groupBy.errors.missingOperator')));
                                }
                                return Promise.resolve();
                              },
                            },
                          ]}
                          wrapperCol={{ span: 24 }}
                        >
                          <Select options={operatorOptions} allowClear placeholder={t('groupBy.operatorPlaceholder')} />
                        </FormItem>
                      </Col>
                      <Col span={11}>
                        <FormItem
                          {...restField}
                          name={[name, 'variable']}
                          rules={[
                            {
                              required: true,
                              validator: (rule, value: string, callback) => {
                                if (
                                  !loading &&
                                  value !== '*' &&
                                  !fieldOptions.columnsNames.some((item) => item.value === value)
                                ) {
                                  return Promise.reject(new Error(t('groupBy.errors.wrongVariable')));
                                }
                                if (!value.length) {
                                  return Promise.reject(new Error(t('groupBy.errors.missingVariable')));
                                }
                                return Promise.resolve();
                              },
                            },
                          ]}
                          wrapperCol={{ span: 24 }}
                        >
                          <SelectWithAddItem
                            loading={loading}
                            options={fieldOptions.variables}
                            placeholder={t('groupBy.variablePlaceholder')}
                            onChange={() => form.validateFields(['aggregateFn'])}
                          />
                        </FormItem>
                      </Col>
                      <Col span={2} css={cssRemoveBtn}>
                        <Button
                          size="large"
                          disabled={readOnly}
                          onClick={() => remove(name)}
                          tip={t('groupBy.remove')}
                          icon={<MinusCircle />}
                        />
                      </Col>
                    </Row>
                  ))}
                  <FormItem wrapperCol={{ span: 24 }}>
                    <Button size="large" type="dashed" onClick={() => add()} block icon={<PlusCircle />}>
                      {t('groupBy.add')}
                    </Button>
                    <Form.ErrorList errors={errors} />
                  </FormItem>
                </>
              )}
            </Form.List>
          </FormItem>
        </Col>
      </Row>
    </FormLayout>
  );
};

export const ModelEditorModalsGroupBySettings = ({ open, data, onClose }: ModelEditorModalsGroupBySettingsProps) => {
  const { t } = useTranslation(['model']);

  return (
    <DraggableModal
      open={open}
      onCancel={onClose}
      title={t('groupBy.title')}
      width={880}
      footer={null}
      destroyOnClose
      footerInContent
    >
      {open && <ModelEditorModalsGroupBySettingsContent data={data} onClose={onClose} t={t} />}
    </DraggableModal>
  );
};

interface ModelEditorModalsGroupBySettingsContentProps
  extends Pick<ModelEditorModalsGroupBySettingsProps, 'data' | 'onClose'> {
  t: TFunction;
}

export interface ModelEditorModalsGroupBySettingsProps {
  open: boolean;
  data: { nodeId: string; initData?: ModelEditorNodeGroupBy };
  onClose: () => void;
}

const cssRemoveBtn = css({
  display: 'flex',
  alignItems: 'start',
  justifyContent: 'center',
});

const cssCheckbox = (): CSSObject => ({
  '.ant-checkbox-inner': {
    width: 24,
    height: 24,

    ':after': {
      width: 10,
      height: 10,
    },
  },
});

import { useColumnSearch } from '@components/ui/table/tableHooks';
import { Table, Tag } from '@ui';
import {
  convertOptionsToFilterItems,
  InformationModal,
  ITableFilterItemWithDescription,
  Loader,
  TableFilterItem,
} from '@components';
import { getFormattedDatetime } from '@shared/utils/Date';
import { AntdIconBox } from '@components/icons';
import { ViewerGroupType } from '@modules/viewer/ViewerTypes';
import { QuestionCircleOutlined } from '@ant-design/icons';
import { TableColumnsType, Typography } from 'antd';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { ISnapshot, SNAPSHOT_STATUS } from '../SnapshotTypes';
import { SNAPSHOT_STATUS_COLOR_MAPPING, SNAPSHOT_STATUS_MAPPING } from '../duck/snapshotConstants';
import {
  snapshotCategoryAggregation,
  SnapshotCategoryOptionHelper,
  SnapshotStoreAggregateSeparator,
} from '../duck/snapshotUtils';
import { useSnapshotTableErrorDetailsQuery } from '../duck/snapshotApi';

export const snapshotTableListOnFilter = (value: any, record: ReturnType<typeof snapshotCategoryAggregation>[0]) =>
  Array.isArray(record.store)
    ? record.store.some((item) =>
        (record.type as ViewerGroupType[]).some(
          (type) => SnapshotCategoryOptionHelper.getOptionValue(item, type) === value,
        ),
      )
    : SnapshotCategoryOptionHelper.getOptionValue(record.store, record.type) === value;

export const InformationModalDetailedError = ({ snapshotTableId }: IInformationModalDetailedErrorProps) => {
  const { snapshotId } = useParams();

  const { data, isFetching } = useSnapshotTableErrorDetailsQuery({ snapshotTableId, snapshotId: snapshotId! });

  return isFetching ? (
    <Loader size="default" mode="absolute" />
  ) : (
    <Typography.Paragraph>
      <pre style={{ margin: 0 }}>{data?.errorMessage}</pre>
    </Typography.Paragraph>
  );
};

export const SnapshotTableList = ({ data, storeList, loading }: ISnapshotTableListProps) => {
  const { t } = useTranslation(['snapshot']);
  const { getColumnSearchProps, locale } = useColumnSearch<ISnapshotTableListProps['data'][0]>();
  const [snapshotTable, setSnapshotTable] = useState<ISnapshotTableListProps['data'][0] | null>(null);

  const clearSnapshotTable = () => setSnapshotTable(null);

  const columns: TableColumnsType<ISnapshotTableListProps['data'][0]> = useMemo(
    () => [
      {
        title: t('createPage.category'),
        dataIndex: 'store',
        filters: convertOptionsToFilterItems(storeList),
        onFilter: snapshotTableListOnFilter,
        render: (_, record) => (
          <TableFilterItem
            title={Array.isArray(record.store) ? record.store.join(SnapshotStoreAggregateSeparator) : record.store}
            description={record.description}
          />
        ),
      },
      {
        title: t('createPage.tableName'),
        dataIndex: 'tableName',
        key: 'tableName',
        ...getColumnSearchProps('tableName'),
      },
      {
        width: 250,
        title: t('viewPage.lastUpdate'),
        dataIndex: 'lastUpdateDate',
        key: 'lastUpdateDate',
        render: (lastUpdateDate: string) =>
          getFormattedDatetime(lastUpdateDate, { format: 'YYYY-MM-DD HH:mm:ss [GMT]' }),
      },
      {
        width: 150,
        title: t('viewPage.status'),
        dataIndex: 'status',
        key: 'snapTable',
        render: (status: SNAPSHOT_STATUS, record) => {
          if (SNAPSHOT_STATUS.FAILED === status) {
            return (
              <>
                <Tag color={SNAPSHOT_STATUS_COLOR_MAPPING[status]} children={SNAPSHOT_STATUS_MAPPING[status]} />
                <AntdIconBox
                  icon={QuestionCircleOutlined}
                  tip={t('tooltipError')}
                  color="danger"
                  onClick={() => setSnapshotTable(record)}
                />
              </>
            );
          }
          return <Tag color={SNAPSHOT_STATUS_COLOR_MAPPING[status]} children={SNAPSHOT_STATUS_MAPPING[status]} />;
        },
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [data, storeList],
  );

  const preparedData = useMemo(() => snapshotCategoryAggregation(data), [data]);

  return (
    <>
      <Table
        size="small"
        columns={columns}
        locale={locale}
        loading={loading}
        dataSource={preparedData}
        pagination={false}
        rowKey="id"
        scroll={{ x: 800 }}
      />
      {snapshotTable && (
        <InformationModal
          title={t('errorDetails')}
          message={<InformationModalDetailedError snapshotTableId={snapshotTable.id!} />}
          userFriendlyErrorMessage={snapshotTable.userFriendlyErrorMessage}
          onClose={clearSnapshotTable}
        />
      )}
    </>
  );
};

interface ISnapshotTableListProps {
  loading?: boolean;
  data: Array<ISnapshot['tablesDetails'][0] & { store: string; type: ViewerGroupType; description?: string }>;
  storeList: ITableFilterItemWithDescription[];
}

interface IInformationModalDetailedErrorProps {
  snapshotTableId: string;
}

import { ICrossStudyResolver } from '@routes/study/RoutesStudy';
import { useLocationResolver } from '@routes/routesHooks';
import { useCallback } from 'react';
import { INodeRendererProps } from 'react-accessible-treeview';
import { useCrossStoreAggregation } from './tableListSiderHooks';
import { ITableListSiderProps } from './TableListSiderTypes';
import { TableListSiderTemplate, TableListSiderTreeItem } from './TableListSiderTemplate';

export const CrossTableListSider = (props: ITableListSiderProps) => {
  const { state: locationState } = useLocationResolver<
    ICrossStudyResolver['dataViewer']['params'],
    { external?: boolean }
  >();
  const isExternalRoute = locationState?.external;
  const { treeData, globalStudy, globalTablesQuery, tablesQueryTreeKey } = useCrossStoreAggregation();

  const customTitle = useCallback(
    (node: INodeRendererProps, draggable?: boolean) => (
      <TableListSiderTreeItem
        node={node}
        draggable={draggable}
        refetch={props.refetch}
        onSelectTable={props.onSelectTable}
        selectedTable={props.selectedTable}
        isExternalRoute={isExternalRoute}
      />
    ),

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [globalTablesQuery.isFetching, props.selectedTable, props.onSelectTable],
  );

  const reloadTables = () => {
    globalTablesQuery.refetch();
    if (props.selectedTable) {
      props.refetch();
    }
  };

  return (
    <TableListSiderTemplate
      {...props}
      treeData={treeData}
      globalStudyId={globalStudy?.id!}
      tablesQueryTreeKey={tablesQueryTreeKey}
      titleRender={customTitle}
      isErrorLoadingTables={globalTablesQuery.isError}
      reloadTables={reloadTables}
      isLoadingData={globalTablesQuery.isFetching}
    />
  );
};

export interface ViewerCHTableInfoData {
  name: string;
  type: string;
}

export interface ViewerCHTableMetaData {
  final: boolean;
}

export interface ViewerCHTableInfoResponse {
  columns: ViewerCHTableInfoData[];
  meta: ViewerCHTableMetaData;
}

export enum FileFormatExport {
  csv = 'CSV',
  json = 'JSON',
  xlsx = 'XLSX',
  custom = 'Custom',
}

export interface ViewerTracedInfoParams {
  tableId: string;
  rowKey: string;
  targetColumn?: string;
}

export interface ViewerCellValueParams {
  value: string;
}

export type GroupsDataResponse = Partial<Group>[];

export type Group = {
  id: number;
  name: string;
  description: string;
  creationDate: string;
  userName: string;
  status: string;
  studyId: number;
  selectedTables: (Partial<GroupTable> | string)[];
};

export type GroupTable = {
  description: string;
  domain: string;
  status: string;
  name: string;
  imported: boolean;
  valid: boolean;
  content?: Record<string, any>;
  entityOid?: string;
  id: number;
  skipExtendedAttributes?: boolean;
  traced?: boolean;
};

export enum ViewerGroupType {
  internal = 'internal',
  external = 'external',
  studyRT = 'studyRT',
  rccGroups = 'rccGroups',
  crossStudyRT = 'crossStudyRT',
  globalOperational = 'globalOperational',
  globalDS = 'globalDS',
  globalStackDS = 'globalStackDS',
}

export enum ViewerGroupTypeNames {
  internal = 'Internal Store',
  external = 'External Store',
  studyRT = 'System Store',
  rccGroups = 'RCC Groups',
  crossStudyRT = 'Cross System Store',
  globalDS = 'Global Internals',
  globalStackDS = 'Global Data Set',
  globalOperational = 'Global Operational Store',
}

export type Task = {
  study_id: number;
  name: 'export' | 'import' | 'discovery';
  file_name?: string;
  table_id?: string;
};

import { appAxios } from '@config/AppConfig';
import { RootState, store } from '@store';
import { TasksApiRoutes } from '@modules/task/duck/taskApi';
import { tasksActions } from '@modules/task/duck/tasksSlice';
import { apiListener } from '@shared/utils/common';
import { IUserQueue } from '@modules/task/TaskTypes';
import { isEqual } from 'lodash';

const QUEUE_UPDATE_INTERVAL = 10000;

export const fetchQueue = async () => {
  try {
    const rootState: RootState = store.getState();

    if (!rootState.app.ready || rootState.app.isUnauthorized) {
      return;
    }

    const tasks = await appAxios.get<IUserQueue>(TasksApiRoutes.userQueue, {
      params: { study_id: undefined, library_id: undefined },
    });

    if (!isEqual(tasks.data.tasks, rootState.tasks.runningTasks)) {
      store.dispatch(tasksActions.setRunningTasks(tasks.data.tasks));
    }
    if (!isEqual(tasks.data.models, rootState.tasks.sparkJobQueue)) {
      store.dispatch(tasksActions.setSparkJobQueue(tasks.data.models));
    }
    if (!isEqual(tasks.data.datasets, rootState.tasks.runningDatasets)) {
      store.dispatch(tasksActions.setRunningDatasets(tasks.data.datasets));
    }
  } catch (e) {
    console.error(e);
  }
};

export const startQueueListener = () => {
  return apiListener(fetchQueue, QUEUE_UPDATE_INTERVAL);
};

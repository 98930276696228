import { Alert, Button, DirectoryTree, Space, Tooltip } from '@ui';
import { FilesPayload, VolumeFile } from '@modules/source/SourceTypes';
import { Plus, Trash } from '@components/icons';
import { VolumeFilesSelector } from '@modules/source/modals/components/save/components/VolumeFilesSelector';
import { generateFileTreeNode, getCleanPathName } from '@modules/source/duck/sourceUtils';
import { DATABRICKS_FIELD } from '@modules/source/modals';
import { FolderOpenOutlined, FolderOutlined } from '@ant-design/icons';
import { TreeDataNode } from 'antd';
import { CSSObject, Theme } from '@emotion/react';
import { TreeNodeProps } from 'antd/lib';
import { useTranslation } from 'react-i18next';
import { RefObject, useEffect, useState } from 'react';

export const VolumeFiles = ({ innerRef, files, setFiles }: DatabricksFilesProps) => {
  const { t } = useTranslation('datasource');

  const [filesTree, setTreeFiles] = useState<DataTreeNode[]>([]);
  const [openModal, setOpenModal] = useState(false);

  const volumeName = innerRef.current.values[DATABRICKS_FIELD];

  useEffect(() => {
    if (files) {
      const data = files.length ? preparedSelectedFilesTree(files) : [];
      setTreeFiles(data);
    }
  }, [files]);

  const removeItemByKey = (key: string) => {
    const filteredData = files.filter((item) => item.parent_folder_path !== key);
    setFiles(filteredData);
  };

  const preparedSelectedFilesTree = (files: FilesPayload[]) =>
    files.map((el) => {
      const selectedFolderKeys = el.selected_folders.map((item) =>
        generateFileTreeNode(el.parent_folder_path, item, true),
      );
      const selectedFileKeys = el.selected_files.map((item) =>
        generateFileTreeNode(el.parent_folder_path, item, false),
      );

      const children = [...selectedFolderKeys, ...selectedFileKeys];

      return {
        key: el.parent_folder_path,
        title: (
          <div>
            {getCleanPathName(el.parent_folder_path, volumeName, true)}
            <Button
              icon={<Trash color="lightGrey" />}
              type="text"
              title={t('delete')}
              onClick={(e: React.MouseEvent<HTMLElement>) => {
                e.stopPropagation();
                removeItemByKey(el.parent_folder_path);
              }}
            />
          </div>
        ),
        isLeaf: false,
        children,
        icon: ({ expanded }: TreeNodeProps) => (
          <Tooltip title={getCleanPathName(el.parent_folder_path, volumeName)} styles={{ root: { maxWidth: 500 } }}>
            {expanded ? <FolderOpenOutlined /> : <FolderOutlined />}
          </Tooltip>
        ),
      };
    });

  if (!volumeName) {
    return <Alert css={cssFullWidthContainer} type="warning" message={t('filesModal.emptyVolumeMessage')} />;
  }

  const onCloseModal = () => {
    setOpenModal(false);
  };

  return (
    <Space direction="vertical" full css={cssContainer}>
      <Alert css={cssFullWidthContainer} type="warning" message={t('filesModal.typeAlert')} />
      <DirectoryTree css={cssFileTree} treeData={filesTree} autoExpandParent withSwitcherIcon={false} />
      <Button
        css={cssAddButton}
        icon={<Plus />}
        type="dashed"
        children={t('filesModal.addFiles')}
        onClick={() => {
          setOpenModal(true);
        }}
      />
      <VolumeFilesSelector
        open={openModal}
        onClose={onCloseModal}
        files={files}
        setFiles={setFiles}
        volumeName={volumeName}
        t={t}
      />
    </Space>
  );
};

interface DatabricksFilesProps {
  innerRef: RefObject<any>;
  files: FilesPayload[];
  setFiles: (values: FilesPayload[]) => void;
}

export interface TableTree extends VolumeFile {
  key: string;
  parent_folder_path?: string;
  children?: TableTree[];
}

interface DataTreeNode extends TreeDataNode {
  isDirectory?: boolean;
}

const cssContainer = (): CSSObject => ({
  gap: 16,
});

const cssAddButton = (): CSSObject => ({ width: '100%', marginBottom: 16 });

const cssFileTree = (theme: Theme): CSSObject => ({
  width: '100%',
  maxHeight: 200,

  '.ant-tree-title': {
    whiteSpace: 'break-spaces',
  },

  '.ant-btn-icon-only': {
    color: theme['color-grey-600'],
    position: 'absolute',
    top: '50%',
    right: 0,
    transform: 'translateY(-50%)',
  },
});

const cssFullWidthContainer = () => ({
  width: '100%',
});

import { DraggableModal, Form, FormLayout, FormItem, Alert } from '@ui';
import { useDeleteJobConfigMutation, useSaveJobConfigMutation } from '@modules/codeLab/duck/codeLabApi';
import { ButtonWithConfirmation } from '@components';
import {
  getDefaultInitialValue,
  prepareFields,
  prepareUpdateData,
  RUN_TYPE,
  SchedulerRunForm,
  SchedulerRunFormValues,
} from '@components/form';
import { ICodeLabJob } from '@modules/codeLab/CodeLabTypes';
import { useAppContext } from '@app/AppContext';
import { parseCodeLabFilePath } from '@modules/codeLab/components/CodeLabFilePath';
import { QueryErrorType } from '@shared/utils/Error';
import { useAppPermissions, useStudyPermissions } from '@modules/user/duck/userHooks';
import { selectGlobalStudy } from '@app/duck/appSelectors';
import { isCrossStudy } from '@shared/utils/common';
import { useTranslation } from 'react-i18next';
import { TFunction } from 'i18next';
import { useSelector } from 'react-redux';
import { ComponentType } from 'react';

const wrapMixPermissions = (Component: ComponentType<ICodeLabModalsSchedulerContentProps>) => {
  const WrapperComponent = (props: ICodeLabModalsSchedulerContentProps) => {
    const globalStudy = useSelector(selectGlobalStudy);
    const {
      appPermissions: { canCrossClSchedulerInsert, canCrossClSchedulerDelete },
    } = useAppPermissions();
    const {
      userPermissions: { canClSchedulerInsert, canClSchedulerDelete },
    } = useStudyPermissions();

    let permissionProps = { mixClSchedulerInsert: canClSchedulerInsert, mixClSchedulerDelete: canClSchedulerDelete };
    if (isCrossStudy(globalStudy?.id)) {
      permissionProps.mixClSchedulerInsert = canCrossClSchedulerInsert;
      permissionProps.mixClSchedulerDelete = canCrossClSchedulerDelete;
    }

    return <Component {...props} {...permissionProps} />;
  };

  return WrapperComponent;
};

const CodeLabModalsSchedulerContent = wrapMixPermissions(
  ({ data, mixClSchedulerDelete, mixClSchedulerInsert, t, onClose }) => {
    const [form] = Form.useForm();
    const [saveJobConfig, saveJobConfigQuery] = useSaveJobConfigMutation();
    const [deleteJobConfig, deleteJobConfigQuery] = useDeleteJobConfigMutation();
    const { notificationApi } = useAppContext();

    const isEdit = !!data.id;

    const onDelete = async () => {
      try {
        await deleteJobConfig(data.notebook_id!).unwrap();
        notificationApi.success({
          message: t('modals.scheduler.successMessageDelete', { name: parseCodeLabFilePath(data.name!) }),
        });
        onClose();
      } catch (error) {
        notificationApi.error({
          message: t('shared.errors.smthWrong'),
          description: (error as QueryErrorType).data.userMsg,
        });
      }
    };

    const onSubmit = async (values: ICodeLabModalsShedulerFormFields) => {
      try {
        const updatedFields: Pick<ICodeLabJob, 'name' | 'notebook_id' | 'job'> & { id?: number } = {
          id: data.id,
          name: data.name!,
          notebook_id: data.notebook_id!,
          job: values.job ? prepareUpdateData({ values: values.job }) : data.job!,
        };

        await saveJobConfig(updatedFields).unwrap();
        notificationApi.success({
          message: t('modals.scheduler.successMessageSave', { name: parseCodeLabFilePath(data.name!) }),
        });
        onClose();
      } catch (error) {
        notificationApi.error({
          message: t('shared.errors.smthWrong'),
          description: (error as QueryErrorType).data.userMsg,
        });
      }
    };

    const notCorrectInputData = !data.name || !data.notebook_id;

    const initValues: ICodeLabModalsShedulerFormFields = {
      job: data.job ? prepareFields(data.job!) : getDefaultInitialValue(RUN_TYPE.once),
    };

    return (
      <FormLayout
        form={form}
        onCancel={onClose}
        onSubmit={onSubmit}
        okText={t('save')}
        initialValues={initValues}
        disabled={notCorrectInputData || !mixClSchedulerInsert}
        hideOkBtn={notCorrectInputData}
        submitIsDisabled={deleteJobConfigQuery.isLoading || !mixClSchedulerInsert}
        cancelIsDisabled={deleteJobConfigQuery.isLoading || saveJobConfigQuery.isLoading}
        extraActions={
          isEdit &&
          mixClSchedulerDelete && (
            <ButtonWithConfirmation
              submitFunc={onDelete}
              confirmContent={t('confirmation.description')}
              confirmTitle={t('confirmation.title')}
              loading={deleteJobConfigQuery.isLoading}
              disabled={saveJobConfigQuery.isLoading}
            />
          )
        }
        errors={saveJobConfigQuery.error ? [(saveJobConfigQuery.error as QueryErrorType).data.userMsg!] : undefined}
      >
        {notCorrectInputData && (
          <FormItem wrapperCol={{ span: 18 }}>
            <Alert type="warning" message={t('modals.scheduler.wrongInputData')} />
          </FormItem>
        )}
        <FormItem wrapperCol={{ span: 24 }}>
          <SchedulerRunForm
            isDisabledProp={notCorrectInputData}
            showShedulerLabel
            hideAutomaticOptions
            renderActiveField
          />
        </FormItem>
      </FormLayout>
    );
  },
);

export const CodeLabModalsScheduler = ({ open, data, onClose }: ICodeLabModalsSchedulerProps) => {
  const { t } = useTranslation(['codeLab']);

  return (
    <DraggableModal
      width="500px"
      open={open}
      onCancel={onClose}
      title={t('modals.scheduler.title', { filePath: parseCodeLabFilePath(data?.name || '') })}
      destroyOnClose
      footer={null}
      footerInContent={true}
    >
      <CodeLabModalsSchedulerContent data={data} t={t} onClose={onClose} />
    </DraggableModal>
  );
};

export interface ICodeLabModalsSchedulerProps {
  open: boolean;
  data: Partial<Pick<ICodeLabJob, 'name' | 'notebook_id' | 'job' | 'id'>>;
  onClose: () => void;
}

export interface ICodeLabModalsSchedulerContentProps extends Omit<ICodeLabModalsSchedulerProps, 'open'> {
  t: TFunction;
  mixClSchedulerInsert?: boolean;
  mixClSchedulerDelete?: boolean;
}

interface ICodeLabModalsShedulerFormFields {
  job: SchedulerRunFormValues;
}

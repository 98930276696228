import { ALLOWED_EXTENTIONS } from '@modules/source/modals';
import { FolderWithFilesIcon } from '@components/icons';
import { FilesPayload } from '@modules/source/SourceTypes';

export const removeVolumeNameFromPath = (path: string, name: string) => path.replace(new RegExp(`^\/?${name}\/?`), '');

export const removeSlash = (path: string) => {
  let clearedPath = path;
  if (clearedPath.endsWith('/')) {
    clearedPath = clearedPath.slice(0, -1);
  }
  if (clearedPath.startsWith('/')) {
    clearedPath = clearedPath.slice(1);
  }
  return clearedPath;
};

export const getNameFromPath = (basePath: string, fullPath: string) => {
  const relativePath = fullPath.replace(basePath, '');
  return removeSlash(relativePath);
};

export const isAllowedFileExtention = (name: string) => {
  const fileExtension = name.split('.').at(-1)?.toLowerCase() ?? '';
  return ALLOWED_EXTENTIONS.includes(fileExtension);
};

export const getShortPath = (path: string) => {
  const parts = path.split('/').filter(Boolean);

  if (parts.length <= 2) return path;
  return `${parts[0]}/.../${parts[parts.length - 1]}`;
};

export const generateFileTreeNode = (parentPath: string, path: string, isDirectory: boolean) => ({
  key: (isDirectory ? 'folder-child:' : 'file-child:') + path,
  title: getNameFromPath(parentPath, path),
  icon: <FolderWithFilesIcon color="darkGrey" />,
  path,
  isLeaf: true,
  expandable: false,
  isDirectory,
});

export const getCleanPathName = (parentPath: string, volumeName: string, useDots: boolean = false) => {
  const path = parentPath !== volumeName ? removeVolumeNameFromPath(parentPath, volumeName) : '/';
  return useDots ? getShortPath(path) : path;
};

export const getSortedData = (data: FilesPayload[]) =>
  data
    .sort((a, b) => a.parent_folder_path.localeCompare(b.parent_folder_path))
    .map((item) => ({
      ...item,
      selected_folders: item.selected_folders.sort((a, b) => a.localeCompare(b)),
    }));

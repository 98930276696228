import {
  Checkbox,
  DraggableModal,
  Form,
  FormItem,
  FormLayout,
  Input,
  Select,
  Tabs,
  TextArea,
  Typography,
  notification,
} from '@ui';
import { Store } from '@modules/stores/StoreTypes';
import { DEFAULT_JOB_SETTINGS } from '@modules/stores/duck/storeConstants';
import { MANUAL_UPLOAD_SOURCE_ID } from '@modules/source/duck/sourceConstants';
import { useSaveStoreMutation, useDeleteStoreMutation } from '@modules/stores/duck/storeApi';
import { useSourceListQuery } from '@modules/source/duck/sourceApi';
import { ButtonWithConfirmation } from '@components';
import {
  getDefaultInitialValue,
  normalizeUsers,
  NotificationsForm,
  prepareFields,
  prepareUpdateData,
  RUN_TYPE,
  SchedulerRunForm,
  SchedulerRunFormValues,
  SearchUsersQuery,
  SearchUsersResponse,
  TNotificationConfig,
} from '@components/form';
import { useLazySearchUsersQuery } from '@modules/user/duck/userApi';
import { useStudyPermissions } from '@modules/user/duck/userHooks';
import { NAME_LIMIT } from '@config/constants';
import { TFunction } from 'i18next';
import { useTranslation } from 'react-i18next';
import { Col, Row, TabsProps } from 'antd';
import { useCallback, useEffect, useState } from 'react';

const tabs = {
  scheduler: {
    id: 'scheduler',
  },
  notifications: {
    id: 'notifications',
  },
};

const StoreModalsSaveStoreContent = ({ data, onClose, isEdit, t }: StoreModalsSaveSourceContentProps) => {
  const {
    userPermissions: { canDataStoreDelete, canDataStoreUpdate },
  } = useStudyPermissions();
  const [searchUsersQuery] = useLazySearchUsersQuery();
  const [form] = Form.useForm();
  const [deleteStore, deleteStoreQuery] = useDeleteStoreMutation();
  const [saveStore, saveStoreQuery] = useSaveStoreMutation();
  const sourceListQuery = useSourceListQuery();
  const dataSourceOption = sourceListQuery.data || [];
  const [isHideScheduler, setHideScheduler] = useState(false);

  const deleteLoading = deleteStoreQuery.isLoading;

  const searchUsers = useCallback(
    async (params: SearchUsersQuery): Promise<SearchUsersResponse> => await searchUsersQuery(params).unwrap(),
    [searchUsersQuery],
  );

  const errorMessage = [deleteStoreQuery, saveStoreQuery]
    .map(
      (result) =>
        result.isError && result.error && ('data' in result.error ? result.error.data.userMsg : result.error?.message),
    )
    .filter(Boolean)
    .join(';');

  const onSubmit = async (values: StoreSaveFieldFormValues) => {
    try {
      const notificationData = values.notification || data?.notification || initValues.notification;

      await saveStore({
        ...values,
        id: data?.id,
        sensitive: false,
        notification: {
          ...notificationData,
          send_email: false, // TODO remove when application could send emails
          users: normalizeUsers(notificationData.users),
        },
        data_source_name: (sourceListQuery?.data || [])?.filter((el) => el.id === values.data_source_id)[0].name,
        job: !isHideScheduler
          ? prepareUpdateData({ values: values.job || getDefaultInitialValue() })
          : DEFAULT_JOB_SETTINGS,
      }).unwrap();
      onClose();

      notification.success({
        message: t(isEdit ? 'notification.form.successMessageEdit' : 'notification.form.successMessageCreate', {
          name: values.name,
        }),
      });
    } catch (e) {
      console.error(e);
      notification.error({
        message: t(isEdit ? 'notification.form.errorMessageEdit' : 'notification.form.errorMessageCreate', {
          name: values.name,
        }),
      });
    }
  };

  const onDeleteStore = async () => {
    try {
      await deleteStore(data.id!).unwrap();
      onClose();
      notification.success({ message: t('notification.form.successMessageDelete', { name: data.name }) });
    } catch (e) {
      console.error(e);
      notification.error({
        message: t('notification.form.errorMessageDelete', { name: data.name }),
      });
    }
  };

  useEffect(() => {
    if (isEdit && data) {
      setHideScheduler(checkIsManualSource(data.data_source_id!));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEdit, data]);

  const initValues = (isEdit &&
    data && {
      name: data.name,
      description: data.description,
      data_source_id: data.data_source_id,
      // sensitive: data.sensitive,
      sensitive: false,
      active: data.active,
      job: data.job && data.job.crontab ? prepareFields(data.job!) : getDefaultInitialValue(RUN_TYPE.once),
      notification: data.notification,
    }) || {
    active: true,
    sensitive: false,
    job: getDefaultInitialValue(RUN_TYPE.once),
    notification: {
      send_email: false,
      users: [],
    },
  };

  const findConnectionTypeId = (sourceId: number) => dataSourceOption.find((el) => el.id === sourceId)?.connection_id;

  const initialConnectionTypeId = findConnectionTypeId(initValues?.data_source_id ?? -1);

  const filteredDataSourceOptions = dataSourceOption.filter(
    (item) =>
      (initValues.data_source_id === item.id || (item.active && !item.system)) &&
      (!initValues.data_source_id || item.connection_id === initialConnectionTypeId),
  );

  const checkIsManualSource = (sourceId: number) =>
    filteredDataSourceOptions.find((el) => el.id === sourceId)?.connection_id === MANUAL_UPLOAD_SOURCE_ID;

  const handleDataSourceChange = (value: number) => {
    setHideScheduler(checkIsManualSource(value));
  };

  const isDisabledForm = isEdit && !canDataStoreUpdate;

  return (
    <FormLayout
      form={form}
      onCancel={onClose}
      onSubmit={onSubmit}
      okText={t('save')}
      initialValues={initValues}
      disabled={isDisabledForm}
      hideOkBtn={isDisabledForm}
      submitIsDisabled={deleteLoading}
      cancelIsDisabled={deleteLoading}
      extraActions={
        isEdit &&
        canDataStoreDelete && (
          <ButtonWithConfirmation
            submitFunc={onDeleteStore}
            confirmContent={t('confirmation.description')}
            confirmTitle={t('confirmation.title')}
            loading={deleteLoading}
            disabled={saveStoreQuery.isLoading}
          />
        )
      }
    >
      <FormItem
        name="name"
        label={t('saveForm.name')}
        rules={[
          { required: true },
          {
            validator: (_, value) => {
              return value.length <= NAME_LIMIT
                ? Promise.resolve()
                : Promise.reject(new Error(t('errors.maxLength', { length: NAME_LIMIT })));
            },
          },
        ]}
      >
        <Input />
      </FormItem>
      <FormItem name="description" label={t('saveForm.description')} rules={[{ required: true }]}>
        <TextArea />
      </FormItem>
      <FormItem
        name="data_source_id"
        label={t('saveForm.dataSource')}
        rules={[{ required: true }]}
        tooltip={isEdit && t('saveForm.tooltipDatasource')}
      >
        <Select
          options={filteredDataSourceOptions}
          placeholder={t('select')}
          loading={sourceListQuery.isLoading}
          fieldNames={{ label: 'name', value: 'id' }}
          onChange={handleDataSourceChange}
        />
      </FormItem>
      <Row gutter={8}>
        {/*<Col span={12}>*/}
        {/*  <FormItem name="sensitive" label={t('saveForm.sensitive')} valuePropName="checked">*/}
        {/*    <Checkbox />*/}
        {/*  </FormItem>*/}
        {/*</Col>*/}
        <Col span={24}>
          <FormItem name="active" label={t('saveForm.active')} valuePropName="checked">
            <Checkbox />
          </FormItem>
        </Col>
      </Row>
      <FormItem wrapperCol={{ span: 24 }}>
        <Tabs
          defaultActiveKey={isHideScheduler ? tabs.notifications.id : tabs.scheduler.id}
          size="small"
          items={
            [
              !isHideScheduler && {
                key: tabs.scheduler.id,
                label: t(`tabs.${tabs.scheduler.id}`),
                children: (
                  <FormItem wrapperCol={{ span: 24 }} asInfo>
                    <SchedulerRunForm
                      isDisabledProp={isDisabledForm}
                      renderActiveField
                      showShedulerLabel
                      hideAutomaticOptions
                    />
                  </FormItem>
                ),
              },
              {
                key: tabs.notifications.id,
                label: t(`tabs.${tabs.notifications.id}`),
                children: <NotificationsForm form={form} data={data?.notification} search={searchUsers} />,
              },
            ].filter((el) => el) as TabsProps['items']
          }
        />
      </FormItem>
      {errorMessage && <Typography.Text type="danger">{errorMessage}</Typography.Text>}
    </FormLayout>
  );
};

export const StoreModalsSaveStore = ({ open, data, onClose }: StoreModalsSaveStoreProps) => {
  const { t } = useTranslation(['datastore']);
  const isEdit = !!(data as Store)?.id;

  return (
    <DraggableModal
      width={555}
      open={open}
      onCancel={onClose}
      title={isEdit ? t('saveForm.titleEdit') : t('saveForm.title')}
      footer={null}
      destroyOnClose
      footerInContent={true}
    >
      <StoreModalsSaveStoreContent data={data} onClose={onClose} isEdit={isEdit} t={t} />
    </DraggableModal>
  );
};

export interface StoreModalsSaveStoreProps {
  open: boolean;
  data: Partial<Store>;
  onClose: () => void;
}

interface StoreModalsSaveSourceContentProps extends Pick<StoreModalsSaveStoreProps, 'data' | 'onClose'> {
  t: TFunction;
  isEdit: boolean;
}

interface StoreSaveFieldFormValues {
  name: string;
  description: string;
  data_source_id: number;
  data_source_name: string;
  sensitive: boolean;
  active: boolean;
  job?: SchedulerRunFormValues & { active: boolean };
  notification: TNotificationConfig;
}

import { BaseIcon, SvgIconProps } from '@components/icons/BaseIcon';
import { forwardRef, ForwardRefExoticComponent } from 'react';

export const FolderWithFilesIcon: ForwardRefExoticComponent<SvgIconProps> = forwardRef((props, ref) => (
  <BaseIcon {...props} forwardRef={ref} width={800} height={800} fill="none" viewBox="0 0 24 24">
    <line stroke="currentColor" strokeMiterlimit={10} strokeWidth={1.91} x1="5.32" y1="18.68" x2="14.86" y2="18.68" />
    <line stroke="currentColor" strokeMiterlimit={10} strokeWidth={1.91} x1="5.32" y1="14.86" x2="14.86" y2="14.86" />
    <line stroke="currentColor" strokeMiterlimit={10} strokeWidth={1.91} x1="5.32" y1="11.05" x2="14.86" y2="11.05" />
    <path
      stroke="currentColor"
      strokeMiterlimit={10}
      strokeWidth={1.91}
      d="M20.59,22.5H3.41A1.92,1.92,0,0,1,1.5,20.59V7.23H18.68V20.59A1.92,1.92,0,0,0,20.59,22.5Z"
    />
    <path
      stroke="currentColor"
      strokeMiterlimit={10}
      strokeWidth={1.91}
      d="M22.5,3.41V20.59a1.91,1.91,0,0,1-3.82,0V7.23H5.32V1.5h9.54l1.87,1.91Z"
    />
  </BaseIcon>
));

import { ViewerDataFormatsFormValues } from '@modules/viewer/modals/components';
import i18n from '@i18n';
import { CSSObject, Theme } from '@emotion/react';
import { debounce } from 'lodash';
import { useEffect, useRef, useState } from 'react';
import DataGrid, { DataGridHandle, DataGridProps } from 'react-data-grid';
import dayjs from 'dayjs';
import 'react-data-grid/lib/styles.css';

const renderValue = {
  dateTime: (value: any, format?: string) => {
    return format && value ? dayjs(value).format(format) : value;
  },
};

export const renderCellTypedValue = (value: any, valueType: string, dataFormats?: ViewerDataFormatsFormValues) => {
  const cellValue = value ?? <span css={cssNull}>{i18n.t('nullValue')}</span>;

  switch (valueType) {
    case 'Bool':
      return <span css={cssBoolean} children={cellValue.toString()} />;
    case 'UInt8':
    case 'UInt16':
    case 'UInt64':
    case 'UInt32':
    case 'UInt128':
    case 'UInt256':
    case 'Int8':
    case 'Int16':
    case 'Int32':
    case 'Int64':
    case 'Int128':
    case 'Int256':
    case 'Float':
    case 'Float32':
    case 'Float64':
    case 'Decimal':
    case 'Decimal32':
    case 'Decimal64':
    case 'Decimal128':
    case 'Decimal256':
      return <span css={cssNumber} children={cellValue} />;
    case 'Date':
    case 'Date32':
      return <span css={cssDate} children={cellValue} />;
    case 'DateTime':
    case 'DateTime64':
      return <span css={cssDate} children={renderValue.dateTime(cellValue, dataFormats?.dateTimeFormat)} />;
    case 'Map':
      return JSON.stringify(cellValue);
    default:
      return <span children={cellValue} />;
  }
};

export function DataTable<DataType extends Record<string, any>>({
  columns,
  rows,
  rowHeight,
  headerRowHeight,
  rowKeyGetter,
}: DataTableProps<DataType>) {
  const tableRef = useRef<DataGridHandle>(null);
  const [maxWidth, setMaxWidth] = useState(900);

  useEffect(() => {
    const onResize = debounce(() => {
      const tableClientRect = tableRef.current?.element?.getBoundingClientRect();
      const maxTableWidth = tableClientRect?.width || window.innerWidth;
      setMaxWidth(columns.length > 2 ? maxTableWidth / 2 : maxTableWidth);
    }, 500);

    onResize();
    window.addEventListener('resize', onResize);
    return () => window.removeEventListener('resize', onResize);
  }, [columns]);

  return (
    <DataGrid
      ref={tableRef}
      columns={columns}
      rows={rows}
      css={cssBody}
      rowHeight={rowHeight}
      headerRowHeight={headerRowHeight}
      defaultColumnOptions={{ resizable: true, maxWidth }}
      className="rdg-light"
      rowKeyGetter={rowKeyGetter}
    />
  );
}

export interface DataTableProps<DataType> extends DataGridProps<DataType> {}

const cssBody = (theme: Theme): CSSObject => ({
  width: '100%',
  height: '100%',
  color: theme['color-grey-900'],
  '.rdg-header-row': {
    fontWeight: 400,
    fontSize: 16,
    color: theme['color-grey-600'],
  },
  '.ant-form-item': {
    marginBottom: 0,
  },
  '.rdg-row': {
    lineHeight: 1.5,
    height: 56,
    fontWeight: 400,
  },
  '.rdg-cell': {
    alignContent: 'center',
  },
});

// TODO no any instructions to colors for some types in mockups so was leave as is
const cssNumber = (theme: Theme) => ({
  color: theme.cyan9,
});

const cssBoolean = (theme: Theme) => ({
  color: theme.blue9,
});

const cssDate = (theme: Theme) => ({
  color: theme.green9,
});

const cssNull = (theme: Theme) => ({
  fontStyle: 'italic',
  color: theme['color-grey-500'],
});

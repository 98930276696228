import { useAnalysisObjectStackDatasetListQuery } from '@modules/library/analysisObjects/duck/analysisObjectApi';
import { TableComponentProps } from '@modules/library/analysisPackage/modals/components';
import { useTablePaginationState } from '@components/ui/table/tableHooks';
import { InformationModal } from '@components';
import { renderInfoModalContent } from '@modules/dataset/components';
import { useStackDatasetObjectColumns } from '@modules/dnaObject/StackDataset/duck/stackDatasetObjectHooks';
import { initialTabPage } from '@modules/library/analysisPackage/modals';
import { IAnalysisPackage } from '@modules/library/analysisPackage/AnalysisPackageTypes';
import { ELibraryEntityKind } from '@modules/library/root/LibraryTypes';
import { IBaseColumnProps, ObjectTable } from '@shared/components/ObjectTable';
import { TableColumnsType } from 'antd';

export const StackDatasetsObjects = ({
  kind,
  isView,
  data,
  analysisPackageById = [],
  selectedTableItems,
  setSelectedTableItems,
  analysisPackageByIdFetching,
}: StackDatasetsObjectsProps) => {
  const { paginationState, getPagination, preparedFilters, onTableChange } = useTablePaginationState(initialTabPage);
  const {
    stackDatasetColumns,
    locale: stackDatasetLocale,
    studiesDetailsStackDatasetModal,
    setStudiesDetailsStackDatasetModal,
  } = useStackDatasetObjectColumns();

  const stackDatasetsObjects = useAnalysisObjectStackDatasetListQuery(
    { page: paginationState.current - 1, page_size: initialTabPage.pageSize, detailed: '1', ...preparedFilters },
    { skip: isView },
  );

  const tableDataStackDatasets =
    ((isView && data ? analysisPackageById : stackDatasetsObjects.data?.items) as any[]) || [];
  const totalItemsStackDatasets = isView && data ? analysisPackageById.length : stackDatasetsObjects.data?.totalItems;

  return (
    <>
      <ObjectTable
        kind={kind}
        columns={stackDatasetColumns as TableColumnsType<IBaseColumnProps>}
        locale={stackDatasetLocale}
        tableDataFetching={stackDatasetsObjects.isFetching || analysisPackageByIdFetching}
        tableData={tableDataStackDatasets}
        totalItems={totalItemsStackDatasets}
        selectedTableItems={selectedTableItems}
        setSelectedTableItems={setSelectedTableItems}
        hideCheckboxes={isView}
        getPagination={getPagination}
        onTableChange={onTableChange}
      />
      {studiesDetailsStackDatasetModal && (
        <InformationModal
          message={renderInfoModalContent(studiesDetailsStackDatasetModal)}
          onClose={() => setStudiesDetailsStackDatasetModal(null)}
          width="500px"
          autoHeightMin={50}
        />
      )}
    </>
  );
};

interface StackDatasetsObjectsProps extends TableComponentProps {
  analysisPackageById?: IAnalysisPackage['objects'][ELibraryEntityKind.StackDataset];
}

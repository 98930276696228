import { useAnalysisObjectPscListQuery } from '@modules/library/analysisObjects/psc/duck/analysisObjectPscApi';
import { ELibraryEntityKind, ELibrarySourceType } from '@modules/library/root/LibraryTypes';
import { TableComponentProps } from '@modules/library/analysisPackage/modals/components';
import { useTablePaginationState } from '@components/ui/table/tableHooks';
import { usePscColumns } from '@modules/library/psc/duck/libraryPscHooks';
import { ILibraryPsc } from '@modules/library/psc/PscTypes';
import { IBaseColumnProps, ObjectTable } from '@shared/components/ObjectTable';
import { initialTabPage } from '@modules/library/analysisPackage/modals';
import { IAnalysisPackage } from '@modules/library/analysisPackage/AnalysisPackageTypes';
import { getLibraryImportPscFromLibrarySource } from '@modules/library/psc/components/LibraryImportPsc';
import { TableColumnsType } from 'antd';

export const PscObjects = ({
  kind,
  isView,
  data,
  analysisPackageById = [],
  selectedTableItems,
  setSelectedTableItems,
  analysisPackageByIdFetching,
}: PscObjectsProps) => {
  const { paginationState, getPagination, preparedFilters, onTableChange } = useTablePaginationState(initialTabPage);
  const { pscColumns, locale: pscLocale } = usePscColumns();

  const pscObjects = useAnalysisObjectPscListQuery(
    { page: paginationState.current - 1, page_size: initialTabPage.pageSize, detailed: '1', ...preparedFilters },
    { skip: isView },
  );

  const tableDataPsc = ((isView && data ? analysisPackageById : pscObjects.data?.items) as ILibraryPsc[]) || [];
  const totalItemsPsc = isView && data ? analysisPackageById.length : pscObjects.data?.totalItems;

  return (
    <ObjectTable
      kind={kind}
      columns={pscColumns as TableColumnsType<IBaseColumnProps>}
      locale={pscLocale}
      tableDataFetching={pscObjects.isFetching || analysisPackageByIdFetching}
      tableData={getLibraryImportPscFromLibrarySource(tableDataPsc, ELibrarySourceType.Library)}
      totalItems={totalItemsPsc}
      selectedTableItems={selectedTableItems}
      setSelectedTableItems={setSelectedTableItems}
      hideCheckboxes={isView}
      getPagination={getPagination}
      onTableChange={onTableChange}
    />
  );
};

interface PscObjectsProps extends TableComponentProps {
  analysisPackageById?: IAnalysisPackage['objects'][ELibraryEntityKind.Psc];
}

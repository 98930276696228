import { SupportedEnvs } from '@app/AppTypes';
import { Col, Form, FormItem, FormLayout, Radio, Row, Select, Table } from '@components/ui';
import { useAuthUserEnvs } from '@modules/auth/duck/authHooks';
import { itemsToOptions } from '@shared/utils/Form';
import { useEnvStudyListQuery, useLibraryListQuery } from '@modules/library/root/duck/libraryApi';
import { selectGlobalLibrary, selectGlobalStudy } from '@app/duck/appSelectors';
import { TablePaginationRequiredProps, useTablePaginationState } from '@components/ui/table/tableHooks';
import { ELibrarySourceType, IBaseLibraryEntitySystemInfo } from '@modules/library/root/LibraryTypes';
import { selectAuthUser } from '@modules/auth/duck/AuthSelector';
import { useAppContext } from '@app/AppContext';
import { QueryErrorType } from '@shared/utils/Error';
import { useFeatures } from '@modules/user/duck/userHooks';
import { LibraryStatus } from '@modules/library/root/duck/libraryConstants';
import { useLibraryObjectCDRReportListQuery } from '@modules/library/libraryObject/duck/libraryObjectApi';
import { useEnvCDRReportListQuery } from '@modules/cdrReport/duck/cdrReportApi';
import { ICDRReport } from '@modules/cdrReport/CDRReportTypes';
import { Key, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { css } from '@emotion/react';
import { TableRowSelection } from 'antd/es/table/interface';
import { prepareCDRReportTableData } from '../duck/cdrReportUtils';
import { ICDRReportObject, IPrepareCDRReportTableData } from '../CDRObjectTypes';
import { useCDRReportObjectColumns } from '../duck/cdrReportObjectHooks';

const initialPage = {
  current: 1,
  pageSize: 10,
  pageSizeOptions: [],
};

const useSelectFormStateValues = (
  { onlyCurrentEnv, defaultSource, hideCurrentSource }: ICDRReportObjectSelectFormProps,
  form: ReturnType<typeof Form.useForm>[0],
) => {
  const { hasGL } = useFeatures();
  const globalStudy = useSelector(selectGlobalStudy);
  const globalLibrary = useSelector(selectGlobalLibrary);
  const { userCurrentEnv, userCurrentEnvLabel, allUserEnvs } = useAuthUserEnvs();

  const sourceTypeOptions = useMemo(() => {
    const sourceOptions: Array<{ label: string; value: string }> = [];

    if (onlyCurrentEnv) {
      sourceOptions.push({ label: `Study (${userCurrentEnvLabel})`, value: userCurrentEnv } as any);
    } else {
      sourceOptions.push(
        ...Object.entries(allUserEnvs || ({} as SupportedEnvs)).map(([env, envData]) => {
          return { label: `Study (${envData!.label})`, value: env } as any;
        }),
      );
    }

    if (hasGL) {
      const libraryOption = {
        label: ELibrarySourceType.Library,
        value: ELibrarySourceType.Library,
      };
      if (defaultSource === ELibrarySourceType.Library) {
        sourceOptions.unshift(libraryOption);
      } else {
        sourceOptions.push(libraryOption);
      }
    }

    return sourceOptions;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allUserEnvs, userCurrentEnv, userCurrentEnvLabel]);

  const initValues = {
    sourceKind: sourceTypeOptions.at(0)?.value,
    sourceId: !hideCurrentSource ? globalStudy?.id || globalLibrary?.id : undefined,
    overwrite: false,
  };

  const currentFormStateRef = useRef({
    sourceKind: initValues.sourceKind,
    sourceId: initValues.sourceId,
  });

  return {
    initValues,
    userCurrentEnv,
    sourceTypeOptions,
    currentFormStateRef,
    currentFormStateSourceKind: currentFormStateRef.current.sourceKind,
    currentFormStateSourceId: currentFormStateRef.current.sourceId,
    isSelectedSourceLibrary: currentFormStateRef.current.sourceKind === ELibrarySourceType.Library,
    globalLibraryId: globalLibrary?.id,
    globalStudyId: globalStudy?.id,
  };
};

const useSelectFormQueries = (
  { libraryStatuses, hideCurrentSource }: ICDRReportObjectSelectFormProps,
  {
    currentFormStateSourceKind,
    currentFormStateSourceId,
    isSelectedSourceLibrary,
    userCurrentEnv,
    globalLibraryId,
    globalStudyId,
  }: ReturnType<typeof useSelectFormStateValues>,
  paginationState: TablePaginationRequiredProps,
  preparedFilters: Record<string, string | undefined>,
) => {
  const libraryListQuery = useLibraryListQuery(
    {
      page_size: 99999,
      status: libraryStatuses ? libraryStatuses.join(',') : undefined,
    },
    { skip: !isSelectedSourceLibrary },
  );
  const studiesListQuery = useEnvStudyListQuery(
    { env: currentFormStateSourceKind! },
    {
      skip: isSelectedSourceLibrary || currentFormStateSourceKind === undefined,
    },
  );
  const libraryObjectCDRReportListQuery = useLibraryObjectCDRReportListQuery(
    {
      detailed: '1',
      library_id: currentFormStateSourceId,
      page: paginationState.current - 1,
      page_size: paginationState.pageSize,
      name: preparedFilters['name'],
    },
    { skip: !isSelectedSourceLibrary || currentFormStateSourceId === undefined },
  );
  const envCDRReportListQuery = useEnvCDRReportListQuery(
    {
      env: currentFormStateSourceKind!,
      study_id: currentFormStateSourceId!,
      page: paginationState.current,
      page_size: paginationState.pageSize,
      name: preparedFilters['name'],
    },
    {
      skip:
        isSelectedSourceLibrary || currentFormStateSourceId === undefined || currentFormStateSourceKind === undefined,
    },
  );

  const filteredSourceOptions = useMemo(() => {
    if (isSelectedSourceLibrary) {
      return itemsToOptions(
        hideCurrentSource
          ? libraryListQuery.data?.items?.filter((item) => item.id !== globalLibraryId)
          : libraryListQuery.data?.items,
      );
    }

    return itemsToOptions(
      hideCurrentSource && currentFormStateSourceKind === userCurrentEnv
        ? studiesListQuery.data?.filter((item) => item.id !== globalStudyId)
        : studiesListQuery.data,
    );
  }, [
    currentFormStateSourceKind,
    globalLibraryId,
    globalStudyId,
    hideCurrentSource,
    isSelectedSourceLibrary,
    libraryListQuery.data?.items,
    studiesListQuery.data,
    userCurrentEnv,
  ]);

  const filteredTableData: TableData = useMemo(() => {
    if (currentFormStateSourceId === undefined) return [];

    if (isSelectedSourceLibrary) {
      return prepareCDRReportTableData({
        data: libraryObjectCDRReportListQuery.currentData?.items || [],
        sourceType: ELibrarySourceType.Library,
      });
    }

    return prepareCDRReportTableData({
      data: envCDRReportListQuery.currentData?.items || [],
      sourceType: ELibrarySourceType.Study,
    });
  }, [
    currentFormStateSourceId,
    isSelectedSourceLibrary,
    envCDRReportListQuery.currentData?.items,
    libraryObjectCDRReportListQuery.currentData?.items,
  ]);

  return {
    libraryListQuery,
    studiesListQuery,
    libraryObjectCDRReportListQuery,
    envCDRReportListQuery,
    filteredSourceOptions,
    filteredTableData,
  };
};

const initSelectedTableItems = {
  selectedRowKeys: [] as Key[],
  selectedRows: [] as TableDataItem[],
};

export const CDRReportObjectSelectForm = (props: ICDRReportObjectSelectFormProps) => {
  const { notificationApi } = useAppContext();
  const { t } = useTranslation(['dnaObject']);
  const [form] = Form.useForm();
  const [selectedTableItems, setSelectedTableItems] = useState(initSelectedTableItems);

  const user = useSelector(selectAuthUser);
  const { setPagination, getPagination, onTableChange, paginationState, preparedFilters } =
    useTablePaginationState(initialPage);

  const selectFormState = useSelectFormStateValues(props, form);
  const selectFormQueries = useSelectFormQueries(props, selectFormState, paginationState, preparedFilters);

  const { cdrReportColumns, locale } = useCDRReportObjectColumns(selectFormState.isSelectedSourceLibrary);

  const onChangeSourceInput = (value: string) => {
    form.setFieldValue('sourceId', undefined);
    setSelectedTableItems(initSelectedTableItems);
    setPagination(initialPage.current, initialPage.pageSize);
    selectFormState.currentFormStateRef.current.sourceId = undefined;
    selectFormState.currentFormStateRef.current.sourceKind = value;
  };

  const onChangeSourceIdInput = async (id: number) => {
    setSelectedTableItems(initSelectedTableItems);
    setPagination(initialPage.current, initialPage.pageSize);
    selectFormState.currentFormStateRef.current.sourceId = id;
  };

  const onSubmitForm = async (values: ICDRReportObjectSelectFormValues) => {
    try {
      let options: IOnSubmitOptions = { overwrite: values.overwrite, kind: ELibrarySourceType.Library };

      if (values['sourceKind'] && values['sourceKind'] !== ELibrarySourceType.Library) {
        const studyName = selectFormQueries.filteredSourceOptions.find((item) => item.value === values['sourceId'])
          ?.label!;
        options = {
          ...options,
          kind: ELibrarySourceType.Study,
          systemInfo: {
            tenant_info: user!.tenant_info,
            env: values['sourceKind'],
            source: studyName,
          },
        };
      }

      await props.onSubmit(
        selectedTableItems.selectedRows.map((item) => item.rawData),
        options,
      );
      notificationApi.success({ message: t('importForm.successMessage', { kind: ELibrarySourceType.Study }) });
      props.onClose();
    } catch (e) {
      if ((e as QueryErrorType)?.data?.userMsg) {
        notificationApi.error({ message: (e as QueryErrorType)?.data?.userMsg });
      }
    }
  };

  const rowSelection: TableRowSelection<TableDataItem> = {
    onChange: (selectedRowKeys, selectedRows) => {
      setSelectedTableItems({ selectedRowKeys, selectedRows });
    },
  };

  const fetchingNames = selectFormQueries.libraryListQuery.isFetching || selectFormQueries.studiesListQuery.isFetching;

  return (
    <FormLayout
      form={form}
      layout="vertical"
      onCancel={props.onClose}
      onSubmit={onSubmitForm}
      okText={t('import')}
      initialValues={selectFormState.initValues}
      submitIsDisabled={
        selectedTableItems.selectedRowKeys.length === 0 ||
        fetchingNames ||
        selectFormQueries.envCDRReportListQuery.isFetching ||
        selectFormQueries.libraryObjectCDRReportListQuery.isFetching
      }
    >
      <Row gutter={24}>
        <Col span={12}>
          <FormItem
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24 }}
            name="sourceKind"
            label={t('importForm.sourceKind')}
            rules={[{ required: true }]}
          >
            <Select
              placeholder={t('importForm.sourceKindPlaceholder')}
              options={selectFormState.sourceTypeOptions}
              onChange={onChangeSourceInput}
            />
          </FormItem>
        </Col>
        <Col span={12}>
          <FormItem
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24 }}
            name="sourceId"
            label={t('importForm.sourceId')}
            rules={[{ required: true }]}
            dependencies={['source']}
          >
            <Select
              placeholder={t('importForm.sourceIdPlaceholder')}
              options={selectFormQueries.filteredSourceOptions}
              loading={fetchingNames}
              onChange={onChangeSourceIdInput}
            />
          </FormItem>
        </Col>
      </Row>
      {!props.hideOverwriteHandles && (
        <Row gutter={24}>
          <Col span={24}>
            <Form.Item name="overwrite" label={t('importForm.duplicateLabel.label')} css={cssHorizontalFormItem}>
              <Radio.Group>
                <Radio value={false}>{t('importForm.duplicateLabel.createCopy')}</Radio>
                <Radio value={true}>{t('importForm.duplicateLabel.useExisting')}</Radio>
              </Radio.Group>
            </Form.Item>
          </Col>
        </Row>
      )}
      <Table
        size="small"
        columns={cdrReportColumns}
        locale={locale}
        loading={
          selectFormQueries.envCDRReportListQuery.isFetching ||
          selectFormQueries.libraryObjectCDRReportListQuery.isFetching
        }
        rowKey="id"
        tableLayout="fixed"
        dataSource={selectFormQueries.filteredTableData}
        pagination={getPagination(
          selectFormState.isSelectedSourceLibrary
            ? selectFormQueries.libraryObjectCDRReportListQuery.currentData?.totalItems
            : selectFormQueries.envCDRReportListQuery.currentData?.total_count,
        )}
        onChange={onTableChange}
        scroll={{ x: 750 }}
        rowSelection={{
          columnWidth: 40,
          preserveSelectedRowKeys: true,
          type: 'checkbox',
          ...rowSelection,
          selectedRowKeys: selectedTableItems.selectedRowKeys,
        }}
      />
    </FormLayout>
  );
};

const cssHorizontalFormItem = css({
  '&&& .ant-form-item-row': {
    flexDirection: 'row',
    alignItems: 'center',
  },
  '&&& .ant-form-item-label': {
    paddingBottom: 0,
    paddingRight: 16,
    flex: 'none',
  },
});

export interface ICDRReportObjectSelectFormProps {
  onClose: () => void;
  onSubmit: (data: (ICDRReportObject | ICDRReport)[], options: IOnSubmitOptions) => void;
  onlyCurrentEnv?: boolean;
  hideCurrentSource?: boolean;
  hideOverwriteHandles?: boolean;
  libraryStatuses?: LibraryStatus[];
  defaultSource?: ELibrarySourceType;
}

interface IOnSubmitOptions {
  overwrite: boolean;
  kind: ELibrarySourceType;
  systemInfo?: IBaseLibraryEntitySystemInfo;
}

interface ICDRReportObjectSelectFormValues {
  sourceKind: ELibrarySourceType;
  sourceId: number;
  overwrite: boolean;
}

type TableData = IPrepareCDRReportTableData<ICDRReportObject> | IPrepareCDRReportTableData<ICDRReport>;
type TableDataItem = IPrepareCDRReportTableData<ICDRReportObject>[0] | IPrepareCDRReportTableData<ICDRReport>[0];

import { RootState, store } from '@store';
import { startNotificationListener } from '@app/components/Notifications/duck/notificationsListeners';
import { startQueueListener } from '@modules/task/duck/queueListeners';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import localeData from 'dayjs/plugin/localeData';
import arraySupport from 'dayjs/plugin/arraySupport';
import timezone from 'dayjs/plugin/timezone';
import isoWeek from 'dayjs/plugin/isoWeek';
import utc from 'dayjs/plugin/utc';
import duration from 'dayjs/plugin/duration';

dayjs.extend(relativeTime);
dayjs.extend(localeData);
dayjs.extend(arraySupport);
dayjs.extend(isoWeek);
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(duration);

const unsubscribe = store.subscribe(() => {
  if ((store.getState() as RootState).app.ready) {
    unsubscribe();
    startNotificationListener();
    startQueueListener();
  }
});

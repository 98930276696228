import { getFormattedDatetime } from '@shared/utils/Date';
import { Typography } from '@ui';

export const AuditLogsDiffTitleContent = ({ text, date, username }: IAuditLogDiffTitleContentProps) => (
  <Typography.Text>
    {username ? `${username}: ` : ''}
    {text && <Typography.Text type="secondary">{text}</Typography.Text>}
    {date && (
      <Typography.Text type="secondary">
        {' '}
        ({getFormattedDatetime(date, { dateInUTC: false, format: 'YYYY-MM-DD HH:mm:ss UTCZ' })})
      </Typography.Text>
    )}
  </Typography.Text>
);

interface IAuditLogDiffTitleContentProps {
  text: string;
  username?: string;
  date?: number;
}

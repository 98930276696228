import { dataStageColors } from '@config/theme/colors';
import { NoDataFound } from '@components';
import { Badge, Button, Checkbox, Divider, getMenuItem, Menu, Space } from '@components/ui';
import { ArrowLeft, ArrowRight, FilterSvg, SortAsc, SortDesc } from '@components/icons';
import { ColumnGroupType, ColumnType, default as AntTable } from 'antd/es/table';
import { TableProps } from 'antd';
import { css, CSSObject, Theme } from '@emotion/react';
import { SortOrder } from 'antd/es/table/interface';
import { FunctionComponent, Key, ReactNode } from 'react';
import { FilterDropdownProps } from 'antd/lib/table/interface';
import { useTranslation } from 'react-i18next';

const CustomTable: FunctionComponent<TableProps<any>> = ({ children, ...props }) => {
  const { t } = useTranslation();

  const itemRender = (current: number, type: string, originalElement: ReactNode) => {
    if (type === 'prev') {
      return <ArrowLeft color="darkGrey" />;
    }
    if (type === 'next') {
      return <ArrowRight color="darkGrey" />;
    }
    return originalElement;
  };

  const customizedColumns = props.columns?.map((col) => ({
    sortIcon: ({ sortOrder }: { sortOrder: SortOrder }) => {
      const color = sortOrder ? 'linkActive' : 'darkGrey';
      return (
        <span css={{ marginLeft: 8, fontSize: 24, display: 'flex' }}>
          {sortOrder === 'descend' ? <SortDesc color={color} /> : <SortAsc color={color} />}
        </span>
      );
    },
    filterIcon: (filtered: boolean) =>
      col.filters && col.filters.length !== 0 ? (
        <Badge count={+filtered} size="small" css={cssBadge(filtered)}>
          <FilterSvg css={{ fontSize: 24 }} color="darkGrey" />
        </Badge>
      ) : null,

    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }: FilterDropdownProps) => {
      if (!col.filters || col.filters.length === 0) return null;

      const items = col.filters.map((filter) =>
        getMenuItem({
          key: filter.value as Key,
          label: (
            <Checkbox
              checked={selectedKeys.includes(filter.value as any)}
              onChange={(e) => {
                const newSelectedKeys = e.target.checked
                  ? [...selectedKeys, filter.value]
                  : selectedKeys.filter((key) => key !== filter.value);
                setSelectedKeys(newSelectedKeys as Key[]);
              }}
            >
              {filter.text}
            </Checkbox>
          ),
        }),
      );
      return (
        <>
          <Menu items={items} />
          <Space full justify="space-between" css={cssFilterButtons}>
            <Button
              disabled={!selectedKeys.length}
              onClick={() => {
                clearFilters?.();
                confirm();
              }}
              size="large"
            >
              {t('table.filterReset')}
            </Button>
            <Button type="primary" onClick={() => confirm()} size="large">
              {t('table.filterConfirm')}
            </Button>
          </Space>
        </>
      );
    },
    onCell: (record: any) => ({
      style: 'customKey' in col && col.customKey === 'narrow' ? { paddingTop: '9.5px', paddingBottom: '9.5px' } : {},
    }),
    ...col,
  }));

  return (
    <AntTable
      {...props}
      css={cssTable}
      locale={{
        ...props.locale,
        emptyText: <NoDataFound />,
      }}
      columns={customizedColumns}
      children={children}
      pagination={
        props.pagination
          ? {
              ...props.pagination,
              simple: true,
              size: 'default',
              itemRender: itemRender,
            }
          : false
      }
    />
  );
};

const SELECTION_COLUMN = AntTable.SELECTION_COLUMN;
const EXPAND_COLUMN = AntTable.EXPAND_COLUMN;
const SELECTION_ALL = AntTable.SELECTION_ALL;
const SELECTION_INVERT = AntTable.SELECTION_INVERT;
const SELECTION_NONE = AntTable.SELECTION_NONE;
const Column = AntTable.Column;
const ColumnGroup = AntTable.ColumnGroup;

const Table = Object.assign(CustomTable, {
  displayName: 'CustomTable',
  SELECTION_COLUMN,
  EXPAND_COLUMN,
  SELECTION_ALL,
  SELECTION_INVERT,
  SELECTION_NONE,
  Column,
  ColumnGroup,
});

const cssTable = (theme: Theme): CSSObject => ({
  marginBottom: 16,
  '&&': {
    '&&& th.ant-table-cell:before': {
      content: 'none',
    },
  },
  '.ant-table': {
    borderRadius: 8,
  },
  '.ant-table-container': {
    border: `1px solid ${theme['color-grey-300']}`,
    borderRadius: 8,
  },
  '.ant-table-row:last-child .ant-table-cell': {
    borderBottom: 'none',
  },
  '&& .ant-table-selection-column': {
    width: 96,
    textAlign: 'left',
  },
  '.ant-checkbox-wrapper': {
    paddingLeft: 8,
    paddingRight: 4,
    '& .ant-checkbox-inner': {
      border: `1.5px solid ${dataStageColors['color-grey-500']}`,
    },
  },
  '.ant-table-column-title': {
    flex: 'none',
  },
  '.ant-table-filter-column': {
    justifyContent: 'normal',

    '.ant-table-filter-trigger': {
      fontSize: 24,
    },
  },
  '.ant-table-column-sorters': {
    justifyContent: 'normal',
  },
  '.ant-pagination-prev': {
    fontSize: 20,
  },
  '.ant-pagination-next': {
    fontSize: 20,
  },
  '&& .ant-checkbox-indeterminate .ant-checkbox-inner': {
    backgroundColor: `${theme['color-grey-900']} !important`,
  },
  '.ant-checkbox-indeterminate .ant-checkbox-inner:after': {
    content: '""',
    position: 'absolute',
    top: '50%',
    left: '20%',
    width: '60%',
    height: '2px',
    backgroundColor: theme['color-white'],
    transform: 'translateY(-50%)',
  },
  '.ant-pagination-slash': {
    visibility: 'hidden',
  },
  '.ant-pagination-slash:before': {
    visibility: 'visible',
    content: '"of"',
    color: 'inherit',
    marginInlineEnd: 0,
  },
});

const cssBadge = (filtered: boolean) =>
  css`
    .ant-badge-count {
      height: 10px;
      min-width: 10px;
      padding: 0;
      top: 4px;
      right: 4px;
      display: ${filtered ? 'block' : 'none'};
      background: ${dataStageColors['color-brand-blue-500']};

      .ant-scroll-number-only {
        display: none;
      }
    }
  `;

export const cssFilterButtons = (theme: Theme): CSSObject => ({
  gap: 12,
  paddingBlock: 12,
  paddingInline: 24,
  borderTop: `1px solid ${theme['color-grey-400']}`,

  '.ant-space-item': {
    flex: 1,
  },
  '.ant-btn': {
    flex: 1,
  },
});

type CustomColumnType<T> = ColumnType<T> & { customKey?: string };
type CustomColumnGroupType<T> = ColumnGroupType<T> & { customKey?: string };
export type CustomColumnsType<T> = (CustomColumnType<T> | CustomColumnGroupType<T>)[];

export { Table };
